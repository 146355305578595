//  This is a Higher Order Function to be used on input onChange events only!!!
const withDurationMask = handler => event => {
  let { value } = event.target
  const cleanNum = Number(value.replace(':', ''))
  if (Number.isNaN(cleanNum) || cleanNum.toString().length > 4)
    return
  event.target.value = getMaskedValue(cleanNum)
  handler(event)
}

const getMaskedValue = num => {
  const paddedStr = String(num).padStart(4, '0')
  let withSeparator = ''
  for (let i = 0; i < paddedStr.length; i++) {
    if (i === 2) {
      withSeparator = withSeparator.concat(':')
    }
    withSeparator = withSeparator.concat(paddedStr[i])
  }
  return withSeparator
}

export const incrementDuration = value => {
  return getMaskedValue(Number(value.replace(':', '')) + 1)
}

export const decrementDuration = value => {
  return getMaskedValue(Number(value.replace(':', '')) - 1)
}

export default withDurationMask
