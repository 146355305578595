import React from 'react'
import { Link, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { Button } from '@designsystem'
import { PortalModal } from '@components/modal'
import formatDateForOnboarding from '@components/onboarding/utils/formatDateForOnboarding'
import axios from 'axios'

export async function loader({ params }) {
  const res = await axios.get(`/pages/admin/workbench/onboarding/groups/${params.campus_filter}/duplicated-group-modal/${params.onboarding_group_id}`)
  return res.data
}

interface LoaderData {
  title: string,
  startDate: string
}

export default function WorkbenchOnboardingGroupDuplicatedGroupModal$onboardingIdRoute() {
  const params = useParams()
  const navigate = useNavigate()
  const loaderData = useLoaderData() as LoaderData
  return (
    <PortalModal
      isOpen
      className="w-[530px]"
      onClose={() => navigate('..')}
    >
      <div className="flex flex-col p-10 space-y-8">
        <h6 className="font-bold text-xl">You already have an onboarding for {loaderData.title} starting on {formatDateForOnboarding(loaderData.startDate)}.</h6>
        <div className="flex space-x-4 justify-end">
          <Button as={Link} to=".." variant="outlined">Cancel</Button>
          <Button as={Link} to={`../../../${params.onboarding_group_id}/content`}>View Existing Onboarding Group</Button>
        </div>
      </div>
    </PortalModal>
  )
}