import PropTypes from 'prop-types'
import React from 'react'

const AttendanceSquare = ({ value, label }) => {
  return (
    <div className={`border rounded-xl flex flex-col py-2 px-2 col-span-1 lg:col-span-8 xl:col-span-4 text-center ${label === 'present' ? 'border-green-10 bg-green-5' :
      label === 'tardy' ? 'border-yellow-30 bg-yellow-2' :
        label === 'absent' ? 'border-danger-10 bg-danger-2' :
          label === 'excused' ? 'border-cyan-20 bg-cyan-10' : ''
      }`}>
      <span className="text-lg font-bold">{value}%</span>
      <span className="text-sm">{label}</span>
    </div>
  )
}
AttendanceSquare.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
}

const AttendanceMetrics = ({ student, metrics }) => {
  if (!metrics) return null

  const {
    total_attendance_present_percentage,
    total_attendance_tardy_percentage,
    total_attendance_excused_percentage,
    total_attendance_absent_percentage
  } = metrics || {}

  return (
    <>
      {student && <span className="text-lg font-bold leading-none">{student.name}</span>}
      <div className="grid grid-cols-1 lg:grid-cols-16 justify-between w-full gap-3 mb-3">
        <AttendanceSquare label="present" value={total_attendance_present_percentage} />
        <AttendanceSquare label="tardy" value={total_attendance_tardy_percentage} />
        <AttendanceSquare label="absent" value={total_attendance_absent_percentage} />
        <AttendanceSquare label="excused" value={total_attendance_excused_percentage} />
      </div>
    </>
  )
}
AttendanceMetrics.propTypes = {
  student: PropTypes.object,
  metrics: PropTypes.object,
}

export default AttendanceMetrics
