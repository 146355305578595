import useSWR from 'swr'

const useTimelineEvents = (student_id) => {
  const { data, error, mutate } = useSWR(
    `/timeline-event/fetch?student_id=${student_id}`
  )

  return {
    events: data?.events.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    ),
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}
export default useTimelineEvents
