import * as  React from 'react'
import axios from 'axios'
import { Tab } from '@headlessui/react'
import { Link, Navigate, Outlet, useActionData, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import formatDateForOnboarding from '@components/onboarding/utils/formatDateForOnboarding'
import OnboardingDatePickerInput, { useDatePicker } from '@components/onboarding/date-picker-input'
import { unstable_Popover as Popover, Button, Icon, useFetcher, Typography, Switch } from '@design-system'

interface LoaderData {
  title: string,
  dueDate: string,
  blocked: boolean,
  releaseDate: string,
  isLockedByPreviousItems: boolean,
  isLockingNextItems: boolean,
  visibleFor: Array<{ label: string, value: string }>
}

type ActionData = {
  redirectUrl: string,
  toast: { message: string, appearance: 'success' | 'error' }
} | undefined

async function loader({ params }) {
  const result = await axios.get(`/backoffice/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}`)
  return result.data
}

async function action({ request, params }) {
  const formData = serializeFormData(await request.formData())
  try {
    const result = await axios.post(`/backoffice/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}`, formData)
    return result.data
  } catch (error) {
    return error.response.data || {
      toast: {
        message: 'Something went wrong. Please try again later.',
        appearance: 'error',
      }
    }
  }
}

function Element() {
  const params = useParams()
  const fetcher = useFetcher()
  const navigate = useNavigate()
  const loaderData = useLoaderData() as LoaderData
  const actionData = useActionData() as ActionData

  React.useEffect(() => {
    if (actionData?.redirectUrl) {
      navigate(actionData.redirectUrl)
    }
  }, [actionData?.redirectUrl])


  if (!params.user_view_type || !loaderData.visibleFor.map(({ value }) => value).includes(params.user_view_type)) {
    return <Navigate to={`./${loaderData.visibleFor[0].value}`} />
  }

  return (
    <div className="flex flex-col space-y-10 mb-6 mx-6">
      <div className="flex justify-between">
        <div className="flex flex-col space-y-2">
          <Typography variant="heading-1" weight="bold">{loaderData.title}</Typography>
          <small>{formatDateForOnboarding(loaderData.dueDate)}</small>
        </div>
        <Popover>
          <Popover.Trigger onClick={(e) => e.stopPropagation()}>
            <Button asChild variant="ghost">
              <Icon name="more-horiz-filled" size="lg"></Icon>
            </Button>
          </Popover.Trigger>
          <Popover.Content className="flex flex-col gap-1" align="center">
            <Link to={`./${params.user_view_type}/edit`} className="py-1 hover:font-bold w-24 bg-white">
              Edit Checklist
            </Link>
            <fetcher.Form method="post" className="py-1 hover:font-bold hover:text-danger-40 w-24 bg-white">
              <button
                type="submit"
                value={params.checklist_id}
                name="id"
              >
                Delete checklist
              </button>
            </fetcher.Form>
          </Popover.Content>
        </Popover>
      </div>
      <div className="flex space-x-6">
        <FormDatePicker name="due_date" label="Due date" defaultValue={loaderData.dueDate} />
        <FormDatePicker name="release_date" label="Release date" defaultValue={loaderData.releaseDate} minDate={new Date()} />
        <OnboardingChecklistSwitchForm name="is_locking_next_items" defaultValue={loaderData.isLockingNextItems} label="Is Locking Next" />
        <OnboardingChecklistSwitchForm name="is_locked_by_previous_items" defaultValue={loaderData.isLockedByPreviousItems} label="Is Locked by Previous" />
      </div>
      <Tab.Group>
        <Tab.List className="flex space-x-10 border-b border-b-gray-30 w-full">
          {loaderData.visibleFor.map((tabItem) => (
            <Tab
              as={Link}
              to={`./${tabItem.value}`}
              key={tabItem.value}
              className={`py-4 ${params.user_view_type === tabItem.value ? 'border-b-2 border-accent ' : ''}`}
            >
              {tabItem.label}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
      <Outlet />
    </div>
  )
}

interface FormDatePickerProps {
  name: string,
  label: string,
  defaultValue?: string,
  minDate?: Date,
}

function FormDatePicker({ name, label, defaultValue, minDate }: FormDatePickerProps) {
  const fetcher = useFetcher()
  const [date, setDate] = useDatePicker(defaultValue)

  const handleSubmitForm = (newDate: string) => {
    fetcher.submit({ [name]: newDate, action: 'updateChecklist' }, { method: 'post' })
  }

  return (
    <fetcher.Form method="post">
      <OnboardingDatePickerInput
        onFormSubmit={handleSubmitForm}
        date={date}
        setDate={setDate}
        name={name}
        label={label}
        minDate={minDate}
        defaultValue={defaultValue}
      />
    </fetcher.Form>
  )
}

interface OnboardingChecklistSwitchFormProps {
  name: string,
  label: string,
  defaultValue: boolean,
}

function OnboardingChecklistSwitchForm({ name, label, defaultValue }: OnboardingChecklistSwitchFormProps) {
  const fetcher = useFetcher()
  const [checked, setChecked] = React.useState(defaultValue)
  
  return (
    <fetcher.Form method="post" className="flex flex-col gap-5">
      <input type="hidden" name="action" value="updateChecklist" />
      <input type="hidden" name={name} value={String(checked)} />
      <Typography variant="body">{label}</Typography>
      <Switch checked={defaultValue} onCheckedChange={() => setChecked((prevCheck) => !prevCheck)} type="submit"  />
    </fetcher.Form>
  )
}

export const OnboardingChecklistIdRoute = { Element, loader, action }