/* eslint-disable react/prop-types */
import React from 'react'
import Modal from '../modal'
import MasteryBar from '../mastery-bar'

const MasteryModal = ({ modalOpen, setModalOpen, school_stage = 'hs' }) => {
  return (
    <Modal
      className="w-[800px]"
      header="Introducing Unit Mastery"
      isOpen={modalOpen}
      onClickClose={() => setModalOpen(false)}
    >
      {school_stage === 'hs' &&
        <>
          <p className="text-sm text-gray-50">
            Your Unit Mastery Level represents the progress you've made on this
            Unit. Gaining Exposure (Mastery Level 1) will provide you with
            foundational knowledge to graduate, and knowing where to dive deeper
            into your interest. Higher levels of mastery in the Unit are evidence
            that you have demonstrated mastery of the Unit. You can re-visit a
            Unit to gain higher mastery levels at any time during your Sora
            Journey.
          </p>
          <div className="divide-y mt-4">
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={0} />
              </div>
              <div className="col-span-3">
                <h3>0 Not Started</h3>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={0.5} />
              </div>
              <div className="col-span-3">
                <h3>0.5 Attempted</h3>
                <p className="text-sm text-gray-50">
                  You did attempt this unit, but it didn't meet the criteria for
                  exposure. You'll need to practice this one some more before
                  you've met the baseline criteria.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={1} />
              </div>
              <div className="col-span-3">
                <h3>1 Exposure</h3>
                <div className="text-sm text-gray-50">
                  <p>
                    I can answer the question “What is…?” about the Unit.
                  </p>
                  <p>
                    I have
                    demonstrated a basic understanding of the Unit in the
                    genre/format of my choice, including but not limited to:
                    Informal writing, Discussion, Graphic representation, Video
                    presentation, Following a procedure, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={2} />
              </div>
              <div className="col-span-3">
                <h3>2 Developing</h3>
                <p className="text-sm text-gray-50">
                  I can answer questions related to “Why...?” and/or “How...?”
                  about the Unit.
                </p>
                <p className="text-sm font-bold text-gray-50 mt-2">
                  Some examples include:
                </p>
                <ul className="text-sm text-gray-50 list-disc list-inside">
                  <li>
                    Why is X the best choice? Why did Y happen in the way it did?
                    How do I know Z is true? How does A work? Why is it useful to
                    know about B? How do people use C effectively?
                  </li>
                  <li>
                    I have demonstrated my understanding of the Unit in the
                    genre/format of my choice, including but not limited to:
                    Writing, Discussion, Graphic representation, Video
                    presentation, Problem solving, etc.
                  </li>
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={3} />
              </div>
              <div className="col-span-3">
                <h3>3 Demonstrating</h3>
                <p className="text-sm text-gray-50">
                  I have created polished, original work in the genre/format of my
                  choice that includes both my understanding of “What?” AND “Why?”
                  and/or “How?”about the Unit and also my own original insight
                  and/or application.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={4} />
              </div>
              <div className="col-span-3">
                <h3>4 Extending</h3>
                <p className="text-sm text-gray-50">
                  My work demonstrates insight and/or originality and/or utility
                  that is above and beyond even the highest expectation of high
                  school-level work AND/OR I have demonstrated exceptional mastery
                  of the Unit by filling the role of Expert and teaching it to
                  others.
                </p>
              </div>
            </div>
          </div>
        </>
      }

      {school_stage === 'ms' &&
        <>
          <p className="text-sm text-gray-50">
            Your Unit Mastery Level represents the progress you've made on this
            Unit. Reaching Mastery Level 1 will provide you with
            foundational knowledge, and help you to know where to dive deeper
            based on your interests. Higher levels of mastery in the Unit are evidence
            that you have demonstrated mastery of the Unit. You can re-visit a
            Unit to gain higher mastery levels at any time during your Sora
            Journey.
          </p>
          <div className="divide-y mt-4">
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={0} />
              </div>
              <div className="col-span-3">
                <h3>0 Not Started</h3>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={0.5} />
              </div>
              <div className="col-span-3">
                <h3>0.5 Attempted</h3>
                <p className="text-sm text-gray-50">
                  You did attempt this unit, but it didn't meet the criteria for
                  exposure. You'll need to practice this one some more before
                  you've met the baseline criteria.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={1} />
              </div>
              <div className="col-span-3">
                <h3>1 Exposure</h3>
                <div className="text-sm text-gray-50">
                  <p className="text-sm text-gray-50">My work demonstrates that I have acquired a basic understanding of the unit.</p>
                  <p className="text-sm text-gray-50 mt-2">I can provide definitions of relevant terminology and concepts for the unit.</p>
                  <p className="text-sm text-gray-50 mt-2">I can summarize or paraphrase information.</p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={2} />
              </div>
              <div className="col-span-3">
                <h3>2 Developing</h3>
                <p className="text-sm text-gray-50">
                  My work demonstrates that I can <strong>make meaning</strong> of the unit content.
                </p>
                <p className="text-sm text-gray-50 mt-2">
                  I can <strong>analyze</strong> unit topics, <strong>explain</strong> my analysis, and <strong>make connections</strong> between topics within the unit.
                </p>
                <p className="text-sm text-gray-50 mt-2">
                  I can <strong>apply my previous knowledge</strong> to my understanding of this unit.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={3} />
              </div>
              <div className="col-span-3">
                <h3>3 Demonstrating</h3>
                <p className="text-sm text-gray-50">
                  My work demonstrates that I can <strong>transfer</strong> my understanding to <strong>new scenarios</strong>.
                </p>
                <p className="text-sm text-gray-50 mt-2">
                  I can <strong>use and apply</strong> my understanding of the unit to <strong>create something new</strong>, reflected in <strong>solutions, performance and innovation</strong>.
                </p>
                <p className="text-sm text-gray-50 mt-2">
                  I can <strong>explain in detail</strong> the <strong>connections</strong> between topics in this unit and others outside the scope of this learning experience.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 gap-x-5">
              <div className="col-span-1">
                <MasteryBar level={4} />
              </div>
              <div className="col-span-3">
                <h3>4 Extending</h3>
                <p className="text-sm text-gray-50">
                  My work demonstrates a level of understanding <strong>beyond the highest expectation of middle school work</strong>.
                </p>
                <p className="text-sm text-gray-50 mt-2">
                  I can create <strong>polished, original work</strong> that shows an <strong>extremely deep understanding</strong> of the unit.
                </p>
                <p className="text-sm text-gray-50 mt-2">
                  I can <strong>effectively teach</strong> others to a <strong>proficient level</strong> of understanding for this unit.
                </p>
              </div>
            </div>
          </div>
        </>
      }

    </Modal >
  )
}

export default MasteryModal
