import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import useFeatureFlag from '@hooks/useFeatureFlag'
import { Popover, Listbox, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faLayerGroup, faTrash } from '@fortawesome/free-solid-svg-icons'
import { usePopper } from 'react-popper'
import useTestGroups from '@hooks/useTestGroups'
import { Button, TextField } from '@designsystem'
import { Icon } from '@design-system'
import { Link } from 'react-router-dom'

export default function Features() {
  const { feature_flags, addGroup, removeGroup, changeStatus } = useFeatureFlag()
  const { test_groups } = useTestGroups()
  return (
    <div>
      <h1 className='font-bold text-2xl'>Features</h1>
      <table className="w-full">
        <thead className="text-left">
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th></th>
            <th>Status</th>
            <th className="text-center">Groups</th>
          </tr>
        </thead>
        <tbody>
          {feature_flags.map(flag => (
            <tr key={flag.id} className='even:bg-gray-20 hover:bg-blue-5'>
              <td className='font-bold'>{flag.title}</td>
              <td>{flag.description}</td>
              <td className={`w-32 ${flag.is_in_sora_labs ? 'text-green-50' : 'text-gray-50'}`}>
                <Link to={`./${flag.id}/sora-labs-settings`}>
                  <legend className="font-bold px-4 flex flex-row justify-between">
                    <Icon
                      name={flag.is_in_sora_labs ? "eye" : "eye-slash"}
                      size="sm"
                    />
                    <span className="text-sm font-bold">Sora Labs</span>
                  </legend>
                </Link>
              </td>
              <td className='w-[130px] py-1'>
                <FlagStatus
                  flagId={flag.id}
                  selectedKey={flag.status}
                  onChange={changeStatus}
                />
              </td>
              <td className="text-center">
                <TestGroupList
                  flagId={flag.id}
                  selectedTestGroups={flag.test_groups}
                  testGroups={test_groups}
                  onAdd={addGroup}
                  onRemove={removeGroup}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const TestGroupList = ({ flagId, selectedTestGroups = [], testGroups = [], onAdd, onRemove }) => {
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  const selectedGroups = selectedTestGroups.map(group => (
    <li key={`group_${flagId}_${group.id}`}>
      <Button onClick={onRemove(flagId, group.id)} startIcon={faTrash} size="xs" variant="nude" />
      {group.title}
    </li>
  ))

  const availableGroups = testGroups.filter(g => !selectedTestGroups.some(stg => stg.id === g.id))

  return (
    <Popover className="relative">
      <Popover.Button ref={setReferenceElement}>
        <Button startIcon={faLayerGroup} variant="nude" />
      </Popover.Button>
      <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
      <Popover.Panel
        className="absolute z-10 w-80 text-left"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className='bg-white shadow border rounded py-2 mx-2'>
          <ul className='flex flex-col w-full '>
            {selectedGroups}
            <li className='p-2'>
              <Listbox onChange={(groupId) => onAdd(flagId, groupId)} >
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default border border-blue-70 rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-blue-70 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-10 sm:text-sm">
                    <span className="block truncate">Add More</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <FontAwesomeIcon icon={faAngleDown} className='text-blue-70' />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {availableGroups.map((option, index) => (
                        <Listbox.Option
                          key={`group_${flagId}_${option.id}_${index}`}
                          className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-90'}`}
                          value={option.id}
                        >
                          {option.title}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </li>
          </ul>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

TestGroupList.propTypes = {
  flagId: PropTypes.any,
  selectedTestGroups: PropTypes.array,
  testGroups: PropTypes.array,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
}

const FlagStatus = ({ flagId, selectedKey, onChange }) => (
  <TextField as="select" defaultValue={selectedKey} onChange={e => onChange(flagId, e.target.value)}>
    <option value='off'>Off</option>
    <option value='on'>On</option>
    <option value='released'>Released</option>
  </TextField>
)

FlagStatus.propTypes = {
  flagId: PropTypes.any,
  selectedKey: PropTypes.string,
  onChange: PropTypes.func
}
