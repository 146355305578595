import React from 'react'
import { Typography, Accordion } from '@design-system'

function Element() {
  return (
    <>
      <Accordion disabledScroll type="single" collapsible variant="inset">
        <Accordion.RootHeader>
          <Typography variant="heading-6" weight="bold">Mentorship/Capstone</Typography>
        </Accordion.RootHeader>
        <Accordion.Item value="mentorship">
          <Accordion.Header>
            <Accordion.Trigger>
              <Accordion.Icon />
              <Typography weight="bold">Mentorship</Typography>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <Typography className="max-w-prose">
              During the 11th-grade year of high school at Sora Schools, students participate in the Mentorship Program. In this program, they are supported in selecting a potential career field of interest, identifying a mentor in the chosen field from their local community, and planning a Capstone Project that they will expand on in 12th grade.
            </Typography>
          </Accordion.Content>
        </Accordion.Item>
        <Accordion.Item value="capstone">
          <Accordion.Header>
            <Accordion.Trigger>
              <Accordion.Icon />
              <Typography weight="bold">Capstone</Typography>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <Typography className="max-w-prose">
              In the 12th-grade year at Sora Schools, students engage in a Capstone Project, allowing them to further explore an issue or topic within their field of interest. Students must also contribute to their local community. The final deliverable can tag units and abilities that a student still needs as long as they relate to their project. The Capstone Project will also reflect their time at Sora Schools, culminating in a showcase presentation before graduation.
            </Typography>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </>
  )
}

export const EmployeeStudentProgramMentorshipCapstoneRoute = {
  Element,
}


