import React from 'react'
import axios from 'axios'
import { Disclosure, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link, Outlet, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { Button } from '@designsystem';
import formatDateForOnboarding from '@components/onboarding/utils/formatDateForOnboarding'

interface Checklist {
  id: number,
  title: string,
  dueDate: string,
  blocked: boolean,
  releaseDate: string | null,
  visibleFor: 'guardian' | 'student' | 'both',
}

interface Phase {
  id: number,
  title: string,
  blocked: boolean,
  checklists: Array<Checklist>
}

interface LoaderData {
  title: string,
  schoolStartDate: string,
  phases: Array<Phase>
}

export async function loader({ params }) {
  const result = await axios.get(`/pages/admin/workbench/onboarding/${params.onboarding_group_id}/content`)
  return result.data
}

export default function WorkbenchOnboardingCampus$OnboardingGroupIdContentRoute() {
  const { phases: content, ...headerData } = useLoaderData() as LoaderData
  const { checklist_id: checklistId, user_view_type: userViewType } = useParams()
  const navigate = useNavigate()

  const handleNavigateToChecklistModal = (checklistId: string) => {
    if (checklistId) {
      return navigate(`./checklist/${checklistId}/${userViewType}/new`)
    }
    return navigate('./checklist/new')
  }

  return (
    <main className="grid grid-cols-2 gap-0">
      <div className="flex flex-col space-y-8 justify-start pr-6 border-r border-r-gray-30">
        <div className="flex flex-col">
          <div className="flex space-x-1 items-center">
            <Button as={Link} role="link" to="../onboarding/groups/0" startIcon={faChevronLeft} variant="nude" tooltip="Back" />
            <h1 className="font-bold text-4xl">{headerData.title}</h1>
          </div>
          <small>{`School Start Date ${formatDateForOnboarding(headerData.schoolStartDate)}`}</small>
        </div>
        <span className="self-start">
          <Button onClick={() => handleNavigateToChecklistModal(checklistId)}>Create checklist</Button>
        </span>
        {content.map((phase) => (
          <Disclosure key={phase.id} defaultOpen>
            {({ open }) => (
              <div>
                <Disclosure.Button className="flex w-full items-center justify-between">
                  <div className="flex justify-start items-center space-x-2">
                    <h3 className="font-bold text-lg">
                      {phase.title}
                    </h3>
                    <small>{`${phase.checklists.length} checklists`}</small>
                  </div>
                  <FontAwesomeIcon icon={faChevronDown} className={`${open ? 'transform -rotate-180' : ''}`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="space-y-2 mt-8">
                    {phase.checklists.map((checklist) => (
                      <Link
                        key={`checklist-${checklist.id}`}
                        to={`./checklist/${checklist.id}/${checklist.visibleFor === 'guardian' ? 'guardian' : 'student'}`}
                        className={`flex py-6 px-4 justify-between bg-white rounded-lg ${Number(checklistId) === checklist.id ? 'border-gray-50 border-2' : 'border border-gray-20'}`}
                      >
                        <div className="flex flex-col space-y-2">
                          <h4 className="font-bold">{checklist.title}</h4>
                          <div className="flex space-x-2 items-center">
                            <small>{new Date(checklist.dueDate).toLocaleDateString([], { month: 'short', day: 'numeric', weekday: 'long' })}</small>
                            <ChecklistReleaseStatusTag blocked={checklist.blocked} releaseDate={checklist.releaseDate} />
                          </div>
                        </div>
                        <ChecklistVisibleToText visibleTo={checklist.visibleFor} />
                      </Link>
                    ))}
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
        ))}
      </div>
      <Outlet />
    </main>
  )
}

interface ChecklistReleaseStatusTagProps {
  blocked: boolean,
  releaseDate: string | null,
}

const pickText = (blocked: boolean, releaseDate: string | null) => {
  if (blocked && releaseDate) {
    return `Release date: ${new Date(releaseDate).toLocaleDateString([], { month: '2-digit', day: '2-digit', year: 'numeric' })}`
  }
  return blocked
    ? 'No release date'
    : 'Released'
}

function ChecklistReleaseStatusTag({ blocked, releaseDate }: ChecklistReleaseStatusTagProps) {
  const color = blocked ? 'bg-gray-20' : 'bg-success-20'
  const text = pickText(blocked, releaseDate)
  return (
    <small className={`font-bold px-2 py-1 rounded-md ${color}`}>{text}</small>
  )
}

interface ChecklistVisibleToTextProps {
  visibleTo: 'guardian' | 'student' | 'both'
}

function ChecklistVisibleToText({ visibleTo }: ChecklistVisibleToTextProps) {
  const text = visibleTo === 'both' ? 'Students | Guardians' : `${visibleTo}s`
  return <small className="self-center capitalize">{text}</small>
}
