import useSWR from 'swr'

/**
 * Imitating a "fetcher" in remix for when we migrate to remix by writing some hooks directly tied to this page
 * Hopefully when we migrate totally to remix, it will be easy to copy these over.
 */
const useGradProgress = ({ student_id, cycle_id } = {}) => {
  const { data, mutate, error } = useSWR(
    student_id
      ? `/pages/graduation_progress/progress?student_id=${student_id}&cycle_id=${cycle_id}`
      : '/units'
  )

  return {
    mutate,
    currentGPACalculationAt: data?.currentGPACalculationAt,
    nextGPACalculationAt: data?.nextGPACalculationAt,
    gpa: data?.gpa || '-',
    overallProgress: (data?.overallProgress || 0) + '%',
    expectedGraduation: data?.expectedGraduation,
    isProgramValid: data?.isProgramValid ? 'Yes' : 'No',
    programValidnessMessages: data?.programValidnessMessages || [],
    isLoading: !error && !data,
    isError: error,
  }
}

export default useGradProgress

export const filterUnit = (filter, unit) => {
  const q = filter && filter.split('$')
  if (q && q.length > 1) return unit.title.toLowerCase().includes(q[1].toLowerCase())
  if (filter === 'all') return true
  if (filter === 'on_program') return unit.is_in_program
  if (filter === 'off_program') return !unit.is_in_program
  if (filter === 'exempt') return unit.is_exempt
  if (unit.is_exempt) return filter === 'exempt'

  const level = parseFloat(unit.level)
  return {
    extending: level >= 4,
    demonstrating: level >= 3 && level < 4,
    developing: level >= 2 && level < 3,
    exposure: level >= 1 && level < 2,
    attempted: level >= 0.5 && level < 1,
    not_started: level === 0 && unit.is_in_program,
    not_completed: level < 1 && unit.is_in_program,
    completed: level >= 1 && unit.is_in_program,
  }[filter] ?? true
}
