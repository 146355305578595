import React from 'react'
import PropTypes from 'prop-types'

export const SchoolStageTag = ({ schoolStage }) => {
  const isMidSchool = schoolStage === 'ms'
  const title = isMidSchool ? 'Middle School' : 'High School'
  return (
    <span className={`rounded border p-1 font-bold ${isMidSchool ? 'border-cyan-600 text-cyan-600' : 'border-purple-600 text-purple-600'}`}>
      {title}
    </span>
  )
}
SchoolStageTag.propTypes = {
  schoolStage: PropTypes.string,
}
