import React from 'react'
import PropTypes from 'prop-types'

const DIRECTION_HORIZONTAL = 'horizontal'
const DIRECTION_VERTICAL = 'vertical'

const WRAPPER_DIRECTION_CLASSES = {
  [DIRECTION_HORIZONTAL]: 'flex-row-reverse gap-2 justify-end items-center',
  [DIRECTION_VERTICAL]: 'flex-col gap-4',
}

const VALUE_DIRECTION_CLASSES = {
  [DIRECTION_HORIZONTAL]: 'text-2xl',
  [DIRECTION_VERTICAL]: 'text-5xl',
}

const UNIT_DIRECTION_CLASSES = {
  [DIRECTION_HORIZONTAL]: 'text-2xl leading-none font-bold',
  [DIRECTION_VERTICAL]: 'text-xl',
}

const POSITIVE = true
const NEGATIVE = false

const COLOR_CLASSES = {
  [POSITIVE]: 'text-green-50',
  [NEGATIVE]: 'text-danger-60'
}

const MetricInnerButton = ({ label, value, positiveValue, unitValue, direction }) => (
  <div className={`font-normal text-left w-full flex ${WRAPPER_DIRECTION_CLASSES[direction]}`}>
    <h1>{label}</h1>
    <p className='flex items-start'>
      <span className={`font-bold leading-none ${VALUE_DIRECTION_CLASSES[direction]} ${COLOR_CLASSES[positiveValue]}`}>{value}</span>
      {unitValue && <span className={`${UNIT_DIRECTION_CLASSES[direction]} ${COLOR_CLASSES[positiveValue]}}`}>{unitValue}</span>}
    </p>
  </div>
)

MetricInnerButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  positiveValue: PropTypes.bool,
  unitValue: PropTypes.string,
  direction: PropTypes.oneOf([DIRECTION_VERTICAL, DIRECTION_HORIZONTAL]),
}

MetricInnerButton.defaultProps = {
  positiveValue: false,
  unitValue: null,
  active: false,
  direction: DIRECTION_VERTICAL,
}

export default MetricInnerButton
