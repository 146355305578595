import { useEffect } from 'react'
import { toast } from 'sonner'

type Toast = {
  toast?: {
    appearance?: 'success' | 'error'
    message: string
    action?: { label: string | React.ReactNode, onClick: () => void }
  }
}
/**
 * @param actionData - An object that contains a toast object with a message and appearance.
 * @description - A custom hook that runs after receiving a toast object as a parameter and displays the toast message.
 * This hook is usually used in Routes which return toasts after an action is performed. This means the actionData can be a result of a route that contains a `useActionData()` or `useFetcher` hook.
 * @example useToast(actionData)
 */
export function useToast<T extends Toast>(actionData: T) {
  useEffect(() => {
    if (actionData?.toast) {
      const toastFunc = ['error', 'success'].includes(actionData.toast.appearance) ? toast[actionData.toast.appearance] : toast
      toastFunc(actionData.toast.message, { action: actionData.toast.action })
    }
  }, [actionData?.toast])
}
