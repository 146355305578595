import React from 'react'
import PropTypes from 'prop-types'
export const UnitList = ({ units = [] }) => {

  return (
    <div className='flex flex-col'>
      {units.length === 0 && <span className='text-alpha/40'>No units required </span>}
      {units.length > 0 && 
        <ul>
          {units.map(unit => (
            <li key={unit.id} >
              <span>[{unit.topic_title}] {unit.title}</span>
            </li>
          ))}
        </ul>
      }
    </div>
  )
}
UnitList.propTypes = {
  units: PropTypes.array,
}
