import PropTypes from 'prop-types'
import useQuery from '@hooks/useQuery'
import React, { useRef, useState } from 'react'

const SearchBar = ({ placeholder, ...rest }) => {
  const [queryParams, setQueryParams] = useQuery()
  const querySearch = queryParams.search || ''
  const [searchTerm, setSearchTerm] = useState(querySearch)
  const searchTimeoutRef = useRef(null)

  const handleSearch = e => {
    const { value } = e.target
    setSearchTerm(value)
    if (searchTimeoutRef.current)
      clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      setQueryParams({
        ...queryParams,
        search: value
      })
      searchTimeoutRef.current = null
    }, 500)
  }

  return <input type="text" placeholder={placeholder} value={searchTerm} onChange={handleSearch} {...rest} />
}

SearchBar.propTypes = {
  placeholder: PropTypes.any
}

export const useSearchTerm = () => {
  const [queryParams, setQueryParams] = useQuery()
  const searchTerm = queryParams?.search?.trim()

  const clear = () => {
    setQueryParams({
      ...queryParams,
      search: null
    })
  }

  return { searchTerm, clear }
}

export default SearchBar
