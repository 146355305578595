export enum Labels {
  ontime = "Task On-Time",
  attendance = "Attendance",
  flagged = "Flagged Tasks",
  missed = "Missed Tasks"
}

export enum Keys {
  flagged = "flagged",
  missed = "missed"
}