import Transition from '../transition'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Button } from '@designsystem'
import { faClose } from '@fortawesome/free-solid-svg-icons'

export const PortalModal = ({
  isOpen,
  onClose,
  children,
  className = '',
  containerClass = 'modal-root',
  el = 'div',
}) => {
  const [container] = useState(document.createElement(el))
  container.classList.add(containerClass)

  useEffect(() => {
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'inherit'
    }
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [isOpen])

  return (
    <>
      {ReactDOM.createPortal(
        <div
          data-cy="portal-modal"
          aria-modal="true"
          className={`
          pt-10 px-5 lg:pt-20 transition-all flex items-start
          justify-center fixed left-0 bottom-0 top-0 right-0 overflow-y-scroll z-60 bg-alpha/80 bg-opacity-75 
          ${!isOpen && 'hidden'}
        `}
          onClick={() => onClose()}
        >
          <div className={`max-w-full opacity-1 ${className}`} onClick={(e) => e.stopPropagation()}>
            <Transition
              show={isOpen}
              enter="transition-all transform duration-200"
              enterFrom="opacity-0 translate-y-20"
              enterTo="opacity-100"
              leave="transition-all transform duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={'h-full bg-white rounded-lg relative mb-10'}>
                <div className="absolute top-2 right-4">
                  <Button startIcon={faClose} onClick={() => onClose()} variant="nude" />
                </div>
                {children}
              </div>
            </Transition>
          </div>
        </div>,
        container
      )}
    </>
  )
}

PortalModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  containerClass: PropTypes.string,
  el: PropTypes.string,
}

function Modal({ header, isOpen, onClickClose, className = '', children }) {
  return (
    <div
      aria-modal="true"
      className={`
    transition-all flex items-start justify-center fixed top-0 left-0 right-0 bottom-0 
    overflow-y-scroll z-50 bg-black bg-opacity-75 
    ${!isOpen && 'hidden'}
  `}
      onClick={onClickClose}
    >
      <div className={`py-4 px-2 ${className}`} onClick={(e) => e.stopPropagation()}>
        <div className="h-full bg-white dark:bg-gray-100 rounded-lg p-4 relative">
          <div className="w-min ml-auto">
            <Button onClick={onClickClose} startIcon={faClose} tooltip="Close" variant="nude" />
          </div>
          {header && <div className="flex items-center">{header}</div>}
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClickClose: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

Modal.defaultProps = {
  height: null,
  width: null,
  isOpen: false,
}
export default Modal
