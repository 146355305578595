import React, { ComponentProps, ReactNode } from 'react'
import { Pill } from '@design-system'

export type PillUnitStudentProgramVariant = 'exempt' | 'legacy-exempt' | 'off-program'

interface PillAllowedProps extends Pick<ComponentProps<typeof Pill>, 'variant' | 'color'> {}

interface VariantProps extends PillAllowedProps {
  childrenFn: (props: Omit<PillUnitStudentProgramProps, 'variant'>) => ReactNode
}

const defaultProps: VariantProps = {
  childrenFn: ({ variant }: PillUnitStudentProgramProps) => <Pill.Value>{variant}</Pill.Value>,
}

const variants: Record<PillUnitStudentProgramVariant, VariantProps> = {
  exempt: {
    color: 'success-40',
    childrenFn: () => <Pill.Value>Exempt</Pill.Value>,
  },
  'legacy-exempt': {
    color: 'success-40',
    childrenFn: () => <Pill.Value>Legacy Exempt</Pill.Value>,
  },

  'off-program': {
    childrenFn: () => <Pill.Value>Off-Program</Pill.Value>,
    color: 'danger-10',
  },
}

interface PillUnitStudentProgramProps {
  variant: PillUnitStudentProgramVariant
}

/**
 * PillUnitStudentProgram display a pill  with unit program status
 */
export const PillUnitStudentProgram = (props: PillUnitStudentProgramProps) => {
  const { childrenFn, ...pillProps } = variants[props.variant] ?? defaultProps
  return <Pill {...pillProps}>{childrenFn({ ...props })}</Pill>
}
