import React from 'react'
import PropTypes from 'prop-types'
import MasteryBar from '@components/mastery-bar'
import useSWR from 'swr'

const UnitRubric = ({ id }) => {
  const { data } = useSWR(id ? `/units/${id}` : null)
  if (!data) return <span>Loading...</span>

  return (
    <div className='flex flex-row gap-8'>
      <div>
        <div className='px-6 py-4 bg-gray-20 dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg'>Unit</div>
      </div>
      <div className='flex-auto flex flex-col gap-4'>
        <div className='flex flex-col gap-2 pb-4 border-b border-b-gray-30'>
          <span className='font-bold text-md'>
            {data.unit?.title}
            <span className="rounded bg-gray-100 uppercase py-1 px-2 ml-2 float-right">
              {data.unit?.school_stage} unit
            </span>
          </span>
          <p className='text-xs text-white'>
            {data.unit?.description}
          </p>
        </div>
        {data.unit?.objectives.length > 0 &&
          <div className='flex flex-row gap-10 pb-4 border-b border-b-gray-30'>
            <div>
              <span className='font-bold'>Objectives</span>
            </div>
            <ul className='flex-auto flex flex-col gap-3 text-sm list-none'>
              {data.unit.objectives.map((obj, index) => <li key={`unit:obj:${index}`}>{obj}</li>)}
            </ul>
          </div>
        }

        {data.unit?.school_stage === 'hs' && <div className='flex flex-col'>
          <span className='font-bold pb-4'>Mastery Rubric</span>
          <ul className='flex flex-col gap-3'>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={0} />
              </div>
              <div className='flex-auto flex flex-col'>
                <span className='font-bold'>0 Not Started</span>
                <p className='text-xs'></p>
              </div>
            </li>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={0.5} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>0.5 Attempted</span>
                <p className='text-xs'>You did attempt this unit, but it didn't meet the criteria for exposure. You'll need to practice this one some more before you've meet the baseline criteria.</p>
              </div>
            </li>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={1} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>1 Exposure</span>
                <p className='text-xs'>I can answer the question “What is…?” about the Unit I have demonstrated a basic understanding of the Unit in the genre/format of my choice, including but not limited to: Informal writing, Discussion, Graphic representation, Video presentation, Following a procedure, etc.</p>
              </div>
            </li>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={2} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>2 Developing</span>
                <div className='text-xs'>
                  <p>I can answer questions related to “Why...?” and/or “How...?” about the Unit.</p>
                  <br />
                  <p className='font-bold'>Some examples include:</p>
                  <ul className='list-circle pl-8'>
                    <li>Why is X the best choice? Why did Y happen in the way it did? How do I know Z is true? How does A work? Why is it useful to know about B? How do people use C effectively?</li>
                    <li>I have demonstrated my understanding of the Unit in the genre/format of my choice, including but not limited to: Writing, Discussion, Graphic representation, Video presentation, Problem solving, etc.</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={3} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>3 Demonstrating</span>
                <p className='text-xs'>I have created polished, original work in the genre/format of my choice that includes both my understanding of “What?” AND “Why?” and/or “How?”about the Unit and also my own original insight and/or application.</p>
              </div>
            </li>
            <li className='flex flex-row gap-6 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={4} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>4 Extending</span>
                <p className='text-xs'>My work demonstrates insight and/or originality and/or utility that is above and beyond even the highest expectation of high school-level work AND/OR I have demonstrated exceptional mastery of the Unit by filling the role of Expert and teaching it to others.</p>
              </div>
            </li>
          </ul>
        </div>}

        {data.unit?.school_stage === 'ms' && <div className='flex flex-col'>
          <span className='font-bold pb-4'>Mastery Rubric</span>
          <ul className='flex flex-col gap-3'>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={0} />
              </div>
              <div className='flex-auto flex flex-col'>
                <span className='font-bold'>0 Not Started</span>
                <p className='text-xs'></p>
              </div>
            </li>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={0.5} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>0.5 Attempted</span>
                <p className="text-xs">
                  You did attempt this unit, but it didn't meet the criteria for
                  exposure. You'll need to practice this one some more before
                  you've met the baseline criteria.
                </p>
              </div>
            </li>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={1} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>1 Exposure</span>
                <div className="text-xs">
                  <p className="text-sm">My work demonstrates that I have acquired a basic understanding of the unit.</p>
                  <p className="text-sm">I can provide definitions of relevant terminology and concepts for the unit.</p>
                  <p className="text-sm">I can summarize or paraphrase information.</p>
                </div>
              </div>
            </li>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={2} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>2 Developing</span>
                <div className='text-xs'>
                  <p>
                    My work demonstrates that I can <strong>make meaning</strong> of the unit content.
                  </p>
                  <p>
                    I can <strong>analyze</strong> unit topics, <strong>explain</strong> my analysis, and <strong>make connections</strong> between topics within the unit.
                  </p>
                  <p>
                    I can <strong>apply my previous knowledge</strong> to my understanding of this unit.
                  </p>
                </div>
              </div>
            </li>
            <li className='flex flex-row gap-6 border-b border-b-gray-30 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={3} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>3 Demonstrating</span>
                <div className='text-xs'>
                  <p>
                    My work demonstrates that I can <strong>transfer</strong> my understanding to <strong>new scenarios</strong>.
                  </p>
                  <p >
                    I can <strong>use and apply</strong> my understanding of the unit to <strong>create something new</strong>, reflected in <strong>solutions, performance and innovation</strong>.
                  </p>
                  <p>
                    I can <strong>explain in detail</strong> the <strong>connections</strong> between topics in this unit and others outside the scope of this learning experience.
                  </p>
                </div>
              </div>
            </li>
            <li className='flex flex-row gap-6 pb-3'>
              <div className='self-start p-1'>
                <MasteryBar level={4} />
              </div>
              <div className='flex-auto flex flex-col gap-1'>
                <span className='font-bold'>4 Extending</span>
                <div className='text-xs'>
                  <p>
                    My work demonstrates a level of understanding <strong>beyond the highest expectation of middle school work</strong>.
                  </p>
                  <p>
                    I can create <strong>polished, original work</strong> that shows an <strong>extremely deep understanding</strong> of the unit.
                  </p>
                  <p>
                    I can <strong>effectively teach</strong> others to a <strong>proficient level</strong> of understanding for this unit.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>}

      </div>
    </div>
  )
}
UnitRubric.propTypes = {
  id: PropTypes.string
}

export default UnitRubric
