import { z, ZodError, ZodType } from 'zod'

/** @argument ErrorType : if you need to assert your error type, just pass it as a generics via asserting with the as keyword */
const handleOneLevelZodError = ({ issues }: ZodError<unknown>) => {
  const formData = {}

  /** 
   *  line of code should be true if the schema is not an object 
   *  This line is completely optional
  */
  if (issues.length === 1 && issues[0].path.length < 1)
    return issues[0].message

  issues.forEach(({ path, message }) => {
    let current = formData
    path.forEach((p, index) => {
      if (index === path.length - 1) {
        current[p] = message  // Set the last p to the message
      } else {
        if (!current[p]) current[p] = {}  // Otherwise, create a new object
        current = current[p]  // Move the reference deeper into the structure
      }
    })
  })

  return formData
}

type ZObjectType = ZodType<Record<string | number, unknown>>

type ZodParams<T extends ZObjectType> = {
  data: z.infer<T>,
  schema: T
}

type ValidationError<T extends ZObjectType> = Partial<Record<keyof T["_output"], unknown>>
type ValidationResult<T extends ZObjectType> =
  | { type: 'error', error: ValidationError<T> }
  | { type: 'data', data: z.infer<T> }


export const handleZodValidation = <T extends ZObjectType>(params: ZodParams<T>): ValidationResult<T> => {
  const { data, schema } = params

  try {
    const res = schema.parse(data)
    return { type: 'data', data: res }
  } catch (error) {
    if (error instanceof ZodError) {
      const formattedErr = handleOneLevelZodError(error)
      return { type: 'error', error: formattedErr as Record<keyof T["_output"], unknown> }
    } else {
      throw new Error(String(error))
    }
  }
}