import * as React from 'react'
import { RegistrationChanges } from '.'
import { Typography, unstable_Tooltip as Tooltip } from '@design-system'

type ExperienceCellProps = Pick<RegistrationChanges, 'experience'>['experience']

export function ExperienceCell({ title, experts }: ExperienceCellProps) {
  return (
    <div className="flex flex-col gap-1 w-44">
      <Tooltip content={title}>
        <Typography variant="callout" weight="bold" className="text-ellipsis line-clamp-1">{title}</Typography>
      </Tooltip>
      {experts.map((expert) =>
        <Typography key={`${title}-${expert}`} variant="callout">{expert}</Typography>
      )}
    </div>
  )
}

