
import React from 'react'
import axios from 'axios'
import { Form, useActionData, useLoaderData, useNavigation, useSubmit } from 'react-router-dom'
import { Button, TextField } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'
import serializeFormData from '@utils/serializeFormData'
import { useToast } from '@hooks/useToast'

type LoaderData = {
  cycles: {
    id: number,
    title: string,
  }[]
}

type ActionData = {
  toast: {
    message: string,
    appearance: 'success' | 'error'
  }
}

export async function loader() {
  const res = await axios.get('/pages/admin/workbench/google/house-events')
  return res.data
}

export async function action({ request }) {
  const formData = serializeFormData(await request.formData())
  try {
    await axios.put('/pages/admin/workbench/google/generate-house-events', formData)
    return {
      toast: {
        message: 'The House Events are being created. It may take a while, please wait...',
        appearance: 'success'
      }
    }
  } catch (error) {
    return {
      toast: {
        message: 'Something went wrong while creating the House Events...',
        appearance: 'error'
      }
    }
  }
}

export default function WorkbenchGoogleHouseEventsRoute() {
  const submit = useSubmit()
  const confirmAction = useConfirmModal()
  const navigation = useNavigation()
  const loaderData = useLoaderData() as LoaderData
  useToast(useActionData() as ActionData)
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const confirmationMessage = {
      title: 'Confirm Creation', subtitle: 'Are you sure you want to create the House Events?', children: <GenerateHouseEventsConfirmationModalMessage />
    }
    if (await confirmAction(confirmationMessage)) {
      submit(formData, { method: 'PUT' })
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h1 className="font-bold text-2xl">
        Create House Events
      </h1>
      <span>Here you can create standup and checkpoint events for all houses. Beware that it also creates the attendances for all those events.*</span>
      <span className="italic font-bold text-sm">*If the next cycle's events were already generated, the attendances won't be generated again, only the calendar events. If there are any adjustments you want to make into the events, such as updating time or conference url, you can do those manually. Any further questions, contact engineering team.</span>
      <Form method="PUT" onSubmit={handleSubmit} className="flex flex-col gap-3 w-96">
        <TextField name="cycle_id" label="Pick a cycle" as="select" required defaultValue={-1}>
          <option value={-1} disabled>Select a cycle</option>
          {loaderData.cycles.map(cycle => (
            <option key={cycle.id} value={cycle.id}>{cycle.title}</option>
          ))}
        </TextField>
        <Button
          type="submit"
          loading={navigation.state === 'submitting'}
        >
          {navigation.state === 'submitting' ? 'Creating...' : 'Create House Events for cycle'}
        </Button>
      </Form>
    </div>
  )
}

function GenerateHouseEventsConfirmationModalMessage() {
  return (
    <span>This sync will create Standup and Checkpoint events for all houses. The events will be created considering next cycle's start, end and holidays.</span>
  )
}