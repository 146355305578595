import serializeFormData from '@utils/serializeFormData'
import axios from 'axios'

export const action = async ({ request }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  const { _action, ...formBody } = serializedFormData

  if (_action === 'deleteTemplate') {
    try {
      await axios.post(`/pages/template/library/deleteTemplate/${formBody.template_id}`, {})
      return {
        toast: {
          message: 'Template deleted successfully!',
          appearance: 'success'
        }
      }
    } catch (error) {
      return {
        toast: {
          message: 'Error deleting template! ' + error,
          appearance: 'error'
        }
      }
    }
  }

  if (_action === 'unarchiveTemplate') {
    try {
      await axios.post(`/pages/template/library/unarchiveTemplate/${formBody.template_id}`, {})
      return {
        toast: {
          message: 'Template unarchived successfully!',
          appearance: 'success'
        }
      }
    } catch (error) {
      return {
        toast: {
          message: 'Error unarchiving template! ' + error,
          appearance: 'error'
        }
      }
    }
  }

  if (_action === 'approveStudy') {
    try {
      await axios.post(`/pages/template/library/approveStudy/${formBody.template_id}`, {})
      return {
        toast: {
          message: 'Template approved!',
          appearance: 'success'
        }
      }
    } catch (error) {
      return {
        toast: {
          message: 'Error approving template. ' + error,
          appearance: 'error'
        }
      }
    }
  }
}
