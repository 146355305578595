import useSWR from 'swr'
import useQuery from './useQuery'

const useCycles = () => {
  const { data, error } = useSWR('/cycles')
  const [queryParams, setQueryParams] = useQuery()
  const { cycle_id } = queryParams

  const cycles = data?.result?.sort(
    (c1, c2) => new Date(c1.start_timestamp) - new Date(c2.start_timestamp)
  )
  const currentCycle =
    cycles &&
    [...cycles]
      .reverse()
      .find((c) => new Date(Date.now()) > new Date(c.start_timestamp))
  const selectedCycle =
    cycle_id && cycles
      ? cycles.find((c) => c.id === Number(cycle_id))
      : currentCycle

  const getNextForCycle = (cycle) => {
    return (
      cycle &&
      cycles.find(
        (c) => new Date(c.start_timestamp) > new Date(cycle.end_timestamp)
      )
    )
  }

  const getPrevForCycle = (cycle) => {
    return (
      cycle &&
      [...cycles]
        .reverse()
        .find(
          (c) => new Date(c.start_timestamp) < new Date(cycle.start_timestamp)
        )
    )
  }

  const goToCycle = (id) => {
    setQueryParams({
      ...queryParams,
      cycle_id: currentCycle.id === id ? null : id,
    })
  }

  const incomingCycle = getNextForCycle(currentCycle)
  const minDate = selectedCycle && new Date(selectedCycle.start_timestamp)
  const maxDate = selectedCycle && new Date(selectedCycle.end_timestamp)

  return {
    cycles,
    currentCycle,
    selectedCycle,
    incomingCycle,
    cycle_id,
    minDate,
    maxDate,
    getNextForCycle,
    getPrevForCycle,
    goToCycle,
    error,
  }
}

export default useCycles
