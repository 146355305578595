import useSWR from 'swr'

const useHouseAdvisors = () => {
  const { data, error } = useSWR('/houses/advisors')
  return {
    advisors: data?.result || [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useHouseAdvisors
