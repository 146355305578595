import React from 'react'
import { Avatar, Typography } from '@design-system'
import SoraLink from '@components/link'
import { Student } from './types'
import { CopyButton } from './copy-button'

interface CellStudentNameProps extends Pick<Student, 'studentEmail' | 'studentId' | 'profileImageUrl' | 'studentName' | 'grade'> { }

export const CellStudentName = ({ studentEmail, studentId, profileImageUrl, studentName, grade }: CellStudentNameProps) => (
  <CopyButton content={studentEmail} className="flex items-center gap-2">
    <Avatar size="lg">
      <Avatar.Image src={profileImageUrl} />
      <Avatar.Fallback>{studentName}</Avatar.Fallback>
    </Avatar>
    <div>
      <Typography variant="callout" weight="bold" className="whitespace-nowrap">
        <SoraLink to={`/employee/students/${studentId}`} className="hover:underline">{studentName}</SoraLink>
      </Typography>
      <Typography variant="callout" className="text-gray-80 dark:text-gray-40 whitespace-nowrap">
        {grade}
      </Typography>
    </div>
  </CopyButton>
)
