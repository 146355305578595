/* eslint-disable react/prop-types */
import React from 'react'
import { RenderedSlate, isBlankValue } from '@components/forms/slate-textarea'
import { useProgressReport } from '@hooks/useReports'
import capitalize from '@utils/capitalize'
import { getCategoryColor } from '@utils/categoryColors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faPrint } from '@fortawesome/free-solid-svg-icons'
import { Popover, Tab } from '@headlessui/react'

const ProgressReports = ({ studentId, cycleId }) => {
  const { progressReports } = useProgressReport(studentId, cycleId)
  const reports = progressReports ? progressReports : []

  const renderComments = (comments) => {
    return (
      comments.map((comment, index) => (
        <li key={`comment_${index}`} className='bg-gray-20 p-2'>
          {<RenderedSlate value={comment.body} />}
        </li>
      ))
    )
  }

  const print = (elem, title = '') => {
    const mywindow = window.open('', 'PRINT', 'height=400,width=600')



    const links = document.getElementsByTagName('link')
    const styles = document.getElementsByTagName('style')
    mywindow.document.write('<html><head><title>Sora Home - ' + title + '</title>')
    for (const link of links) {
      mywindow.document.write(link.outerHTML.toString())
    }
    for (const style of styles) {
      mywindow.document.write(style.outerHTML.toString())
    }
    mywindow.document.write("")
    mywindow.document.write('</head><body class="bg-gray-10" >')
    mywindow.document.write('<div class="w-full text-xl font-bold text-center p-3">' + title + '</div>')
    mywindow.document.write(document.getElementById(elem).innerHTML)
    mywindow.document.write('</body></html>')

    mywindow.document.close()

    setTimeout(() => {
      mywindow.focus()
      mywindow.print()
      mywindow.close()
    }, 1000)

    return true
  }

  const renderCriterias = (criterias) => {
    return (
      criterias.map((criteria, index) => (
        <React.Fragment key={`criteria_${index}`}>
          <div className='py-1 flex flex-row'>
            <span className='mr-2'>{criteria.title}</span>
            <Popover className="relative">
              <Popover.Button className='border-2 border-gray-60 text-gray-60 text-center text-xs rounded-full h-6 w-6'>
                <FontAwesomeIcon icon={faInfo} />
              </Popover.Button>
              <Popover.Panel className="absolute shadow border z-50 left-8 top-0 w-96">
                <div className='bg-white p-4 text-left'>{criteria.description}</div>
              </Popover.Panel>
            </Popover>
          </div>
          {criteria.score_level == 1 &&
            <div className='m-1 h-8 rounded-full text-center text-white font-bold bg-danger-40 border border-2 border-danger-60'>
              Significant Concern
            </div>
          }
          {criteria.score_level == 2 &&
            <div className='m-1 h-8 rounded-full text-center text-white font-bold bg-yellow-40 border border-2 border-yellow-720'>
              Some Concern
            </div>
          }
          {criteria.score_level == 3 &&
            <div className='m-1 h-8 rounded-full text-center text-white font-bold bg-green-50 border border-2 border-green-90'>
              On Track
            </div>
          }
          {![1, 2, 3].includes(criteria.score_level) &&
            <div className='m-1 h-8 rounded-full text-center text-white font-bold bg-white border border-2 border-gray-70'>
              Missing
            </div>
          }
        </React.Fragment>
      ))
    )
  }

  const renderAbilities = (abilities) => {
    return (
      abilities.map((ability, index) => (
        <div key={`ability_${index}`} className='flex flex-col flex-auto bg-white p-2 rounded shadow'>
          <h3 className='font-bold'>{ability.title}</h3>
          <div className='grid grid-cols-[auto_200px] bg-gray-20 m-2 px-2 py-1 rounded shadow'>
            {renderCriterias(ability.criterias)}
          </div>
        </div>
      ))
    )
  }

  const renderThemeDescription = (text) => {
    if (isBlankValue(text)) return
    return (
      <Popover className='relative'>
        <Popover.Button className='text-blue-70 underline'>More details</Popover.Button>
        <Popover.Panel className='absolute z-10 bg-white border p-2 shadow rounded mt-2 ml-2 w-[51.25rem]'>
          <RenderedSlate value={text} />
        </Popover.Panel>
      </Popover>
    )
  }

  const renderTheme = (theme) => {
    return (
      <div className='grid md:grid-cols-5 r-sm:grid-rows-2 r-sm:grid-flow-col r-sm:gap-y-2'>
        <div className='flex flex-col md:col-span-3'>
          <div className='flex flex-row pb-2'>
            <div className='flex flex-col flex-auto bg-white p-2 mr-1 rounded shadow'>
              <div className='flex flex-row'>
                <h2 className='font-bold'>{theme.title}</h2>
                <small className={`rounded ml-2 py-1 px-2 ${getCategoryColor(theme.category_name).bgDark} font-bold text-xs  whitespace-nowrap`}>{theme.category_name.split('_').map(capitalize).join(' ')}</small>
              </div>

              <div>Expert{theme.experts.length > 1 ? 's' : ''}: <span>{theme.experts.reduce((acc, curr) => `${acc.length > 0 ? ', ' : acc}${curr.first_name} ${curr.last_name}`, '')}</span></div>
              {renderThemeDescription(theme.description)}
            </div>
            <div className='bg-white rounded shadow p-2 mr-2 flex-col'>
              <h3 className='text-center font-bold pb-2'>Attendance</h3>
              <ul className='flex flex-row text-center justify-between text-sm text-white font-bold'>
                <li className='flex flex-col mx-1 p-2 bg-green-50 rounded-lg border-2 border-green-90'>
                  <span>{theme.attendances.present}%</span>
                  <span>Present</span>
                </li>
                <li className='flex flex-col mx-1 p-2 bg-yellow-40 rounded-lg border-2 border-yellow-720'>
                  <span>{theme.attendances.tardy}%</span>
                  <span>Tardy</span>
                </li>
                <li className='flex flex-col mx-1 p-2 bg-danger-40 rounded-lg border-2 border-danger-60'>
                  <span>{theme.attendances.absent}%</span>
                  <span>Absent</span>
                </li>
                <li className='flex flex-col mx-1 p-2 bg-blue-40 rounded-lg border-2 border-blue-60'>
                  <span>{theme.attendances.excused}%</span>
                  <span>Excused</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='flex flex-auto mr-2'>{renderAbilities(theme.abilities)}</div>
        </div>
        <div className='bg-white rounded shadow p-2 md:col-span-2 r-sm:mr-2'>
          <h3 className='font-bold'>Comments</h3>
          <ul className='h-auto overflow-y-auto'>{renderComments(theme.comments)}</ul>
        </div>
      </div>
    )
  }

  const reportTitles = reports.map(r => r.title)

  return (
    <div className="flex flex-col flex-grow pb-8 text-gray-70 pt-8">
      {
        reports?.length
          ? <Tab.Group>
            <Tab.List className='border-b-2 mb-2'>
              {reportTitles.map((title, index) => (
                <Tab key={`report_title_${index}`} className={({ selected }) => selected ? 'p-2 mr-2 rounded-t font-bold bg-blue-70 text-white' : 'p-2 mr-2 font-bold opacity-60'}>
                  {title}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {reports.map((report, index) => (
                <Tab.Panel key={`report_tab_${index}`}>
                  <div id={`report_tab_${index}`}>
                    <div className='no-print text-right w-full'>
                      <button onClick={() => print(`report_tab_${index}`, report.title)} className='text-white bg-blue-70 rounded-full px-3 py-2'>
                        <FontAwesomeIcon icon={faPrint} />
                      </button>
                    </div>
                    {report.themes.map((theme) => (
                      <>
                        <div className='pt-2 pb-4' key={`progress_report_theme_${theme.id}_${index}`}>
                          {renderTheme(theme)}
                        </div>
                        <div className='break-page-print' />
                      </>
                    ))}
                  </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
          : <span>No reports in this cycle.</span>
      }
    </div>
  )
}

export default ProgressReports
