import React, { ComponentProps, ReactNode, PropsWithChildren } from 'react'
import { Pill, unstable_Tooltip as Tooltip } from '@design-system'

export type PillTopicStudentProgramVariant = 'wildcard' | 'elective' | 'external-elective'

interface PillAllowedProps extends Pick<ComponentProps<typeof Pill>, 'variant' | 'color'> { }

interface VariantProps extends PillAllowedProps {
  childrenFn: (props: Omit<PillTopicStudentProgram, 'variant'>) => ReactNode
  tooltip: string
}

const defaultProps: VariantProps = {
  childrenFn: ({ children }: PillTopicStudentProgram) => <Pill.Value>{children}</Pill.Value>,
  tooltip: ''
}

const variants: Record<PillTopicStudentProgramVariant, VariantProps> = {
  wildcard: {
    color: 'purple-10',
    childrenFn: () => <Pill.Value>Wildcard</Pill.Value>,
    tooltip: 'Required topic of student choice.'
  },
  elective: {
    color: 'blue-5',
    childrenFn: () => <Pill.Value>Elective</Pill.Value>,
    tooltip: 'Elective topic of student choice.'
  },
  'external-elective': {
    childrenFn: () => <Pill.Value>External Elective</Pill.Value>,
    color: 'turquoise-5',
    tooltip: 'Elective topic of student choice, completed outside of Sora.'
  },
}

interface PillTopicStudentProgram extends PropsWithChildren {
  variant: PillTopicStudentProgramVariant
}

/**
 * PillTopicStudentProgram display a pill  with Topic program status
 */
export const PillTopicStudentProgram = (props: PillTopicStudentProgram) => {
  const { childrenFn, tooltip, ...pillProps } = variants[props.variant] ?? defaultProps
  return <Tooltip content={tooltip}><Pill {...pillProps}>{childrenFn({ ...props })}</Pill></Tooltip>
}
