import React, { useState, forwardRef, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface DatePickerInputProps {
  date: Date,
  handleChange: (_: Date) => void,
  name: string,
  label: string,
  minDate?: Date,
  required?: boolean,
  fullWidth?: boolean,
  defaultValue?: string,
}

export function useDatePicker(defaultValue?: string) {
  const [date, setDate] = useState<Date>()

  useEffect(() => {
    setDate(defaultValue ? new Date(defaultValue) : null)
  }, [defaultValue])

  return [date, setDate] as const
}

const DatePickerInputButton = forwardRef(
  (
    { value, onClick, placeholder, fullWidth }: { fullWidth: boolean, value: string, placeholder?: string, onClick: () => void },
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        type="button"
        className={`bg-white dark:bg-gray-90 dark:text-white text-gray-90 border border-gray-40
      hover:bg-gray-10 hover:border-gray-50 hover:text-gray-100
      focus-within:ring-1 focus-within:ring-accent focus-within:!bg-white focus-within:text-gray-100 focus-within:border-gray-50)
      h-12 p-2 flex justify-between items-center rounded-lg text-left ${fullWidth ? 'w-full' : 'w-52'}`}
        onClick={onClick}
        ref={ref}
      >
        <p>{value || placeholder}</p>
        <FontAwesomeIcon icon={faChevronDown} size="sm" />
      </button>
    )
  })

export function DatePickerInput({ date, required = false, name, label, minDate, fullWidth = false, handleChange }: DatePickerInputProps) {
  return (
    <div className="flex flex-col">
      <label className="text-base mb-1">
        {label}
        {required && <span data-testid='required' className='text-danger-40 font-bold'> *</span>}
      </label>
      <input type="hidden" required={required} name={name} value={date.toISOString()} />
      <ReactDatePicker
        selected={date}
        minDate={minDate}
        onChange={(newDate) => {
          handleChange(newDate)
        }}
        placeholderText="Pick a date"
        className="w-full"
        /* @ts-expect-error */
        customInput={<DatePickerInputButton fullWidth={fullWidth} />}
      />
    </div>
  )
}
