import React from 'react'
import PropTypes from 'prop-types'

const CycleSelector = ({ required, cycles, noLabel, name = 'competition_interval_id', ...rest }) => {
  const cyclesWithLabel = cycles.map((cycle) => {
    const start = new Date(cycle.start_timestamp).toLocaleDateString([], {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
    const end = new Date(cycle.end_timestamp).toLocaleDateString([], {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
    return {
      id: cycle.id,
      label: cycle.title || `${start} - ${end}`,
    }
  })
  return <label>
    {!noLabel && <h3 className="ml-3 text-sm font-bold">Cycle:</h3>}
    <select
      name={name}
      className="border-gray-10 rounded-lg py-1 px-2 mt-0.5 w-full"
      {...rest}
    >
      <option disabled value=''> -- select a future cycle -- </option>
      {cyclesWithLabel.map(({ id, label }) => (
        <option key={id} value={id}>
          {label}
        </option>
      ))}
    </select>
  </label>
}

CycleSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  cycles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    start_timestamp: PropTypes.string,
    end_timestamp: PropTypes.string,
    title: PropTypes.string,
  })),
  noLabel: PropTypes.bool,
  name: PropTypes.string,
}

export default CycleSelector
