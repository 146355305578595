import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { createPortal } from 'react-dom'

import 'react-datepicker/dist/react-datepicker.css'
import './custom.css'

const DateTimePicker = forwardRef(({
  name,
  readOnly,
  readOnlyNode,
  className,
  onClickReadOnly = () => { },
  value,
  onChange,
  defaultValue,
  prefix = '',
  dateFormat = 'Pp',
  filterTime = () => true,
  customInput,
  injectTimes,
  showTimeSelect = true,
  ...rest
}, ref) => {
  const [localState, setLocalState] = useState(defaultValue ? new Date(defaultValue).toISOString() : new Date().toISOString())

  if (readOnly)
    return <div onClick={onClickReadOnly}>
      {prefix}
      <div className={`inline ml-2 outline-none w-full border-b-2 border-dotted border-gray-40 hover:border-black cursor-text ${className}`}>
        {readOnlyNode || new Date(localState).toLocaleDateString([], {
          month: 'numeric', day: 'numeric', year: '2-digit', hour: 'numeric', minute: 'numeric'
        })}
      </div>
    </div>

  if (!onChange) {
    return <>
      <input type="hidden" name={name} value={localState} ref={ref} />
      <DatePicker
        popperContainer={({ children }) => createPortal(children, document.body)}
        showTimeSelect={showTimeSelect}
        filterTime={filterTime}
        className="border-gray-10 rounded-lg py-1 mt-0.5 w-full font-normal dark:text-black"
        dateFormat={dateFormat}
        selected={localState ? new Date(localState) : null}
        onChange={(newDate) => setLocalState(newDate.toISOString())}
        customInput={customInput ? customInput : null}
        {...rest}
      />
    </>
  }

  return <>
    <input type="hidden" name={name} value={value} ref={ref} />
    <DatePicker
      {...rest}
      popperContainer={({ children }) => createPortal(children, document.body)}
      showTimeSelect={showTimeSelect}
      filterTime={filterTime}
      ref={ref}
      className="border-gray-10 rounded-lg py-1 mt-0.5 w-full font-normal dark:text-black"
      dateFormat={dateFormat}
      selected={value ? new Date(value) : null}
      onChange={newDate => {
        onChange({
          target: {
            name,
            value: newDate ? new Date(newDate).toISOString() : undefined,
          }
        })
      }}
      customInput={customInput ? customInput : null}
      injectTimes={injectTimes ? injectTimes : null}
    />
  </>

})

DateTimePicker.displayName = 'DateTimePicker'

DateTimePicker.defaultProps = {
  className: '',
  onClickReadOnly: () => null,
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  customInput: PropTypes.any,
  injectTimes: PropTypes.any,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClickReadOnly: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  readOnly: PropTypes.bool,
  readOnlyNode: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  filterTime: PropTypes.func,
  showTimeSelect: PropTypes.bool,
}

export default DateTimePicker
