import * as React from 'react'
import axios from 'axios'
import { Form, Outlet, useLoaderData } from 'react-router-dom'
import SoraLink from '@components/link'
import { Button, Icon, unstable_Table as Table } from '@design-system'
import Paginator from '@components/paginator'
import { format } from 'date-fns'

interface LoaderData {
  jobs: Array<{
    id: number,
    type: string,
    resourceId: string,
    status: string,
    errorsCount: number,
    updatedAt: Date,
  }>,
  headers: Array<string>,
  totalPages: number,
  currentPage: number,
}
async function loader({ request }) {
  const searchParams = new URL(request.url).searchParams
  const res = await axios.get('/backoffice/workbench/student-heartbeat-server/jobs', {
    params: searchParams,
  })
  return res.data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData

  return (
    <div className="px-6">
      <Form id="filter-form" method="get" />
      <div className="flex justify-center pt-2 pb-6">
        <Paginator formId="filter-form" totalPages={loaderData.totalPages} currentPage={loaderData.currentPage} />
      </div>
      <Table className="border-separate border border-gray-30 rounded-lg">
        <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
          <Table.Row>
            {loaderData.headers.map((header, index) => (
              <Table.Head key={index} className="w-48 first:pl-4">{header}</Table.Head>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body className="[&_tr:last-child]:border-1">
          {loaderData.jobs.map((job) => (
            <Table.Row key={job.id} className="last-of-type:rounded-b-md">
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{job.id}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{job.type}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{job.resourceId}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{job.status}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{job.errorsCount}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{format(new Date(job.updatedAt), 'yyyy, MMM d HH:mm')}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                <Button asChild>
                  <SoraLink to={`./${job.id}`}>
                    <Icon name="arrow-right-curved-circle" size="xs" /> Check logs
                  </SoraLink>
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="flex justify-center pt-2 pb-6">
        <Paginator formId="filter-form" totalPages={loaderData.totalPages} currentPage={loaderData.currentPage} />
      </div>
      <Outlet />
    </div>
  )
}
export const WorkbenchStudentHearbeatServerJobsRoute = { loader, Element }
