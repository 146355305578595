import React, { Fragment, PropsWithChildren, useState } from 'react'
import { usePopper } from 'react-popper'
import formatDistance from 'date-fns/formatDistance'
import { Popover, Transition } from "@headlessui/react"
import { Button } from '@designsystem'
import type { Notification as NotificationType } from './types'
import isSoraHomeLink from '@utils/isSoraHomeLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

type ListProps = PropsWithChildren<{
  hasNotifications: boolean,
}>

function List({ hasNotifications, children }: ListProps) {
  return hasNotifications ? (
    <ol className="flex flex-col">
      {children}
    </ol>
  ) : (
    <div className="flex flex-col space-y-2 justify-center items-center h-full">
      <img src="/assets/notifications/no_new_notifications.png" alt="No new notifications image" title="No new notifications" />
      <h1 className="text-3xl font-bold text-center">No new notifications</h1>
    </div>
  )
}

type ItemProps = NotificationType & {
  onClick: () => any
  onDeleteNotification: () => void,
  isSelected?: boolean,
}

function Item({
  id,
  title,
  type,
  read,
  body,
  category,
  image_url,
  image_alt,
  cta_text,
  created_at,
  icon_alt,
  icon_url,
  onClick,
  onDeleteNotification,
  isSelected,
  redirect_url,
}: PropsWithChildren<ItemProps>) {
  const [show, setShow] = useState(true)
  const [showUnreadDot, setShowUnreadDot] = useState(!read)

  return (
    <Transition
      appear
      show={show}
      afterLeave={onDeleteNotification}
      as={Fragment}
    >
      <Transition.Child
        className={`flex space-x-4 w-full relative py-5 pl-2 hover:bg-gray-30 ${isSelected && 'bg-blue-2'}`}
        onClick={() => read ? onClick() : setShowUnreadDot(false)}
        data-testid={`notification-${id}`}
        as="li"
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-400"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <span className="flex h-min space-x-2 w-16">
          <span className={`${read ? 'invisible' : ''} w-2 flex self-center`}>
            <Transition
              as={Fragment}
              appear
              show={showUnreadDot}
              afterLeave={onClick}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-400"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <i className="w-2 h-2 flex rounded-full bg-blue-40 self-center" />
              </Transition.Child>
            </Transition>
          </span>
          <ItemIcon type={type} category={category} icon_alt={icon_alt} icon_url={icon_url} />
        </span>
        <div className={`flex flex-col w-full place-items-start ${redirect_url ? 'cursor-pointer' : ''}`}>
          <div className="flex justify-between w-full items-center">
            <h3 className="font-bold text-lg">{title}</h3>
            <ItemPopover onDeleteNotification={() => setShow(false)} />
          </div>
          {image_url && <img src={image_url} alt={image_alt} className="h-20 my-4 rounded-lg w-11/12 object-cover" />}
          <p className="mr-3 line-clamp-3 overflow-ellipsis">
            {body}
          </p>
          <small className="mt-4 text-gray-70">{formatDistance(new Date(created_at), new Date(), { addSuffix: true })}</small>
          {cta_text && <span className="font-bold mt-4 self-start"><Button as="span" size="sm" variant="outlined">
            {cta_text}
            {redirect_url && !isSoraHomeLink(redirect_url) && <span>
              <FontAwesomeIcon size="xs" icon={faExternalLinkAlt} />
            </span>}
          </Button></span>}
        </div>
      </Transition.Child>
    </Transition >
  )
}

type ItemIconProps = Partial<Pick<ItemProps, 'icon_alt' | 'icon_url'>> & Pick<ItemProps, 'type' | 'category'>

export function ItemIcon({ type, category, icon_alt, icon_url }: ItemIconProps) {
  if (category === 'house_reports') {
    return (
      <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-green-5" title="Academic Notification">
        <span className="text-2xl mt-0.5">🍀</span>
      </span>
    )
  }
  switch (type) {
    case 'ACADEMIC':
      return icon_url
        ? <img src={icon_url} alt={icon_alt} className="w-10 h-10 rounded-full" title="Academic Notification" />
        : (
          <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-blue-2" title="Academic Notification">
            <img src="/assets/icons/academic-icon.svg" className="w-4 h-4" alt="Academic icon" />
          </span>
        )
    case 'PRODUCT':
      return (
        <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-blue-2" title="Product Notification">
          <img src="/assets/icons/flag-icon.svg" className="w-4 h-4" alt="Product icon" />
        </span>
      )
    case 'REPORTS_AND_INSIGHTS':
      return (
        <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-blue-2" title="Reports and Insights Notification">
          <img src="/assets/icons/rocket-icon.svg" className="w-5 h-5" alt="Reports icon" />
        </span>
      )
    case 'SCHOOL':
      return (
        <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-blue-2" title="School Notification">
          <img src="/assets/icons/sora-icon.png" className="w-5 h-5" alt="School icon" />
        </span>
      )
    default:
      return null
  }
}

type ItemPopoverProps = Pick<ItemProps, 'onDeleteNotification'>

function ItemPopover({ onDeleteNotification }: ItemPopoverProps) {
  const [popperElement, setPopperElement] = useState<HTMLDivElement>()
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-end' })

  return (
    <Popover>
      <Popover.Button ref={setReferenceElement} className="rounded-full py-2 px-4 text-black hover:bg-gray-40 active:bg-gray-50 ">
        <img src="/assets/icons/ellipsis-icon.svg" className="w-6 h-6" alt="Notification Options" title="Notification Options" />
      </Popover.Button>
      <Popover.Panel
        className="z-10"
        ref={setPopperElement}
        style={{ ...styles.popper, filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25))' }}
        {...attributes.popper}
      >
        <ul className="rounded-lg grid items-center overflow-hidden bg-white">
          <li
            className="flex space-x-3 items-start hover:bg-gray-10 p-4 w-40"
            onClick={(e) => {
              e.stopPropagation()
              onDeleteNotification()
            }}
          >
            <img src="/assets/icons/trash-icon.svg" alt="Delete icon" title="Delete notification" className="w-6 h-6" />
            <span>
              Delete
            </span>
          </li>
        </ul>
      </Popover.Panel>
    </Popover>
  )
}

const Notification = {
  List: List,
  Item: Item,
}


export default Notification
