import React, { useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import Checkbox from '@components/forms/checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import SoraLink from '@components/link'
import { Button } from '@designsystem'
import capitalize from '@utils/capitalize'

const StudentOptionsItem = ({ item, onCheckedStudentId, checked } = {}) => {
  const checkboxRef = useRef(null)
  const handleSelected = (e) => {
    if (e.target.nodeName === 'A') return
    if (e.target !== checkboxRef.current) checkboxRef.current.checked = !checkboxRef.current.checked
    onCheckedStudentId(item.student_id, checkboxRef.current.checked)
  }

  useEffect(() => {
    checkboxRef.current.checked = checked
  }, [checked])

  return (
    <tr className="border transition-all duration-200 ease-in-out hover:bg-gray-10" onClick={handleSelected}>
      <td className="py-2 w-16 text-center">
        <Checkbox ref={checkboxRef} id={`student_${item.student_id}`} name={`student_${item.student_id}`} onChange={handleSelected} className="rounded" />
      </td>
      <td className="w-2/3">{item.student_id} - {item.student_name}</td>
      <td className="w-36">
        <Pill type="mid_cycle" item={item} />
      </td>
      <td className="w-36">
        <Pill type="end_cycle" item={item} />
      </td>
      <td className="w-36 text-center">
        <Button
          as={SoraLink}
          to={`/employee/students/${item.student_id}/reports`}
          target="_blank"
          size="xs"
          variant="outlined"
        >
          Preview
        </Button>
      </td>
    </tr>
  )
}
StudentOptionsItem.propTypes = {
  item: PropTypes.object,
  onCheckedStudentId: PropTypes.func,
  checked: PropTypes.bool,
}

const Pill = ({ type, item }) => {
  const showPill = item[type] || (type === 'mid_cycle' && item.has_report_assessment || type === 'end_cycle' && item.has_final_assessment)
  if (!showPill) return null

  const colourClassNames = item[type]
    ? { bg: 'bg-turquoise-50 border-turquoise-50', text: 'text-turquoise-50' }
    : { bg: 'bg-gray-60 border-gray-60', text: 'text-gray-60' }

  return (
    <div className={`flex items-center rounded-full border-2 w-32 p-px ${colourClassNames.bg}`}>
      <div className="flex border rounded-full h-5 w-5 m-px bg-white">
        <FontAwesomeIcon icon={faCheck} className={`m-auto ${colourClassNames.text}`} />
      </div>
      <p className="font-bold text-xs text-white mx-auto">{type.split('_').map(capitalize).join('-')}</p>
    </div>
  )
}
Pill.propTypes = {
  type: PropTypes.oneOf(['mid_cycle', 'end_cycle']),
  item: PropTypes.object,
}

export default StudentOptionsItem
