import React from 'react'
import { Button, Icon, Typography, unstable_Tooltip as Tooltip } from '@design-system'
import SoraLink from '@components/link'

interface HeaderProps {
  id: number
  title: string
  canManageHouses: boolean
  isHouseAdvisor: boolean
}

export function Header({ id, title, canManageHouses, isHouseAdvisor }: HeaderProps) {
  return (
    <header className="flex justify-between">
      <div className="flex flex-col items-start">
        <div className="flex gap-2">
          {!isHouseAdvisor && (
            <Tooltip content="Back">
              <Button asChild variant="outlined" color="soft" size="sm" aria-label="Back">
                <SoraLink to="/houses">
                  <Icon name="arrow-left" size="sm" />
                </SoraLink>
              </Button>
            </Tooltip>
          )}
          <Typography variant="heading-3" weight="bold">{title}</Typography>
        </div>
        {isHouseAdvisor && (
          <Button asChild variant="ghost" color="accent" size="sm" className="-ml-4">
            <SoraLink to="/houses">
              Explore other houses <Icon name="chevron-right" size="sm" />
            </SoraLink>
          </Button>
        )}
      </div>
      {canManageHouses && (
        <Tooltip content="Edit house">
          <span>
            <Button variant="outlined" color="soft" size="sm" aria-label="Edit house" asChild>
              <SoraLink to={`/houses/edit/${id}`} aria-label="Edit house">
                <Icon name="edit-3" size="sm" />
              </SoraLink>
            </Button>
          </span>
        </Tooltip>
      )}
    </header>
  )
}



