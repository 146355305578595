import Select from '@components/forms/select'
import SelectGuardians from '@components/forms/select-guardians'
import TableHeader from '@components/table/table-header'
import useCurrentUser from '@hooks/useCurrentUser'
import useGuardianDocuments from '@hooks/useGuardianDocuments'
import useGuardians from '@hooks/useGuardians'
import { useThisPageTitle } from '@hooks/usePageTitle.jsx'
import useQuery from '@hooks/useQuery'
import useToggle from '@hooks/useToggle'
import EditableDocument from './editable-document'
import React, { useMemo } from 'react'
import { Button } from '@designsystem'
import { useParams } from 'react-router'

const TAGS_SUGGESTION = [
  'TRANSCRIPT',
  'REIMBURSEMENT',
  'MEDIA',
  'OTHER STUDENT INFO'
]

// eslint-disable-next-line react/prop-types
export default function Documents() {
  const { student_id } = useParams()
  const studentId = Number(student_id)
  const { guardians: allGuardians } = useGuardians({ student_ids: [studentId] || [] })
  const [params, setParams] = useQuery()
  const currentUser = useCurrentUser()
  const isGuardian = currentUser?.role === 'guardian'

  const {
    documents: allSentDocs,
    tags,
    create,
    update,
    destroy,
    LoadMoreButton: LoadMoreSentDocsButton
  } = useGuardianDocuments({ ...params, is_received: false, student_id })

  const {
    documents: allReceivedDocs,
    LoadMoreButton: LoadMoreReceivedDocsButton
  } = useGuardianDocuments({ ...params, is_received: true, student_id })

  useThisPageTitle('Documents')

  const [showAddDocForm, toggleShowAddDocForm] = useToggle(false)
  const [sentDocuments, receivedDocuments, guardians] = useMemo(() => [
    allSentDocs?.filter(d => d.student_id === studentId),
    allReceivedDocs?.filter(d => d.student_id === studentId),
    (allGuardians?.filter(g => g.students_ids.includes(studentId)) || []).filter(g => g.email)
  ], [allGuardians, allSentDocs, allReceivedDocs, studentId])

  return <div className="flex flex-col py-3">
    <div className='flex justify-between'>
      <Button
        className="bg-blue-70 text-white text-xs space-x-2 font-bold float-right p-2 rounded-md uppercase mb-5"
        type="button"
        size="sm"
        onClick={() => {
          toggleShowAddDocForm()
        }}>
        Add Document
      </Button>
    </div>

    {showAddDocForm && <EditableDocument tags={TAGS_SUGGESTION} guardians={guardians} onCancel={toggleShowAddDocForm} onSave={create} />}

    <TableHeader>
      <TableHeader.Column colSpan={1} className="hidden lg:block">FILES</TableHeader.Column>
      <TableHeader.Column colSpan={3}>
        <Select
          isClearable={true}
          className="w-full min-w-42 lg:min-w-auto"
          options={tags}
          onChange={(tag, { action }) => {
            if (action === 'clear') {
              const newParams = { ...params }
              delete newParams['tag']
              setParams({ ...newParams })
            } else {
              setParams({ ...params, tag: tag.value })
            }
          }}
          value={params.tag && [{ label: params.tag, value: params.tag }]}
          placeholder="Tag"
        />
      </TableHeader.Column>
      <TableHeader.Column colSpan={3}>
        <SelectGuardians
          className="w-full min-w-42 lg:min-w-auto"
          guardians={guardians}
          onChange={(guardian_id, _, { action }) => {
            if (action === 'clear') {
              const newParams = { ...params }
              delete newParams['guardian_id']
              setParams({ ...newParams })
            } else {
              setParams({ ...params, guardian_id })
            }
          }}
          selected_ids={[isGuardian ? currentUser.id : Number(params.guardian_id)]}
          isDisabled={isGuardian}
          placeholder="Guardian"
        />
      </TableHeader.Column>
      <TableHeader.Column colSpan={2} className="hidden lg:block">RELATED TO STUDENT</TableHeader.Column>
      <TableHeader.Column colSpan={2} className="hidden lg:flex">CREATED AT</TableHeader.Column>
      <TableHeader.Column colSpan={1} className="hidden lg:block">ORIGIN</TableHeader.Column>
    </TableHeader>
    {sentDocuments.map((d) => <EditableDocument
      key={d.id}
      onDelete={destroy}
      guardians={guardians}
      onSave={update}
      readOnly
      tags={TAGS_SUGGESTION}
      guardian_document={d}
      type="sent"
    />)}
    <LoadMoreSentDocsButton />
    {receivedDocuments.map((d) => <EditableDocument
      key={d.id}
      onDelete={destroy}
      guardians={guardians}
      onSave={update}
      readOnly
      tags={TAGS_SUGGESTION}
      guardian_document={d}
      type="received"
    />)}
    <LoadMoreReceivedDocsButton />
  </div>
}

