import useSWR, { mutate as globalMutate } from 'swr'
import axios from 'axios'
import { toast } from 'sonner'

const useGroups = () => {
  const { data, error, mutate } = useSWR('/permissions/groups')
  

  const mutateAll = async () => {
    globalMutate('/permissions')
    globalMutate('/users')
    mutate()
  }

  const create = async (title, type) => {
    try {
      await axios.post('/permissions/groups/', { title, type })
      globalMutate('/permissions')
      mutateAll()
      toast.success('Successfully created new group')
    } catch (e) {
      console.error(e)
      toast.error(e?.response?.data?.error)
    }
  }

  const addUserToGroup = async (permission_group_id, user_id) => {
    try {
      await axios.post(`/permissions/groups/users`, { user_id, permission_group_id })
      mutateAll()
      toast.success('Successfully added user to group')
    } catch (e) {
      console.error(e)
      toast.error(e?.response?.data?.error)
    }
  }

  const removeUserFromGroup = async (permission_group_id, user_id) => {
    try {
      await axios.delete(`/permissions/groups/users?user_id=${user_id}&permission_group_id=${permission_group_id}`)
      mutateAll()
      toast.success('Successfully removed user from group')
    } catch (e) {
      console.error(e)
      toast.error(e?.response?.data?.error)
    }
  }

  return {
    groups: data?.result || [],
    isError: error,
    mutate: mutateAll,
    create,
    addUserToGroup,
    removeUserFromGroup
  }
}

export default useGroups
