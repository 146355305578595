import React from 'react'
import axios from 'axios'
import { Form, Outlet, redirect, useLoaderData, useSearchParams, useSubmit } from 'react-router-dom'
import format from 'date-fns/format'
import capitalize from '@utils/capitalize'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { unstable_Datepicker as Datepicker, EmptyState } from '@design-system'
import { Button } from '@design-system'
import EmptyStateIllustration from './illustration.svg'

export interface HouseAttendanceLoaderData {
  formErrors: Record<string, string>,
  initialDate: string,
  minDate: string,
  maxDate: string,
  availableDates: string[],
  eventId: number,
  kinds: string[],
  selectedKind: string,
}

async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/houses/${params.houseId}/attendances?${searchParams.toString()}`)
  const eventId = data.eventId
  if (eventId) {
    if (Number(params.eventId) !== Number(eventId)) {
      return redirect(`${eventId}?${searchParams.toString()}`)
    }
  } else if (params.eventId) {
    return redirect(`.?${searchParams.toString()}`)
  }

  return data
}

function Element() {
  const {
    eventId,
    selectedKind,
    kinds,
    formErrors,
    initialDate,
    availableDates,
    minDate,
    maxDate,
  } = useLoaderData() as HouseAttendanceLoaderData
  const submit = useSubmit()
  const [searchParams] = useSearchParams()
  const cycleId = searchParams.get('cycle_id')

  return (
    <div className="flex flex-col gap-4 items-stretch">
      <Form method='get' replace className="flex flex-row gap-2 items-center" onChange={e => {
        submit(e.currentTarget)
      }}>
        {cycleId && <input type='hidden' name='cycle_id' value={cycleId} />}
        <div className="flex gap-2">
          <Datepicker
            name='date'
            error={formErrors['date']}
            defaultSelected={utcToZonedTime(initialDate, Intl.DateTimeFormat().resolvedOptions().timeZone)}
            maxDate={new Date(maxDate)}
            minDate={new Date(minDate)}
            placeholderText="Pick a date"
            filterDate={(date) => availableDates.includes(format(date, 'yyyy-MM-dd'))}
          />
        </div>
        {kinds.map(kind => (
          <Button
            type='submit'
            name='kind'
            variant='contained'
            color={selectedKind === kind ? 'dark' : 'soft'}
            value={kind}
          >
            {capitalize(kind)}
          </Button>
        ))}
      </Form>
      {!eventId ? (
        <EmptyState>
          <EmptyState.Illustration>
            <img src={EmptyStateIllustration} alt="Nothing to report" />
          </EmptyState.Illustration>
          <EmptyState.Title>No {selectedKind}</EmptyState.Title>
          <EmptyState.Description>There is no {selectedKind} on this day</EmptyState.Description>
        </EmptyState>
      ) : (
        <Outlet />
      )}
    </div>
  )
}

export const HouseAttendancesRoute = {
  loader,
  Element,
}


