/* eslint-disable react/prop-types */
import React from 'react'
import Slideover from '../../../../components/slideover'
import formatDate from '../../../../utils/formatDate'
import MasteryBar from '../../../../components/mastery-bar'

const AbilityOpportunity = ({ demo, index }) => {
  return (
    <div className={`p-6 bg-white dark:bg-gray-95 dark:border-gray-90 border border-gray-30 gap-4 lg:gap-0 rounded grid grid-cols-1 lg:grid-cols-4 ${demo.discarded ? 'opacity-25' : ''}`}>
      <div className="flex flex-col">
        <h3 className="font-bold mr-4">{demo.title === "Missed Opportunity" ? demo.title : `Opportunity ${index + 1}`}</h3>
        <p>On {formatDate(demo.created_at)}</p>
      </div>
      <div className="flex flex-col col-span-2">
        {demo.task_title ? (
          <span>{demo.task_title}</span>
        ) : (
          <span className="italic text-gray-50">No task found</span>
        )}

        {demo.assessor_name !== ' ' ? (
          <span>{demo.assessor_name}</span>
        ) : (
          <span className="italic text-gray-50">No assessor found</span>
        )}
      </div>

      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <span className="uppercase text-xs tracking-wide font-bold mr-2">
            Mastery Level {demo.level}
          </span>
        </div>
        <MasteryBar level={demo.level} />
      </div>
    </div>
  )
}

const AbilitySlideover = ({ ability, open, setOpen }) => {
  return (
    <Slideover
      title={ability.title}
      open={open}
      setOpen={setOpen}
      maxWidth={'max-w-4xl'}
    >
      <h3 className="text-lg font-bold mb-4">
        Opportunities ({ability.completions} / {ability.target})
      </h3>

      {ability.exemptions > 0 && (
        <div className="pb-4">
          You are exempted from {ability.exemptions} ability opportunities
          based on your year standing at June 2022 or at the
          time you joined Sora.
        </div>
      )}
      <div className="space-y-4">
        {ability.demos.map((demo, idx) => {
          return <AbilityOpportunity key={demo.id} demo={demo} index={idx} />
        })}
      </div>
    </Slideover>
  )
}

export default AbilitySlideover
