import useSWR from 'swr'

const useUserFeatureFlag = () => {
  const { data, error, mutate } = useSWR('/feature-flags/allowed')
  const flags = data?.results || []
  const checkBetaFeature = (key) => flags.some(flag => flag.key === key)

  return {
    isError: error,
    mutate,
    checkBetaFeature,
  }
}

export default useUserFeatureFlag
