/* eslint-disable react/prop-types */
import React from 'react'
import HsPanel from '@components/pace-and-progress/graduation/hs-panel'
import MsPanel from '@components/pace-and-progress/graduation/ms-panel'
import BottomSection from '@components/pace-and-progress/graduation/bottom-section'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import axios from 'axios'
import { Alert, Button } from '@design-system'
import SoraLink from '@components/link'

export const loader = async ({ params, request }) => {
  const url = new URL(request.url)
  const cycleId = url.searchParams?.get('cycle_id')
  const { data } = await axios.get(`/pages/student/${params.student_id}/progress/graduation?cycle_id=${cycleId}`)
  return data
}

export default function StudentGraduationRoute() {
  const loaderData = useLoaderData()
  const revalidator = useRevalidator()
  const mutate = revalidator.revalidate

  return (
    <>
      {loaderData.hasFeatureFlagStudentProgramEnabled && (
        <Alert variant="notice" className="mb-6">
          <Alert.Title>This page is being phased out.</Alert.Title>
          <Alert.Description>Please use the new Program page for the latest features and updates.</Alert.Description>
          <Alert.Actions>
            <Button asChild size="xs" variant="outlined">
              <SoraLink to={`../program/${loaderData.schoolStage}`}>
                Go to Program page
              </SoraLink>
            </Button>
          </Alert.Actions>
        </Alert>
      )}
      {loaderData.schoolStage === 'hs' ? <HsPanel data={loaderData} /> : <MsPanel data={loaderData} />}
      <BottomSection data={loaderData} mutate={mutate} />
    </>
  )
}
