import React from 'react'
import PropTypes from 'prop-types'
import MasteryBar from '@components/mastery-bar'
import useSWR from 'swr'

const UnitRubric = ({ id }) => {
  const { data } = useSWR(id ? `/units/${id}` : null)
  if (!data) return <span>Loading...</span>

  return (
    <div className="flex flex-row gap-8">
      <div>
        <div className="px-6 py-4 bg-gray-20 dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg">Unit</div>
      </div>
      <div className="flex-auto flex flex-col gap-4">
        <div className="flex flex-col gap-2 pb-4 border-b border-b-gray-30">
          <span className="font-bold text-md">{data.unit?.title}</span>
          <p className="text-xs text-white">
            {data.unit?.description}
          </p>
        </div>
        {data.unit?.objectives.length > 0 && (
          <div className="flex flex-row gap-10 pb-4 border-b border-b-gray-30">
            <div>
              <span className="font-bold">Objectives</span>
            </div>
            <ul className="flex-auto flex flex-col gap-3 text-sm list-none">
              {data.unit.objectives.map((obj, index) => (
                <li key={`unit:obj:${index}`}>{obj}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex flex-col">
          <span className="font-bold pb-4">Mastery Rubric</span>
          <ul className="flex flex-col gap-3">
            <li className="flex flex-row gap-6 border-b border-b-gray-30 pb-3">
              <div className="self-start p-1">
                <MasteryBar level={0} />
              </div>
              <div className="flex-auto flex flex-col">
                <span className="font-bold">0 Not Started</span>
                <p className="text-xs"></p>
              </div>
            </li>
            <li className="flex flex-row gap-6 border-b border-b-gray-30 pb-3">
              <div className="self-start p-1">
                <MasteryBar level={0.5} />
              </div>
              <div className="flex-auto flex flex-col gap-1">
                <span className="font-bold">0.5 Attempted</span>
                <p className="text-xs">
                  You did attempt this unit, but it didn't meet the criteria for
                  exposure. You'll need to practice this one some more before
                  you've met the baseline criteria.
                </p>
              </div>
            </li>
            <li className="flex flex-row gap-6 border-b border-b-gray-30 pb-3">
              <div className="self-start p-1">
                <MasteryBar level={1} />
              </div>
              <div className="flex-auto flex flex-col gap-1">
                <span className="font-bold">1 Exposure</span>
                <p className="text-xs">
                  I can answer the question “What is…?” about the Unit.
                </p>
                <p className="text-xs">
                  I have demonstrated a basic understanding of the Unit in the
                  genre/format of my choice, including but not limited to:
                  Informal writing, Discussion, Graphic representation, Video
                  presentation, Following a procedure, etc.
                </p>
              </div>
            </li>
            <li className="flex flex-row gap-6 border-b border-b-gray-30 pb-3">
              <div className="self-start p-1">
                <MasteryBar level={2} />
              </div>
              <div className="flex-auto flex flex-col gap-1">
                <span className="font-bold">2 Developing</span>
                <div className="text-xs">
                  <p>
                    I can answer questions related to “Why...?” and/or “How...?”
                    about the Unit.
                  </p>
                  <br />
                  <p className="font-bold">Some examples include:</p>
                  <ul className="list-circle pl-8">
                    <li>
                      Why is X the best choice? Why did Y happen in the way it
                      did? How do I know Z is true? How does A work? Why is it
                      useful to know about B? How do people use C effectively?
                    </li>
                    <li>
                      I have demonstrated my understanding of the Unit in the
                      genre/format of my choice, including but not limited to:
                      Writing, Discussion, Graphic representation, Video
                      presentation, Problem solving, etc.
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="flex flex-row gap-6 border-b border-b-gray-30 pb-3">
              <div className="self-start p-1">
                <MasteryBar level={3} />
              </div>
              <div className="flex-auto flex flex-col gap-1">
                <span className="font-bold">3 Demonstrating</span>
                <p className="text-xs">
                  I have created polished, original work in the genre/format of
                  my choice that includes both my understanding of “What?” AND
                  “Why?” and/or “How?”about the Unit and also my own original
                  insight and/or application.
                </p>
              </div>
            </li>
            <li className="flex flex-row gap-6 pb-3">
              <div className="self-start p-1">
                <MasteryBar level={4} />
              </div>
              <div className="flex-auto flex flex-col gap-1">
                <span className="font-bold">4 Extending</span>
                <p className="text-xs">
                  My work demonstrates insight and/or originality and/or utility
                  that is above and beyond even the highest expectation of high
                  school-level work AND/OR I have demonstrated exceptional
                  mastery of the Unit by filling the role of Expert and
                  teaching it to others.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div >
  )
}
UnitRubric.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
}

export default UnitRubric
