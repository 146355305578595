import React, { useEffect } from 'react'
import { Link, Outlet, useLoaderData, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { Alert, Button, Icon, Typography, useFetcher, unstable_Tooltip as Tooltip, Combobox, Pill } from '@design-system'
import serializeFormData from '@utils/serializeFormData'
import SoraLink from '@components/link'

type LoaderData = {
  startAt: string,
  endAt: string,
  conferenceUrlLabel: string
  conferenceUrlLink: string
  hasMatchingZoomEvent: boolean
  isWaitingZoomEvent: boolean
  isFailedToConnectWithZoom: boolean
  isEditingEnabled: boolean
  alert?: {
    variant?: 'notice'
    title: string
    description: string
    action: string
    label: string
  }
  attendances: {
    id: number
    studentId: number
    studentName: string
    status: string
    cameraOn: boolean
    participated: boolean
    notifiedAt: string
    options: string[]
  }[]
}

async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance/default/${params.sessionId}?${searchParams.toString()}`)
  return data
}

async function action({ params, request }) {
  try {
    const formData = await request.formData()
    const serializedFormData = serializeFormData(formData)
    const response = await axios.post(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance/default/${params.sessionId}`, serializedFormData)
    return response.data
  } catch (error) {
    const data = error.response?.data
    return data || {
      toast: {
        message: 'Something went wrong. Please try again later.',
        appearance: 'error',
      }
    }
  }
}


function Element() {
  const [searchParams] = useSearchParams()
  const {
    startAt,
    endAt,
    conferenceUrlLabel,
    conferenceUrlLink,
    hasMatchingZoomEvent,
    isWaitingZoomEvent,
    isFailedToConnectWithZoom,
    isEditingEnabled,
    alert,
    attendances,
  } = useLoaderData() as LoaderData
  const fetcher = useFetcher()
  const [isEditing, setIsEditing] = React.useState(false)

  const handleToggleEditingStatus = () => {
    if (isEditingEnabled) {
      setIsEditing(!isEditing)
    }
  }

  useEffect(() => {
    if (fetcher.state === 'idle') {
      setIsEditing(false)
    }
  }, [fetcher.state])

  const tooltip = "This event hasn't happened yet"

  return (
    <>
      <section className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Icon size='sm' name='clock' />
          <Typography>
            {new Date(startAt).toLocaleTimeString([])} to {new Date(endAt).toLocaleTimeString([], { timeZoneName: 'short' })}
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <Icon size='sm' name='link-2' />
          <a href={conferenceUrlLink} target='_blank' rel="noreferrer noopener"
            className="text-md hover:underline flex flex-row items-center gap-2 text-accent font-bold">
            {conferenceUrlLabel}
          </a>
        </div>
        <div className="flex items-center gap-2">
          <Icon size='sm' name='video-camera' className={isFailedToConnectWithZoom && 'text-danger'} />
          <Typography color={isFailedToConnectWithZoom ? 'danger' : undefined}>
            {hasMatchingZoomEvent
              ? (
                <>
                  Connected with Zoom Attendance. <Link
                    to={'zoom-report?' + searchParams.toString()}
                    className="hover:underline text-accent font-bold">
                    View Report
                  </Link>
                </>
              ) : isWaitingZoomEvent
                ? 'Waiting for meeting to start on Zoom'
                : 'Failed to connect with Zoom'
            }
          </Typography>
        </div>
      </section>
      {alert && (
        <Alert variant={alert.variant}>
          <Alert.Title>{alert.title}</Alert.Title>
          {alert.description && (
            <Alert.Description>{alert.description}</Alert.Description>
          )}
          {alert.action && (
            <Alert.Actions>
              <fetcher.Form method="post">
                <input type="hidden" name="_action" value={alert.action} />
                <Button size='xs' type="submit">
                  {alert.label}
                </Button>
              </fetcher.Form>
            </Alert.Actions>
          )}
        </Alert>
      )}
      <section>
        <fetcher.Form method="post" className="bg-white dark:bg-gray-100 border dark:border-gray-90 rounded">
          <table className="table-fixed w-full text-center">
            <thead>
              <tr>
                <th className="p-3 text-left border-b dark:border-gray-90" colSpan={3}>
                  <Typography variant='heading-6' weight='bold'>
                    Attendance
                  </Typography>
                </th>
                <th className="p-3 text-right border-b dark:border-gray-90" colSpan={1}>
                  {!isEditing ? (
                    tooltip ? (
                      <Tooltip content={tooltip}>
                        <Button type="button" size='sm' variant='outlined' disabled={!isEditingEnabled} onClick={handleToggleEditingStatus}>
                          Edit
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button type="button" size='sm' variant='outlined' disabled={!isEditingEnabled} onClick={handleToggleEditingStatus}>
                        Edit
                      </Button>
                    )
                  ) : (
                    <div className="flex flex-row gap-2 justify-end">
                      <Button type="button" size='sm' variant='outlined' onClick={handleToggleEditingStatus}>
                        Cancel
                      </Button>
                      <Button type="submit" size='sm' name="_action" value="overwrite_attendances">
                        Save
                      </Button>
                    </div>
                  )}
                </th>
              </tr>
              <tr>
                <th className="p-3 border-b dark:border-gray-90 text-left">
                  <Typography variant="footnote" weight="bold">Student name</Typography>
                </th>
                <th className="p-3 border-b dark:border-gray-90 text-left">
                  <Typography variant="footnote" weight="bold">Status</Typography>
                </th>
                <th className="p-3 border-b dark:border-gray-90">
                  <Typography variant="footnote" weight="bold">Camera On</Typography>
                </th>
                <th className="p-3 border-b dark:border-gray-90">
                  <Typography variant="footnote" weight="bold">Participated</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {attendances.map(attendance => (
                <tr key={attendance.id} className="hover:bg-gray-5 dark:hover:bg-gray-98">
                  <td>
                    {isEditing && (
                      <input type="hidden" name={`attendances[${attendance.id}][id]`} defaultValue={attendance.id} />
                    )}
                    <div className="p-3 text-left">
                      <SoraLink to={`/employee/students/${attendance.studentId}/overview`} className='hover:underline'>
                        {attendance.studentName}
                      </SoraLink>
                      {attendance.notifiedAt && (
                        <div className="text-xxs text-danger-60 -bottom-3">
                          Parents notified in {new Date(attendance.notifiedAt).toLocaleString([], {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          })}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="p-3 flex flex-row gap-2">
                      {isEditing ? (
                        <Combobox
                          name={`attendances[${attendance.id}][status]`}
                          defaultValue={attendance.status}>
                          {attendance.options.map(option => (
                            <Combobox.Item key={option} value={option}>{option}</Combobox.Item>
                          ))}
                        </Combobox>
                      ) : attendance.status.split(',').map(st => {
                        return <Pill key={st} color={STATUS_BG_COLORS[st.trim()]}>
                          <Pill.Value>{st}</Pill.Value>
                        </Pill>
                      })}
                    </div>
                  </td>
                  <td>
                    <div className="p-3">
                      {isEditing ? (
                        <input type="checkbox"
                          name={`attendances[${attendance.id}][cameraOn]`}
                          disabled={!isEditing}
                          defaultChecked={attendance.cameraOn}
                        />
                      ) : (
                        attendance.cameraOn ? (
                          <Icon name="check" className="text-success-60" />
                        ) : (
                          <Icon name="cross" className="text-danger" />
                        )
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="p-3">
                      {isEditing ? (
                        <input type="checkbox"
                          name={`attendances[${attendance.id}][participated]`}
                          disabled={!isEditing}
                          defaultChecked={attendance.participated}
                        />
                      ) : (
                        attendance.participated ? (
                          <Icon name="check" className="text-success-60" />
                        ) : (
                          <Icon name="cross" className="text-danger" />
                        )
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </fetcher.Form>
      </section>
      <Outlet />
    </>
  )
}

const STATUS_BG_COLORS = {
  'Present': 'success-30',
  'Unexcused Absent': 'danger-5',
  'Insufficient': 'danger-5',
  'Excused Absent': undefined,
  'Late': 'yellow-20',
}

export const FacilitateExperienceAttendanceDefaultSessionRoute = {
  loader,
  action,
  Element,
}
