import useSWR from 'swr'

const useUsers = () => {
  const { data, error } = useSWR(`/users`)

  return {
    users: data?.result || [],
    isLoading: !error && !data,
    isError: error
  }
}
export default useUsers
