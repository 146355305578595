const getUniqueObjectsInArray = (arr, objKey = null) => {
  if (!arr) arr = []
  if (objKey)
    return [...new Map(arr.map(item => [item[objKey], item])).values()]

  return arr.filter((v, i) => {
    const vStr = JSON.stringify(v)
    return i === arr.findIndex(obj => JSON.stringify(obj) === vStr)
  })
}

export default getUniqueObjectsInArray
