import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type ExtractProps<T> = T extends React.ComponentType<infer P> ? P : T

type OnboardingListboxProps = {
  selectedValue: {
    value: string | number,
    label: string,
  },
  disabled?: boolean,
  options: Array<{
    value: string | number,
    label: string,
  }>
} & ExtractProps<typeof Listbox>

export default function OnboardingListbox({ selectedValue, onChange, disabled = false, options, ...other }: OnboardingListboxProps) {
  return (
    <Listbox
      disabled={disabled}
      onChange={onChange}
      value={selectedValue}
      {...other}
    >
      {({ open }) => (
        <div className="relative w-full">
          <Listbox.Button className={
            classNames(
              disabled ? 'cursor-not-allowed opacity-50' : '',
              'relative w-full cursor-default rounded-lg bg-white py-3 pl-4 pr-10 text-left text-gray-100 shadow-sm ring-1 ring-inset ring-gray-40 focus:outline-none focus:ring-2 focus:ring-blue-40 sm:text-sm sm:leading-6'
            )
          }>
            <span className="block truncate">{selectedValue.label}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leaveTo="opacity-0"
            leaveFrom="opacity-100"
            leave="transition ease-in duration-100"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((status) => (
                <Listbox.Option
                  value={status}
                  key={status.value}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-gray-90 text-white' : 'text-gray-100',
                      'relative cursor-default select-none py-2 pr-9'
                    )
                  }>
                  {({ selected }) => (
                    <span
                      className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                    >
                      {status.label}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
