import useSWR from 'swr'
import axios from 'axios'
import { toast } from 'sonner'

const useGroups = () => {
  const { data, error, mutate } = useSWR('/permissions')
  

  const addPermissionToGroup = async (permission_group_id, permission_id) => {
    try {
      await axios.post(`/permissions/groups/permissions/`, { permission_group_id, permission_id })
      mutate()
      toast.success('Added permission to group')
    } catch (e) {
      console.error(e)
      toast.error(e?.response?.data?.error)
    }
  }

  const removePermissionFromGroup = async (permission_group_id, permission_id) => {
    try {
      await axios.delete(`/permissions/groups/permissions?permission_group_id=${permission_group_id}&permission_id=${permission_id}`)
      mutate()
      toast.success('Removed permission from group')
    } catch (e) {
      console.error(e)
      toast.error(e?.response?.data?.error)
    }
  }

  return {
    groups: data?.result || [],
    isError: error,
    mutate,
    addPermissionToGroup,
    removePermissionFromGroup,
  }
}

export default useGroups
