/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Notification from '@components/notification'
import Modal from '@components/modal'

const LearnAboutProgressCard = ({ type = 'hs', page = 'graduation' }) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false)

  return (
    <>
      <Modal
        isOpen={isVideoModalOpen}
        onClickClose={() => {
          const iframe = document.getElementById('progress-video-iframe')
          iframe.setAttribute('src', iframe.getAttribute('src'))
          setVideoModalOpen(false)
        }}
        header={
          <span className="absolute top-7 left-5 font-bold text-2xl">
            {page === 'cycle' ? 'Pace & Progress Cycle' : 'Pace & Progress (Graduation)'}
          </span>
        }
      >
        {page === 'cycle' ? (
          <iframe
            id="progress-video-iframe"
            src="https://player.vimeo.com/video/733321476?h=8859de7076&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width="1276"
            height="720"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Pace &amp;amp; Progress Cycle"
            className="border p-1 rounded mt-3"
          ></iframe>
        ) : (
          <iframe
            id="progress-video-iframe"
            src="https://player.vimeo.com/video/733321952?h=23e8168324&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width="1152"
            height="720"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Pace &amp;amp; Progress (Graduation)"
            className="border p-1 rounded mt-3"
          ></iframe>
        )}
      </Modal>
      <Notification identification="learn-about-progress" color="green" className='mt-6 group-[.flutter]/flutter:hidden'>
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
          <div
            className="flex flex-row cursor-pointer"
            onClick={() => setVideoModalOpen(true)}
          >
            <img
              className="rounded w-1/2"
              src={`/assets/video/pace_and_progress_${page}_thumbnail.png`}
            />
            <div className="flex flex-col ml-4">
              <span className="font-bold">Progress</span>
              <span className="text-sm">Video</span>
            </div>
          </div>
          <p className="opacity-75 col-span-3">
            We created 2 new ways you will more easily see your progress on Sora
            Home. You will have both short (This Cycle) and long term (
            {type === 'hs' ? 'Graduation' : 'Year'}) views of your % progress &
            your mastery levels! Learn more about the new way to view progress
            here.
          </p>
          <a
            href={
              type === 'hs'
                ? 'https://soraschools.slab.com/posts/new-how-to-see-your-progress-fahhiz1m#hcsp2-weve-simplified-soras-academic-system'
                : 'https://soraschools.slab.com/posts/sora-middle-school-curriculum-7yeelxv0'
            }
            className={`bg-green-90 dark:bg-green-60 dark:hover:bg-green-50 text-white font-bold w-full rounded-full text-center flex items-center justify-center self-start py-2`}
          >
            Learn about progress
          </a>
        </div>
      </Notification>
    </>
  )
}

export default LearnAboutProgressCard
