
export const ON_TRACK = 'ontrack'
export const NEEDS_IMPROVEMENT = 'needs_improvement'
export const NOT_COVERED = null

export const LABELS = {
  [ON_TRACK]: 'On track',
  [NEEDS_IMPROVEMENT]: 'Needs Improvement',
  [NOT_COVERED]: 'Not Yet Covered'
}

export const BACKGROUND_COLORS = {
  [ON_TRACK]: 'bg-green-2',
  [NEEDS_IMPROVEMENT]: 'bg-yellow-5',
  [NOT_COVERED]: 'bg-gray-10'
}

export const BACKGROUND_HOVER_COLORS = {
  [ON_TRACK]: 'hover:bg-green-30',
  [NEEDS_IMPROVEMENT]: 'hover:bg-yellow-20',
  [NOT_COVERED]: 'hover:bg-gray-40'
}

export const BORDER_COLORS = {
  [ON_TRACK]: 'border-green-30',
  [NEEDS_IMPROVEMENT]: 'border-yellow-20',
  [NOT_COVERED]: 'border-gray-40'
}

export const TEXT_COLORS = {
  [ON_TRACK]: 'text-green-50',
  [NEEDS_IMPROVEMENT]: 'text-yellow-40',
  [NOT_COVERED]: 'text-gray-60'
}

export const ICONS = {
  [ON_TRACK]: 'thumbup',
  [NEEDS_IMPROVEMENT]: 'mountain',
  [NOT_COVERED]: 'soon'
}
