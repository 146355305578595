import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@designsystem'
import MetricButton from '@components/facilitate/metrics/metric-button'
import TaskList from '@components/facilitate/metrics/task-list'
import { Labels } from '@components/facilitate/metrics/constants'

const MetricsTaskList = ({ handleBack, student_id, theme_id, metric }) => {
  const { data } = useSWR(`/reports/v2/progress-report/${student_id}/theme/${theme_id}/tasks/${metric}`)
  return (
    <div className="flex flex-col gap-3">
      <div>
        <Button variant="nude" size="sm" startIcon={faChevronLeft} onClick={handleBack}>
          Back
        </Button>
      </div>
      {data === undefined && (
        <p>Loading...</p>
      )}
      {data && (
        <>
          <MetricButton label={Labels[metric]} value={data.length} direction="horizontal" active disabled />
          <TaskList status={metric} items={data} />
        </>
      )}
    </div>
  )
}

MetricsTaskList.propTypes = {
  handleBack: PropTypes.func.isRequired,
  theme_id: PropTypes.number,
  student_id: PropTypes.number,
  metric: PropTypes.string
}

export default MetricsTaskList
