import useSWR from 'swr'

const useStudentEmergencyContact = (student_id) => {
  const { data, error, mutate } = useSWR(student_id ? `/students/${student_id}/emergencyContact` : null)
  return {
    emergencyContact: data || {},
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export default useStudentEmergencyContact
