import React from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router-dom'
import { Button, Icon } from '@design-system'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import serializeFormData from "@utils/serializeFormData"
import { RemovePathway } from './remove-pathway'
import { AddPathway } from './add-pathway'

const loader = async ({ params, request }) => {
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'
  const cycleId = searchParams.get('cycle_id')
  
  const result = await axios.get('/backoffice/workbench/pathways/management', { params: { action, cycleId } })
  return result?.data
}

const action = async ({ params, request }) => {
  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  try {
    const result = await axios.post(`/backoffice/workbench/pathways/management`, { ...serializedFormData, action })
    return result?.data
  } catch (error) {
    return {
      toast: { message: 'There was an error running this action. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigate = useNavigateWithCycle()
  const { pathways } = useLoaderData()

  const categoryView = (title) => {
    switch (title.toLowerCase()) {
      case 'stem':
        return (<span className='rounded py-1 px-2 bg-green-5 font-bold text-xs whitespace-nowrap'>Stem</span>)
      case 'humanities':
        return (<span className='rounded py-1 px-2 bg-turquoise-5 font-bold text-xs whitespace-nowrap'>Humanities</span>)
      case 'math_learning_goal':
        return (<span className='rounded py-1 px-2 bg-purple-5 font-bold text-xs whitespace-nowrap'>Math</span>)
      case 'electives':
        return (<span className='rounded py-1 px-2 bg-orange-5 font-bold text-xs whitespace-nowrap'>Electives</span>)
    }
  }

  return (
    <div className='flex flex-row justify-around'>
      <div className='flex flex-col p-2 gap-4 w-1/2 min-w-[400px]'>
        <AddPathway />
        <table className=''>
          <thead className='border-b'>
            <tr>
              <th className='p-3 text-left'>Title</th>
              <th className='p-3 text-left'>Type</th>
              <th className='p-3 text-left'>Students Assigned</th>
              <th className='w-20'></th>
            </tr>
          </thead>
          <tbody>
            {pathways.map(pathway => (
              <tr key={pathway.id} className='hover:bg-alpha/5'>
                <td className='p-3'>{pathway.title}</td>
                <td className='p-3'>{categoryView(pathway.type)}</td>
                <td className='p-3'>{pathway.students_amount}</td>
                <td className='flex flex-row gap-2 justify-end p-3'>
                  <Button variant='outlined' size='xs' onClick={() => { navigate(`./${pathway.id}`) }}>
                    <Icon name='edit-3' size='xs' />
                  </Button>
                  <RemovePathway id={pathway.id} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

}

export const WorkbenchPathwaysManagementIndexRoute = {
  loader,
  action,
  Element,
}
