import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './override.css'

const ExcusePeriodSelector = ({ watch, control, register }) => {
  const showTimePicker = !watch('allDay')
  return (
    <div className="flex -ml-1.5">
      <div className="flex flex-col mr-5">
        <FontAwesomeIcon icon={faCalendar} />
        <span className="text-tiny mt-1">(EST)</span>
      </div>
      <div className="flex flex-col space-y-3 -ml-1">
        {
          watch('single_day')
            ? (<div className="flex flex-col space-y-2">
              <Controller
                control={control}
                name="start_at_day"
                defaultValue={new Date(Date.now())}
                render={({ onChange, value }) => (
                  <DatePicker
                    className="rounded max-w-32 text-sm"
                    dateFormat="MMM d, yyyy"
                    onChange={onChange}
                    onChangeRaw={(e) => { e.preventDefault() }}
                    selected={value}
                  />
                )}
              />
              {
                showTimePicker &&
                <div className="flex space-x-5">
                  <div className="flex flex-col">
                    <span className="text-xs font-bold">From (EST)</span>
                    <Controller
                      control={control}
                      name="start_at_time"
                      defaultValue={new Date(Date.now())}
                      render={({ onChange, value }) => (
                        <DatePicker
                          className="rounded max-w-24 text-sm"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aaa"
                          onChange={onChange}
                          onChangeRaw={(e) => { e.preventDefault() }}
                          selected={value}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs font-bold">To (EST)</span>
                    <Controller
                      control={control}
                      name="end_at_time"
                      render={({ onChange, value }) => (
                        <DatePicker
                          className="rounded max-w-24 text-sm"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aaa"
                          onChange={onChange}
                          onChangeRaw={(e) => { e.preventDefault() }}
                          selected={value}
                        />
                      )}
                    />
                  </div>
                </div>
              }
            </div>)
            : (<div className="flex flex-col space-y-2">
              <div className="flex flex-col">
                <span className="text-sm font-bold">From</span>
                <div className="flex">
                  <Controller
                    control={control}
                    name="start_at_day"
                    defaultValue={new Date(Date.now())}
                    render={({ onChange, value }) => (
                      <DatePicker
                        className="rounded max-w-32 mr-5 text-sm"
                        dateFormat="MMM d, yyyy"
                        onChange={onChange}
                        onChangeRaw={(e) => { e.preventDefault() }}
                        selected={value}
                      />
                    )}
                  />
                  {
                    showTimePicker &&
                    <Controller
                      control={control}
                      name="start_at_time"
                      defaultValue={new Date(Date.now())}
                      render={({ onChange, value }) => (
                        <>
                          <DatePicker
                            className="rounded max-w-[85px] text-sm"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aaa"
                            onChange={onChange}
                            onChangeRaw={(e) => { e.preventDefault() }}
                            selected={value}
                          />
                          <span className="mt-[9px] text-sm ml-1">(EST)</span>
                        </>
                      )}
                    />
                  }
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-bold">To</span>
                <div className="flex">
                  <Controller
                    control={control}
                    name="end_at_day"
                    defaultValue={new Date(Date.now())}
                    render={({ onChange, value }) => (
                      <DatePicker
                        className="rounded max-w-32 mr-5 text-sm"
                        dateFormat="MMM d, yyyy"
                        onChange={onChange}
                        onChangeRaw={(e) => { e.preventDefault() }}
                        selected={value}
                      />
                    )}
                  />
                  {
                    showTimePicker &&
                    <Controller
                      control={control}
                      name="end_at_time"
                      defaultValue={new Date(Date.now())}
                      render={({ onChange, value }) => (
                        <>
                          <DatePicker
                            className="rounded max-w-[85px] text-sm"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat={`h:mm aaa`}
                            onChange={onChange}
                            onChangeRaw={(e) => { e.preventDefault() }}
                            selected={value}
                          />
                          <span className="mt-[9px] text-sm ml-1">(EST)</span>
                        </>
                      )}
                    />
                  }
                </div>
              </div>
            </div>)
        }
        <div className="flex space-x-3">
          <div className="flex items-center space-x-2">
            <input name="allDay" ref={register} type="checkbox" className="rounded" />
            <span className="text-sm font-bold">All day</span>
          </div>
          <div className="flex items-center space-x-2">
            <input name="single_day" ref={register} type="checkbox" className="rounded" />
            <span className="text-sm font-bold">One day or less</span>
          </div>
        </div>
      </div>
    </div >
  )
}
ExcusePeriodSelector.propTypes = {
  watch: PropTypes.func,
  register: PropTypes.func,
  control: PropTypes.object,
}

export default ExcusePeriodSelector
