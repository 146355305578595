import React from 'react'
import { useFetcher } from '@design-system'
import { useSearchParams } from 'react-router-dom'

interface CycleSelectorProps {
  cycles: {
    id: string
    title: string
    current: boolean
    open: boolean
  }[]
  selectedCycleId: number,
  className?: string
}

export const CycleSelector = ({ cycles, selectedCycleId, className }: CycleSelectorProps) => {
  const fetcher = useFetcher()
  const [_, setSearchParams] = useSearchParams()

  const handleChange = (event) => {
    event.preventDefault()
    const urlParams = new URLSearchParams(document.location.search)
    const currentParams = Object.fromEntries(urlParams)
    setSearchParams({ ...currentParams, cycle_id: event.target.value })

    fetcher.submit({ ...currentParams, employeeId: event.target.value }, { method: 'get' })
  }

  return (
    <fetcher.Form onChange={handleChange} className={className}>
      <select key={selectedCycleId} name="cycle_id" defaultValue={selectedCycleId}
        className="w-full block py-2 pr-8 rounded text-md border-gray-40 dark:border-gray-70 dark:text-gray-5 dark:bg-gray-90 dark:hover:bg-gray-95 dark:focus:bg-gray-10"
      >
        {cycles.map(cycle => (
          <option key={cycle.id} value={cycle.id}>
            {cycle.title}
            {cycle.current && ' \u2192 current'}
            {cycle.open && ' \u2192 journey building...'}
          </option>
        ))}
      </select>
    </fetcher.Form>
  )
}
