import React from 'react'
import PropTypes from 'prop-types'
import { Button, useFetcher } from '@design-system'

export const RunCreditSync = ({ notAllowed = false, isProcessing = false, cycleId, cycleTitle }) => { 
  const fetcher = useFetcher()
  const isLoading = fetcher.state === 'loading' || isProcessing

  return (
    <fetcher.Form method='post' className="px-4 flex flex-col gap-4 text-sm">
      <input type="hidden" name="_action" value="credit_sync" />
      <input type="hidden" name="cycle_id" value={cycleId} />
      <div className="flex items-center gap-2">
        <input
          id="ignore_archived_themes"
          type="checkbox"
          name="ignore_archived_themes"
          disabled={notAllowed}
          className="rounded disabled:opacity-30"
        />
        <label htmlFor="ignore_archived_themes">
          Ignore archived themes
        </label>
      </div>
      <div className="flex items-center gap-2">
        <input
          id="ignore_students_left_sora"
          type="checkbox"
          name="ignore_students_left_sora"
          disabled={notAllowed}
          className="rounded disabled:opacity-30"
        />
        <label htmlFor="ignore_students_left_sora">
          Ignore students left Sora
        </label>
      </div>
      <Button type='submit' loading={isLoading} disabled={isLoading || notAllowed}>
        Run credit sync for {cycleTitle}  
      </Button>
    </fetcher.Form>
  )
}
RunCreditSync.propTypes = {
  notAllowed: PropTypes.bool,
  isProcessing: PropTypes.bool,
  cycleId: PropTypes.number,
  cycleTitle: PropTypes.string,
}
