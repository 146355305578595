import React from 'react'
import { useNavigation, useSearchParams } from 'react-router-dom'
import { unstable_Tooltip as Tooltip } from '@design-system'
import { Student } from './types'
import { Trend } from './trend'
import { Skeleton } from '@design-system'

interface CellAttendanceProps extends Pick<Student, 'actualAttendanceCount' | 'expectedAttendanceCount' | 'trendAttendanceCount' | 'missedFinalTasksCount'> { }

export const CellAttendance = ({ actualAttendanceCount, expectedAttendanceCount, trendAttendanceCount, missedFinalTasksCount }: CellAttendanceProps) => {
  const [searchParams] = useSearchParams()
  const navigation = useNavigation()
  const timeframe = searchParams.get("timeframe") ?? "lastSevenDays"
  if (navigation.state === 'loading' || actualAttendanceCount === undefined || expectedAttendanceCount === undefined)
    return <Skeleton data-testid='skeleton' className="h-5" />
  return (
    <div className="flex items-center gap-2">
      <Tooltip content={`Student has attended ${actualAttendanceCount}/${expectedAttendanceCount} expeditions during the ${timeframe === "lastSevenDays" ? 'last 7 days' : 'current cycle'}.`}>
        <span>{actualAttendanceCount}/{expectedAttendanceCount}</span>
      </Tooltip>
      <Trend label="Attendance" value={trendAttendanceCount} />
    </div>
  )
}
