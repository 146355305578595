import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { Pill } from '@design-system'

export type Variant = 'new' | 'off-program'

interface PillAllowedProps extends Pick<ComponentProps<typeof Pill>, 'rounded' | 'variant' | 'color' | 'disabled'> {}

interface VariantProps extends PillAllowedProps {
  childrenFn: (props: Omit<PillPerksProps, 'variant'>) => ReactNode
}

const defaultProps: VariantProps = {
  childrenFn: ({ children }: PillPerksProps) => <Pill.Value>{children}</Pill.Value>,
}

const variants: Record<Variant, VariantProps> = {
  new: {
    childrenFn: defaultProps.childrenFn,
    color: 'success-40',
  },
  'off-program': {
    childrenFn: defaultProps.childrenFn,
    disabled: true,
  },
}

interface PillPerksProps extends PropsWithChildren {
  variant?: Variant
}

/**
 * PillPerks display a pill with student progress
 */
export const PillPerks = (props: PillPerksProps) => {
  const { childrenFn, ...pillProps } = variants[props.variant] ?? defaultProps
  return <Pill {...pillProps}>{childrenFn({ ...props })}</Pill>
}
