/* eslint-disable react/prop-types */
import React from 'react'
import Slideover from '../../../../components/slideover'
import MasteryBar from '../../../../components/mastery-bar'
import useUnitDemonstrations from '../hooks/useUnitDemonstrations'
import formatDate from '../../../../utils/formatDate'

const UnitDemonstration = ({ demo, index }) => {
  const dropped = index !== 0 || demo.level < 1
  return (
    <div className={`p-6 bg-white border rounded grid grid-cols-3 justify-between items-center ${dropped ? 'opacity-25' : ''}`}>
      <div className="flex flex-col">
        <h3 className="font-bold mr-4">{demo.level < 0.5 ? 'Missed opportunity' : demo.level < 1 ? 'Attempted' : index === 0 ? `Demonstration #${index + 1}` : 'Dropped'}</h3>
        <p>On {formatDate(demo.created_at)}</p>
      </div>
      <div className="flex flex-col">
        {demo.title ? (
          <span>{demo.title}</span>
        ) : (
          <span className="italic text-gray-50">No task found</span>
        )}

        {demo.assessor_name !== ' ' ? (
          <span>{demo.assessor_name}</span>
        ) : (
          <span className="italic text-gray-50">No assessor found</span>
        )}
      </div>

      <div className="justify-self-end">
        <div className="flex flex-row items-center">
          <span className="uppercase text-xs tracking-wide font-bold mr-2">
            Mastery Level {demo.level}
          </span>
        </div>
        <MasteryBar level={demo.level} />
      </div>
    </div>
  )
}

const UnitSlideover = ({ student_id, unit_id, unit_title, open, setOpen }) => {
  const { demonstrations } = useUnitDemonstrations({
    student_id,
    unit_id,
  })

  return (
    <Slideover title="Total Unit Demonstrations" open={open} setOpen={setOpen}>
      <div className="text-lg mb-4">{unit_title}</div>
      <div className="space-y-4">
        {demonstrations.map((demo, idx) => {
          return <UnitDemonstration key={idx} demo={demo} index={idx} />
        })}
      </div>
    </Slideover>
  )
}

export default UnitSlideover
