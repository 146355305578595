import React, { useEffect } from 'react'
import { Tab } from '@headlessui/react'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'

const TABS: { url: string; label: string }[] = [
  { url: 'sync-users', label: 'Sync Users' }
]

export default function WorkbenchCannyRoute() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.endsWith('canny')) {
      return navigate(`./${TABS.at(0).url}`)
    }
  }, [])

  return (
    <Tab.Group>
      <Tab.List className="flex space-x-10 border-b border-b-gray-30 w-full">
        {TABS.map(t => (
          <Tab
            key={t.url}
            as={Link}
            to={`./${t.url}`}
            className={`py-2 ${location.pathname.includes(t.url) ? 'border-b-2 font-bold border-b-accent ' : ''}`}
          >
            {t.label}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel className="mt-4">
          <Outlet />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  )
}
