import React from 'react'
import PropTypes from 'prop-types'
import Emoji from "@components/emoji"

const FeedbackSummaryCard = ({ title, emoji, units, abilities, className, onSelect }) => {
  return (
    <div className={`flex items-center ${onSelect ? 'cursor-pointer' : ''} px-8 py-5 space-x-5 rounded-lg w-full ${className}`} onClick={onSelect}>
      <Emoji name={emoji} title={title} type="png" size={8} />
      <div className="flex flex-col">
        <span className="font-bold">{title}</span>
        <span className="text-gray-70">{units} Units | {abilities} Abilities</span>
      </div>
    </div>
  )
}
FeedbackSummaryCard.propTypes = {
  title: PropTypes.string,
  emoji: PropTypes.string,
  units: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  abilities: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onSelect: PropTypes.func,
}

export default FeedbackSummaryCard
