import axios from 'axios'
import { toast } from 'sonner'
import useSWR from 'swr'
import useCycles from './useCycles'

const useBulkSync = () => {
  const { selectedCycle } = useCycles()
  const { data: { result = {} } = { result: {} }, mutate, error } = useSWR(() => `/expeditions/sync-all-for-cycle?cycle_id=${selectedCycle.id}`)
  

  const sync = () =>
    axios
      .post('/expeditions/sync-all-for-cycle', {
        cycle_id: selectedCycle.id
      })
      .then(res => {
        const { message, type } = res.data.result
        toast[type](message)
        mutate()
      })

  return {
    sync,
    data: result,
    isError: error,
    isLoading: !result
  }
}
export default useBulkSync
