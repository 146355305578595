import { Button, TextField } from "@designsystem"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { useToast } from "@hooks/useToast"
import serializeFormData from "@utils/serializeFormData"
import axios from "axios"
import React, {  useRef, useState } from "react"
import { Form, json, useActionData, useLoaderData, useNavigation, useSubmit } from "react-router-dom"

interface AssignedEmployee {
  id: number,
  conferenceUrl: string,
  cohorts: number[]
}

interface LoaderData {
  employees: {
    id: number,
    name: string,
    conferenceUrl: string,
  }[],
  cohorts: {
    id: number,
    title: string,
  }[],
  cycles: {
    id: number,
    title: string,
  }[]
}

type ActionData = {
  toast: {
    message: string,
    appearance: 'success' | 'error'
  }
}

export async function loader() {
  const res = await axios.get('/pages/admin/workbench/google/office-hours')
  return res.data
}

export async function action({ request }) {
  const formData = serializeFormData(await request.formData())
  try {
    await axios.put('/pages/admin/workbench/google/generate-office-hours', formData)
    return {
      toast: {
        message: 'The Office Hours Events are being created. It may take a while, please wait...',
        appearance: 'success'
      }
    }
  } catch (error) {
    return {
      toast: {
        message: 'Something went wrong while creating the Office Hours Events...',
        appearance: 'error'
      }
    }
  }
}

export default function WorkbenchGoogleOfficeHoursRoute() {
  const loaderData = useLoaderData() as LoaderData
  useToast(useActionData() as ActionData)
  const navigation = useNavigation()
  const submit = useSubmit()
  const formRef = useRef<HTMLFormElement>()
  const [zoomLink, setZoomLink] = useState('')
  const [assignedEmployees, setAssignedEmployees] = useState<AssignedEmployee[]>([])

  const handleAddAction = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const newEmployee = {
      id: Number(formData.get('employee_id')),
      conferenceUrl: formData.get('conference_url') as string,
      cohorts: formData.getAll('cohorts[]').map((c) => Number(c))
    }
    setAssignedEmployees((prevState) => [...prevState, newEmployee])
    setZoomLink('')
    formRef.current.reset()
  }

  const handleRemoveAction = (id: number) => {
    setAssignedEmployees((prevState) => [...prevState.filter((emp) => emp.id !== id)])
  }
  const avaialbleEmployees = loaderData.employees.filter((emp) => !assignedEmployees.find((aEmp) => aEmp.id === emp.id))
  return (
    <section className="grid grid-cols-2 gap-4">
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">Assign Office Hours</h1>
        <Button onClick={() => {
        }}>Explosion</Button>
        <span>Assign employees to review their data and create office hours.</span>
        <form className="flex flex-col gap-4" ref={formRef} onSubmit={handleAddAction}>
          <TextField label="Employee" name="employee_id" fullWidth required as="select" defaultValue={-1} onChange={(e) => {
            const employeeId = Number(e.target.value)
            setZoomLink(avaialbleEmployees.find(({ id }) => id === employeeId)?.conferenceUrl || '')
          }}>
            <option value={-1}>Select an employee</option>
            {avaialbleEmployees.map((employee) => (
              <option key={`employee-${employee.id}`} value={employee.id}>{employee.name}</option>
            ))}
          </TextField>
          <fieldset className="gap-4 flex" aria-required="true">
            <legend className="mb-2">Cohorts</legend>
            <label className="flex items-center gap-1">
              <input type="checkbox" name="cohorts[]" value="1" />
              <span>Chronos</span>
            </label>
            <label className="flex items-center gap-1">
              <input type="checkbox" name="cohorts[]" value="2" />
              <span>Nova</span>
            </label>
            <label className="flex items-center gap-1">
              <input type="checkbox" name="cohorts[]" value="3" />
              <span>Athena</span>
            </label>
          </fieldset>
          <TextField label="Zoom Link" name="conference_url" fullWidth required value={zoomLink} onChange={(e) => setZoomLink(e.target.value)} />
          <Button type="submit">Assign Employee</Button>
        </form>
      </div>
      {assignedEmployees.length > 0 ? (
        <div className="flex flex-col gap-3">
          <h2 className="text-2xl font-bold">Assigned Employees List</h2>
          <TextField form="creation-form" name="cycle_id" label="Pick a cycle" as="select" fullWidth required defaultValue={-1}>
            <option value={-1}>Select a cycle</option>
            {loaderData.cycles.map((cycle) => (
              <option key={`cycle-${cycle.id}`} value={cycle.id}>{cycle.title}</option>
            ))}
          </TextField>
          {assignedEmployees.map((aEmp, index) => (
            <div key={`aEmp_${aEmp.id}`} className="flex flex-col rounded-lg bg-white p-4 gap-3">
              <input type="hidden" name={`assigned_employees[${index}].id`} value={aEmp.id} />
              <input type="hidden" name={`assigned_employees[${index}].conference_url`} value={aEmp.conferenceUrl} />
              <input type="hidden" name={`assigned_employees[${index}].cohorts`} value={aEmp.cohorts.toString()} />
              <div className="flex gap-1">
                <span className="font-bold">Name:</span>
                <span>{loaderData.employees.find((emp) => emp.id === Number(aEmp.id))?.name}</span>
              </div>
              <div className="flex gap-1">
                <span className="font-bold">Zoom Link:</span>
                <span>{aEmp.conferenceUrl}</span>
              </div>
              <div className="flex gap-1">
                <span className="font-bold">Cohorts:</span>
                <span>{aEmp.cohorts.map((c) => loaderData.cohorts.find((co) => co.id === c)?.title).join(', ')}</span>
              </div>
              <span className="self-end">
                <Button type="button" onClick={() => handleRemoveAction(aEmp.id)} startIcon={faTrash}>Remove Assignment</Button>
              </span>
            </div>
          ))}
          <Form method="PUT" id="creation-form" onSubmit={(e) => {
            e.preventDefault()
            submit(e.currentTarget, { method: 'PUT' })
            setAssignedEmployees([])
          }}>
            <input type="hidden" name="assignedEmployees" value={JSON.stringify(assignedEmployees)} />
            <span className="flex self-center justify-center">
              <Button loading={navigation.state === 'submitting'} type="submit">Create Office Hours For All Assigned Employees</Button>
            </span>
          </Form>
        </div>
      )
        : (
          <div className="flex flex-col space-y-2 justify-center items-center h-full">
            <img src="/assets/notifications/no_new_notifications.png" alt="Cactus in desert background" />
            <h1 className="text-3xl font-bold text-center">No employees assigned</h1>
          </div>
        )
      }
    </section >
  )
}

