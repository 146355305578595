import Select from '@components/forms/select'
import React, { useState } from 'react'
import usePermissions from './usePermissions'
import getColors from '../colors'

const getMatrix = (groups) => {
  if (!groups[0]?.permissions) return []

  const permissionsLength = groups[0].permissions.length
  const rows = []

  for (let i = 0; i < permissionsLength; i++) {
    rows.push(
      groups.map((group, j) => {
        return {
          permission_group_id: group.permission_group_id,
          permission_id: group.permissions[i].id,
          permission_title: group.permissions[i].title,
          has_permission: group.permissions[i].has_permission,
          colors: group.colors,
        }
      })
    )
  }
  return rows
}

const PermissionsTable = () => {
  const [selectedGroupsIds, setSelectedGroupsIds] = useState([])
  const { groups: groupsData = [], addPermissionToGroup, removePermissionFromGroup } = usePermissions()

  const groups = groupsData.map((group, i) => ({ ...group, colors: getColors(i) }))

  const handlePermissionChange = async (permission_group_id, permission_id, has_permission) => {
    if (has_permission)
      return await removePermissionFromGroup(permission_group_id, permission_id)

    await addPermissionToGroup(permission_group_id, permission_id)
  }

  const handleSelectGroupChange = (e) => setSelectedGroupsIds(e.map(g => g.value))

  const groupsOptions = groups.map(({ permission_group_id, permission_group }) => ({ value: permission_group_id, label: permission_group }))
  const visibleGroups = selectedGroupsIds.length ? groups.filter(g => selectedGroupsIds.includes(g.permission_group_id)) : groups

  const matrix = getMatrix(visibleGroups)

  return (
    <div>
      <h1 className='font-bold text-2xl'>Permissions</h1>
      <div className="text-sm bg-white rounded shadow-md p-2 mt-2">
        <div className="mb-4">
          <Select
            isMulti
            isSearchable={false}
            placeholder="Select groups to filter"
            value={groupsOptions.filter(option => selectedGroupsIds.includes(option.value))}
            options={groupsOptions}
            onChange={handleSelectGroupChange}
            className="text-gray-50 w-full"
          />
        </div>
        <table className="w-full table-fixed text-center uppercase border">
          <thead>
            <tr>
              <th className="w-44" />
              {visibleGroups.map((group, i) => (
                <th className={`text-xs ${group.colors.text}`} key={group.permission_group}>
                  {group.permission_group}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {matrix.map((row, i) => (
              <tr key={i} className="border border-gray-10">
                {row.map(({ has_permission, permission_group_id, permission_id, permission_title, colors }, j) => (
                  <>
                    {j === 0 && (<td className={`bg-gray-10 px-2 text-xs ${i > 0 && 'border-t border-white'}`}>{permission_title}</td>)}
                    <td key={j}>
                      <div className="py-3 w-full flex justify-center">
                        <div className={`h-8 w-8 rounded-full border cursor-pointer hover:ring-1 ${colors.border} ${has_permission && colors.bg}`}
                          onClick={() => handlePermissionChange(permission_group_id, permission_id, has_permission)}
                        >
                        </div>
                      </div>
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PermissionsTable
