import React from 'react'
import { Tabs } from '@design-system'
import { Outlet } from 'react-router'

const Element = () => {

  return (
    <div className='flex flex-col h-full relative'>
      <div className='flex flex-row justify-between items-center pb-3'>
        <span className='font-bold text-4xl'>Pathways</span>
      </div>
      <Tabs>
        <Tabs.Item title="Management" to="management" />
        <Tabs.Item title="Students" to="students" />
      </Tabs>
      <div className='mt-4 relative h-full'>
        <div className='absolute overflow-auto top-0 bottom-0 right-0 left-0 h-full'>
          <Outlet />
        </div>
      </div>
    </div>
  )

}

export const WorkbenchPathwaysRoute = {
  Element,
}
