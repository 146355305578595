import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MasteryBar from '@components/mastery-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import useSWR from 'swr'

const AbilityRubric = ({ id }) => {
  const { data } = useSWR(id ? `/abilities/v2/${id}` : null)
  const ability = data?.ability || null

  const levelMap = {
    attempted: 0.5,
    exposure: 1,
    developing: 2,
    demonstrating: 3,
    extending: 4,
  }

  const [activeLevels, setActiveLevels] = useState(
    new Set(['attempted', 'exposure', 'developing', 'demonstrating', 'extending'])
  )

  if (!ability) return <span>Loading...</span>

  return (
    <div className="flex flex-col gap-4 whitespace-pre-wrap">
      <div className="flex flex-row border-b items-center border-gray-40 dark:border-gray-60 w-full pb-4">
        <div className="px-6 py-4 bg-gray-20 dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg">Ability</div>
        <span className="font-bold text-md ml-4">{ability.title}</span>
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <h4 className="font-bold text-lg lg:self-end">Rubric levels</h4>
        {Object.keys(levelMap).map((level, idx) => {
          return (
            <button
              key={`button-${idx}`}
              className={`border border-green-50 px-2 py-1 rounded-full text-sm ${activeLevels.has(level)
                ? 'text-white bg-green-50'
                : 'text-green-50'
                }`}
              type="button"
              onClick={() => {
                if (activeLevels.has(level)) {
                  setActiveLevels(
                    (prev) => new Set([...prev].filter((x) => x !== level))
                  )
                } else {
                  setActiveLevels((prev) => new Set(prev.add(level)))
                }
              }}
            >
              {levelMap[level]} {level}
              {activeLevels.has(level) && (
                <FontAwesomeIcon
                  icon={faX}
                  className="ml-2 h-3 w-3 text-white cursor-pointer"
                />
              )}
            </button>
          )
        })}
      </div>
      {Array.from(activeLevels)
        .sort((a, b) => levelMap[a] - levelMap[b])
        .map((levelName, idx) => {
          return (
            <div
              className="grid grid-cols-1 lg:grid-cols-5 gap-4 border-b border-gray-40 dark:border-gray-60 pb-4"
              key={`levelname-${idx}`}
            >
              <div>
                <span className="text-sm font-bold">
                  <span className="capitalize">
                    {levelMap[levelName]} {levelName}
                  </span>
                </span>
                <MasteryBar level={levelMap[levelName]} />
              </div>
              {ability.subabilities.map((subability, idx) => {
                return (
                  <div key={`demo-${idx}`} className="flex flex-col">
                    <span className="text-sm font-bold">
                      {subability.title}
                    </span>
                    <span className="text-xs">{subability[levelName]}</span>
                  </div>
                )
              })}
            </div>
          )
        })}
    </div>
  )
}

AbilityRubric.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
}

export default AbilityRubric
