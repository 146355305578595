import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Popover, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import Datepicker from '@components/forms/datepicker'
import axios from 'axios'
import { toast } from 'sonner'
import capitalize from '@utils/capitalize'
import { usePopper } from 'react-popper'
import { tzAgnosticDate } from '@utils/tzAgnosticDate'
import useSWR from 'swr'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'

export default function HouseCell({ studentId, house, afterSubmit, latestHouse }) {
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  return <Popover>
    <Popover.Button title="Change Houses" className="flex items-center text-left space-x-2 group" ref={setReferenceElement}>

      {house === 'Unassigned' ?
        <div>
          {capitalize(house)}
          <div className="text-xs text-gray-50 mt-1">Last house: {latestHouse}</div>
        </div> :
        <div>{capitalize(house)}</div>}
      <FontAwesomeIcon className="hidden group-hover:flex mb-1" icon={faPencilAlt} />
    </Popover.Button>
    <Transition
      className="absolute z-10"
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {({ open }) => (
          <div className="bg-white border my-1 rounded shadow-xl divide-y dark:bg-gray-95">
            {open && (
              <StudentHousesEditableList studentId={studentId} afterSubmit={afterSubmit} />
            )}
          </div>
        )}
      </Popover.Panel>
    </Transition>
  </Popover>
}

HouseCell.propTypes = {
  studentId: PropTypes.number,
  house: PropTypes.string,
  latestHouse: PropTypes.string,
  afterSubmit: PropTypes.func,
}

function StudentHousesEditableList({ studentId, afterSubmit }) {
  const { data, error, mutate } = useSWR(`/pages/employee/students/${studentId}/house-editor`)
  const isLoading = !data && !error
  const [submissionState, setSubmissionState] = useState(null)

  if (isLoading) {
    return <div className="p-4">Loading...</div>
  }

  const houseHistory = data.houseHistory
  const canEditStudentHouse = data.canEditStudentHouse
  const houses = data.houses
  const isUnassigned = houseHistory?.length === 0

  const handleHouseAssigns = e => {
    e.preventDefault()
    const house = Object.fromEntries(new FormData(e.target))
    const { title: newHouseName } = houses.find(h => Number(h.id) === Number(house.house_id)) || {}
    axios.post('/pages/employee/students/house-editor', house)
      .then(() => { setSubmissionState(null); toast.success('House added') })
      .catch(() => { toast.error('Something went wrong') })
      .finally(() => {
        mutate()
        afterSubmit?.(studentId, newHouseName && { house: `Added ${newHouseName}, joined on ${house.joined_date}` })
      })
  }

  const handleDeleteHouseHistory = student_house_id => {
    const { house_title: removedHouseName = null, joined_date } = houseHistory.find(h => Number(h.id) === Number(student_house_id)) || {}
    const [houseJoinedDate] = joined_date?.split('T') || []
    axios.delete(`/pages/employee/students/house-editor/${student_house_id}`)
      .then(() => { setSubmissionState(null); toast.success('House removed') })
      .catch(() => { toast.error('Something went wrong') })
      .finally(() => {
        mutate()
        afterSubmit?.(studentId, removedHouseName && { house: `Removed ${removedHouseName}, joined on ${houseJoinedDate}` })
      })
  }

  return <>
    <ul className="divide-y p-2 whitespace-nowrap">
      {houseHistory
        .map(sh => {
          return <li key={sh.id} className="whitespace-nowrap flex justify-between space-x-4 py-2 px-2">
            <span className="flex-1">Joins {sh.house_title}</span>
            <span>{tzAgnosticDate(sh.joined_date).toLocaleDateString()}</span>
            {canEditStudentHouse && sh.is_editable && (
              <button type="button" className="text-danger-40 flex-none" onClick={(e) => {
                e.preventDefault()
                handleDeleteHouseHistory(sh.id)
              }}><FontAwesomeIcon icon={faTrash} /></button>
            )}
          </li>
        })}
      {isUnassigned && <span className="p-4">Assign the first House</span>}
    </ul>
    <form onSubmit={(e) => {
      e.preventDefault()
      handleHouseAssigns(e)
    }} className="flex justify-center space-x-2 p-2 text-center text-xs">
      <input type="hidden" name="student_id" value={studentId} />
      {submissionState === 'assigning' && (
        <div>
          <div className="grid grid-cols-2 gap-2 min-w-80">
            <label>
              Assign to
              <select required name="house_id" className="border border-gray-10 rounded-lg py-[7px] pl-2 text-xs mt-0.5 w-full text-black">
                {houses.map(h => <option key={h.id} value={h.id}>{h.title}</option>)}
              </select>
            </label>
            <label>
              Starting At
              <Datepicker
                name="joined_date"
                required
                min={format(addDays(new Date(), 1), 'yyyy-MM-dd')}
                className="dark:text-black"
              />
            </label>
          </div>
          <div className="flex flex-start mt-2 space-x-2">
            <button className="bg-cyan-40 text-white text-xs font-bold rounded px-2 py-1">Save</button>
            <button type="button" className="bg-white text-cyan-40 border border-cyan-40 text-xs font-bold rounded px-2 py-1" onClick={(e) => {
              e.preventDefault()
              setSubmissionState(null)
            }}>Cancel</button>
          </div>
        </div>
      )}
      {canEditStudentHouse && !submissionState && (
        <button type="button" className="bg-cyan-40 text-white text-xs font-bold rounded px-2 py-1 whitespace-nowrap" onClick={() =>
          setSubmissionState('assigning')}>
          Assign
        </button>
      )}
    </form>
  </>
}

StudentHousesEditableList.propTypes = {
  studentId: PropTypes.number,
  afterSubmit: PropTypes.func,
}
