import React, { useEffect } from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router-dom'
import { toast } from 'sonner'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SoraLink from '@components/link'
import { Button, Logo } from '@design-system'
import { ReactComponent as BtnGoogleLightFocusIos } from '@assets/svgs/btn-google.svg'
import useQuery from '@hooks/useQuery'
import { requestForToken } from '@services/push-notification'

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

export const loader = async ({ request }) => {
  const url = new URL(request.url)
  let error = url.searchParams.get('error')
  if (error) url.searchParams.delete('error')
  window.history.replaceState({}, document.title, url.href)
  if (error?.length > 0) return { error }
  const returnTo = url.searchParams.get('return_to')

  try {
    const { data } = await axios.get(`/auth/validate`, { baseURL: '/' })
    if (data.valid && !returnTo) window.location.href = '/'
  } catch (err) {
    return { error: err.message }
  }

  return {}
}

export default function SignInRoute() {
  const loaderData = useLoaderData()
  const [query] = useQuery()

  useEffect(() => {
    if (loaderData.error) toast.error(loaderData.error)
  }, [loaderData])


  const handleLoginWithGoogle = async () => {
    const pushNotificationToken = await requestForToken()
    const urlParams = new URLSearchParams()
    urlParams.append('push_notification_token', pushNotificationToken)
    if(query.return_to) {
      urlParams.append('return_to', query.return_to)
    }
    if (process.env.NODE_ENV === 'development') {
      window.location.href = `http://localhost:3001/auth/google/web?${encodeURI(urlParams.toString())}`
    } else {
      window.location.href = `/auth/google/web?${encodeURI(urlParams.toString())}`
    }
  }

  const isLoading = false

  return (
    <div className="h-full flex items-center justify-center">
      {isIE11 && (
        <h1 className="absolute top-0 bg-yellow-20 dark:bg-yellow-10 font-semibold text-gray-100 rounded my-2 mx-auto p-4 z-10">
          Change or update your browser for a better experience. We recommend
          these browsers: Google Chrome, Firefox, MS Edge, Safari, and Opera
        </h1>
      )}
      <div className="max-w-md w-full sm:border border-gray-30 dark:border-gray-90 md:rounded-xl text-center flex flex-col gap-8 p-8 bg-white dark:bg-gray-100 sm:dark:bg-gray-95">
        <div className="flex justify-center">
          <a href="https://soraschools.com/" target="_blank" rel="noreferrer">
            <Logo data-cy="login-page-sora-logo" size="lg" />
          </a>
        </div>
        {isLoading ? (
          <div className="flex-1 flex flex-col justify-around items-center">
            <FontAwesomeIcon className="animate-spin" icon={faSpinner} />
          </div>
        ) : (
          <>
            <p>
              Log in to continue as <strong>Student</strong> or{' '}
              <strong>Faculty</strong>
            </p>
            <Button
              autoFocus
              data-cy="google-login-btn"
              variant="outlined"
              color="soft"
              className="!ring-0"
              onClick={handleLoginWithGoogle}
            >
              <BtnGoogleLightFocusIos
                alt="Google's G colored from top to bottom: red, yellow, green and blue."
              />
              Continue with Google
            </Button>
            <div className="flex items-center">
              <hr className="flex-1 border-gray-20 dark:border-gray-85" />
              <div className="relative px-2 z-5 inline-block">OR</div>
              <hr className="flex-1  border-gray-20 dark:border-gray-85" />
            </div>
            <div data-cy="guardian-login-container">
              <Button asChild variant="ghost">
                <SoraLink to="https://app.soraschools.com">
                  Continue as a Guardian
                </SoraLink>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

