import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const MyOtherSeparator = ({ myLabel = ' My Own', otherLabel = ' Other\'s', centerLabels = true }) => {
  return <div className={`my-2 text-gray-20 text-xs uppercase font-bold flex flex-col ${centerLabels ? 'items-center' : ''}`}>
    <div className="w-40"><FontAwesomeIcon icon={faArrowUp} /> {myLabel}</div>
    <div className="w-full border-b-2 border-dashed border-gray-10"></div>
    <div className="w-40"><FontAwesomeIcon icon={faArrowDown} /> {otherLabel}</div>
  </div>
}

MyOtherSeparator.propTypes = {
  myLabel: PropTypes.string,
  otherLabel: PropTypes.string,
  centerLabels: PropTypes.bool
}

export default MyOtherSeparator
