import * as React from 'react'
import {
  Button,
  unstable_Collapsible as Collapsible,
  unstable_Popover as Popover,
  DropdownMenu,
  Icon,
  Typography,
} from '@design-system'
import SlateTextarea, { BlockButton, LinkButton, MarkButton } from '@components/forms/slate-textarea'
import { LoaderData, type TaskPerk } from './types'
import { CommentBankButton } from './comment-bank-button'

type StandardTaskBodyProps = {
  taskUnits: TaskPerk[]
  taskAbilities: TaskPerk[]
  presetComments: Pick<LoaderData, 'presetComments'>['presetComments']
}

export function StandardTaskBody({ taskUnits, taskAbilities, presetComments }: StandardTaskBodyProps) {
  return (
    <>
      {taskUnits.length > 0 && (
        <Typography variant="body" weight="bold" className="pb-2 pt-6">
          Units ({taskUnits.length})
        </Typography>
      )}

      <div className="flex flex-col gap-4">
        {taskUnits.map((el, index) => {
          return (
            <React.Fragment key={`collapsible_unit_${el.id}`}>
              <input type="hidden" name={`${el.type}[${index}][title]`} value={el.title}></input>
              <input type="hidden" name={`${el.type}[${index}][id]`} value={el.id}></input>
              <input type="hidden" name={`${el.type}[${index}][comment_id]`} value={el.comment_id}></input>
              <PerkCollapsible
                formName={`${el.type}[${index}][flag]`}
                initialDropdownValue={el.flag ?? 'not_covered'}
                topicTitle={el.topicTitle}
                perkTitle={el.title}
                masteryText={`Current mastery: ${el.mastery}`}
              >
                <PerkSlateTextArea perk={el} index={index} presetComments={presetComments} />
              </PerkCollapsible>
            </React.Fragment>
          )
        })}
      </div>

      {taskAbilities.length > 0 && (
        <Typography variant="body" weight="bold" className="pb-2 pt-6">
          Abilities ({taskAbilities.length})
        </Typography>
      )}
      <div className="flex flex-col gap-4">
        {taskAbilities.map((el, index) => {
          return (
            <React.Fragment key={`collapsible_ability_${el.id}`}>
              <input type="hidden" name={`${el.type}[${index}][title]`} value={el.title}></input>
              <input type="hidden" name={`${el.type}[${index}][id]`} value={el.id}></input>
              <input type="hidden" name={`${el.type}[${index}][comment_id]`} value={el.comment_id}></input>
              <PerkCollapsible
                formName={`${el.type}[${index}][flag]`}
                initialDropdownValue={el.flag ?? 'not_covered'}
                perkTitle={el.title}
                masteryText={`Current mastery: ${el.mastery}`}
              >
                <PerkSlateTextArea perk={el} index={index} presetComments={presetComments} />
              </PerkCollapsible>
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}

interface PerkSlateTextAreaProps {
  perk: TaskPerk
  index: number
  presetComments: Pick<StandardTaskBodyProps, 'presetComments'>['presetComments']
}

function PerkSlateTextArea({ perk, index, presetComments }: PerkSlateTextAreaProps) {
  const slateTextAreaRef = React.useRef<typeof SlateTextarea>()

  return (
    <SlateTextarea
      ref={slateTextAreaRef}
      // @ts-expect-error slate is not typed so it doesn't know about the value prop
      value={perk.comment}
      className="h-48"
      name={`${perk.type}[${index}][comment]`}
      customTopBar={<SlateTextAreaCustomBar />}
      customBottomBar={
        <CommentBankButton
          presetComments={[...presetComments.experience, ...presetComments.general]}
          linkTo="../comment-bank"
          emptyLinkTo="../comment-bank/preset-comment/create"
          alignOffset={-400}
          className="flex flex-col gap-2 min-h-80 max-h-96 w-[30rem]"
          trigger={
            <div className="flex items-center sticky bottom-0 bg-white z-5 px-2">
              <Popover.Trigger>
                <Button type="button" variant={`ghost`} color={'dark'} size="sm">
                  <Icon name={'comment-plus'} />
                </Button>
              </Popover.Trigger>
            </div>
          }
          onSelectComment={(commentBody) => {
            // @ts-expect-error slate is not typed so it doesn't know about forward ref values
            if (slateTextAreaRef.current) slateTextAreaRef.current?.appendValue(commentBody)
          }}
        />
      }
    />
  )
}

function SlateTextAreaCustomBar() {
  return (
    <>
      <div className="flex items-center border-b-[1px] border-neutral-300  sticky top-0 bg-white z-5 h-9 py-1">
        <MarkButton
          disabled={false}
          format="bold"
          icon={<Icon className="leading-['inherit']" name="text-bold" size="md" />}
        />
        <MarkButton
          disabled={false}
          format="italic"
          icon={<Icon className="leading-['inherit']" name="text-italic" size="md" />}
        />
        <BlockButton
          disabled={false}
          format="numbered-list"
          icon={<Icon className="leading-['inherit']" name="list-ordered" size="md" />}
        />
        <BlockButton
          disabled={false}
          format="bulleted-list"
          icon={<Icon className="leading-['inherit']" name="list-unordered" size="md" />}
        />
        <LinkButton disabled={false} />
        <MarkButton
          disabled={false}
          format="strikethrough"
          icon={<Icon className="leading-['inherit']" name="text-strikethrough" size="md" />}
        />
        <MarkButton
          disabled={false}
          format="underline"
          icon={<Icon className="leading-['inherit']" name="text-underline" size="md" />}
        />
      </div>
    </>
  )
}

interface PerkCollapsibleProps {
  formName: string
  children: React.ReactNode
  initialDropdownValue?: string
  topicTitle?: string | null
  perkTitle: string
  masteryText: string
}

function PerkCollapsible({
  formName,
  children,
  initialDropdownValue = 'not_covered',
  topicTitle = null,
  perkTitle,
  masteryText,
}: PerkCollapsibleProps) {
  const [open, setOpen] = React.useState(initialDropdownValue === 'not_covered' ? false : true)

  function handleDropdownStatusChange(value: string): boolean {
    if (value === 'not_covered') {
      setOpen(false)
      return false
    }
    setOpen(true)
    return true
  }

  React.useEffect(() => {
    setOpen(initialDropdownValue === 'not_covered' ? false : true)
  }, [initialDropdownValue])

  return (
    <Collapsible open={open} className="flex flex-col w-full p-4 bg-stone-50 rounded-lg gap-2 justify-between">
      <div className="flex self-stretch items-center">
        <div className="grow  flex-col items-star">
          {topicTitle && <Typography variant="footnote">{topicTitle}</Typography>}
          <Typography variant="callout" weight="bold">
            {perkTitle}
          </Typography>
          <Typography variant="callout">{masteryText}</Typography>
        </div>
        <TaskFeedbackDropdown
          formName={formName}
          initialValue={initialDropdownValue}
          onChangeStatus={handleDropdownStatusChange}
        ></TaskFeedbackDropdown>
      </div>
      <Collapsible.Content>{children}</Collapsible.Content>
    </Collapsible>
  )
}

function TaskFeedbackDropdown({ initialValue = 'not_covered', formName, onChangeStatus }) {
  const [status, setStatus] = React.useState(initialValue)

  React.useEffect(() => {
    setStatus(initialValue)
  }, [initialValue])

  const valueLabel = {
    ontrack: 'On Track',
    needs_improvement: 'Needs Improvement',
    not_covered: 'Not Yet Covered',
  }

  return (
    <>
      <input type="hidden" name={formName} value={status} />
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button variant="outlined" size="xs">
            {valueLabel[status]} <Icon name="chevron-down" size="xs" />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="w-56 bg-white">
          <DropdownMenu.Separator />
          <DropdownMenu.RadioGroup
            value={status}
            onValueChange={(value) => {
              setStatus(value)
              onChangeStatus(value)
            }}
          >
            {Object.entries(valueLabel).map(([key, value]) => (
              <DropdownMenu.RadioItem key={key} value={key}>
                {value}
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu>
    </>
  )
}
