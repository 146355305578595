import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { Pill } from '@design-system'

export type PathwayVariant = 'activity' | 'electives' | 'expedition' | 'humanities' | 'independent_study' | 'language_learning_goal' | 'learning_goal' | 'math_learning_goal' | 'stem' | 'track' | 'coworking'

interface PillAllowedProps extends Pick<ComponentProps<typeof Pill>, 'rounded' | 'variant' | 'color' | 'disabled'> { }

const variants: Record<PathwayVariant, PillAllowedProps> = {
  activity: {
    color: 'cyan-5',
  },
  electives: {
    color: 'orange-5',
  },
  expedition: {
    color: 'pink-5',
  },
  humanities: {
    color: 'turquoise-5',
  },
  independent_study: {
    color: 'purple-5',
  },
  language_learning_goal: {
    color: 'blue-5',
  },
  learning_goal: {
    color: 'notice-5',
  },
  math_learning_goal: {
    color: 'purple-5',
  },
  stem: {
    color: 'green-5',
  },
  track: {
    color: 'green-5',
  },
  coworking: {
    color: 'yellow-5',
  },
}

interface PillPathwayProps extends PropsWithChildren {
  variant?: PathwayVariant
  title: string
}

/**
 * PillPathway display a pill with student progress
 */
export const PillPathway = (props: PillPathwayProps) => {
  const { ...pillProps } = variants[props.variant] ?? {}
  return (
    <Pill {...pillProps}>
      <Pill.Value>{props.title}</Pill.Value>
    </Pill>
  )
}
