import React from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router-dom'
import { Typography } from '@design-system'
import { MasterLabelValuesType, PillMasteryLabels } from '@blocks/pill-mastery-labels'

interface LoaderData {
  exemption?: {
    credit: number
    grade: string
    school: string
    course: string
    subject: string
  }
  units: {
    id: number
    title: string
    avgMastery: MasterLabelValuesType
    isExempt: boolean
  }[]
}

export async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.student_id}/program/${params.schoolStage}/${params.subjectId}/details/${params.topicId}?${searchParams.toString()}`)
  return data
}

function Element() {
  const { exemption, units } = useLoaderData() as LoaderData
  return (
    <>
      {exemption && (
        <div className="space-y-4 pt-2">
          <Typography weight="bold">Exemption</Typography>
          <div className="pt-4 flex flex-wrap gap-x-12 lg:gap-x-20 gap-y-4 border-t-2 border-solid border-gray-30 dark:border-gray-90">
            {Object.entries(exemption).map(([label, value]) => (
              <div className="flex flex-col gap-3" key={label}>
                <Typography weight="bold" className="capitalize">{label}</Typography>
                <Typography>{value}</Typography>
              </div>
            ))}
          </div>
        </div>
      )}
      {units?.length > 0 && (
        <div className="space-y-4 pt-2">
          <Typography weight="bold">Units</Typography>
          <ul>
            {units.map(({ id, title, avgMastery, isExempt }) => (
              <li key={id} className="flex justify-between items-center gap-2 py-4 border-t-2 first:border-solid border-gray-30 dark:border-gray-90 border-dashed">
                <Typography weight="bold">{title}</Typography>
                <PillMasteryLabels value={avgMastery} isExempt={isExempt} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

export const EmployeeStudentProgramSubjectDetailsTopicRoute = {
  Element,
  loader,
}


