import React from 'react'
import { useTable, useFilters, useGlobalFilter, useRowSelect } from 'react-table'
import { Button } from '@designsystem'
import formatDate from '@utils/formatDate'
import pluralize from '@utils/pluralize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrash, faX } from '@fortawesome/free-solid-svg-icons'
import { IReportTable, ReportTableColumns } from './types'

export function ReportTable({ data, onApproveExcuse, onRejectExcuse, onRemoveExcuse }: IReportTable) {
  const dateOpts = {
    timeZone: 'America/New_York',
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: 'numeric',
    hour12: true,
    weekday: 'short',
  }

  const columns = React.useMemo<ReportTableColumns[]>(() => [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }) => (<RowSelectionCheckbox {...getToggleAllRowsSelectedProps()} />),
      Cell: ({ row }) => (<RowSelectionCheckbox {...row.getToggleRowSelectedProps()} />),
    },
    { Header: 'Requester', accessor: 'requester_name' },
    { Header: 'Email', accessor: 'requester_email' },
    { Header: 'Student', accessor: 'student_name' },
    { Header: 'Reason', accessor: 'reason' },
    { Header: 'Approved', accessor: 'approved', Cell: ({ value }) => <FontAwesomeIcon data-testid="approved-icon" data-approved={value ? 'true' : 'false'} icon={value ? faCheck : faX} className={`fill-current mx-auto w-full ${value ? 'text-turquoise-50' : 'text-danger-50'}`} /> },
    { Header: 'Start', accessor: 'start_at', Cell: ({ value }) => formatDate(value, 'en-US', dateOpts) },
    { Header: 'End', accessor: 'end_at', Cell: ({ value }) => formatDate(value, 'en-US', dateOpts) },
    { Header: 'Created at', accessor: 'created_at', Cell: ({ value }) => formatDate(value, 'en-US', dateOpts) },
    {
      Header: 'Options', Cell: ({ row: { original: { id, approved } } }) => (
        <div className="flex items-center justify-center">
          <Button data-testid="remove-excuse-btn" size="xs" tooltip="Remove Excuse" startIcon={faTrash} variant="nude" onClick={() => onRemoveExcuse(id)} />
          <Button data-testid={`${approved ? 'reject' : 'approve'}-excuse-btn`} size="xs" tooltip={(approved ? 'Reject' : 'Approve') + ' Excuse'} startIcon={approved ? faX : faCheck} variant="nude" onClick={() => {
            if (approved) { onRejectExcuse(id) }
            else { onApproveExcuse(id) }
          }} />
        </div>
      )
    }],
    []
  )

  const table = useTable(
    { columns, data },
    useRowSelect,
  )

  return (
    <>
      <div className="flex space-x-3 min-h-10 items-center my-2">
        {!!table.selectedFlatRows.length && <span data-testid="selected-rows-label">You've selected {pluralize(table.selectedFlatRows.length, 'excuse', undefined, true)}.</span>}
        {!!table.selectedFlatRows.length && (
          <>
            <Button
              size="xs"
              variant="outlined"
              startIcon={faTrash}
              onClick={() => { onRemoveExcuse(getSelectedRowsIds(table.selectedFlatRows)) }}
              data-testid="remove-selected-rows-btn"
            >
              Remove?
            </Button>
            <Button
              size="xs"
              variant="outlined"
              startIcon={faCheck}
              onClick={() => { onApproveExcuse(getSelectedRowsIds(table.selectedFlatRows)) }}
              data-testid="approve-selected-rows-btn"
            >
              Approve?
            </Button>
            <Button
              size="xs"
              variant="outlined"
              startIcon={faX}
              onClick={() => { onRejectExcuse(getSelectedRowsIds(table.selectedFlatRows)) }}
              data-testid="reject-selected-rows-btn"
            >
              Reject?
            </Button>
          </>
        )}
      </div>
      <div className="overflow-y-auto max-h-[30rem] md:max-h-[35rem] lg:max-h-[40rem] w-full">
        <table className="w-full" {...table.getTableProps()} data-testid="excuse-report-table">
          <thead>
            {table.headerGroups.map((headerGroup, id) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={id} className="bg-white dark:bg-black" data-testid="excuse-report-table-header-row">
                {headerGroup.headers.map((column, id) => (
                  <th {...column.getHeaderProps()} key={id} className="border px-3 py-2 text-left text-sm last:text-center" data-testid={`excuse-report-table-header-row-cell-${column.Header}`}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...table.getTableBodyProps()}>
            {table.rows.map((row) => {
              table.prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={row.original.id} className="bg-white hover:bg-blue-5 dark:bg-black dark:hover:bg-gray-95" data-testid="excuse-report-table-row">
                  {row.cells.map((cell, id) => (
                    <td {...cell.getCellProps()} key={id} className="border px-3 py-2 text-left text-xs" data-testid={`excuse-report-table-row-cell-${typeof cell.column.Header === 'string' ? cell.column.Header : ''}`}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

    </>
  )
}

const RowSelectionCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div className="flex items-center justify-center">
        <input className="rounded" type="checkbox" ref={resolvedRef} data-testid="select-row-checkbox" {...rest} />
      </div>
    )
  }
)

function getSelectedRowsIds(rows) { return rows?.map(r => r.original.id) || [] }
