import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import capitalize from '@utils/capitalize'
import { getSubjectColors } from '@utils/subjectColors'
import { getSubjectSectionTooltipText } from '../exemptions.utils'

const SectionTitle = ({ position, title }) => {
  return (
    <div
      className={`w-full flex flex-row justify-center items-center rounded-lg font-bold text-white px-2 py-1 ${getSubjectColors(
        position
      ).bg} text-center`}
    >
      <h4>{capitalize(title)} + Requirements</h4>
      <a
        data-tip
        data-for={`${title}-tooltip`}
        className="rounded-full w-5 h-5 border flex items-center justify-center border-white text-white ml-2 text-xs"
      >
        ?
      </a>
      <ReactTooltip id={`${title}-tooltip`}>
        <ul className="list-circle w-md">
          {getSubjectSectionTooltipText(title).map((text, i) => (
            <li key={text + i}>{text}</li>
          ))}
        </ul>
      </ReactTooltip>
    </div>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string,
  position: PropTypes.number,
}

export default SectionTitle
