import React, { useState } from 'react'
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  PaginationState,
} from '@tanstack/react-table'
import { TExperienceStudentRow } from './types'

export const ExperienceStudentsReportTable = ({ data }: { data: TExperienceStudentRow[] }) => {
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 25 })

  const columnHelper = createColumnHelper<TExperienceStudentRow>()
  const columns = [
    columnHelper.group({
      header: 'Experience',
      columns: [
        columnHelper.accessor('experience_id', { header: 'ID', cell: (c) => c.getValue() }),
        columnHelper.accessor('experience_title', { header: 'Title', cell: (c) => c.getValue() }),
        columnHelper.accessor('experience_type', { header: 'Type', cell: (c) => c.getValue() }),
      ],
    }),
    columnHelper.group({
      header: 'Student',
      columns: [
        columnHelper.accessor('student_name', { header: 'Name', cell: (c) => c.getValue() }),
        columnHelper.accessor('student_email', { header: 'Email', cell: (c) => c.getValue() }),
      ],
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  return (
    <>
      <table className="table-auto w-full border bg-white text-[0.9rem]" data-testid="experiences-report-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="text-left border-b">
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan} className={`px-3 py-2 ${!header.placeholderId && header.subHeaders.length && 'border-r'}`}>
                  {header.isPlaceholder ? null : (
                    <div className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''} onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!table.getRowModel().rows.length && (
            <tr className="border-b hover:bg-gray-10 text-center" data-testid="experiences-report-table-row">
              <td colSpan={table.getLeafHeaders().length}>No expeditions found...</td>
            </tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="border-b hover:bg-gray-10" data-testid="experiences-report-table-row">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-3 py-1">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row items-center justify-end py-2 gap-3">
        <button className="border rounded p-1" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>{'<<'}</button>
        <button className="border rounded p-1" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>{'<'}</button>
        <button className="border rounded p-1" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>{'>'}</button>
        <button className="border rounded p-1" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>{'>>'}</button>
        <span className="flex items-center gap-1">
          Page <strong>{table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</strong>
        </span>
        <select value={table.getState().pagination.pageSize} onChange={(e) => { table.setPageSize(Number(e.target.value)) }} className="h-10 rounded">
          {[25, 50, 100, data.length].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize === data.length ? 'All' : pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}
