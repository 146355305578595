import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import differenceInYears from 'date-fns/differenceInYears'
import isSameYear from 'date-fns/isSameYear'
import addYears from 'date-fns/addYears'
import pluralize from '@utils/pluralize'

const EXPECTED_YEARS_TO_GRADUATE = {
  ms: 3,
  hs: 4
}

export default function ExpectedGraduationDateCell({ school_stage, expected_graduation, start_date }) {
  const expectedYearToGraduate = addYears(new Date(start_date), EXPECTED_YEARS_TO_GRADUATE[school_stage])
  const timeDiff = differenceInYears(new Date(expected_graduation), expectedYearToGraduate)
  const showIcon = expected_graduation !== 'n/a' && !isSameYear(new Date(expected_graduation), expectedYearToGraduate)
  const msg = `Graduation is ${pluralize(Math.abs(timeDiff), 'year', undefined, true)} ${timeDiff > 0 ? 'slower' : 'faster'} than expected`
  return (
    <div className="flex items-center space-x-1 relative">
      <span data-testid="graduation-date-label">{expected_graduation}</span>
      {showIcon && (
        <Popover>
          <Popover.Button title={msg}>
            <FontAwesomeIcon icon={faCircleInfo} size="sm" data-testid="original-graduation-date-btn" className={`${timeDiff > 0 ? 'text-yellow-50' : 'text-turquoise-50'}`} />
          </Popover.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel className="flex flex-col bg-white border p-2 rounded shadow-xl whitespace-nowrap space-y-2 absolute z-1">
              <span className="text-xs" data-testid="progress-label">{msg}</span>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </div>
  )
}
ExpectedGraduationDateCell.propTypes = {
  school_stage: PropTypes.string,
  expected_graduation: PropTypes.string,
  start_date: PropTypes.string,
}
