import React from 'react'
import { Form, Link } from 'react-router-dom'
import { PortalModal } from '@components/modal'
import { Button, TextField } from '@designsystem'
import { SelectOption } from '@routes/admin/workbench/onboarding/families/$campusFilter/add-families-to-group'

interface OnboardingGroupModalProps {
  title: string,
  onClose: () => void,
  campus: SelectOption[],
  cycles: SelectOption[],
  selectedCycle?: string,
  selectedCampus?: string,
}

export default function OnboardingGroupModal({
  title,
  campus,
  cycles,
  onClose,
  selectedCycle = '',
  selectedCampus = '',
}: OnboardingGroupModalProps) {
  return (
    <PortalModal
      isOpen
      onClose={onClose}
      className="w-[755px]"
    >
      <div className="flex flex-col space-y-10">
        <header className="flex flex-col items-center w-full py-4 border-b-2 border-b-gray-10">
          <h1 className="text-2xl font-bold pt-4">{title}</h1>
        </header>
        <Form className="px-14 pb-16 flex flex-col space-y-6" method="post">
          <TextField required defaultValue={selectedCampus} label="Campus" as="select" name="cohort_id" fullWidth>
            <option value="" disabled>Select a campus</option>
            {campus.map((campus) => (
              <option key={campus.value} value={campus.value}>{campus.label}</option>
            ))}
          </TextField>
          <TextField required defaultValue={selectedCycle} label="School Start Date" as="select" name="cycle_id" fullWidth>
            <option value="" disabled>Select a Start Date</option>
            {cycles.map((cycle) => (
              <option key={cycle.value} value={cycle.value}>{cycle.label}</option>
            ))}
          </TextField>
          <div className="flex space-x-4 self-end">
            <Button as={Link} to=".." variant="outlined">Cancel</Button>
            <Button type="submit">Save</Button>
          </div>
        </Form>
      </div>
    </PortalModal>
  )
}