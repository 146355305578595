/* eslint-disable react/prop-types */
import React from 'react'

const ProgressIndicator = ({ children, percent, twsize, cutoff }) => {
  const size = twsize * 4
  const MAX_CIRCUMFRANCE =
    2 * (size / 2 - size / 10) * Math.PI * ((360 - cutoff) / 360)
  const strokeDashoffset = MAX_CIRCUMFRANCE - MAX_CIRCUMFRANCE * percent

  return (
    <div className={`w-${twsize} h-${twsize} relative mx-auto`}>
      <div className="flex justify-center items-center w-full h-full">
        {children}
      </div>

      {/* base layer */}
      <svg
        width={`${size}px`}
        height={`${size}px`}
        className="absolute top-0 left-0"
      >
        <circle
          cx={`${size / 2}`}
          cy={`${size / 2}`}
          r={`${size / 2 - size / 10}`}
          className='stroke-gray-30 dark:stroke-gray-60'
          fill="none"
          strokeWidth={`${size / 10}`}
          strokeDasharray={MAX_CIRCUMFRANCE}
          strokeDashoffset="0"
          transform={`rotate(${90 + cutoff / 2}, ${size / 2}, ${size / 2})`}
        />
      </svg>

      <svg
        width={`${size}px`}
        height={`${size}px`}
        className="absolute top-0 left-0"
      >
        <circle
          cx={`${size / 2}`}
          cy={`${size / 2}`}
          r={`${size / 2 - size / 10}`}
          // strokeLinecap="round"
          className='stroke-turquoise-40 dark:stroke-green-30 '
          fill="none"
          strokeWidth={`${size / 10}`}
          strokeDasharray={MAX_CIRCUMFRANCE}
          strokeDashoffset={!isNaN(strokeDashoffset) ? strokeDashoffset : 0}
          transform={`rotate(${90 + cutoff / 2}, ${size / 2}, ${size / 2})`}
        />
      </svg>
    </div>
  )
}

export default ProgressIndicator
