import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Popover, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { usePopper } from 'react-popper'
import axios from 'axios'
import { toast } from 'sonner'
import { TextField } from '@designsystem'
import format from 'date-fns/format'
import { tzAgnosticDate } from '@utils/tzAgnosticDate'

export default function GraduationDateCell({ student_id, graduation_date, source_graduation_date, afterSubmit }) {
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  return <Popover>
    <Popover.Button title="Change Houses" className="flex items-center text-left space-x-2 group" ref={setReferenceElement}>
      <span>{graduation_date}</span>
      <FontAwesomeIcon className="hidden group-hover:flex mb-1" icon={faPencilAlt} />
    </Popover.Button>
    <Transition
      className="absolute z-10"
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {({ open }) => (
          <div className="bg-white border my-1 rounded shadow-xl divide-y dark:bg-gray-95">
            {open && (
              <GraduationDateForm student_id={student_id} source_graduation_date={source_graduation_date} afterSubmit={afterSubmit} />
            )}
          </div>
        )}
      </Popover.Panel>
    </Transition>
  </Popover>
}

GraduationDateCell.propTypes = {
  student_id: PropTypes.number,
  graduation_date: PropTypes.string,
  source_graduation_date: PropTypes.string,
  afterSubmit: PropTypes.func,
}

function GraduationDateForm({ student_id, source_graduation_date, afterSubmit }) {


  const handleGraduationDateChange = e => {
    e.preventDefault()
    const body = Object.fromEntries(new FormData(e.target))
    axios.post('/students/graduation-date', body)
      .then(() => { toast.success('Graduation date changed') })
      .catch(() => { toast.error('Something went wrong') })
      .finally(() => {
        afterSubmit?.(student_id, body && { graduation_date: body.graduation_date })
      })
  }

  return <form onSubmit={(e) => {
    handleGraduationDateChange(e)
  }} className="flex justify-center space-x-2 p-2 text-center text-xs">
    <input type="hidden" name="student_id" value={student_id} />
    <TextField
      className="p-0"
      type="date"
      name="graduation_date"
      defaultValue={
        format(tzAgnosticDate(source_graduation_date), 'yyyy-MM-dd')
      }
    />
    <button className="bg-cyan-40 text-white text-xs font-bold rounded px-2 py-1">Save</button>
  </form>
}

GraduationDateForm.propTypes = {
  student_id: PropTypes.number,
  afterSubmit: PropTypes.func,
  graduation_date: PropTypes.string,
  source_graduation_date: PropTypes.string,
}
