import React, { useMemo, useState } from 'react'
import useSWR from 'swr'
import { toast } from 'sonner'
import PropTypes from 'prop-types'
import axios from 'axios'
import differenceInDays from 'date-fns/differenceInDays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Popover, Transition } from '@headlessui/react'
import Datepicker from '@components/forms/datepicker'
import { tzAgnosticDate } from '@utils/tzAgnosticDate'
// import format from 'date-fns/format'
// import addDays from 'date-fns/addDays'

export default function LeavesCell({ studentId, housesHistory, studentStatus, canEditStudentLeaves, afterSubmit }) {
  const sortedHistory = housesHistory?.sort(hh => hh.joined_date) || []
  return (
    <div className="flex flex-col space-y-1">
      <StudentLeaveStatusLabel currentStatus={studentStatus} leavesHistory={sortedHistory} />
      <Popover>
        <Popover.Button title="Student Status" className="text-left group focus:outline-none">
          {
            studentStatus === 'withdrawn'
              ? <FontAwesomeIcon className="hidden group-hover:flex mb-1" icon={faPencilAlt} />
              : <div className="flex items-center px-3 py-px rounded-lg space-x-2 border bg-gray-25 text-xxs text-gray-20 hover:bg-blue-70 hover:text-white hover:border-blue-70">
                <FontAwesomeIcon icon={faPencilAlt} />
                <span>{canEditStudentLeaves ? 'Add' : 'View'} Leaves</span>
              </div>
          }
        </Popover.Button>
        <Transition
          className="absolute z-10"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel>
            <div className="bg-white border my-1 rounded shadow-xl divide-y dark:bg-gray-95">
              <StudentLeavesEditableList
                studentId={studentId}
                leavesHistory={sortedHistory.filter(hh => hh.house_id === null)}
                canEditStudentLeaves={canEditStudentLeaves}
                afterSubmit={afterSubmit}
              />
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}
LeavesCell.propTypes = {
  studentId: PropTypes.number,
  housesHistory: PropTypes.array,
  studentStatus: PropTypes.string,
  afterSubmit: PropTypes.func,
  canEditStudentLeaves: PropTypes.bool,
}

function StudentLeavesEditableList({ studentId, canEditStudentLeaves, afterSubmit }) {
  const { data, error, mutate } = useSWR(`/pages/employee/students/${studentId}/leaves-editor`)
  const isLoading = !data && !error
  const [submissionState, setSubmissionState] = useState(null)

  const leavesHistory = useMemo(() => {
    return data?.leavesHistory?.sort(sh => sh.joined_date)?.reverse() || []
  }, [data?.leavesHistory])

  if (isLoading) {
    return <div className="p-4">Loading...</div>
  }

  const handleHouseAssigns = e => {
    const leave = Object.fromEntries(new FormData(e.target))
    e.preventDefault()
    axios.post('/pages/employee/students/leaves-editor', leave)
      .then(() => { setSubmissionState(null); toast.success('Leave added') })
      .catch(() => { toast.error('Something went wrong') })
      .finally(() => {
        mutate()
        afterSubmit?.(studentId, leave.joined_date && {
          leave_date: `Added for ${leave.joined_date}${leave.return_date ? `, returns on ${leave.return_date}` : ''}`
        })
      })
  }

  const handleDeleteHouseHistory = student_house_id => {
    const leave = leavesHistory.find(lh => Number(lh.id) === Number(student_house_id))
    const [joined_date] = leave?.joined_date?.split('T') || []
    axios.delete(`/pages/employee/students/leaves-editor/${student_house_id}`)
      .then(() => { setSubmissionState(null); toast.success('Leave removed') })
      .catch(() => { toast.error('Something went wrong') })
      .finally(() => {
        mutate()
        afterSubmit?.(studentId, joined_date && { leave_date: `Removed for ${joined_date}` })
      })
  }

  return <>
    <ul className="divide-y p-2 whitespace-nowrap">
      {leavesHistory?.length
        ? leavesHistory
          .map(sh => (
            <li key={sh.joined_date} className="whitespace-nowrap flex justify-between space-x-4 py-2 px-2">
              <span className="flex-1">Leaves</span>
              <span>{tzAgnosticDate(sh.joined_date).toLocaleDateString()}</span>
              {
                canEditStudentLeaves
                // && sh.is_editable 
                && (
                  <button className="text-danger-40 flex-none" onClick={() => { handleDeleteHouseHistory(sh.id); close() }}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
            </li>
          ))
        : <span className="p-4">Student has no leaves</span>
      }
    </ul>
    <form onSubmit={(e) => {
      handleHouseAssigns(e)
      close()
    }} className="flex justify-center p-2 text-center text-xs">
      <input type="hidden" name="student_id" value={studentId} />
      {submissionState === 'adding_leave' && (
        <div className="flex flex-col space-y-2">
          <div className="flex items-center space-x-1 min-w-40">
            <label>
              Leaves at
              <Datepicker name="joined_date"
                // min={format(addDays(new Date(), 1), 'yyyy-MM-dd')} 
                required
                className="dark:text-black" />
            </label>
          </div>
          <div className="flex flex-start mt-2 space-x-2">
            <button className="bg-cyan-40 text-white text-xs font-bold rounded px-2 py-1">Save</button>
            <button type="button" className="bg-white text-cyan-40 border border-cyan-40 text-xs font-bold rounded px-2 py-1" onClick={() => setSubmissionState(null)}>Cancel</button>
          </div>
        </div>
      )}
      {canEditStudentLeaves && !submissionState && (
        <button className="flex bg-cyan-40 text-white text-xs font-bold rounded px-2 py-1 whitespace-nowrap mx-auto" onClick={() => setSubmissionState('adding_leave')}>Add Leave</button>
      )}
    </form>
  </>
}
StudentLeavesEditableList.propTypes = {
  studentId: PropTypes.number,
  canEditStudentLeaves: PropTypes.bool,
  afterSubmit: PropTypes.func,
}

function StudentLeaveStatusLabel({ currentStatus, leavesHistory = [] }) {
  if (currentStatus === 'withdrawn') {
    const leaveDate = leavesHistory.at(-1)?.joined_date
    return (
      <div className="flex flex-col text-sm">
        <span data-testid="student-leave-status">{leaveDate ? 'Last day at Sora' : 'Withdrawn'}</span>
        <span data-testid="student-leave-value">{leaveDate ? new Date(leaveDate).toLocaleDateString('en-US') : ''}</span>
      </div>
    )
  }

  if (currentStatus === 'leave_of_absence') {
    const [end, start] = [leavesHistory.at(-2)?.joined_date, leavesHistory.at(-1)?.joined_date]
    const dtStart = start ? new Date(start).toLocaleDateString('en-US') : ''
    const dtEnd = end ? new Date(end).toLocaleDateString('en-US') : ''

    const diff = differenceInDays(new Date(end), new Date(start))
    const diffDays = diff && diff > 0 ? diff : null
    return (
      <div className="flex flex-col text-sm">
        <div className="flex items-center space-x-1" data-testid="student-leave-status">
          <span>Pause period</span>
          {diffDays && <span className='text-xxs'>({diffDays} days)</span>}
        </div>
        {dtStart && dtEnd && <span data-testid='student-leave-value'>{dtStart} - {dtEnd}</span>}
      </div>
    )
  }

  return null
}
StudentLeaveStatusLabel.propTypes = {
  currentStatus: PropTypes.string,
  leavesHistory: PropTypes.array,
}
