// util for rounding to nearest step unit
// the progress bars are dynamically generated, meaning the width (tailwind) is set in the browser
// since tailwind purges at compile time, some widths (w-[20%] etc) might not be included.
// to compensate, I added every 5% increment from 5 to 100 in the index.css file, so that way we don't have
// to rely on tailwind purging out the JIT css. This function helps us round to the nearest 5%
const round = (value, step) => {
  step || (step = 1.0)
  var inv = 1.0 / step
  return Math.round(value * inv) / inv
}

export default round
