import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import useToggle from '@hooks/useToggle.js'

export function TableRowColumn({
  colSpan,
  label,
  hideLabelDesktop,
  className = '',
  children,
  isCollapsed,
  toggleCollapse }
) {
  let childrenNode = children
  if (React.isValidElement(childrenNode) && typeof childrenNode.type === 'function')
    childrenNode = React.cloneElement(
      children,
      { isCollapsed, toggleCollapse }
    )
  return <div
    className={`${colSpan ? `col-span-${colSpan}` : 'col-span-1'} 
      p-1 text-gray-70 group flex ${className}`}>
    <div className={`flex flex-1 ${className}`}>
      {label &&
        <span className={`font-bold text-xs mr-1 ${hideLabelDesktop === undefined ||
          hideLabelDesktop ? 'lg:hidden' : ''}`}>
          {label}
          <span className="lg:hidden">:</span>
        </span>}
      {childrenNode}
    </div>
  </div>
}

TableRowColumn.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  colSpan: PropTypes.number,
  hideLabelDesktop: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  toggleCollapse: PropTypes.func
}

export const tableRowPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  collapsibleSlot: PropTypes.node
}

const TableRow = forwardRef((
  {
    children,
    className = '',
    collapsibleSlot
  },
  ref) => {
  const [isCollapsed, toggleCollapse] = useToggle(true)
  const childrenNode = []
  const childrenArray = Array.isArray(children) ? children : [children]
  childrenArray.forEach((child, index) => {
    let newChild = child
    if (React.isValidElement(newChild) && typeof newChild.type === 'function') {
      newChild = React.cloneElement(
        child,
        { isCollapsed, toggleCollapse, key: index }
      )
    }
    childrenNode.push(newChild)
  })

  return <section
    className={`block lg:grid grid-cols-12 gap-2 space-y-2 lg:space-y-0
    items-center text-xs p-2 bg-white rounded border border-gray-10 shadow
    lg:border-none my-2 ${className}`}
    ref={ref}>
    {childrenNode}
    {!isCollapsed && <div className="col-span-12">{collapsibleSlot}</div>}
  </section>
})

TableRow.displayName = 'TableRow'
TableRow.propTypes = tableRowPropTypes

TableRow.Column = TableRowColumn

export default TableRow
