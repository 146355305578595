/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//components
import ProgressIndicator from '../../ProgressIndicator'
// utils
import { getSubjectColors } from '@utils/subjectColors'
import { SubjectIcon } from '@utils/subjectIcons'
import useUnits from '@hooks/useUnits'

export default function SubjectDisclosure({
  subject,
  selectedTopic,
  setSelectedTopic,
  scrollToTopic,
  isEditable = true,
  studentId,
  mutate,
}) {
  const [open, setOpen] = useState(false)
  const { addTopicToProgram, removeTopicFromProgram } = useUnits({ student_id: studentId })
  const topics = subject.title === 'World Language' && !isEditable
    ? subject.topics.filter(topic => !topic.off_program) : subject.topics

  return (
    <div className={`p-6 bg-white  dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded ${open ? '' : 'hover:bg-gray-5 dark:hover:bg-gray-90'}`}>
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className="flex flex-row items-center">
          {open ? (
            <ProgressIndicator
              percent={subject.completions / subject.target}
              twsize="16"
              cutoff="0"
            >
              <div className="flex items-center justify-center">
                <span className="text-xs">
                  {subject.completions} / {subject.target}
                </span>
              </div>
            </ProgressIndicator>
          ) : (
            <div
              className={`w-16 h-16 rounded-full flex items-center justify-center ${getSubjectColors(
                subject.id
              ).bg}`}
            >
              <SubjectIcon
                id={subject.id}
                selected={'true'}
                className={`max-w-8 max-h-8 `}
              />
            </div>
          )}
          <span className="text-lg ml-4">{subject.title}</span>
        </div>
        <div
          className={`flex justify-center items-center rounded-full h-8 w-8 ${open ? 'bg-gray-30 dark:bg-gray-90' : 'bg-gray-30 dark:bg-gray-100'
            }`}
        >
          {open ? (
            <FontAwesomeIcon icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon icon={faAngleUp} />
          )}
        </div>
      </div>
      {open && (
        <div className="flex flex-col mt-4">
          {topics.map(topic => (
            <TopicListItem
              key={topic.id}
              topic={topic}
              isSelected={selectedTopic && selectedTopic.id === topic.id}
              onClick={() => { setSelectedTopic(topic); scrollToTopic(topic.id) }}
              removeTopicFromProgram={removeTopicFromProgram}
              addTopicToProgram={addTopicToProgram}
              isEditable={isEditable}
              mutate={mutate}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const TopicListItem = ({ topic, isSelected, onClick, isEditable, addTopicToProgram, removeTopicFromProgram, mutate }) => {
  const [status, setStatus] = useState(topic.off_program ? 'off-program' : topic.is_elective ? 'elective' : 'required')

  return <div
    onClick={e => { e.preventDefault(); onClick(e) }}
    className={`flex items-center border-t py-3 border-l-6 last:pb-0 cursor-pointer gap-x-1 leading-none ${isSelected ? 'font-bold' : 'border-transparent'}`}
  >
    <p className={`text-[0.9rem] ${status === 'off-program' && 'text-gray-40 dark:text-gray-60'}`}>
      {topic.title}
    </p>
    {status !== 'off-program' && (
      <p className={`text-xs ${topic.target && 'text-gray-40 dark:text-gray-60'}`}>
        ({topic.completions}/{topic.target})
      </p>
    )}
    {status !== 'off-program' && topic.is_exempt && (
      <p className="inline-block relative group">
        <span className="bg-green-30 dark:bg-green-70 rounded-full px-2 text-xs py-1">
          exempt
        </span>
        <span className="group hidden group-hover:inline absolute p-4 bottom-[-107px] right-0 text-xs bg-gray-10 dark:bg-gray-100 dark:border-gray-90 border-gray-30 w-[200px] z-10 shadow-md rounded border">
          You've been exempt from this topic! Looks like you
          completed this work outside Sora.
          Exempted units count towards graduation progress,
          but don't count towards GPA.
        </span>
      </p>
    )}
    {status !== 'required' && (
      <div className="inline-block relative group">
        {isEditable ? (
          <select
            value={status}
            className={`pr-7 rounded-full px-2 text-xxs border-none ${status === 'elective' ? 'bg-gray-10 dark:bg-gray-70' : 'bg-danger-10 dark:bg-danger-80'}`}
            onChange={
              async (e) => {
                const newValue = e.target.value
                const oldValue = status
                const hasChanged = newValue !== oldValue
                if (!hasChanged) return

                setStatus(newValue)
                try {
                  if (newValue === 'off-program')
                    await removeTopicFromProgram(topic.id)
                  else
                    await addTopicToProgram(topic.id)
                  mutate()
                } catch (e) {
                  setStatus(oldValue)
                }
              }
            }
          >
            <option value='elective'>elective</option>
            <option value='off-program'>off-program</option>
          </select>
        ) : (
          status === 'off-program' && <span className="rounded-full px-2 py-1 text-xs bg-danger-10 dark:bg-danger-80">{status}</span>
        )}
      </div>
    )}
  </div>
}
