import React from 'react'
import ReactDOM from "react-dom"
import ReactTooltip, { TooltipProps } from 'react-tooltip'

const TooltipComponent: React.FC<TooltipProps> = ({
  id,
  place = 'right',
  effect = 'solid',
  disable,
  ...props
}) => (
  <ReactTooltip
    id={id}
    place={place}
    effect={effect}
    disable={disable}
    {...props}
  />
)

const Tooltip: React.FC<TooltipProps & { isFromPortal?: boolean }> = ({ isFromPortal, ...props }) => {
  const domNode = document.createElement('div');
  document.body.appendChild(domNode);

  // Wrapper component to portal react-tooltips
  function BodyPortal({ children }) {
    return (
      <>
        {ReactDOM.createPortal(
          children,
          domNode
        )}
      </>
    )
  }

  return isFromPortal ? (
    <BodyPortal>
      <TooltipComponent {...props} />
    </BodyPortal>
  ) :
    <TooltipComponent {...props} />
}

export default Tooltip