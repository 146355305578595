import useSWR from 'swr'

const useUnitDemonstrations = ({ student_id, unit_id } = {}) => {
  const { data, error } = useSWR(
    `/pages/graduation_progress/unit-demonstrations?student_id=${student_id}&unit_id=${unit_id}`
  )

  return {
    demonstrations: data?.demonstrations || [],
    isLoading: !error && !data,
    isError: error,
  }
}

export default useUnitDemonstrations
