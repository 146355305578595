import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@designsystem'
import Notification from './notification'
import NotificationFilter from './filter'
import { NotificationTabs } from './types'
import Slideover from '@components/slideover'
import isSoraHomeLink from '@utils/isSoraHomeLink'
import useNotifications from '@hooks/useNotifications'
import openExternalLink from '@utils/openExternalLink'

type NotificationsProps = {
  open: boolean,
  onCloseNotifications: () => void,
  user: {
    user_id: number,
    role: string,
  },
}

export default function Notifications({ open, onCloseNotifications, user }: NotificationsProps) {
  return (
    <Slideover
      open={open}
      title={<span className="text-gray-90">Notifications</span>}
      maxWidth="max-w-none sm:w-[520px]"
      setOpen={onCloseNotifications}
    >
      {open && <NotificationsContent user={user} onCloseNotifications={onCloseNotifications} />}
    </Slideover>
  )
}

type NotificationsContentProps = Pick<NotificationsProps, 'user' | 'onCloseNotifications'>

export function NotificationsContent({ user, onCloseNotifications }: NotificationsContentProps) {
  const [selectedTab, setSelectedTab] = useState<NotificationTabs>(NotificationTabs.ALL)
  const {
    isLoading,
    notifications,
    handleDelete,
    handleMarkAsRead,
    handleReadAllNotifications,
  } = useNotifications(Number(user?.user_id), selectedTab)

  const navigate = useNavigate()

  const handleNavigateToNotificationsPage = (user: Pick<NotificationsContentProps, 'user'>['user'], page: 'view' | 'settings' | 'details', notificationId?: number) => {
    const pageSuffix = page === 'view' ?
      `view/${selectedTab}`
      : page === 'settings'
        ? 'settings'
        : `view/${selectedTab}/details/${notificationId}`
    const path = `employee/${user?.user_id}/notifications/${pageSuffix}`

    onCloseNotifications()
    return navigate(path)
  }

  const handleSelectTab = (tab: NotificationTabs) => {
    setSelectedTab(tab)
  }

  const handleReadAndRedirect = async (notificationId: number, read: boolean, user: Pick<NotificationsContentProps, 'user'>['user'], redirectUrl?: string, content?: Array<unknown>) => {
    if (!read) await handleMarkAsRead(notificationId)
    if (content) return handleNavigateToNotificationsPage(user, 'details', notificationId)
    if (redirectUrl) {
      if (isSoraHomeLink(redirectUrl)) {
        onCloseNotifications()
        navigate(buildCorrectRedirect(redirectUrl))
      } else {
        openExternalLink(redirectUrl)
      }
    }
  }

  return (
    <section className="flex justify-between flex-col h-full">
      <div className="flex space-y-4 flex-col">
        <NotificationFilter
          selectedTab={selectedTab}
          onSelectTab={handleSelectTab}
          onClickSettingsIcon={() => handleNavigateToNotificationsPage(user, 'settings')}
        />
        <div className="flex justify-end">
          <button
            className="text-blue-50 text-sm hover:underline font-bold"
            title="Mark all notifications as Read"
            onClick={() => handleReadAllNotifications(user?.user_id as number)}
          >
            Mark all as Read
          </button>
        </div>
        {isLoading
          ? <FontAwesomeIcon icon={faSpinner} spin />
          : <Notification.List hasNotifications={notifications?.length > 0}>
            {notifications?.map((notification) => (
              <Notification.Item
                key={notification.id}
                {...notification}
                onClick={() => handleReadAndRedirect(notification.id, notification.read, user, notification.redirect_url, notification.content)}
                onDeleteNotification={() => handleDelete(notification.id)}
              />
            ))}
          </Notification.List>
        }
      </div>
      <span style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)' }} className="rounded-full justify-center sticky bottom-5 self-center">
        <Button onClick={() => handleNavigateToNotificationsPage(user, 'view')}>
          View all
        </Button>
      </span>
    </section>
  )
}

function buildCorrectRedirect(url: string) {
  const parsedUrl = new URL((url.startsWith('/') && window.location.origin) + url)
  return parsedUrl.pathname + parsedUrl.search
}