/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { faInfoCircle, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasteryBar from '../../../../components/mastery-bar'
import MasteryModal from '../../../../components/mastery-modal'
import { getSubjectColors } from '../../../../utils/subjectColors'
import { SubjectIcon } from '../../../../utils/subjectIcons'

export default function UnitCard({ unit, toggleActiveUnit, subject_id, schoolStage, isMsMasteryLevelFeatureFlagEnabled }) {
  const [modalOpen, setModalOpen] = useState(false)
  const level = parseFloat(unit.level)
  const masteryLevelStr = isMsMasteryLevelFeatureFlagEnabled ? _getGradeName(level, schoolStage) : level
  const shouldShowProgressBar = isMsMasteryLevelFeatureFlagEnabled ? schoolStage !== 'ms' : true
  return (
    <div className="w-full bg-white dark:bg-gray-95 dark:border-gray-90 border border-gray-30 rounded p-8 pr-4 flex flex-col md:flex-row gap-6 md:gap-8">
      <MasteryModal modalOpen={modalOpen} setModalOpen={setModalOpen} school_stage={unit.school_stage} />
      <div className='min-w-40'>
        {unit.image_url ? (
          <img src={unit.image_url} alt={unit.image_alt} className="rounded" />
        ) : (
          <div
            className={`h-32 md:h-[100px] self-start flex items-center justify-center rounded-lg ${getSubjectColors(
              subject_id
            ).bg}`}
          >
            <SubjectIcon
              id={unit.subject_id}
              selected={'true'}
              className={`max-w-8 max-h-8 `}
            />
          </div>
        )}
      </div>
      <div className="grow">
        <div className="flex space-x-px">
          <h3 className="font-bold">{unit.title}</h3>
          {!unit.is_in_program && (
            <div className="inline-block relative group">
              <span className="ml-2 bg-danger-10 dark:bg-danger-80 rounded-full px-2 text-xs">
                off program
              </span>
              <span className="group hidden group-hover:inline absolute p-4 bottom-[-107px] right-0 text-xs bg-gray-10 dark:bg-gray-100 dark:border-gray-90 border-gray-30 w-[200px] z-10 shadow-md rounded border">
                This unit belongs to an elective topic you haven't opted-in to. If
                you're interested in adding this elective to your program,
                reach out to your House Advisor.
              </span>
            </div>
          )}
        </div>
        <p className="text-sm text-gray-90 dark:text-gray-50">
          {unit.description ? unit.description : 'No description available.'}
        </p>
      </div>
      <div className="flex flex-row justify-between items-center relative min-w-56 gap-8">
        {unit.is_exempt ? (
          <div className="uppercase text-xs tracking-wide font-bold">
            Exempt
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <span className="text-xs tracking-wide mr-2">
                Mastery level: {shouldShowProgressBar === false && <br></br>}<span className='font-bold'>{masteryLevelStr}</span>
              </span>
              <div>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="cursor-pointer z-10"
                  onClick={() => setModalOpen(true)}
                />
              </div>
            </div>
            {shouldShowProgressBar && (
              <MasteryBar level={level} />
            )}
          </div>
        )}
        {toggleActiveUnit && (
          <FontAwesomeIcon
            className="hover:cursor-pointer px-3 py-2 border rounded-full"
            icon={faAngleRight}
            onClick={toggleActiveUnit}
          />
        )}
      </div>
    </div>
  )
}

const _getGradeName = (level = 0, schoolStage) => {
  if (schoolStage === 'ms') {
    if (level === 0) {
      return 'Not Started'
    } else if (level < 1) {
      return 'Attempted'
    } else if (level < 2) {
      return 'Exposure'
    } else if (level < 3) {
      return 'Developing'
    } else if (level < 4) {
      return 'Demonstrating'
    } else {
      return 'Extending'
    }
  }

  return level
}
