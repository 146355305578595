import * as React from 'react'
import { Combobox as HeadlessUiCombobox, Transition } from '@headlessui/react'
import { cn, Icon } from '@design-system'
import { TextField } from '@designsystem'
import type { LabelValue } from './types'
import { useThrottle } from '@hooks/useThrottle'
import { FormContextData, useFormContext } from './form-context'

interface ComboboxProps {
  options: Array<{ label: string; value: string }>
  placeholder: string
  onChange: (value: string) => void
  disabled?: boolean
}

export function Combobox({ options, placeholder, onChange, disabled = false }: ComboboxProps) {
  const [filterValue, setFilterValue] = React.useState('')
  const trothledFilterValue = useThrottle(filterValue, 300)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const filteredValues = React.useMemo(
    () =>
      filterValue === ''
        ? options
        : options.filter((opt) => {
          return opt.label.toLowerCase().includes(filterValue.toLowerCase())
        }),
    [trothledFilterValue, options]
  )

  return (
    <HeadlessUiCombobox
      nullable
      disabled={disabled}
      onChange={(value) => {
        if (value) onChange(value as string)
        if (inputRef.current) {
          inputRef.current.value = ''
          inputRef.current.focus()
        }
      }}
    >
      {({ open }) => (
        <>
          <HeadlessUiCombobox.Input
            as={TextField}
            ref={inputRef}
            displayValue={() => ''}
            placeholder={placeholder}
            onChange={(event) => setFilterValue(event.target.value)}
            onClick={() => {
              if (!open) {
                buttonRef.current?.click()
              }
            }}
            onFocus={() => {
              buttonRef.current?.click()
            }}
            endAdornment={
              <HeadlessUiCombobox.Button ref={buttonRef} className="inset-y-0 right-0 flex items-center pr-2">
                <Icon name="chevron-down" size="sm" className="ml-2 shrink-0 opacity-50" />
              </HeadlessUiCombobox.Button>
            }
          />
          <Transition
            as="div"
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <HeadlessUiCombobox.Options className="absolute w-full z-10 max-h-48 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredValues.map((value) => (
                <HeadlessUiCombobox.Option
                  key={value.value}
                  value={value.value}
                  className={({ active }) =>
                    cn(active ? 'bg-gray-90 text-white' : 'text-gray-100', 'relative cursor-default select-none py-2 pr-9')
                  }
                >
                  {({ selected }) => (
                    <span className={cn(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                      {value.label}
                    </span>
                  )}
                </HeadlessUiCombobox.Option>
              ))}
            </HeadlessUiCombobox.Options>
          </Transition>
        </>
      )}
    </HeadlessUiCombobox>
  )
}

export function useFormControlledCombobox<T extends LabelValue[]>({
  values,
  formKey,
}: {
  values: T
  formKey: keyof Omit<FormContextData, 'sessions'>
}) {
  const [formData, setFormData] = useFormContext()
  const templateValues = formData?.[formKey]
  const selectedIds = templateValues?.split(',')
  const notSelectedItems = values.filter((value) => !selectedIds?.includes(value.value))
  const selectedItems = values.filter((value) => selectedIds?.includes(value.value))
  const selectedItemString = selectedItems.map((item) => item.value).join(',')

  const handleAddParam = (value: string) => {
    setFormData((prevState) => {
      const prevValues = prevState[formKey] ? prevState[formKey].split(',') : []
      prevValues.push(value)
      return {
        ...prevState,
        [formKey]: prevValues.join(','),
      }
    })
  }

  const handleRemoveParam = (value: string) => {
    setFormData((prevState) => {
      const prevValues = prevState[formKey] ? prevState[formKey].split(',') : []
      return {
        ...prevState,
        [formKey]: prevValues.filter((prevVal) => prevVal !== value).join(','),
      }
    })
  }

  return { handleAddParam, handleRemoveParam, selectedItems, notSelectedItems, selectedItemString }
}
