import React, { useEffect } from 'react'
import axios from 'axios'
import { useActionData, useLoaderData, useNavigate } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import OnboardingChecklistModal from '@components/onboarding/checklist/modal'
import { useToast } from '@hooks/useToast'

interface LoaderData {
  onboardingPhasesOptions: Array<{ label: string, value: number }>
  checklist?: {
    id: number,
    title: string,
    visibleFor: 'both' | 'guardian' | 'student',
    onboardingPhaseId: number,
    dueDate: string,
    studentDescription: string,
    guardianDescription: string,
  }
}

type ActionData = {
  toast: {
    message: string,
    appearance: 'success' | 'error',
  },
  redirectUrl: string,
} | undefined


export async function loader({ params }) {
  const result = await axios.get(`/pages/admin/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}/edit`)
  return result.data
}

export async function action({ request, params }) {
  const formData = serializeFormData(await request.formData())
  const body = {
    id: formData.id,
    title: formData.title,
    guardian_description: formData.guardian_description,
    student_description: formData.student_description,
    due_date: formData.due_date,
    onboarding_phase_id: formData.onboarding_phase_id,
    visible_for: Object.keys(formData?.users || {}).length === 2 ? 'both' : Object.keys(formData?.users)[0],
  }
  try {
    const res = await axios.post(`/pages/admin/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}/edit`, body)
    const redirectPath = res.data
    return {
      toast: { message: 'Checklist edited', appearance: 'success' },
      redirectUrl: `../${formData.id}/${redirectPath}`
    }
  } catch (error) {
    return {
      toast: {
        message: 'Something went wrong while editing this checklist',
        appearance: 'error',
      }
    }
  }
}

export default function WorkbenchOnboardingChecklistEditRoute() {
  const navigate = useNavigate()
  const actionData = useActionData() as ActionData
  const loaderData = useLoaderData() as LoaderData
  useToast(actionData)

  useEffect(() => {
    if (actionData?.redirectUrl) {
      navigate(actionData.redirectUrl)
    }
  }, [actionData?.redirectUrl])

  return (
    <OnboardingChecklistModal
      title="Edit Checklist"
      onClose={() => navigate('..')}
      checklist={loaderData?.checklist}
      onboardingPhasesOptions={loaderData?.onboardingPhasesOptions}
    />
  )
}
