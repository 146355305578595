import { Button, Icon, useFetcher } from '@design-system'
import { NavigateCycle } from '@hooks/useNavigateWithCycle'
import React from 'react'

export const AddPathway = () => {
  const fetcher = useFetcher()

  const isLoading = fetcher.state === 'loading' && fetcher.formData?.get('_action') === 'create_pathway'

  if (fetcher.data?.id) return (<NavigateCycle to={`./${fetcher.data?.id}`} />)

  return (
    <fetcher.Form method='post' className='flex flex-row justify-end'>
      <input type="hidden" name="_action" value="create_pathway" />
      <Button size='sm' type='submit' loading={isLoading} disabled={isLoading} >
        <Icon name='plus' size='sm' />
        <span>Add new</span>
      </Button>
    </fetcher.Form>
  )
}
