import React from 'react'

function Element() {
  return (
    <div className='flex flex-col items-center gap-4 py-50'>
      <img src="/assets/reports.png" alt="Reports" className='h-40' />
      <p>Choose the report you want to work on the left</p>
    </div>
  )
}

export const ReportsIndexRoute = { Element }
