import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import subDays from 'date-fns/subDays'
import isToday from 'date-fns/isToday'
import endOfDay from 'date-fns/endOfDay'
import add from 'date-fns/add'
import sub from 'date-fns/sub'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import startOfDay from 'date-fns/startOfDay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay, faChevronDown, faChevronLeft, faChevronRight, faReply } from '@fortawesome/free-solid-svg-icons'
import { Disclosure } from '@headlessui/react'
import axios from 'axios'
import useCycles from '@hooks/useCycles'
import DatePicker from 'react-datepicker'
import getUniqueObjectsInArray from '@utils/filter/getUniqueObjectsInArray'
import { Button } from '@designsystem'

const StudentsMissesTodayDisclosure = ({ students, label, emptyMsg, setSelectedStudent }) => (
  <Disclosure>
    {({ open }) => (
      <>
        <Disclosure.Button className="w-full focus:outline-none">
          <div className="flex mt-3 items-center py-1 rounded-lg hover:bg-gray-20 justify-between px-3">
            <div className="flex space-x-2 items-center mr-auto">
              <span className="text-xs lg:text-lg font-bold text-black">{students?.length || 0}</span>
              <span className="text-left hidden sm:inline text-xs lg:text-base font-bold text-black">{label}</span>
            </div>
            <div className="flex space-x-2 items-center ml-auto">
              <FontAwesomeIcon icon={faChevronDown} className={`${open ? 'transform rotate-180' : ''} text-xs lg:text-base text-gray-70`} />
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className="max-h-90 overflow-y-auto pb-3 text-gray-70 space-y-1">
          {
            students?.length ?
              <div className="flex flex-col space-y-2 mt-1">
                {
                  students.map(({ id, name }) => (
                    <span
                      onClick={() => { setSelectedStudent && setSelectedStudent({ value: id, label: name }) }}
                      className={`text-xs lg:text-sm md:ml-5 border-b w-10/12 ${setSelectedStudent && 'hover:cursor-pointer'}`}
                      key={id}
                    >
                      {name}
                    </span>
                  ))
                }
              </div>
              : <span className="text-xs lg:text-sm md:ml-5">{emptyMsg}</span>
          }
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
)
StudentsMissesTodayDisclosure.propTypes = {
  students: PropTypes.array,
  label: PropTypes.string,
  emptyMsg: PropTypes.string,
  setSelectedStudent: PropTypes.func
}

const fetchStudentMissesData = async (day, attType) => {
  const { data } = await axios.get(`/pages/attendance/missesOnDay?day=${day}&attType=${attType}`)
  return data
}

const StudentsMissesInDay = ({ setSelectedStudent, attType }) => {
  const [studentsMissesSelectedDate, setStudentsMissesSelectedDate] = useState(new Date())
  const [studentsMisses, setStudentsMisses] = useState({ excused: [], absent: [] })
  const { minDate, maxDate } = useCycles()

  const { excusedStudents, absentStudents } = useMemo(() => {
    const { excused = [], absent = [] } = studentsMisses || {}
    return {
      excusedStudents: getUniqueObjectsInArray(
        excused.map(att => ({ id: att.student_id, name: att.student_name })),
        'name'
      ).sort((e1, e2) => e1.name.localeCompare(e2.name)),
      absentStudents: getUniqueObjectsInArray(
        absent.map(att => ({ id: att.student_id, name: att.student_name })),
        'name'
      ).sort((e1, e2) => e1.name.localeCompare(e2.name)),
    }
  }, [studentsMisses])

  const isLeftArrowDisabled = () => {
    const subbed = endOfDay(sub(studentsMissesSelectedDate, { days: 1 }))
    return isBefore(subbed, new Date(minDate))
  }
  const isRightArrowDisabled = () => {
    const added = startOfDay(add(studentsMissesSelectedDate, { days: 1 }))
    return isAfter(added, new Date(maxDate))
  }

  useEffect(() => {
    (async () => {
      setStudentsMisses(
        await fetchStudentMissesData(
          format(studentsMissesSelectedDate, 'yyyy-MM-dd'),
          attType
        )
      )
    })()
  }, [studentsMissesSelectedDate, attType])

  return (
    <div className="flex flex-col mt-5">
      <div className="flex items-center gap-2">
        <Button
          type="button"
          size="xs"
          variant="nude"
          onClick={() => { setStudentsMissesSelectedDate(prevState => subDays(prevState, 1)) }}
          disabled={isLeftArrowDisabled()}
          startIcon={faChevronLeft}
          tooltip="Previous"
        />
        <Button
          type="button"
          size="xs"
          variant="nude"
          className="hidden md:inline text-xs hover:cursor-pointer focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed text-regular-dark"
          onClick={() => { setStudentsMissesSelectedDate(prevState => addDays(prevState, 1)) }}
          disabled={isRightArrowDisabled()}
          startIcon={faChevronRight}
          tooltip="Next"
        />
        <span className="flex-auto text-xs md:text-base xl:text-lg text-black">{format(studentsMissesSelectedDate, 'iii, MMM dd')}</span>
        <div>
          <DatePicker
            onChange={(date) => { setStudentsMissesSelectedDate(date) }}
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
            popperPlacement="top-end"
            customInput={
              <Button size="xs" variant="nude" startIcon={faCalendarDay}></Button>
            }
          />
        </div>
        <Button size="xs" disabled={isToday(studentsMissesSelectedDate)} startIcon={!isToday(studentsMissesSelectedDate) && faReply} onClick={() => setStudentsMissesSelectedDate(new Date())}>
          Today
        </Button>
      </div>
      <StudentsMissesTodayDisclosure
        label="Excused"
        emptyMsg="No excuses for this day"
        students={excusedStudents}
        setSelectedStudent={setSelectedStudent}
      />
      <StudentsMissesTodayDisclosure
        label="Absent"
        emptyMsg="No absences for this day"
        students={absentStudents}
        setSelectedStudent={setSelectedStudent}
      />
    </div>
  )
}
StudentsMissesInDay.propTypes = {
  setSelectedStudent: PropTypes.func,
  attType: PropTypes.string,
}

export default StudentsMissesInDay
