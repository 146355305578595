const queriedUrl = (url, query = {}) => {
  const filteredQuery = Object.entries(query).filter(([, val]) => !!val)
  if (filteredQuery.length === 0)
    return url
  return url + '?' + filteredQuery.map(([key, val]) => {
    if (Array.isArray(val)) {
      return val.map((value) => `${key}[]=${value}`).join('&')
    }
    return `${key}=${val}`
  }).join('&')
}

export default queriedUrl
