import axios from 'axios'
import React from 'react'
import { ActionFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom'
import { Button, Typography } from '@design-system'
import { Dialog, unstable_Checkbox as Checkbox, useFetcher } from '@design-system'
import { TextField } from '@designsystem'
import { CreatePresetCommentSlateTextArea } from './create-preset-comment-slate-text-area'

async function loader({ params }: ActionFunctionArgs) {
  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/comment-bank/preset-comment/${params.commentId}`
  )
  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/comment-bank/preset-comment/${params.commentId}`,
    formData
  )

  return { status: res.status, ...res.data }
}

interface LoaderDataProps {
  title: string
  initialValues: {
    title: string
    body: string
    allExperiences: boolean
  }
}

function Element() {
  const { title, initialValues } = useLoaderData() as LoaderDataProps
  const fetcher = useFetcher()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col w-[460px] overflow-hidden bg-white border-x">
      <Dialog
        open={true}
        onClose={() => {
          navigate('../')
        }}
      >
        <Dialog.Content>
          <Dialog.Header>
            <Dialog.Title>
              {title}
            </Dialog.Title>
            <Dialog.Close />
          </Dialog.Header>
          <fetcher.Form method="POST" id="createPresetCommentForm">
            <Dialog.Body>
              <TextField placeholder="Comment Title" name="title" required trim defaultValue={initialValues.title} />
              <CreatePresetCommentSlateTextArea name="body" body={initialValues.body} />
              <Typography variant="callout">
                {'This comment will be saved in your comment bank and you can reuse across students and assignments related to this '}
                <Typography className="inline" variant="callout" weight="bold">
                  same expedition instance.
                </Typography>
              </Typography>
              <div className="flex gap-2">
                <Checkbox id="all_experiences_checkbox" name="allExperiences" defaultChecked={initialValues.allExperiences} />
                <Typography variant="body" asChild>
                  <label htmlFor="all_experiences_checkbox">Make comment available across all my expeditions</label>
                </Typography>
              </div>
            </Dialog.Body>
            <Dialog.Footer>
              <Button type="submit">
                Save comment
              </Button>
            </Dialog.Footer>
          </fetcher.Form>
        </Dialog.Content>
      </Dialog>
    </div>
  )
}
export const FacilitateExperienceTaskStudentCommentBankCreateRoute = {
  Element,
  action,
  loader
}
