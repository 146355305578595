import React from 'react'

import TestGroup from '@components/test-group'
import Features from '@components/features'
import { Outlet } from 'react-router'

const FeatureFlags = () => {

  return (
    <div className="pb-40 space-y-10">
      <TestGroup />
      <Features />
      <Outlet />
    </div>
  )
}

export default FeatureFlags
