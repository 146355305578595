import * as React from 'react'
import axios from 'axios'
import { Button, useFetcher } from '@design-system'

async function action() { 
  const res = await axios.post('/backoffice/workbench/student-heartbeat-server/basic-setup')
  return res.data
}

function Element() {
  const fetcher = useFetcher()
  const loading = fetcher.state === 'loading' || fetcher.state === 'submitting'
  return (
    <fetcher.Form method="POST">
      <Button type="submit" loading={loading}>Run basic setup sync</Button>
    </fetcher.Form>
  )
}

export const WorkbenchStudentHearbeatServerBasicSetupRoute = { action, Element }
