import React from 'react'
import PropTypes from 'prop-types'

const StudentLearningExperiencesAttendanceList = ({ experiences }) => {
  if (!experiences) return null
  return (
    <div className='flex flex-col'>
      <span className='text-xs md:text-base'>Learning Experiences</span>
      <div className='border mb-3 mt-1' />
      {
        experiences.length ?
          experiences.map((exp, i) => {
            const nAbsent = Number(exp.absent || 0)
            return (
              <div key={i} className='grid grid-cols-12 justify-between mb-1'>
                <span className='text-xs xl:text-base col-span-10 text-gray-70 whitespace-nowrap truncate'>
                  {exp.theme_title}
                </span>
                <span className={`text-xs xl:text-base col-span-2 ml-auto whitespace-nowrap ${(nAbsent >= 5) ? 'text-danger-40' :
                  (nAbsent === 4) ? 'text-orange-30' :
                    (nAbsent === 3) ? 'text-yellow-40' :
                      'text-gray-70'
                  }`}>
                  {nAbsent} / 5
                </span>
              </div>
            )
          },
          )
          : <span className='text-sm'>Student is not enrolled in any experience</span>
      }
    </div>
  )
}
StudentLearningExperiencesAttendanceList.propTypes = {
  experiences: PropTypes.array,
}

export default StudentLearningExperiencesAttendanceList
