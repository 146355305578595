import React, { PropsWithChildren } from 'react'

export const Aside = ({ children }: PropsWithChildren) => (
  <aside className="sticky top-28 z-30 mb-6 px-1 hidden sm:block self-start">
    <nav>
      <ul>
        {children}
      </ul>
    </nav>
  </aside>
)

