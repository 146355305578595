import useSWR from 'swr'
import axios from 'axios'

const useTestGroups = () => {
  const { data, error, mutate } = useSWR('/test-groups')

  const create = async (title) => {
    await axios.post('/test-groups', { title })
    mutate()
  }

  const remove = async (id) => {
    await axios.delete(`/test-groups/${id}`)
    mutate()
  }

  const addUser = async (id, email) => {
    const result = await axios.post(`/test-groups/${id}/user`, { email })
    if (result?.data?.error) return alert('Email not found, check again please!')
    mutate()
  }

  const removeUser = async (id, userId) => {
    await axios.delete(`/test-groups/${id}/user/${userId}`)
    mutate()
  }

  return {
    test_groups: data?.test_groups || [],
    isError: error,
    mutate,
    create,
    remove,
    addUser,
    removeUser
  }
}

export default useTestGroups
