import React from 'react'
import axios from 'axios'
import { useThisPageTitle } from '@hooks/usePageTitle'
import serializeFormData from '@utils/serializeFormData'
import { redirect, useActionData, useLoaderData } from 'react-router-dom'
import WorkbenchUnitForm from './form'
import { useToast } from '@hooks/useToast'

export default function EditUnitWorkbenchRoute() {
  const loaderData = useLoaderData()
  const [unit] = loaderData.units || []
  const topics = loaderData.topics || []

  useToast(useActionData())
  useThisPageTitle(`Workbench Edit Unit ${unit?.title}`)

  if (!unit) return <span>Unit not found</span>

  return (
    <WorkbenchUnitForm unit={unit} topics={topics} />
  )
}

export const loader = async ({ params }) => {
  const result = await axios.get(`/pages/admin/workbench/units/${params.unit_id || ''}`)
  return result?.data
}

export const action = async ({ request, params }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  try {
    await axios.put(`/pages/admin/workbench/units/${params.unit_id}`, { ...serializedFormData })
    return redirect('/workbench/units')
  } catch (error) {
    console.error(error)
    return {
      toast: { message: 'We encountered an error while updating the unit. If the issue persists, please reach out to our technical support for help.', appearance: 'error' }
    }
  }
}
