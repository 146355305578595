import React from 'react'
import SoraLink from '@components/link'
import { Card, Typography } from '@design-system'

interface CardProps {
  title: string
  subtitle: string
  to: string
}

export function QuickActionsCard({ title, subtitle, to }: CardProps) {
  return (
    <Card asChild actionable>
      <SoraLink to={to}>
        <Card.Content>
          <Typography variant="subheadline" weight="bold">{title}</Typography>
          <Typography variant="subheadline">{subtitle}</Typography>
        </Card.Content>
        <Card.Arrow />
      </SoraLink>
    </Card>
  )
}
