export default i => [
  { bg: 'bg-danger-30', border: 'border-danger-30', text: 'text-danger-30' },
  { bg: 'bg-orange-30', border: 'border-orange-30', text: 'text-orange-30' },
  { bg: 'bg-yellow-30', border: 'border-yellow-30', text: 'text-yellow-30' },
  { bg: 'bg-cyan-30', border: 'border-cyan-30', text: 'text-cyan-30' },
  { bg: 'bg-green-50', border: 'border-green-50', text: 'text-green-50' },
  { bg: 'bg-cyan-50', border: 'border-cyan-50', text: 'text-cyan-50' },
  { bg: 'bg-blue-30', border: 'border-blue-30', text: 'text-blue-30' },
  { bg: 'bg-blue-40', border: 'border-blue-40', text: 'text-blue-40' },
  { bg: 'bg-green-40', border: 'border-green-40', text: 'text-green-40' },
  { bg: 'bg-danger-40', border: 'border-danger-40', text: 'text-danger-40' },
  { bg: 'bg-purple-20', border: 'border-purple-20', text: 'text-purple-20' },
  { bg: 'bg-purple-40', border: 'border-purple-40', text: 'text-purple-40' },
  { bg: 'bg-pink-20', border: 'border-pink-20', text: 'text-pink-20' },
][i] || { bg: 'bg-gray-50', border: 'border-gray-50', text: 'text-gray-50' }
