import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  gradeNumberForELA,
} from './exemptions.utils'
import SectionTitle from './components/section-title'
import ExemptionCard from './components/exemption-card'
import SubjectSidebar from './components/subject-sidebar'
import BaseExemptionForm from './components/base-exemption-form'
import TransferCreditInfo from './components/transfer-credit-info'
import { Button } from '@designsystem'

export default function Exemptions({
  staticExemptions,
  isReadOnly,
  clearTopicId,
  subjects,
}) {
  const [isEditing, setIsEditing] = useState({})

  useEffect(() => {
    if (clearTopicId) {
      toggleTopic(clearTopicId)
    }
  }, [clearTopicId])

  const toggleTopic = (topicId) => {
    setIsEditing(prevState => ({
      ...prevState,
      [topicId]: !prevState[topicId]
    }))
  }

  const buildDefaultValues = (topic) => {
    return {
      id: topic.exemption_id || topic.id,
      course: topic.exemption_course,
      credit: topic.exemption_credit,
      grade: topic.exemption_grade,
      school_name: topic.exemption_school,
      year: topic.exemption_year,
      topic_id: topic.topic_id,
      is_elective: topic.is_elective,
    }
  }

  const sidebarItems = [
    ...subjects.map(sub => sub.title),
    ...Object.keys(staticExemptions)
  ]

  return (
    <div className="w-full flex space-x-4 overflow-scroll">
      <SubjectSidebar
        sidebarItems={sidebarItems}
      />
      <div className="flex-1 flex flex-col w-full items-center">
        <TransferCreditInfo />
        {
          subjects.map((subject, index) => (
            <section
              key={subject.id}
              id={subject.title.toLowerCase().split(' ').join('_')}
              className="w-full flex flex-col space-y-5 my-4"
            >
              <SectionTitle title={subject.title} position={index} />
              {
                subject.topics.map(topic => {
                  if (topic.exemption && !isEditing[topic.id]) {
                    return <ExemptionCard
                      key={topic.id}
                      topic={topic}
                      subject={subject.title}
                      exemption={topic.exemption}
                      isReadOnly={isReadOnly}
                      toggleEditing={() => {
                        toggleTopic(topic.id)
                      }}
                    />
                  }

                  if (isReadOnly)
                    return null

                  return <div
                    key={topic.id}
                    data-cy="exemption_card"
                    className="p-4 bg-white border w-full mb-2"
                  >
                    <small>Sora Course Name</small>
                    <div className="flex flex-row items-center space-x-2">
                      {!isReadOnly && (
                        <input
                          type="checkbox"
                          aria-label="Exemption checkbox"
                          name={topic.id}
                          checked={isEditing[topic.id]}
                          onChange={() =>
                            toggleTopic(topic.id)
                          }
                        />
                      )}
                      <h3 className="align-middle text-lg font-bold whitespace-nowrap">
                        {topic.title +
                          (subject === 'ELA' && !topic.is_elective
                            ? gradeNumberForELA(topic.title)
                            : '')}
                      </h3>
                      {topic.is_elective && (
                        <span
                          data-cy="elective_label"
                          className="rounded-md px-2 py-0.5 bg-blue-40 text-xs text-center text-white ml-2"
                        >
                          Elective
                        </span>
                      )}
                    </div>

                    {isEditing[topic.id] && (
                      <BaseExemptionForm
                        topic={topic}
                        defaultValues={{
                          ...topic.exemption,
                          topic_id: topic.id,
                        }}
                        onDiscardChanges={() => {
                          toggleTopic(topic.id)
                        }}
                      />
                    )}
                  </div>
                })
              }
              {isEditing[`external-${subject.id}`] ? (
                <div
                  data-cy="static_exemption_form_wrapper"
                  className="p-4 bg-white border w-full mb-2"
                >
                  <BaseExemptionForm
                    defaultValues={{
                      clearKey: `external-${subject.id}`,
                      subject_id: subject.id
                    }}
                    onDiscardChanges={() => {
                      setIsEditing(prev => ({
                        ...prev,
                        [`external-${subject.id}`]: false
                      }))
                    }}
                  />
                </div>
              ) : !isReadOnly && !isEditing[`external-${subject.id}`] && (
                <Button
                  data-cy={`add_another_button_${subject.id}`}
                  variant="nude"
                  onClick={() => {
                    setIsEditing(prev => ({
                      ...prev,
                      [`external-${subject.id}`]: true
                    }))
                  }}
                >
                  + Add External {subject.title} Elective
                </Button>
              )}
            </section>
          ))
        }
        {
          Object.keys(staticExemptions).map((staticKey, index) => (
            <section
              key={staticKey}
              id={staticKey}
              className="flex flex-col space-y-5 first:mb-4 first:mt-0 my-4 w-full"
            >
              <SectionTitle
                title={staticKey}
                position={Object.keys(staticExemptions).length + index}
              />
              {staticExemptions[staticKey].map((staticExemptionItem, index) =>
                staticExemptionItem.exemption_status !== 'NONE' &&
                  !isEditing[staticExemptionItem.id] ? (
                  <ExemptionCard
                    key={`${staticExemptionItem.course}-${index}`}
                    isStaticExemption
                    subject={staticKey}
                    exemption={staticExemptionItem}
                    isReadOnly={isReadOnly}
                    toggleEditing={() => {
                      setIsEditing((prevState) => {
                        return {
                          ...prevState,
                          [staticExemptionItem.id]:
                            !prevState[staticExemptionItem.id],
                        }
                      })
                    }}
                  />
                ) : isEditing[staticExemptionItem.id] ? (
                  <div
                    key={staticExemptionItem.id}
                    data-cy="static_exemption_form_wrapper"
                    className="p-4 bg-white border w-full mb-2"
                  >
                    <BaseExemptionForm
                      defaultValues={buildDefaultValues(staticExemptionItem)}
                      onDiscardChanges={() => {
                        setIsEditing((prevState) => {
                          return {
                            ...prevState,
                            [staticExemptionItem.id]:
                              !prevState[staticExemptionItem.id],
                          }
                        })
                      }}
                    />
                  </div>
                ) : null
              )}
            </section>
          ))}
      </div>
    </div>
  )
}

Exemptions.propTypes = {
  staticExemptions: PropTypes.object,
  isReadOnly: PropTypes.bool,
  clearTopicId: PropTypes.string,
  subjects: PropTypes.array,
}
