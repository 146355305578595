import React from "react"
import axios from "axios"
import { Form, Link, useActionData, useLoaderData } from "react-router-dom"
import { Button } from "@designsystem"
import serializeFormData from "@utils/serializeFormData"
import { ItemIcon } from "@components/notifications/notification"
import type { NotificationPreferences, NotificationType, PreferenceItem } from "@components/notifications/types"
import { useToast } from "@hooks/useToast"



export default function Employee$UserIdNotificationsSettingsRoute() {
  const data = useLoaderData() as NotificationPreferences
  useToast(useActionData() as ActionData)

  return (
    <div className="flex bg-white p-4 md:pl-20 md:py-10 rounded-xs flex-col space-y-6 mb-10">
      <header className="flex space-x-5 items-center">
        <Button variant="nude" size="auto" to="../view/all" as={Link}>
          <img src="/assets/icons/arrow-left-icon.svg" className="md:w-10 md:h-10 w-6 h-6" alt="Back icon" title="Back to notifications" />
        </Button>
        <h1 className="text-2xl md:text-5xl font-bold">Notifications Settings</h1>
      </header>
      <section className="flex flex-col space-y-7 md:space-y-15 h-full">
        {Object.entries(data).map(([type, settings]) => (
          <div key={type} className="md:grid md:grid-cols-3 md:grid-rows-[min-content,min-content] flex flex-col space-y-3 md:space-y-0 md:gap-y-4">
            <div className="flex space-x-2 items-center">
              <ItemIcon type={type as NotificationType} />
              <h3 className="text-lg font-bold capitalize border-b-1 pb-1 border-b-gray-30 w-full">{type.replaceAll('_', ' ').toLocaleLowerCase()} Notifications</h3>
            </div>
            <div className="hidden md:flex md:w-full md:justify-around md:h-9 md:border-b-1 md:pb-3 md:border-b-gray-30">
              <span>Email</span>
              <span>Web</span>
            </div>
            <div className="hidden md:block md:h-9 border-b-1 pb-3 border-b-gray-30" />
            <ul className="space-y-5 md:space-y-6 md:row-start-2 md:col-span-2">
              {Object.entries(settings).map(([category, setting]) => (
                <SettingsItem key={setting.title} type={type} category={category} {...setting} />
              ))}
            </ul>
          </div>
        ))}
      </section>
    </div>
  )
}

type SettingsItemProps = {
  type: string,
  category: string,
} & Omit<PreferenceItem, 'mobile'>

function SettingsItem({
  title,
  type,
  category,
  email,
  web,
}: SettingsItemProps) {
  return (
    <li className="flex flex-col md:grid md:grid-cols-2 md:auto-rows-min space-y-3 md:auto-cols-min md:space-y-0">
      <p className="font-bold md:font-normal self-start">{title}</p>
      <div className="md:justify-around flex flex-col space-y-7 md:space-y-0 md:flex-row">
        <div className="flex justify-between">
          <span className="md:hidden text-sm self-center">Email</span>
          <Form method="post">
            <input type="hidden" name="muteAction" value={email ? 'mute' : 'unmute'} />
            <input type="hidden" name="category" value={category} />
            <input type="hidden" name="type" value={type} />
            <input type="hidden" name="channel" value="email" />
            <Button type="submit" variant="nude">
              <img src="/assets/icons/check-icon.svg" className={`${email ? '' : 'opacity-20'} w-7 h-7`} alt="check icon" />
            </Button>
          </Form>
        </div>
        <div className="flex justify-between">
          <span className="md:hidden text-sm">Web</span>
          <Form method="post">
            <input type="hidden" name="muteAction" value={web ? 'mute' : 'unmute'} />
            <input type="hidden" name="category" value={category} />
            <input type="hidden" name="type" value={type} />
            <input type="hidden" name="channel" value="web" />
            <Button type="submit" variant="nude">
              <img src="/assets/icons/check-icon.svg" className={`${web ? '' : 'opacity-20'} w-7 h-7`} alt="check icon" />
            </Button>
          </Form>
        </div>
      </div>
    </li>
  )
}

export async function loader({ params }) {
  const { user_id: userId } = params || {}

  try {
    const { data } = await axios.get(`new/notifications/preferences/${userId}`)
    return data
  } catch (error) {
    console.error(error)
  }
}

type SettingsActionFormData = {
  category: string,
  type: NotificationType,
  channel?: 'web' | 'mobile' | 'email',
  muteAction?: 'mute' | 'unmute'
}

type ActionData = {
  toast: {
    message: string,
    appearance: 'success' | 'error',
  }
}

export async function action({ request, params }) {
  const { user_id: userId } = params
  const formData = await request.formData()
  const jsonData = serializeFormData(formData) as SettingsActionFormData

  try {
    await axios.put(`new/notifications/preferences/${userId}`, jsonData)
    return {
      toast: {
        appearance: 'success',
        message: `Notification ${jsonData.muteAction === 'unmute' ? 'unmuted' : 'muted'}.`,
      }
    }
  } catch (error) {
    console.error(error)
    return {
      toast: {
        appearance: 'error',
        message: 'Something went wrong.',
      }
    }
  }

}
