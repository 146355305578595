import axios from 'axios'

const loader = async () => {
  const { data: enrolments } = await axios.get('/enrolments/family')
  return {
    enrolments
  }
}

export default loader
