import React from 'react'
import axios from 'axios'
import { useLoaderData, useSearchParams } from 'react-router-dom'
import { StudentRow } from './student-row'
import serializeFormData from '@utils/serializeFormData'
import { ColumnFilter } from './column-filter'

const loader = async ({params, request}) => {
  const searchParams = new URL(request.url).searchParams
  const action =  searchParams.get('_action') || 'default'
  const cycleId =  searchParams.get('cycle_id')
  const studentId =  searchParams.get('student_id')
  const schoolStage =  searchParams.get('schoolStage')
  const campusId =  searchParams.get('campusId')
  const houseId =  searchParams.get('houseId')

  console.log('request.url', request.url)

  if(action === 'clean') return null
  
  const result = await axios.get('/backoffice/workbench/math-placement/students', { params: { action, cycle_id: cycleId, student_id: studentId, schoolStage, campusId, houseId } })

  return result.data
}

const action = async ({ _, request }) => {
  const currentSearchParams = new URL(window.location.href).searchParams
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action
  const cycleId =  currentSearchParams.get('cycle_id')

  try {
    await axios.post(`/backoffice/workbench/math-placement/students`, { ...serializedFormData, action, cycle_id: cycleId })
    return null
  } catch (error) {
    return {
      toast: { message: 'There was an error. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const { data: { studentsMath, schoolStages, campuses, houses }, readonly } = useLoaderData()
  const [searchParams] = useSearchParams()
  const cycleId = Number(searchParams.get('cycle_id')) || null

  return (
    <table className='w-full'>
      <thead className='sticky top-0 bg-screen-primary z-30'>
        <tr className='text-left'>
          <th className='p-2'><ColumnFilter title='School Stage' field='schoolStage' options={schoolStages} /></th>
          <th className='p-2'><ColumnFilter title='Campus' field='campusId' options={campuses} /></th>
          <th className='p-2'><ColumnFilter title='House' field='houseId' options={houses} /></th>
          <th className='p-2'>Student</th>
          <th className='p-2'>Expected Graduation</th>
          <th className='p-2'>Math Main Placement</th>
        </tr>
        <tr>
          <th colSpan={6} className='bg-gray-50 h-px'/>
        </tr>
      </thead>
      <tbody>
        {studentsMath.map((student) => <StudentRow key={`std_${student.id}`} cycleId={cycleId} student={student} isReadOnly={readonly}  />)}
      </tbody>
    </table>
  )
}

export const WorkbenchMathPlacementStudentsRoute = {
  loader,
  action,
  Element,
}
