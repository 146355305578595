import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Emoji from '@components/emoji'
import FeedbackFlagIcon from './feedback-flag-icon'
import FlagItemMenuItem from './flag-item-menu-item'
import { ON_TRACK, NEEDS_IMPROVEMENT, NOT_COVERED, LABELS, ICONS, TEXT_COLORS } from "@utils/constants/formativeFeedback"

const OPTIONS = [
  {
    id: ON_TRACK,
    isVisible: () => true
  },
  {
    id: NEEDS_IMPROVEMENT,
    isVisible: ({ hideNeedsImprovements }) => !hideNeedsImprovements
  },
  {
    id: NOT_COVERED,
    isVisible: ({ hideNotYetCovered }) => !hideNotYetCovered
  }
]


const FlagItem = ({
  selected,
  onChange,
  onRemove,
  disabled = false,
  removable = false,
  hideNeedsImprovements = false,
  hideNotYetCovered = false,
}) => {

  const handleSelect = (option) => (e) => {
    onChange(option)
  }

  const options = useMemo(
    () => OPTIONS.filter(({ isVisible }) => isVisible({ hideNeedsImprovements, hideNotYetCovered })),
    [hideNeedsImprovements, hideNotYetCovered]
  )

  return (
    <Menu as='div' className='relative'>
      <div>
        <Menu.Button className={`${disabled ? 'cursor-default' : ''}`} disabled={disabled}>
          <FeedbackFlagIcon flag={selected} title={!disabled && "Change feedback status"} />
        </Menu.Button>
      </div>
      <Menu.Items className="flex flex-col gap-1 absolute right-0 mt-2 w-56 cursor-pointer origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        <div>
          {options.map(option => (
            <FlagItemMenuItem key={option.id}
              onClick={handleSelect(option.id)}
              label={LABELS[option.id]}
              icon={<Emoji name={ICONS[option.id]} title={`${option.id}`} />}
              className={TEXT_COLORS[option.id]}
            />
          ))}
          {removable &&
            <FlagItemMenuItem
              onClick={onRemove}
              label="Remove"
              icon={<FontAwesomeIcon icon={faTrash} className="text-danger-40" />}
              className="text-black"
            />
          }
        </div>
      </Menu.Items>
    </Menu>
  )
}
FlagItem.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  removable: PropTypes.bool,
  hideNeedsImprovements: PropTypes.bool,
  hideNotYetCovered: PropTypes.bool,
}

export default FlagItem
