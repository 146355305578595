import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

function getNextSortDirection(sortDirection) {
  switch (sortDirection) {
    case undefined:
      return 'DESC'
    case null:
      return 'DESC'
    case 'DESC':
      return 'ASC'
    case 'ASC':
      return null
    default:
      return null
  }
}

function TableHeaderColumn({ sortEnabled, children, onClickSort, colSpan, className = '' }) {
  const [sortDirection, setSortDirection] = useState(null)

  const onClickSortCb = () => {
    const newSortDirection = getNextSortDirection(sortDirection)
    setSortDirection(newSortDirection)
    onClickSort(newSortDirection)
  }

  return <div
    onClick={() => {
      if (sortEnabled) onClickSortCb()
    }}
    className={`${colSpan ? `col-span-${colSpan}` : 'col-span-1'}
      ${sortEnabled ? 'group-hover:underline' : ''} 
      space-x-2 p-1 text-gray-70 group flex ${className}`}>
    {children}
    {sortEnabled && <FontAwesomeIcon
      visibility={sortDirection ? 'visible' : 'hidden'}
      icon={sortDirection === 'ASC' ? faChevronUp : faChevronDown} />}
  </div>
}

TableHeaderColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  sortEnabled: PropTypes.bool,
  onClickSort: PropTypes.func,
  colSpan: PropTypes.number
}

function TableHeader({ className = '', children }) {
  return <header
    className={`grid auto-cols-max lg:grid-cols-12 grid-flow-col gap-2 font-bold text-xs uppercase p-2 items-center ${className}`}>
    {children}
  </header>
}

TableHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

TableHeader.Column = TableHeaderColumn

export default TableHeader
