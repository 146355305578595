import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import ShortSlateTextarea, { isBlankValue, valueOrEmptySlate } from '@components/forms/short-slate-textarea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleLeft, faAngleUp, faCircleInfo, faPlus } from '@fortawesome/free-solid-svg-icons'
import FlagItem from './flag-item'
import HistoryFeedbackList from './history-feedback-list'
import ReactTooltip from 'react-tooltip'
import PresetCommentPopover from './preset-comment-popover'

const AUTO_SAVE_SLATE_KEY_PREFIX = 'auto_save_slate'

const buildAutoSaveSlateKey = (location, item) => {
  return `${AUTO_SAVE_SLATE_KEY_PREFIX}_${item.id}_${location.pathname}`
}

const PickedItem = ({
  type,
  item,
  onRemove,
  onChange,
  onSave,
  onShowRubrics,
  onShowPresetComment,
  disabled,
  compressed,
  allowAddFeedback,
  presetComments,
  refreshPresetComments,
  taskSubmissionId,
  onRemoveFeedbackItem,
  startExpanded = false,
}) => {
  const slateRef = useRef(null)
  const presetRef = useRef(null)
  const location = useLocation()
  const [expanded, setExpanded] = useState(startExpanded)
  const [viewMode, setViewMode] = useState('LIST_FEEDBACKS')
  const [selectedPresetComment, setPresetComment] = useState(valueOrEmptySlate(null))
  const autoSaveSlateKey = buildAutoSaveSlateKey(location, item)
  const localStorageComment = JSON.parse(window.localStorage.getItem(autoSaveSlateKey))?.value ?? undefined
  const defaultSlateValue = !isBlankValue(selectedPresetComment) ? selectedPresetComment : localStorageComment
  item.comment = !isBlankValue(item.comment) ? item.comment : defaultSlateValue

  useEffect(() => {
    if (localStorageComment) {
      setViewMode('EDIT_FEEDBACK')
      setExpanded(true)
    }
  }, [])

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (viewMode === 'EDIT_FEEDBACK') {
        const state = {
          item,
          type: type === 'unit' ? 'units' : 'abilities',
          value: slateRef.current?.value,
        }
        window.localStorage.setItem(autoSaveSlateKey, JSON.stringify(state))
      }
    }, 3000)
    return () => window.clearInterval(interval)
  }, [viewMode])

  const handleExpand = () => { setExpanded(!expanded) }

  const handleFlagChange = (flag) => {
    onChange(type, { ...item, ...{ flag } })
    setExpanded(flag !== 'not_yet_covered')
  }

  const handleCommentChange = (comment) => {
    onChange(type, { ...item, comment: valueOrEmptySlate(comment) })
  }

  const handleCommentSave = () => {
    onSave(type, item)
    window.localStorage.removeItem(autoSaveSlateKey)
    setViewMode('LIST_FEEDBACKS')
    setPresetComment(valueOrEmptySlate(null))
  }

  const handleRubricInfo = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onShowRubrics(type, item.id)
  }

  const handleSelectPresetComment = (comment) => {
    handleCommentChange(comment)
    setPresetComment(comment)
    setViewMode('EDIT_FEEDBACK')
  }

  const handleSelectNewComment = () => {
    handleCommentChange(null)
    setPresetComment(valueOrEmptySlate(null))
    setViewMode('EDIT_FEEDBACK')
  }

  const handleOpenPresetComment = () => {
    presetRef?.current?.open()
  }

  const title = `${item.title}${item.topic_title ? ` (${item.topic_title})` : ''}`
  const hasPresetComments = presetComments?.length > 0

  return (
    <li className='flex flex-col border-b border-b-gray-30 py-4 gap-4'>
      <ReactTooltip delayShow={1000} />
      <div className='flex flex-row justify-between items-center gap-3'>
        <FlagItem selected={item.flag} removable={!item.picked} onChange={handleFlagChange} onRemove={onRemove} disabled={disabled || !allowAddFeedback} />
        <div className='flex-auto flex flex-row justify-between items-center gap-4 cursor-pointer' onClick={handleExpand}>
          <div className={`flex-auto flex flex-col w-0 text-sm ${!item.flag ? 'text-gray-40' : ''}`}>
            <span className='font-bold truncate' data-tip={title}>{title}</span>
            <div className='flex flex-row gap-2'>
              <span className="text-gray-60">Current Mastery: {item.level || 0}</span>
              <span className='text-gray-70 cursor-pointer' onClick={handleRubricInfo}><FontAwesomeIcon icon={faCircleInfo} /></span>
            </div>
          </div>
          <div className='whitespace-nowrap flex-0'>
            {item.flag && !expanded && <FontAwesomeIcon icon={faAngleDown} />}
            {item.flag && expanded && <FontAwesomeIcon icon={faAngleUp} />}
          </div>
        </div>
      </div>
      <div className={item.flag && expanded ? '' : 'hidden'}>
        {(viewMode === 'CREATE_FEEDBACK') &&
          <div className='flex flex-col'>
            <PresetCommentPopover
              ref={presetRef}
              onSelectedPresetComment={handleSelectPresetComment}
              onOpenManager={onShowPresetComment}
              presetComments={presetComments}
              refreshList={refreshPresetComments}
            >
              <>
                <span>{hasPresetComments ? 'Use' : 'Create'} a </span>
                <button className="text-blue-20" onClick={() => hasPresetComments ? handleOpenPresetComment() : onShowPresetComment()}>preset feedback</button>
                <span> or start a </span>
                <button className="text-blue-20" onClick={handleSelectNewComment}>new comment</button>
              </>
            </PresetCommentPopover>
          </div>
        }
        {(viewMode === 'EDIT_FEEDBACK' && !disabled) &&
          <div className='flex flex-col' style={{ wordBreak: "break-word" }}>
            <ShortSlateTextarea
              ref={slateRef}
              id={`assment:${type}:${item.id}`}
              className='min-h-45'
              placeholder={item.flag === 'ontrack' ? 'What did students do well on?' : 'What needs improvement?'}
              onChange={handleCommentChange}
              onSave={handleCommentSave}
              value={defaultSlateValue}
              compressed={compressed}
              postable={true}
            />
            {item?.feedbacks?.length > 0 ?
              <div className='flex flex-row py-2'>
                <div className='flex flex-row gap-2 justify-center items-center cursor-pointer hover:underline' onClick={() => { setViewMode('LIST_FEEDBACKS') }}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                  <span>See History</span>
                </div>
                <div className='flex-auto'></div>
              </div>
              : <div className='flex flex-row py-2'>
                <div className='flex flex-row gap-2 justify-center items-center cursor-pointer hover:underline' onClick={() => { setViewMode('CREATE_FEEDBACK') }}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                  <span>Back</span>
                </div>
                <div className='flex-auto'></div>
              </div>
            }
          </div>
        }
        {(viewMode === 'LIST_FEEDBACKS' || disabled) &&
          <div className='flex flex-col gap-4'>
            {(!disabled && allowAddFeedback) &&
              <div className='flex flex-row'>
                <div className='flex flex-row gap-2 rounded-3xl px-4 py-2 hover:bg-gray-30 bg-gray-10 justify-center items-center cursor-pointer' onClick={() => { setViewMode('CREATE_FEEDBACK') }}>
                  <span>
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <span className='font-bold'>New Feedback</span>
                </div>
                <div className='flex-auto'></div>
              </div>
            }
            <HistoryFeedbackList list={item?.feedbacks} removeble={!disabled && allowAddFeedback} currentTaskSubmissionId={taskSubmissionId} onRemoveItem={onRemoveFeedbackItem} type={type} startExpanded={startExpanded} />
          </div>
        }
      </div>
    </li>
  )
}
PickedItem.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onShowRubrics: PropTypes.func,
  onShowPresetComment: PropTypes.func,
  disabled: PropTypes.bool,
  compressed: PropTypes.bool,
  allowAddFeedback: PropTypes.bool,
  presetComments: PropTypes.array,
  refreshPresetComments: PropTypes.func,
  taskType: PropTypes.string,
  taskSubmissionId: PropTypes.number,
  onRemoveFeedbackItem: PropTypes.func,
  startExpanded: PropTypes.bool,
}

export default PickedItem
