import axios from 'axios'
import useSWR from 'swr'
import useCycles from './useCycles'
import _refiner from 'refiner-js'

export const authAxios = axios.create({
  baseURL: '/auth',
})

const useCurrentUser = () => {
  const { selectedCycle } = useCycles()
  const { data: currentUser } = useSWR(
    () => `/current-user?cycle_id=${selectedCycle.id}`,
    {
      fetcher: async (url) => {
        const res = await authAxios.get(url).catch((error) => error.response)
        if (res.status >= 300 || res.status < 200) {
          const error = new Error('An error occured while fetching the data')
          error.info = res.data
          error.status = res.status
          throw error
        }
        return res.data
      },
    }
  )
  return currentUser
}

export default useCurrentUser
