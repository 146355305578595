import React from 'react'

const ProgressContent = () => {
  return (
    <div>
      <p>
        Progress reports are snapshots of your student's progress at that point
        in the cycle. It's an opportunity for Experts to provide transparent
        feedback on how things are going. Progress reports are
        <strong> not</strong> tied to the student's ability to earn stars or
        abilities at the end of each cycle.
      </p>
      <h3 className="font-bold text-lg mt-4">
        When do progress reports happen?
      </h3>
      <p>Progress reports are sent out twice per cycle:</p>
      <ul className="mt-4 list-disc list-inside">
        <li>Mid-cycle (released after the end of the 3rd week each cycle)</li>
        <li>End-of-cycle (released the Monday after the end of each cycle)</li>
      </ul>
      <p className="mt-4">
        When progress reports are released, you'll get a notification from a
        member of the Sora team letting you know.
      </p>
      <h3 className="font-bold text-lg mt-4">How do they work?</h3>
      Expect to receive a progress report for each enrolled experience, twice a
      cycle. The progress report will include this information:
      <ul className="mt-4 list-disc list-inside">
        <li>Prepared for and participates in meetings</li>
        <li>Completes and submits tasks on time</li>
        <li>Quality of work/products</li>
        <li>Overall progress</li>
        <li>Narrative feedback</li>
      </ul>
      <p className="mt-4">
        We believe feedback presents an opportunity to solve a problem rather
        than to criticize. The feedback we strive to provide through progress
        reports and unit + ability assessments supports the way that
        individuals learn - by trying things, making mistakes, reflecting on
        those mistakes, and then trying again.
      </p>
    </div>
  )
}

export default ProgressContent
