import React, { useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignLeft, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt, faUser } from '@fortawesome/free-regular-svg-icons'
import set from 'date-fns/set'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import axios from 'axios'
import useToggle from '@hooks/useToggle'
import ExcusePeriodSelector from './excusePeriodSelector'
import ExcuseRecurringPeriodSelector from './excuseRecurringPeriodSelector'
import { Button } from '@designsystem'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset'
import add from 'date-fns/add'
import sub from 'date-fns/sub'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

const absenceReasonsOpts = [
  'Medical appointment',
  'Religious holiday',
  'Family matters/emergency',
  'School-related activity',
  'Feeling sick or unwell',
  'Other',
].map(r => ({ value: r, label: r }))

const createExcuse = async (excuse) => {
  const { data: { id } } = await axios.post('/excuses', excuse)
  return id
}

const updateExcuse = async (id, excuse) => {
  await axios.patch('/excuses/' + id, excuse)
}

const createRecurringExcuse = async (recurrence, excuse) => {
  const { data: ids } = await axios.post('/excuses/recurring', { recurrence, excuse })
  return ids
}

const updateRecurringExcuse = async (recurrenceId, recurrence, excuseId, excuse, eventsToUpdate) => {
  const { data: ids } = await axios.patch('/excuses/recurring/' + recurrenceId, { recurrence, excuseId, excuse, eventsToUpdate })
  return ids
}

const ExcuseSubmissionModal = ({ onClose, onSave, selectedStudent, excusePeriod, students, isAdmin = false }) => {
  const studentOpts = useMemo(() => (students || []).map(s => ({ value: s?.id, label: s.name })), [students])
  const [isSubmitting, toggleIsSubmitting] = useToggle(false)

  const { register, watch, control, handleSubmit, reset } = useForm()


  const keyPressedFn = useCallback(({ keyCode }) => {
    if (keyCode === 27) { // Close modal w/ Esc key
      onClose()
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    document.addEventListener('keydown', keyPressedFn, false)
    return () => {
      document.removeEventListener('keydown', keyPressedFn, false)
    }
  }, [keyPressedFn])

  useEffect(() => {
    const { start_at, start = null, end_at, end = null, id = null, student_id = null, additional_comments = '', reason = '', recurring_id = null, allDay = false, recurring_period_start, recurring_period_end, recurring_days } = excusePeriod
    const excStart = utcToZonedTime(start_at || start, 'America/New_York')
    const excEnd = utcToZonedTime(end_at || end, 'America/New_York')

    const recurringDays = (
      !Array.isArray(recurring_days)
        ? (recurring_days || '').replace(/[^a-zA-Z,]/g, '').split(',')
        : recurring_days
    ).filter(Boolean).map(d => ({ label: d[0].toUpperCase() + d.slice(1), value: d.toLowerCase() }))

    const resetValues = {
      id,
      allDay,
      additional_comments,
      recurring_id,
      recurring_period_start,
      recurring_period_end,
      recurring_days: recurringDays,
      recurring_excuse_start_at: excStart,
      recurring_excuse_end_at: excEnd,
      start_at_day: excStart,
      start_at_time: excStart,
      end_at_day: excEnd,
      end_at_time: excEnd,
      single_day: isSameDay(excStart, excEnd)
    }

    if (student_id || selectedStudent) {
      const id = student_id || selectedStudent?.id || selectedStudent?.value
      resetValues.student = {
        value: id,
        label: students.find(stu => stu.id === id)?.name || ''
      }
    }

    if (absenceReasonsOpts.find(opt => opt.value === reason)) {
      resetValues.absence_reason_select = { value: reason, label: reason }
    } else if (reason) {
      resetValues.absence_reason_select = { value: 'Other', label: 'Other' }
      resetValues.absence_reason_text = reason
    }

    reset(resetValues)
  }, [reset, students, excusePeriod, selectedStudent])

  const singleStudentProps = {}
  if (studentOpts?.length === 1) {
    singleStudentProps.value = studentOpts[0]
    singleStudentProps.defaultValue = studentOpts[0]
    singleStudentProps.isMulti = false
    singleStudentProps.isDisabled = true
    singleStudentProps.components = { DropdownIndicator: null, IndicatorSeparator: null }
  }

  const showOtherReasonInput = watch('absence_reason_select')?.value === 'Other'
  const isEditing = !!watch('id')
  const isRecurrence = !!watch('recurring_id')

  const submitSingleExcuse = async (data, student, reason) => {
    let { id = null } = data

    if (data.single_day) {
      data.end_at_day = data.start_at_day
    }
    if (data.allDay) {
      data.start_at_time = set(data.start_at_day, { hours: 0, minutes: 0, seconds: 0 })
      data.end_at_time = set(data.end_at_day, { hours: 23, minutes: 59, seconds: 59 })
    }

    const start_at = zonedTimeToUtc(new Date(`${format(data.start_at_day, 'yyyy-MM-dd')}T${format(data.start_at_time, 'HH:mm:ss')}`), 'America/New_York')
    const end_at = zonedTimeToUtc(new Date(`${format(data.end_at_day, 'yyyy-MM-dd')}T${format(data.end_at_time, 'HH:mm:ss')}`), 'America/New_York')
    try {
      const calendarEvent = await Promise.all(student.map(async ({ student_id, student_name }) => {
        const excuseData = {
          start_at,
          end_at,
          student_id,
          reason: reason || null,
          additional_comments: data.additional_comments || null
        }
        if (!id) {
          id = await createExcuse(excuseData)
        } else {
          await updateExcuse(id, excuseData)
        }

        return {
          ...excuseData,
          id,
          title: student_name + ' - ' + (reason || 'Absence')
        }
      }))

      toast.success(isEditing ? 'Excuse updated!' : 'Excuse created!')
      onSave(calendarEvent)
      onClose()
    } catch (e) {
      console.error(e)
      toast.error(e?.response?.data?.message)
    }
  }

  const submitRecurringExcuse = async (data, student, reason) => {
    const { id: excuse_id = null, recurring_id = null } = data

    if (data.recurring_allDay) {
      data.recurring_excuse_start_at = startOfDay(data.recurring_period_start)
      data.recurring_excuse_end_at = endOfDay(data.recurring_period_start)
    }

    const recurrence = {
      period_start: zonedTimeToUtc(startOfDay(new Date(data.recurring_period_start)), 'America/New_York'),
      period_end: zonedTimeToUtc(endOfDay(new Date(data.recurring_period_end)), 'America/New_York'),
      weekdays: data.recurring_days.map(d => d.value),
      all_day: !!data.recurring_allDay
    }

    const isDST = (date = new Date()) => {
      // the DST window in the united states starts on the second sunday of March and ends on the first sunday of november
      // therefore, july will always be returning a date within the DST time window
      const julyOffset = getTimezoneOffset('America/New_York', new Date(date.getFullYear(), 6, 2))
      const dateOffset = getTimezoneOffset('America/New_York', date)
      return julyOffset === dateOffset
    }

    let start_at = set(new Date(data.recurring_period_start), { minutes: getMinutes(data.recurring_excuse_start_at), hours: getHours(data.recurring_excuse_start_at) })
    let end_at = set(new Date(data.recurring_period_start), { minutes: getMinutes(data.recurring_excuse_end_at), hours: getHours(data.recurring_excuse_end_at) })

    if (recurring_id && isDST(start_at) && !isDST(data.recurring_excuse_start_at)) {
      start_at = add(start_at, { hours: 1 })
      end_at = add(end_at, { hours: 1 })
    } else if (recurring_id && !isDST(start_at) && isDST(data.recurring_excuse_start_at)) {
      start_at = sub(start_at, { hours: 1 })
      end_at = sub(end_at, { hours: 1 })
    }

    const excuse = {
      reason,
      start_at: zonedTimeToUtc(start_at, 'America/New_York'),
      end_at: zonedTimeToUtc(end_at, 'America/New_York'),
      additional_comments: data.additional_comments
    }

    try {
      const calendarEvents = (await Promise.all(student.map(async ({ student_id, student_name }) => {
        const ids = recurring_id
          ? await updateRecurringExcuse(recurring_id, recurrence, excuse_id, { ...excuse, student_id }, data.eventsToUpdate)
          : await createRecurringExcuse(recurrence, { ...excuse, student_id })

        return ids.map(id => ({
          ...excuse,
          id,
          student_id,
          title: student_name + ' - ' + (reason || 'Absence')
        }))
      }))).flat()
      if (!calendarEvents?.length) return toast('No Excuses were created! Is the configuration correct?')

      onSave(calendarEvents)
      toast.success('Excuses created!')
      onClose()
    } catch (e) {
      console.error(e)
      toast.error(e?.response?.data?.message)
    }
  }

  const onSubmit = async (data) => {
    toggleIsSubmitting()

    const student = (Array.isArray(data.student) ? data.student : [data.student]).map(s => ({ student_id: s?.value, student_name: s?.label }))
    if (!data.student || !student.length) {
      toggleIsSubmitting()
      return toast.error('Please select a student')
    }

    if (data.recurring_excuse && !data?.recurring_days?.length) {
      toggleIsSubmitting()
      return toast.error('Please select the recurring days')
    }

    let reason = data?.absence_reason_select?.value || ''
    if (reason === 'Other') {
      reason = data.absence_reason_text || ''
    }

    await ((data.recurring_excuse || data.recurring_id) && isAdmin ? submitRecurringExcuse(data, student, reason) : submitSingleExcuse(data, student, reason))
    toggleIsSubmitting()
  }

  return <div
    aria-modal="true"
    className="absolute top-[1%] left-1/2 transform -translate-x-1/2 z-100 md:min-w-96 shadow-xl rounded-lg ring-1 ring-black ring-opacity-5"
    onClick={onClose}
  >
    <div className="h-full bg-white rounded-lg p-6 space-y-6" onClick={e => e.stopPropagation()}>
      <header className="items-center flex border-b-1 font-bold text-lg">
        <span>{isEditing ? `Update ${isRecurrence && isAdmin ? 'Recurring' : ''} Absence` : 'Add an Absence'}</span>
        <svg
          onClick={onClose}
          className="fill-current text-gray-70 w-6 h-6 cursor-pointer ml-auto"
          viewBox="0 0 18 18"
        >
          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
        </svg>
      </header>
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-3 max-w-90">
          <div className="flex items-center">
            <input name="id" className="hidden" type="number" ref={register({ valueAsNumber: true })} />
            <input name="recurring_id" className="hidden" type="number" ref={register({ valueAsNumber: true })} />
            <FontAwesomeIcon className="mr-5" icon={faUser} />
            <div className="flex flex-col w-full">
              <Controller
                isMulti
                name="student"
                placeholder="Select one or more students"
                className="w-full text-sm"
                options={studentOpts}
                control={control}
                as={Select}
                defaultValue={null}
                isDisabled={isEditing}
                styles={{ multiValueRemove: (base) => ({ ...base, display: 'none' }) }}
                {...singleStudentProps}
              />
            </div>
          </div>
          {
            isAdmin && !isEditing ?
              <div className="flex flex-row w-full">
                <div className="mr-5">
                  <FontAwesomeIcon icon={faRedoAlt} />
                </div>
                <div className="space-x-2">
                  <span className="text-sm font-bold -ml-1">Recurring Excuse?</span>
                  <input name="recurring_excuse" ref={register} type="checkbox" className="rounded" />
                </div>
              </div>
              : null
          }
          {
            !isAdmin
              ? <ExcusePeriodSelector control={control} register={register} watch={watch} />
              : !watch('recurring_excuse') && !watch('recurring_id')
                ? <ExcusePeriodSelector control={control} register={register} watch={watch} />
                : <ExcuseRecurringPeriodSelector control={control} register={register} watch={watch} />
          }
          <div className="flex w-full">
            <div className="mr-5">
              <FontAwesomeIcon icon={faAlignLeft} />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <span className="text-sm">Why will they be absent?</span>
              <div className="flex flex-col">
                <Controller
                  name="absence_reason_select"
                  defaultValue={null}
                  options={absenceReasonsOpts}
                  control={control}
                  isSearchable={false}
                  className="border-0 text-sm"
                  as={Select}
                />
                {
                  showOtherReasonInput
                    ? <input ref={register} placeholder="Please write the reason..." name="absence_reason_text" className="-mt-px border-r-1 border-l-1 border-b-1 rounded border-gray-20" type="text" />
                    : null
                }
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="mr-5">
              <FontAwesomeIcon icon={faCommentAlt} />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <span className="text-sm">Any additional comments?</span>
              <input name="additional_comments" ref={register} className="rounded border-gray-20" type="text" />
            </div>
          </div>
          {
            isRecurrence && isAdmin &&
            <div className="flex">
              <div className="mr-5">
                <FontAwesomeIcon icon={faRedoAlt} />
              </div>
              <div className="space-y-2">
                <span className="text-sm">Update Recurrence for:</span>
                <div>
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2">
                      <input defaultChecked={true} type="radio" value="current_following" name="eventsToUpdate" ref={register} />
                      <span className="text-sm">This and following events</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input type="radio" value="current" name="eventsToUpdate" ref={register} />
                      <span className="text-sm">This Event</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input type="radio" value="all" name="eventsToUpdate" ref={register} />
                      <span className="text-sm">All Events</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="flex mt-5 ml-auto">
          <Button size="sm" type="submit" loading={isSubmitting}>Submit</Button>
        </div>
      </form>
    </div>
  </div>
}
ExcuseSubmissionModal.propTypes = {
  isAdmin: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  selectedStudent: PropTypes.object,
  excusePeriod: PropTypes.object,
  students: PropTypes.array,
}

export default ExcuseSubmissionModal
