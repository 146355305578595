import React from 'react'
import Groups from './groups'
import PermissionsTable from './table/index'

const Permissions = () => {

  return (
    <div className="pb-40 space-y-10">
      <Groups />
      <PermissionsTable />
    </div>
  )
}

export default Permissions
