import React from 'react'
import { useLoaderData } from 'react-router'
import axios from 'axios'
import { useThisPageTitle } from '@hooks/usePageTitle'
import SoraLink from '@components/link'
import PropTypes from 'prop-types'
import { Button } from '@designsystem'

const WorkbenchUnitsList = () => {
  const data = useLoaderData()
  useThisPageTitle(`Workbench Units`, { backTo: '/workbench/units' })
  let titleIndex = null
  if (!data) return <span>Loading...</span>

  const { units } = data
  if (!units || units.length === 0) return <span>No units</span>

  const Item = ({ unit }) => <SoraLink className="pl-4" to={`./edit/${unit.id}`}>
    <span className={`border rounded bg-gray-40 px-2 mr-2 inline-block w-10 text-center uppercase text-white font-bold text-sm ${unit.school_stage === 'hs' ? 'bg-green-30' : 'bg-blue-30'}`}>
      {unit.school_stage}
    </span>
    {unit.title}
  </SoraLink>

  Item.propTypes = {
    unit: PropTypes.object.isRequired,
  }

  return (
    <div>
      <div className="text-right">
        <Button as="a" href="units/new">Create Unit</Button>
      </div>
      <ul className='flex flex-col gap-2'>
        {units.map(unit => {
          if (unit.topic_id !== titleIndex) {
            titleIndex = unit.topic_id
            return (<>
              <li className='text-blue-60 font-bold'>
                {unit.topic_title}
              </li>
              <Item key={unit.id} unit={unit} />
            </>)
          }

          return <Item key={unit.id} unit={unit} />
        })}
      </ul >
    </div>
  )
}

export const loader = async () => {
  const result = await axios.get(`/pages/admin/workbench/units`)
  return result?.data
}

export default WorkbenchUnitsList
