import axios from 'axios'
import type { ActionData } from './types'

export async function action(): Promise<ActionData> {
  try {
    await axios.put('/pages/admin/workbench/canny/sync-users')
    return {
      toast: {
        message: 'The Sync has started. It may take a while, please wait...',
        appearance: 'success'
      }
    }
  } catch (error) {
    return {
      toast: {
        message: 'Something went wrong while syncing...',
        appearance: 'error'
      }
    }
  }
}
