import React from 'react'
import { Navigate } from 'react-router'

const Element = () => {

  return (
    <Navigate to="management" replace={true} />
  )

}

export const WorkbenchPathwaysIndexRoute = {
  Element,
}
