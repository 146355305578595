import axios from 'axios'
import useSWR from 'swr'
import { toast } from 'sonner'

const useUnits = ({
  student_id,
  revalidateOnFocus = true,
  showDemonstrations = false,
} = {}) => {
  const { data, mutate } = useSWR(
    student_id
      ? `/dashboard/student-units?student_id=${student_id}&show_demonstrations=${showDemonstrations}`
      : '/units',
    { revalidateOnFocus }
  )

  
  const addTopicToProgram = (topic_id) =>
    student_id &&
    axios
      .post('/topics/add-to-program', {
        topic_id,
        student_id,
      })
      .then(async () => {
        await mutate()
        return true
      })
      .catch((err) => {
        console.error('error', err)
        const message = err.response.data.error.message
        toast.error(message)
        return { error: new Error(message) }
      })

  const removeTopicFromProgram = (topic_id) =>
    student_id &&
    axios
      .post('/topics/remove-from-program', {
        topic_id,
        student_id,
      })
      .then(async () => {
        await mutate()
        return true
      })
      .catch((err) => {
        console.error('error', err)
        const message = err.response.data.error.message
        toast.error(message)
        return { error: new Error(message) }
      })

  return {
    addTopicToProgram,
    removeTopicFromProgram,
    units: data?.units || [],
  }
}

export default useUnits
