import * as React from 'react'
import axios from 'axios'
import { LoaderFunctionArgs, useLoaderData, defer, Await, useAsyncValue, Params } from 'react-router-dom'
import SoraLink from '@components/link'
import { unstable_Table as Table, Skeleton, Typography, cn, Icon, IconProps } from '@design-system'

type TaskStatus = 'pending' | 'not_yet_reviewed' | 'reviewed' | 'missed' | 'flagged'

interface LoaderData {
  students: Array<{
    id: number,
    name: string,
    tasks: Array<{
      id: number,
      status: TaskStatus,
      isFinal: boolean,
    }>
  }>
  tasks: Array<{
    id: number,
    title: string,
    isFinal: boolean,
    isPending: boolean
  }>
  showFlaggedCaption: boolean
}


async function getData(params: Params<string>, searchParams: URLSearchParams) {
  const { type, experienceId } = params
  const res = await axios.get(`/backoffice/facilitate/${type}/${experienceId}?${searchParams.toString()}`)
  return res.data
}

async function loader({ params, request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const data = getData(params, searchParams)
  return defer({ data })
}

function Element() {
  const loaderData = useLoaderData() as { data: LoaderData }
  return (
    <React.Suspense fallback={<TableSkeleton />}>
      <Await resolve={loaderData.data}>
        <div className="flex flex-col gap-4">
          <TaskStatusCaption />
          <StudentTaskTable />
        </div>
      </Await>
    </React.Suspense >
  )
}


function TaskStatusCaption() {
  const loaderData = useAsyncValue() as LoaderData

  return (
    <div className="flex place-content-end items-center min-w-full gap-4">
      <div className="flex items-center gap-2">
        <StatusIcon status="not_yet_reviewed" />
        <Typography variant="callout" weight="bold">Not yet reviewed</Typography>
      </div>
      <div className="flex items-center gap-2">
        <StatusIcon status="reviewed" />
        <Typography variant="callout" weight="bold">Reviewed</Typography>
      </div>
      {loaderData.showFlaggedCaption ? (
        <div className="flex items-center gap-2">
          <StatusIcon status="flagged" />
          <Typography variant="callout" weight="bold">Flagged</Typography>
        </div>
      ) : null}
      <div className="flex items-center gap-2">
        <StatusIcon status="missed" />
        <Typography variant="callout" weight="bold">Missed</Typography>
      </div>
      <div className="flex items-center gap-2">
        <StatusIcon status="pending" />
        <Typography variant="callout" weight="bold">Pending Submission</Typography>
      </div>
    </div>
  )
}

function StudentTaskTable() {
  const loaderData = useAsyncValue() as LoaderData

  return (
    <Table className="border-separate w-full border border-gray-30 rounded-lg">
      <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
        <Table.Row>
          <Table.Head className="w-48 first:pl-4">Student Name</Table.Head>
          {loaderData.tasks.map((task) => (
            <Table.Head key={task.id} className={cn('max-w-24 text-center', task.isPending ? 'text-gray-40' : '')}>
              <SoraLink to={`./tasks/${task.id}/students/${loaderData.students[0].id}${task.isFinal ? '/feedback' : ''}`}>
                {task.title.split(':').slice(0, 1)}
              </SoraLink>
            </Table.Head>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body className="[&_tr:last-child]:border-1">
        {loaderData.students.map((student) => (
          <Table.Row key={student.id} className="last-of-type:rounded-b-md">
            <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
              <SoraLink to={`/employee/students/${student.id}/overview`} className="hover:underline">
                {student.name}
              </SoraLink>
            </Table.Cell>
            {student.tasks.map((task) => (
              <Table.Cell
                key={`${student.id}-${task.id}`}
                className="capitalize text-center font-bold whitespace-nowrap border-t border-t-gray-30 dark:border-t-gray-90"
              >
                <SoraLink to={`./tasks/${task.id}/students/${student.id}${task.isFinal ? '/feedback' : ''}`}>
                  <StatusIcon status={task.status} />
                </SoraLink>
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

interface StatusIconProps {
  status: TaskStatus,
}

function StatusIcon({ status }: StatusIconProps) {
  const COLOR_NAME_BY_STATUS: {
    [key in TaskStatus]: {
      color: string,
      name: IconProps['name']
    } // just doing the typing here for autocompletion on icon name
  } = {
    reviewed: {
      color: 'text-green-40',
      name: 'check-rec-filled'
    },
    not_yet_reviewed: {
      color: 'text-blue-40',
      name: 'minus-rec-filled'
    },
    pending: {
      color: 'text-gray-40',
      name: 'minus-octagon'
    },
    flagged: {
      color: 'text-danger-40',
      name: 'flag-filled'
    },
    missed: {
      color: 'text-yellow-40',
      name: 'minus-rec-filled'
    },
  }

  const { color, name } = COLOR_NAME_BY_STATUS[status]

  return <Icon name={name} className={color} />
}

function TableSkeleton() {
  return (
    <div className="space-y-5 mt-4">
      {Array.from({ length: 10 }, (_, j) => (
        <Skeleton key={`table-skeleton-${j}`} className="h-12" />
      ))}
    </div>
  )
}

export const FacilitateTypeExperienceIdRoute = { loader, Element }
