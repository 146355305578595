import React, { useRef } from 'react'
import useTestGroups from '@hooks/useTestGroups'
import { Disclosure } from "@headlessui/react"
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, TextField } from '@designsystem'

const TestGroup = () => {
  const inputTitle = useRef(null)
  const inputEmails = useRef(new Array())
  const { test_groups, create, remove, addUser, removeUser } = useTestGroups()

  const handleAdd = async () => {
    const title = inputTitle.current.value
    if (title.length <= 0) return

    await create(title)
    inputTitle.current.value = ''
  }

  const handleAddUser = (id, fieldIndex) => async () => {
    const email = inputEmails.current[fieldIndex].value
    if (email.length <= 0) return

    await addUser(id, email)
    inputEmails.current[fieldIndex].value = ''
  }

  const handleRemoveUser = (id, userId) => async () => {
    if (!id || !userId) return
    await removeUser(id, userId)
  }

  const handleRemove = (id) => async () => {
    if (!id) return
    remove(id)
  }

  const testGroups = test_groups
    .sort((g1, g2) => g1.title.localeCompare(g2.title))
    .map((group, index) => {
      const users = group.users?.map((user) => (
        <li key={`group_email_${group.id}_${user.id}`} className='p-1'>
          <Button onClick={handleRemoveUser(group.id, user.id)} startIcon={faTrash} size="xs" tooltip="Delete" variant="nude" /> {user.email}
        </li>
      ))
      return (
        <li key={`test_group_${group.id}`} className='flex flex-col my-2'>
          <Disclosure>
            <Disclosure.Button className="rounded bg-turquoise-50 text-white font-bold px-2 py-1 flex flex-row justify-between items-center dark">
              {group.title}
              <Button onClick={handleRemove(group.id)} startIcon={faTrash} size="sm" variant="nude" tooltip="Delete" />
            </Disclosure.Button>
            <Disclosure.Panel className="bg-white">
              <ul className='h-64 overflow-y-auto p-2'>
                <li className='flex mb-2 space-x-2'>
                  <div className='min-w-xs'>
                    <TextField ref={(element) => inputEmails.current[`${index}${group.id}`] = element} name={`group-${group.id}-email`} id={`group-${group.id}-email`} placeholder='Type an email' />
                  </div>
                  <Button onClick={handleAddUser(group.id, `${index}${group.id}`)}>Add User</Button>
                </li>
                {users}
              </ul>
            </Disclosure.Panel>
          </Disclosure>
        </li>
      )
    })

  return (
    <div>
      <h1 className='font-bold text-2xl'>Groups</h1>
      <div>
        <fieldset className="flex space-x-2">
          <TextField ref={inputTitle} name="test-grouop-title" id="test-grouop-title" placeholder='Type a title' />
          <Button onClick={handleAdd}>Add</Button>
        </fieldset>
        <ul>
          {testGroups}
        </ul>
      </div>
    </div>
  )
}

export default TestGroup
