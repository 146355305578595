import axios from 'axios'
import { Button } from '@designsystem'
import React, { useState } from 'react'
import { toast } from 'sonner'

export default function WorkbenchHeartbeatRoute() {

  

  const [sending, setSending] = useState(false)

  const syncWithHearbeat = () => {
    setSending(true)
    axios.post('/pages/admin/workbench/heartbeat', {
      type: 'syncParentsWithHeartbeat'
    }).then(res => {
      const { migrated, invited } = res.data
      migrated + invited === 0 && toast(`No users were affected.`)
      migrated > 0 && toast.success(`Successfuly migrated ${migrated} ${migrated > 1 ? 'users' : 'user'}.`)
      invited > 0 && toast.success(`Successfuly invited ${invited} ${invited > 1 ? 'users' : 'user'}.`)
    }).catch(err => {
      console.error('err', err)
    }).finally(() => {
      setSending(false)
    })
  }

  return (
    <div>
      <Button loading={sending} onClick={syncWithHearbeat}>
        {sending ? 'Syncing...' : 'Sync Guardians with Heartbeat'}
      </Button>
    </div >
  )
}
