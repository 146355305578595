import React, { ReactNode } from 'react'
import { Card, Typography, Icon } from '@design-system'
import SoraLink from '@components/link'

export type CardGraduationProgressVariant = 'completed' | 'ahead-of-schedule' | 'on-track' | 'falling-behind'

interface VariantProps {
  childrenFn: () => ReactNode
  bgColor?: string
}

const defaultProps: VariantProps = {
  childrenFn: () => <Typography variant="heading-5" weight="bold">View Progress</Typography>
}

const variants: Record<CardGraduationProgressVariant, VariantProps> = {
  completed: {
    childrenFn: () => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold">Completed</Typography>
          <Icon name="check-circle" size="sm" />
        </div>
        <Typography variant="subheadline">Progress</Typography>
      </>
    ),
    bgColor: 'bg-green-20 hover:bg-green-20/90'
  },
  'ahead-of-schedule': {
    childrenFn: () => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold">Ahead of schedule</Typography>
          <Icon name="star" size="sm" />
        </div>
        <Typography variant="subheadline">Progress</Typography>
      </>
    ),
    bgColor: 'bg-success-40 hover:bg-success-40/90'
  },
  'on-track': {
    childrenFn: () => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold">On track</Typography>
          <Icon name="thumbs-up" size="sm" />
        </div>
        <Typography variant="subheadline">Progress</Typography>
      </>
    ),
    bgColor: 'bg-notice-25 hover:bg-notice-25/90'
  },
  'falling-behind': {
    childrenFn: () => (
      <>
        <div className="flex gap-2 items-center">
          <Typography variant="heading-5" weight="bold">Falling behind</Typography>
          <Icon name="alert-triangle" size="sm" />
        </div>
        <Typography variant="subheadline">Progress</Typography>
      </>
    ),
    bgColor: 'bg-warning-20 hover:bg-warning-20/90'
  },
}

interface CardGraduationProgressProps {
  variant?: CardGraduationProgressVariant
  url: string
}

/**
 * PillGraduationProgress display a pill with student progress
 */
export const CardGraduationProgress = ({ variant, url }: CardGraduationProgressProps) => {
  const { childrenFn, bgColor } = variants[variant] ?? defaultProps
  return (
    <Card actionable className={bgColor} data-testid="card-graduation-progress">
      <SoraLink to={url} target="_blank" className="flex justify-between items-center grow">
        <Card.Content className="space-y-1">
          {childrenFn()}
        </Card.Content>
        <Card.Arrow />
      </SoraLink>
    </Card>
  )
}
