import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePopper } from 'react-popper'
import { Popover, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faPaperPlane, faLocationDot } from '@fortawesome/free-solid-svg-icons'

const GuardiansCell = ({ guardians = [] }) => {
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)
  if (!guardians?.length) return null
  const hasNoPrimaryGuardian = guardians.some(guardian => guardian && guardian.has_no_primary_guardian)
  return <Popover>
    <Popover.Button title="Guardians" className="hover:underline text-left" data-testid="guardian-cell-btn" ref={setReferenceElement}>
      <div className="flex flex-col text-left">
        {guardians[0] && <span>{guardians[0].name}</span>}
        {guardians[1] && <span>{guardians[1].name}</span>}
      </div>
    </Popover.Button>
    <Transition
      className="absolute z-90"
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className="flex flex-col bg-white px-6 border my-1 rounded shadow-2xl divide-y-1 dark:bg-gray-95">
          {hasNoPrimaryGuardian && <span className="text-danger-40 font-bold py-3">Needs to set a primary guardian to be able to receive SMS!</span>}
          {guardians.map((guardian) => {
            if (!guardian) return <></>
            const isPrimaryWithoutPhone = guardian.is_primary && !guardian.phone
            return (
              <div className="flex flex-col py-5 space-y-1" key={guardian.name}>
                <div className="flex items-center space-x-1">
                  <span className="font-bold">{guardian.name}</span>
                  <span className="text-xxs">{guardian.is_primary ? '(primary)' : ''}</span>
                </div>
                {isPrimaryWithoutPhone && <span className="text-danger-40 font-bold">Needs to input a phone number to receive SMS!</span>}
                {
                  guardian.phone &&
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faPhone} className="min-w-5 mr-2" />
                    <span>{guardian.phone}</span>
                  </div>
                }
                {
                  guardian.email &&
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faPaperPlane} className="min-w-5 mr-2" />
                    <a className="hover:underline" href={"mailto:" + guardian.email} target="_blank" rel="noreferrer">{guardian.email}</a>
                  </div>
                }
                {
                  guardian.address &&
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faLocationDot} className="min-w-5 mr-2" />
                    <span className="whitespace-nowrap">{guardian.address}</span>
                  </div>
                }
              </div>
            )
          })}
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
}

GuardiansCell.propTypes = {
  guardians: PropTypes.array
}

export default GuardiansCell
