import { useLocation, useNavigate } from 'react-router-dom'

const useQuery = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = Array.from(
    new URLSearchParams(location.search).entries()
  ).reduce((acc, [key, value]) => {
    if (key.includes('[]')) {
      const cleanKey = key.replace('[]', '')
      return { ...acc, [cleanKey]: (acc[cleanKey] || []).concat(value) }
    }
    return { ...acc, [key]: value }
  }, {}) as Record<string, string | string[]>

  const setParams = (nextParams: Record<string, string | string[]>, navigateUrl = '') => {
    let nextQuery = new URLSearchParams()
    for (const [key, value] of Object.entries(nextParams)) {
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            nextQuery.append(key + '[]', item)
          })
        } else {
          nextQuery.set(key, value)
        }
      }
    }
    navigate(`${navigateUrl ?? location.pathname}${nextQuery.toString() === '' ? '' : `?${nextQuery.toString()}`}`)
  }

  const toggleParam = (key: string, value: string) => {
    const keyArrayValues = params[key] as string[]
    const newKeyValue = keyArrayValues?.length > 0 && keyArrayValues.some((v) => v === value)
      ? keyArrayValues.filter((v) => v !== value)
      : (keyArrayValues || []).concat(value)
    setParams({
      ...params,
      [key]: newKeyValue
    })
  }

  return [params, setParams, toggleParam] as const
}

export default useQuery
