import React from 'react'
import ProfileEdit from './form'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import SoraLink from '@components/link'
import { useParams } from 'react-router'
import useSWR from 'swr'
import { Button } from '@designsystem'

const Profile = () => {
  const { employee_id } = useParams()
  const { data, mutate } = useSWR(`/pages/admin/workbench/employees/${employee_id}`)

  if (!data) return <span>Loading..</span>

  const { employee, jobs, cohorts } = data
  if (!employee) return null

  return (
    <section className="h-full flex flex-col justify-stretch overflow-y-hidden">
      <main className="flex-1 flex h-full overflow-y-auto space-x-2">
        <section className="flex-1 h-full flex flex-col pb-4">
          <div className="flex flex-row items-center mb-4">
            <Button variant="nude" size="sm" startIcon={faAngleLeft} as={SoraLink} to="../">
              Back
            </Button>
            <h1 className="flex-none text-3xl font-bold">{null}</h1>
          </div>
          <ProfileEdit employee={employee} jobs={jobs} cohorts={cohorts} mutate={mutate} />
        </section>
      </main>
    </section>
  )
}

export default Profile
