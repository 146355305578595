import React from 'react'
import PropTypes from 'prop-types'

const Avatar = ({ name, imgUrl, className, classNameImg = 'rounded-full' }) => {
  const hasImgUrl = imgUrl && imgUrl.length > 0
  const fallbackSrc = `https://ui-avatars.com/api/?name=${name?.split(' ').map(n => n.substring(0, 5)).join('+')}&bold=true&format=png`
  const srcUrl = hasImgUrl ? imgUrl : fallbackSrc

  return (
    <div className={className}>
      <img
        className={`block ${classNameImg}`}
        src={srcUrl}
        alt={name}
        role='avatar'
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = fallbackSrc
        }}
      />
    </div>
  )
}

Avatar.propTypes = {
  name: PropTypes.string,
  imgUrl: PropTypes.string,
  className: PropTypes.string,
  classNameImg: PropTypes.string,
}

export default Avatar
