import axios from 'axios'
import useSWR from 'swr'

const useFeatureFlag = () => {
  const { data, error, mutate } = useSWR('/feature-flags')

  const addGroup = async (id, testGroupId) => {
    await axios.post(`/feature-flags/${id}/test-group/${testGroupId}`)
    mutate()
  }

  const removeGroup = (id, testGroupId) => async () => {
    await axios.delete(`/feature-flags/${id}/test-group/${testGroupId}`)
    mutate()
  }

  const changeStatus = async (id, status) => {
    await axios.put(`/feature-flags/${id}`, { status })
    mutate()
  }

  return {
    feature_flags: data?.feature_flags || [],
    isError: error,
    mutate,
    addGroup,
    removeGroup,
    changeStatus
  }
}

export default useFeatureFlag
