import React, { PropsWithChildren, Children } from 'react'

const Root = ({ children }: PropsWithChildren) => (
  <div className="h-20">
    <header role="banner" className="fixed left-0 right-0 z-40 top-0 h-20 flex-none px-4 scroll-locked flex items-center justify-between bg-white dark:bg-gray-100 border-b border-b-gray-30 dark:border-b-gray-90">
      {children}
    </header>
  </div>
)

const Ul = ({ children }: PropsWithChildren) => (
  <ul className="flex items-center gap-3 grow justify-end">
    {Children.map(children, (child) => (
      <li className="flex items-center">{child}</li>
    ))}
  </ul>
)

export const TopNav = Object.assign(Root, { Ul })
