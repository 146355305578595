import { initializeApp, getApps, getApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'

export const firebaseConfig = {
  apiKey: "AIzaSyCNbwMyBhVM2u7LHgM-JmzrcxZbb0urNxo",
  authDomain: "sora-app-86bdd.firebaseapp.com",
  projectId: "sora-app-86bdd",
  storageBucket: "sora-app-86bdd.appspot.com",
  messagingSenderId: "861837156995",
  appId: "1:861837156995:web:03961b297d3b1a8a6a31b6"
}

export const startFirebase = async () => {
  const apps = getApps()
  if (!apps.length) initializeApp(firebaseConfig)
}

export const requestForToken = async () => {
  let currentToken = null
  try {
    const canContinue = await isSupported()
    if (canContinue) {
      const app = getApp()
      const messaging = getMessaging(app)
      const notificationGranted = await requestPermission()
      if (notificationGranted) {
        currentToken = await getToken(messaging, { vapidKey: 'BKXT4cZGqnzS0w6thwU6f_LPRnNqKNugT5mjSuHB_1VQpB-pBAPjbRzBPSriSpt5c1ZvrP1zRVkoz7tCO80TCA0' })
      }
    }
  } catch (err) {
    currentToken = null
  }

  return currentToken
}

export const requestPermission = async () => {
  const permission = await Notification.requestPermission()
  return permission === 'granted'
}
