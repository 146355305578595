import React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData } from 'react-router-dom'
import SidebarOpt from './sidebar-opt'

const loader = async () => {
  const result = await axios.get('/backoffice/workbench/_index')
  return result.data
}

const Element = () => {
  const { items } = useLoaderData()

  return (
    <div className="flex flex-row gap-4 h-full">
      <div className='w-fit h-full '>
        <ul className="self-start top-24 flex flex-col border-l border-l-gray-30 border-r border-r-gray-30 p-4 min-w-40 max-w-52 gap-2 overflow-auto h-full">
          <li className="text-lg font-bold text-gray-70 border-b border-b-gray-30">Tools</li>
          {items.map((item) => (
            <SidebarOpt key={item.url} url={item.url} label={item.label} />
          ))}
        </ul>
      </div>
      <div className='flex-1 flex flex-col relative h-full'>
        <div className="overflow-auto absolute top-0 bottom-0 left-0 right-0">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export const WorkbenchRoute = {
  loader,
  Element,
}
