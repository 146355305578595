import * as React from 'react'
import axios from 'axios'
import { useNavigate, useLoaderData, LoaderFunctionArgs } from 'react-router-dom'
import { Dialog, Button, unstable_Table as Table, Typography, Card } from '@design-system'

interface LoaderData {
  id: number,
  type: string,
  resourceId: string,
  errors: Array<string>,
  info: Array<string>,
}
async function loader({ params }: LoaderFunctionArgs) {
  const res = await axios.get(`/backoffice/workbench/student-heartbeat-server/jobs/${params.jobId}`)
  return res.data
}

function Element() {
  const [infoFilter, setInfoFilter] = React.useState<'errors' | 'info'>('errors')
  const loaderData = useLoaderData() as LoaderData
  const pickedData = loaderData?.[infoFilter]
  const navigate = useNavigate()

  return (
    <Dialog open onClose={() => navigate(-1)}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>
            Logs details
          </Dialog.Title>
          <Dialog.Close />
        </Dialog.Header>
        <Dialog.Body>
          <Card>
            <Card.Content className="flex flex-col gap-2">
              <Typography>
                <b>Job ID:</b> {loaderData.id}
              </Typography>
              <Typography>
                <b>Type:</b> {loaderData.type}
              </Typography>
              <Typography>
                <b>Resource ID:</b> {loaderData.resourceId}
              </Typography>
            </Card.Content>
          </Card>
          <div className="flex gap-2">
            <Button variant="contained" color={infoFilter === 'errors' ? 'dark' : 'soft'} size="sm" onClick={() => setInfoFilter('errors')}>Errors</Button>
            <Button variant="contained" color={infoFilter === 'info' ? 'dark' : 'soft'} size="sm" onClick={() => setInfoFilter('info')}>Infos</Button>
          </div>
          <Table className="border-separate border border-gray-30 rounded-lg">
            <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
              <Table.Row>
                <Table.Head className="w-48 first:pl-4 capitalize">{infoFilter}</Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body className="[&_tr:last-child]:border-1">
              {pickedData && pickedData?.length ? pickedData?.map((data, index) => (
                <Table.Row key={index} className="last-of-type:rounded-b-md">
                  <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{data}</Table.Cell>
                </Table.Row>
              )) : (
                <Table.Row className="last-of-type:rounded-b-md">
                  <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">No {infoFilter} logged for this job.</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog>
  )
}
export const WorkbenchStudentHearbeatServerJobsLogsRoute = { loader, Element }
