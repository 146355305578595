import React, { useState } from 'react'
import { toast } from 'sonner'
import axios from 'axios'
import { Button } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'

export default function WorkbenchAdminRoute() {
  const confirmAction = useConfirmModal()
  const [syncBtnDisabled, setSyncBtnDisabled] = useState(false)

  const updateStudentDomain = async () => {
    if (
      await confirmAction({
        title: 'Confirm Student Domain force update',
        subtitle:
          'Make sure you have a clear reason to execute this action, like an update or script that bypassed the SH flows. Executing this will force all student domain entities to be recalculated.',
      })
    ) {
      try {
        setSyncBtnDisabled(true)
        toast.success('Force updated started. It may take a while, please wait...')
        await axios.post('/pages/admin/workbench/admin-actions/update-domains')
        setSyncBtnDisabled(false)
      } catch (e) {
        toast.error('Something went wrong while syncing...')
        console.error(e)
      }
      setTimeout(() => {
        setSyncBtnDisabled(false)
      }, 5000)
    }
  }

  return (
    <div className="flex flex-col pt-3 gap-16">
      <div>
        <div>
          <span className="bg-yellow-5 p-3 border border-yellow-30 rounded">
            If you are not on the product or engineering team, you're probably not on the correct place.
          </span>
        </div>
        <div className="mt-5">
          <Button onClick={updateStudentDomain} loading={syncBtnDisabled}>
            {syncBtnDisabled ? 'Syncing...' : 'Student Domain Force Update'}
          </Button>
        </div>
      </div>
    </div>
  )
}
