import axios from 'axios'

export const loader = async ({ params, request }) => {
  const { data: loaderData } = await axios.get(
    `/pages/employee/students/${params.student_id}/transcript`,
    { params: new URL(request.url).searchParams }
  )

  const { data: transcriptFile, headers } = await axios.get(
    `/pages/employee/students/${params.student_id}/transcript/file`,
    { responseType: 'blob', params: new URL(request.url).searchParams }
  )
  const [, filename = 'transcript.pdf'] = headers['content-disposition'].match(/filename="([^"]+)"/)
  return { loaderData, filename, transcriptFile }
}
