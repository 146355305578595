import React from 'react'
import { Outlet } from 'react-router-dom'
import { SubmenuPortal } from '@routes/index'
import { SoraNavLink } from '@components/link'
import { Typography } from '@design-system'

const EmployeeAttendancesRoute = () => {
  return (
    <>
      <Typography variant="heading-3" weight="bold" className="mb-6">Attendances</Typography>
      <SubmenuPortal>
        <div
          className="grid grid-cols-7 text-center items-end rounded-xl h-full w-[800px]"
        >
          <SoraNavLink to='calendar' className={active => `w-full border-b-2 py-1.5 text-xs font-bold uppercase 
            ${active ? 'border-accent text-accent' : 'border-transparent'
            }`}>
            Calendar
          </SoraNavLink>
          <SoraNavLink to='report' className={active => `w-full border-b-2 py-1.5 text-xs font-bold uppercase 
            ${active ? 'border-accent text-accent' : 'border-transparent'
            }`}>
            Report
          </SoraNavLink>
        </div>
      </SubmenuPortal>
      <Outlet />
    </>
  )
}

export default EmployeeAttendancesRoute
