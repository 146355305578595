import React, { useState, useEffect } from 'react'
import withDurationMask, { incrementDuration, decrementDuration } from '../../utils/withDurationMask'
import { TextField, type TextFieldProps } from '@designsystem'

type DurationInputProps = Omit<TextFieldProps, 'onChange'>

export function DurationInput({
  defaultValue,
  name,
  ...rest }: DurationInputProps) {

  const [localState, setLocalState] = useState((defaultValue) + ((defaultValue).toString().length === 5 ? ':00' : ''))

  const handleChange = event => {
    let { value: targetValue } = event.target
    setLocalState((targetValue + ':00'))
  }

  const handleKeyDown = event => {
    const { keyCode, target: { value } } = event
    if (keyCode === 38)
      setLocalState(incrementDuration(value) + ':00')
    if (keyCode === 40)
      setLocalState(decrementDuration(value) + ':00')
  }

  return (
    <TextField
      type="text"
      name={name}
      value={localState.slice(0, -3)}
      onKeyDown={handleKeyDown}
      onChange={withDurationMask(handleChange)}
      {...rest}
    />
  )

}


