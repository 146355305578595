import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import useSWR from 'swr'
import PropTypes from 'prop-types'
import { useLoaderData, useParams, useSearchParams } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import OldCycleReport from './old-reports'
import MidCycleReport from './mid-cycle-report'
import EndCycleReport from './end-cycle-report'
import useCurrentUser from '@hooks/useCurrentUser'
import useUserFeatureFlag from '@hooks/useUserFeatureFlag'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@designsystem'
import axios from 'axios'
import Select from 'react-select'
import format from 'date-fns/format'

export async function loader({ params, request }) {
  const searchParams = new URL(request.url).searchParams
  const cycleId = searchParams.get('cycle_id')
  const { data } = await axios.get(`/pages/student/${params.student_id}/progress/reports${cycleId ? `?cycle_id=${cycleId}` : ''}`)
  return data
}

export default function ProgressReports() {
  const loaderData = useLoaderData()
  const [, setSearchParams] = useSearchParams()

  const cycleOptions = loaderData.cycles
    .map((c) => ({
      value: c.cycle_id,
      period: `${format(new Date(c.start_timestamp), 'MMMM dd')} - ${format(new Date(c.end_timestamp), 'MMMM dd')}`,
      label: (
        <span>
          {c.title}:{' '}
          <span className="font-bold">
            {format(new Date(c.start_timestamp), 'MMMM dd')} - {format(new Date(c.end_timestamp), 'MMMM dd')}
          </span>
        </span>
      )
    }))

  return (
    <div className="flex flex-col space-y-8 bg-white py-8 px-5 mr-1 rounded">
      <div className="flex items-center space-x-3">
        <span className="text-3xl font-bold">
          {loaderData.cycleTitle} Progress Reports
        </span>
        <Select
          key={loaderData.cycleId}
          className="w-96"
          isSearchable={false}
          defaultValue={
            cycleOptions.find((c) => c.value === loaderData.cycleId) || ''
          }
          options={cycleOptions}
          onChange={(e) => setSearchParams({ cycle_id: e.value })}
        />
      </div>
      {
        loaderData.cycleId ? (
          <ReportsTab
            cycleId={Number(loaderData.cycleId)}
            cycleRelativeCounter={Number(loaderData.cycleRelativeCounter)}
            cyclePeriodTitle={loaderData.cycleTitle}
          />
        ) : (
          <span>Cycle not available for student.</span>
        )
      }
    </div>
  )
}

const CycleReportList = forwardRef(({ type, studentId, cycleId, cyclePeriodTitle }, ref) => {
  const { data: reports, error } = useSWR(() => `/reports/v2/progress-report/${studentId}/cycle/${cycleId}/${type}`)
  const [listState, setListState] = useState([])
  const [hasDefaultExpanded, setHasDefaultExpanded] = useState(false)

  const expandAll = () => {
    setListState(reports.map((report, i) => `${type}-${i}`))
  }

  useImperativeHandle(ref, () => ({
    expandAll: () => {
      const lastState = [...listState]
      expandAll()
      return lastState
    },
    collapseAll: () => {
      setListState([])
    },
    recoverState: (state = []) => {
      setListState(state)
    }
  }))

  if (!reports && !error) return <span className="p-8">Loading reports...</span>

  if (!reports.length)
    return <span className="p-8">No Reports for this Cycle</span>

  if (!!reports && !listState.length && !hasDefaultExpanded) {
    expandAll()
    setHasDefaultExpanded(true)
  }

  const toggleExpandState = (id) => () => {
    if (listState.some(ls => ls === id))
      setListState(listState.filter(ls => ls !== id))
    else
      setListState([...listState, id])
  }

  return (
    <div className="flex flex-col space-y-10">
      {reports.map((report, i) =>
        type === 'mid' ? (
          <MidCycleReport key={`report:${type}:${i}`} report={report} cyclePeriodTitle={cyclePeriodTitle} isExpanded={listState.some(ls => ls === `${type}-${i}`)} toggleIsExpanded={toggleExpandState(`${type}-${i}`)} />
        ) : type === 'final' ? (
          <EndCycleReport key={`report:${type}:${i}`} report={report} cycleId={cycleId} cyclePeriodTitle={cyclePeriodTitle} isExpanded={listState.some(ls => ls === `${type}-${i}`)} toggleIsExpanded={toggleExpandState(`${type}-${i}`)} />
        ) : null
      )}
    </div>
  )
})

CycleReportList.displayName = 'CycleReportList'

CycleReportList.propTypes = {
  type: PropTypes.oneOf(['final', 'mid']).isRequired,
  studentId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  cycleId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  cyclePeriodTitle: PropTypes.string,
}

const ReportsTab = ({ cycleId, cycleRelativeCounter, cyclePeriodTitle }) => {
  const { student_id } = useParams()
  const cycleReportRef = useRef(null)
  const [selectedTab, setSelectedTab] = useState('mid-cycle')
  const currentUser = useCurrentUser()
  const { checkBetaFeature } = useUserFeatureFlag()
  const isSoraScore = checkBetaFeature('sora_score')

  const studentId = Number(student_id)

  const { data, error } = useSWR(
    () => `/reports/v2/progress-report/${studentId}/cycle/${cycleId}`
  )

  useEffect(() => {
    if (!data?.result?.mid_cycle && !data?.result?.end_cycle && isSoraScore) {
      setSelectedTab('development-assessment')
    }
  }, [data?.result?.end_cycle, data?.result?.mid_cycle, isSoraScore])

  if (!data && !error) return <span>Loading...</span>

  const handlePrint = async () => {
    window.open(`/app/reports/students/${student_id}/cycle/${cycleId}/${selectedTab}.pdf`, '_blank')
  }
  
  if (cycleRelativeCounter && cycleRelativeCounter <= 20)
    return <OldCycleReport studentId={studentId} cycleId={cycleId} />

  return (
    <div className="flex flex-col space-y-3">
      <div className='flex flex-row items-center'>
        <RadioGroup
          value={selectedTab}
          onChange={setSelectedTab}
          className="grid grid-cols-7 items-end rounded-xl h-full w-[800px]"
        >
          {(currentUser?.admin || data?.result?.mid_cycle) && (
            <RadioGroup.Option value="mid-cycle">
              {({ checked }) => (
                <button
                  data-testid="tab_mid-cycle"
                  className={`w-full py-1.5 text-black ${checked && 'font-bold'}`}
                >
                  Mid-Cycle
                </button>
              )}
            </RadioGroup.Option>
          )}
          {(currentUser?.admin || data?.result?.end_cycle) && (
            <RadioGroup.Option value="end-cycle">
              {({ checked }) => (
                <button
                  data-testid="tab_end-cycle"
                  className={`w-full py-1.5 text-black ${checked && 'font-bold'}`}
                >
                  End-Cycle
                </button>
              )}
            </RadioGroup.Option>
          )}
          {isSoraScore && (
            <RadioGroup.Option
              value="development-assessment"
              className="col-span-2"
            >
              {({ checked }) => (
                <button
                  data-testid="tab_development-assessment"
                  className={`w-full whitespace-nowrap py-1.5 text-black ${checked && 'font-bold'}`}
                >
                  Development Assessment
                </button>
              )}
            </RadioGroup.Option>
          )}
        </RadioGroup>
        {['mid-cycle', 'end-cycle'].includes(selectedTab) && (
          <div className='flex-auto flex flex-row justify-end'>
            <Button
              color="cta"
              tooltip="Print"
              onClick={() => handlePrint()}
              loading={false}
              startIcon={faPrint}
            />
          </div>
        )}
      </div>
      <div id={`print-area-${selectedTab}`}>
        {selectedTab === 'mid-cycle' &&
          (currentUser?.admin || data?.result?.mid_cycle) && (
            <CycleReportList
              ref={cycleReportRef}
              studentId={studentId}
              cycleId={cycleId}
              type="mid"
              cyclePeriodTitle={cyclePeriodTitle}
            />
          )}
        {selectedTab === 'end-cycle' &&
          (currentUser?.admin || data?.result?.end_cycle) && (
            <CycleReportList
              ref={cycleReportRef}
              studentId={studentId}
              cycleId={cycleId}
              type="final"
              cyclePeriodTitle={cyclePeriodTitle}
            />
          )}
      </div>
    </div>
  )
}

ReportsTab.propTypes = {
  cycleId: PropTypes.number.isRequired,
  cycleRelativeCounter: PropTypes.number,
  cyclePeriodTitle: PropTypes.string,
}
