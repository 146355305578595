import React from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import NotificationFilter from "@components/notifications/filter"
import type { NotificationTabs } from "@components/notifications/types"

export default function Employee$IdNotificationsRoute() {
  const navigate = useNavigate()
  const { notification_type: notificationType } = useParams() as { notification_type: NotificationTabs }

  const handleSelectTab = (tab: string) => {
    navigate(`./${tab}`)
  }

  return (
    <section className="flex flex-col space-y-8 px-5 md:pl-20 py-10 min-h-[100vh] bg-white">
      <h1 className="text-2xl md:text-4xl font-bold">Notifications</h1>
      <NotificationFilter
        selectedTab={notificationType}
        onSelectTab={handleSelectTab}
        onClickSettingsIcon={() => navigate('../settings')}
        gridCols={2}
        popoverPlacement="bottom"
      />
      <div className="flex flex-col">
        <Outlet />
      </div>
    </section>
  )
}
