{/* prettier-ignore */ }
import { Toaster, unstable_TooltipBase as TooltipBase } from '@design-system'
import 'regenerator-runtime/runtime'
import React from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { PageTitleProvider } from '@hooks/usePageTitle'
import { ConfirmModalProvider } from '@hooks/useConfirmModal/provider'
import PropTypes from 'prop-types'
import { SWRConfig } from 'swr'
import { captureException } from '@sentry/react'
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'

/**
 * Legacy Routes
*/
import ErrorBoundary, { ErrorComponent } from '@components/error-boundary'
import ReleaseProgressReport, { loader as releaseProgressReportLoader } from '@routes/admin/workbench/reports/progress.jsx'
import AttendanceFacultyRoute, {
  loader as attendanceFacultyLoader,
} from '@routes/employee/attendances/calendar'
import Documents from '@routes/employee/students/$studentId/documents'
import FeatureFlags from '@routes/admin/workbench/feature-flags'
import Permissions from '@routes/admin/workbench/permissions'
import ProgressReports, {
  loader as studentProgressReportsLoader,
} from '@routes/employee/students/$studentId/progress-report'
import SignInRoute, { loader as signInLoader } from '@routes/signin'
import TemplateExpeditionLibrary from '@routes/template/library'
import { loader as templateExpeditionLibraryLoader } from '@routes/template/library/loader'
import { action as templateExpeditionLibraryAction } from '@routes/template/library/action'
import EmployeeAtendancesRoute from '@routes/employee/attendances'
import EmployeeAttendancesReportRoute from '@routes/employee/attendances/report'
import { loader as employeeAttendancesReportLoader } from '@routes/employee/attendances/report/loader'
import { StudentTranscriptRoute } from '@routes/employee/students/$studentId/transcript'
import { loader as studentTranscriptLoader } from '@routes/employee/students/$studentId/transcript/loader'
import EnrolmentsRoute from '@routes/admin/enrolments'
import enrolmentsLoader from '@routes/admin/enrolments/loader'
import enrolmentsAction from '@routes/admin/enrolments/action'
import Impersonate, {
  loader as impersonateLoader,
  action as impersonateAction
} from '@routes/employee/impersonate'
import EmployeeExpeditionsEditPage from '@routes/employee/expeditions/edit'
import { loader as employeeExpeditionsEditLoader } from '@routes/employee/expeditions/edit/loader'
import EmployeeRoute from '@routes/employee'
import EmployeeStudentsRoute from '@routes/employee/students'
import { loader as employeeStudentsIndexLoader } from '@routes/employee/students/loader'
import WorkbenchUnitsList, { loader as WorkbenchUnitsListLoader } from '@routes/admin/workbench/units/list'
import WorkbenchUnitsRoute from '@routes/admin/workbench/units/index'
import Admissions, { loader as admissionsLoader, action as admissionsAction } from '@routes/admin/workbench/admissions/admissions'
import EditUnitWorkbenchRoute, { loader as EditUnitWorkbenchLoader, action as EditUnitWorkbenchAction } from '@routes/admin/workbench/units/edit'
import NewUnitWorkbenchRoute, { loader as NewUnitWorkbenchLoader, action as NewUnitWorkbenchAction } from '@routes/admin/workbench/units/new'
import WorkbenchIndex from '@routes/admin/workbench/index'
import WorkbenchHeartbeatRoute from '@routes/admin/workbench/hearbeat'
import WorkbenchEmployeesRoute from '@routes/admin/workbench/employees'
import WorkbenchEmployeeProfile from '@routes/admin/workbench/employees/profile/index'
import WorkbenchEmployeeNew from '@routes/admin/workbench/employees/profile/new'
import WorkbenchEmployeeTable from '@routes/admin/workbench/employees/table/index'
import WorkbenchMailchimpRoute from '@routes/admin/workbench/mailchimp'
import WorkbenchAdminRoute from '@routes/admin/workbench/admin-actions'
import WorkbenchGoogleRoute, { loader as workbenchGoogleLoader, } from '@routes/admin/workbench/google'
import Employee$IdNotificationsRoute from '@routes/employee/$user_id/notifications'
import Employee$UserIdNotifications$NotificationTypeRoute, {
  loader as notificationsTypeLoader,
  action as notificationsTypeAction,
} from '@routes/employee/$user_id/notifications/$notification_type'
import StudentGraduationRoute, {
  loader as studentGraduationLoader
} from '@routes/employee/students/$studentId/graduation'
import EmployeeStudentContactRoute from '@routes/employee/students/$studentId/contact'
import { loader as employeeStudentContactLoader } from '@routes/employee/students/$studentId/contact/loader'
import ExemptionsEditRoute, {
  loader as exemptionsEditLoader,
  action as exemptionsEditAction,
} from '@routes/employee/students/$studentId/exemptionsEdit'
import NotificationsWorkbenchRoute, {
  action as notificationsWorkbenchAction,
  loader as notificationsWorkbenchLoader,
} from '@routes/admin/workbench/notifications'
import Employee$UserIdNotificationsSettingsRoute, {
  loader as userNotificationSettingsLoader,
  action as userNotificationSettingsAction,
} from '@routes/employee/$user_id/notifications/settings'
import { IndexNoAuthRoute, loader as indexNoAuthLoader } from './routes'
import { NavigateCycle } from '@hooks/useNavigateWithCycle'
import Employee$UserIdNotifications$NotificationTypeDetailsIdRoute, {
  loader as userNotificationDetailsLoader,
} from '@routes/employee/$user_id/notifications/$notification_type/details/$id'
import WorkbenchOnboardingAddFamiliesToGroupRoute, {
  loader as workbenchOnboardingAddFamiliesToGroupLoader,
  action as workbenchOnboardingAddFamiliesToGroupAction,
} from '@routes/admin/workbench/onboarding/families/$campusFilter/add-families-to-group'
import WorkbenchOnboardingCampus$OnboardingGroupIdContentRoute, {
  loader as workbenchOnboardingCampus$OnboardingGroupIdContentLoader,
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content'
import WorkbenchOnboardingChecklistNewRoute, {
  loader as workbenchOnboardingChecklistNewRouteLoader,
  action as workbenchOnboardingChecklistNewRouteAction
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content/checklist/new'
import WorkbenchOnboardingGroupsNewRoute, {
  loader as workbenchOnboardingGroupsNewLoader,
  action as workbenchOnboardingGroupsNewAction
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/new'
import WorkbenchOnboardingGroupDuplicatedGroupModal$onboardingIdRoute, {
  loader as workbenchOnboardingGroupDuplicatedGroupModal$onboardingIdLoader
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/duplicated-group-modal/$onboardingGroupId'
import WorkbenchOnboardingChecklistEditRoute, {
  loader as workbenchOnboardingChecklistEditLoader,
  action as workbenchOnboardingChecklistEditAction
} from '@routes/admin/workbench/onboarding/$onboardingGroupId/content/checklist/$checklistId/$userViewType/edit'
import WorkbenchOnboardingGroups$OnboardingGroupIdRoute, {
  loader as workbenchOnboardingGroups$OnboardingGroupIdLoader,
  action as workbenchOnboardingGroups$OnboardingGroupIdAction,
} from '@routes/admin/workbench/onboarding/groups/$campusFilter/edit/$onboardingGroupId'
import WorkbenchGoogleOfficeHoursRoute, {
  loader as workbenchGoogleOfficeHoursLoader,
  action as workbenchGoogleOfficeHoursAction,
} from '@routes/admin/workbench/google/office-hours'
import WorkbenchCannyRoute from '@routes/admin/workbench/canny'
import WorkbenchCannySyncUsersRoute from '@routes/admin/workbench/canny/sync-users'
import { action as workbenchCannySyncUsersAction } from '@routes/admin/workbench/canny/sync-users/action'
import WorkbenchGoogleHouseEventsRoute, { loader as workbenchGoogleHouseEventLoader, action as workbenchGoogleHouseEventAction } from '@routes/admin/workbench/google/house-events'
import WorkbenchGoogleForceHolidaysRoute, { action as workbenchGoogleForceHolidaysAction } from '@routes/admin/workbench/google/force-holidays'
import StudentsRequests, { loader as studentsRequestsLoader } from '@routes/admin/reports/students/requests'
import ExperiencesRosterReportsRoutes from '@routes/admin/reports/experiences-roster'
import experiencesRosterReportsLoader from '@routes/admin/reports/experiences-roster/loader'
import StudentMissingUnits, { loader as studentMissingUnitsLoader } from '@routes/admin/reports/students/missing/units'
import UnitsCompletion, { loader as unitsCompletionLoader } from '@routes/admin/reports/units-completion'
import StudentAvailabilityReport, { loader as studentAvailabilityReportLoader } from '@routes/admin/reports/students/availability'
import MaterialsReportRoute, { loader as materialsReportLoader } from '@routes/admin/reports/materials'
import { HousesIndexRoute } from '@features/houses._index'
import { HouseNewRoute } from '@features/houses.new'
import { HouseRoute } from '@features/houses.$houseId'
import { HouseOverviewRoute } from '@features/houses.$houseId.overview'
import { HouseOverviewReportRoute } from '@features/houses.$houseId.overview.$reportId'
import { HouseOverviewReportNoteDialogRoute } from '@features/houses.$houseId.overview.$reportId.note'
import { HouseStudentsRoute } from '@features/houses.$houseId.students'
import { HouseAttendancesRoute } from '@features/houses.$houseId.attendances'
import { HouseAttendanceEventRoute } from '@features/houses.$houseId.attendances.$eventId'
import { HouseEditRoute } from '@features/houses.edit.$houseId'
import { FeatureFlagSettingsLabsRoute } from '@routes/admin/workbench/feature-flag/$feature_flag_id/sora-labs-settings'
import { EmployeeStudentWithdrawalRoute } from '@routes/employee/students/$studentId/withdrawal'
import AdmissionsConfig from '@routes/admin/workbench/admissions/config/admissions-config'
import AdmissionsCopyConfig, { loader as admissionsCopyConfigLoader, action as admissionsCopyConfigAction } from '@routes/admin/workbench/admissions/config/admissions-copy-config'
import AdmissionsStartDatesConfig, { loader as admissionsStartDatesConfigLoader, action as admissionsStartDatesConfigAction } from '@routes/admin/workbench/admissions/config/admissions-start-dates-config'

/**
 * Features
 */
import { RootRoute } from '@features/_root'
import { RootAuthenticatedRoute } from '@features/_root._authenticated'
import { HomeRoute } from '@features/home'
import { FacilitateExperienceTaskRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId'
import { FacilitateExperienceAttendanceRoute } from '@features/facilitate_.$type_.$experienceId_.attendance'
import { FacilitateExperienceAttendanceDefaultRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default'
import { FacilitateExperienceAttendanceDefaultSessionRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default.$sessionId'
import { FacilitateExperienceAttendanceDefaultSessionZoomReportRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.default.$sessionId.zoom-report'
import { FacilitateExperienceAttendanceAggregatedRoute } from '@features/facilitate_.$type_.$experienceId_.attendance.aggregated'
import { FacilitateExperienceTaskStudentRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId'
import { FacilitateExperienceTaskStudentMetricsRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.metrics'
import { FacilitateExperienceTaskStudentDeadlineExtensionRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.deadline-extension'
import { FacilitateExperienceTaskStudentFeedbackRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.feedback'
import { FacilitateExperienceTaskStudentCommentBankRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.comment-bank'
import { FacilitateExperienceTaskStudentCommentBankCreateRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.comment-bank.preset-comment.$commentId'
import { FacilitateExperienceTaskStudentMidCycleReportAiRoute } from '@features/facilitate_.$type_.$experienceId_.tasks.$taskId.students.$studentId.mid-cycle-report-ai'
import { ImpactDashboardRoute } from '@features/impact-dashboard'
import { NEW_TemplateFormRoute } from '@features/template.($templateId).edit'
import { HouseAttendanceEventZoomReportRoute } from '@features/houses.$houseId.attendances.$eventId.zoom-report'
import { NEW_ExperienceRoute } from '@features/experiences'
import { NEW_ExperienceFormRoute } from '@features/experiences.($experienceId).edit'
import { NEW_ExpeditionTasksRoute } from '@features/experiences.$experienceId.tasks'
import { UsePickedTemplateRoute } from '@features/template.$templateId.use-picked-template'
import { OnboardingGroupsRoute } from '@features/workbench.onboarding.groups'
import { OnboardingGroupsCampusId } from '@features/workbench.onboarding.groups.$campusId'
import { CyclesRoute } from '@features/cycles'
import { AppSsoRoute } from '@features/app-sso'
import { EmployeeStudentRoute } from '@features/employee.students.$studentId'
import { EmployeeStudentOverviewRoute } from '@features/employee.students.$studentId.overview'
import { EmployeeStudentNotesRoute } from '@features/employee.students.$studentId.notes'
import { EmployeeStudentLearningAvailabilityRoute } from '@features/employee.students.$studentId.learning-availability'
import { EmployeeStudentNotesFormRoute } from '@features/employee.students.$studentId.notes.form.($noteId)'
import { EmployeeStudentProgramRoute } from '@features/employee.students.$studentId.program.$schoolStage'
import { EmployeeStudentProgramOverviewRoute } from '@features/employee.students.$studentId.program.$schoolStage.overview'
import { EmployeeStudentProgramMentorshipCapstoneRoute } from '@features/employee.students.$studentId.program.$schoolStage.mentorship-capstone'
import { EmployeeStudentProgramSubjectRoute } from '@features/employee.students.$studentId.program.$schoolStage.$subjectId'
import { EmployeeStudentProgramSubjectDetailsTopicRoute } from '@features/employee.students.$studentId.program.$schoolStage.$subjectId.details.$topicId'
import { EmployeeStudentProgramSubjectExemptionRoute } from '@features/employee.students.$studentId.program.$schoolStage.$subjectId.exemption.$topicId'
import { EmployeeStudentProgramSubjectExternalElectiveRoute } from '@features/employee.students.$studentId.program.$schoolStage.$subjectId.external-elective.$topicId'
import { Onboarding$TodoIdRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId.$userViewType.todo.$todoId'
import { OnboardingUserTypeRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId.$userType'
import { OnboardingChecklistIdRoute } from '@features/workbench.onboarding.$onboardingGroupId.content.checklist.$checklistId'
import { WorkbenchRoute } from '@features/workbench._index'
import { WorkbenchMathPlacementRoute } from '@features/workbench.math-placement'
import { WorkbenchMathPlacementTimelineRoute } from '@features/workbench.math-placement.timeline'
import { WorkbenchMathPlacementIndexRoute } from '@features/workbench.math-placement._index'
import { WorkbenchMathPlacementStudentsRoute } from '@features/workbench.math-placement.students'
import { WorkbenchOnboardingFamiliesReportRoute } from '@features/workbench.onboarding.families'
import { WorkbenchOnboardingFamiliesReportFilterRoute } from '@features/workbench.onboarding.families.$campusFilter'
import { WorkbenchOnboardingRoute } from '@features/workbench.onboarding'
import { FacilitateRoute } from '@features/facilitate'
import { FacilitateTypeRoute } from '@features/facilitate.$type'
import { FacilitateTypeExperienceIdRoute } from '@features/facilitate.$type.$experienceId'
import { WorkbenchPathwaysIndexRoute } from '@features/workbench.pathways._index'
import { WorkbenchPathwaysRoute } from '@features/workbench.pathways'
import { WorkbenchPathwaysManagementIndexRoute } from '@features/workbench.pathways.management._index'
import { WorkbenchPathwaysManagementPathwayIdRoute } from '@features/workbench.pathways.management.$pathwayId'
import { WorkbenchPathwaysStudentsRoute } from '@features/workbench.pathways.students'
import { FacultyAvailabilityRoute } from '@features/faculty-availability.$employeeId'
import { WorkbenchStudentHearbeatServerRoute } from '@features/workbench.student-heartbeat-server'
import { WorkbenchStudentHearbeatServerBasicSetupRoute } from '@features/workbench.student-heartbeat-server.basic-setup'
import { WorkbenchStudentHearbeatServerStudentsRoute } from '@features/workbench.student-heartbeat-server.students'
import { WorkbenchStudentHearbeatServerHousesRoute } from '@features/workbench.student-heartbeat-server.houses'
import { WorkbenchStudentHearbeatServerExperiencesRoute } from '@features/workbench.student-heartbeat-server.experiences'
import { WorkbenchStudentHearbeatServerUsersRoute } from '@features/workbench.student-heartbeat-server.users'
import { WorkbenchStudentHearbeatServerEmployeesRoute } from '@features/workbench.student-heartbeat-server.employees'
import { WorkbenchStudentHearbeatServerJobsRoute } from '@features/workbench.student-heartbeat-server.jobs'
import { WorkbenchStudentHearbeatServerJobsLogsRoute } from '@features/workbench.student-heartbeat-server.jobs.$jobId'
import { CycleIdRoute } from '@features/cycles.$cycleId'
import { WorkbenchGoogleCalendarSyncRoute } from '@features/workbench.google-calendar-sync'
import { WorkbenchRosettaStoneRoute } from '@features/workbench.rosetta-stone'
import { WorkbenchRosettaStoneUpdateCreditRoute } from '@features/workbench.rosetta-stone.update-credit'
import { WorkbenchRosettaStoneManageUsersRoute } from '@features/workbench.rosetta-stone.manage-users'
import { ClubsRoute } from '@features/clubs_'
import { ClubsEditRoute } from '@features/clubs_.($clubId).edit'
import { ClubsMembersRoute } from '@features/clubs_.$clubId.members'
import { WorkbenchGoogleGroupsSyncRoute } from '@features/workbench.google.sync-groups'
import { ExpeditionRegistrationsRoute } from '@features/experiences.$experienceId.registrations'
import { NewPasswordRoute } from '@routes/recover-password'
import { ReportsRegistrationChangesRoute } from '@features/reports.registration-changes'
import { ReportsRoute } from '@features/reports'
import { ReportsIndexRoute } from '@features/reports._index'

axios.defaults.baseURL = '/api'
axios.defaults.headers.common['X-Frontend-Id'] = import.meta.env.VITE_APP_GITHUB_SHA

function App() {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter
  )
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootRoute.Element />} loader={RootRoute.loader} errorElement={<ErrorComponent />}>
        <Route path="/" element={<RootAuthenticatedRoute.Element />} loader={RootAuthenticatedRoute.loader} action={RootAuthenticatedRoute.action}>
          <Route index element={<NavigateCycle to="/home" />} />
          <Route path="app-sso" loader={AppSsoRoute.loader} />
          <Route path="home" element={<HomeRoute.Element />} loader={HomeRoute.loader} />
          <Route path="facilitate" element={<FacilitateRoute.Element />} loader={FacilitateRoute.loader} >
            <Route path=":type" element={<FacilitateTypeRoute.Element />} loader={FacilitateTypeRoute.loader}>
              <Route path=":experienceId" element={<FacilitateTypeExperienceIdRoute.Element />} loader={FacilitateTypeExperienceIdRoute.loader} />
            </Route>
          </Route>
          <Route path='facilitate/:type/:experienceId/attendance' element={<FacilitateExperienceAttendanceRoute.Element />} loader={FacilitateExperienceAttendanceRoute.loader}>
            <Route path='default' element={<FacilitateExperienceAttendanceDefaultRoute.Element />} loader={FacilitateExperienceAttendanceDefaultRoute.loader}>
              <Route path=':sessionId' element={<FacilitateExperienceAttendanceDefaultSessionRoute.Element />} loader={FacilitateExperienceAttendanceDefaultSessionRoute.loader} action={FacilitateExperienceAttendanceDefaultSessionRoute.action}>
                <Route path="zoom-report" element={<FacilitateExperienceAttendanceDefaultSessionZoomReportRoute.Element />} loader={FacilitateExperienceAttendanceDefaultSessionZoomReportRoute.loader} />
              </Route>
            </Route>
            <Route path='aggregated' element={<FacilitateExperienceAttendanceAggregatedRoute.Element />} loader={FacilitateExperienceAttendanceAggregatedRoute.loader} />
          </Route>
          <Route path='facilitate/:type/:experienceId/tasks/:taskId' element={<FacilitateExperienceTaskRoute.Element />} loader={FacilitateExperienceTaskRoute.loader}>
            <Route path='students/:studentId' element={<FacilitateExperienceTaskStudentRoute.Element />} loader={FacilitateExperienceTaskStudentRoute.loader} action={FacilitateExperienceTaskStudentRoute.action}>
              <Route path='metrics' element={<FacilitateExperienceTaskStudentMetricsRoute.Element />} loader={FacilitateExperienceTaskStudentMetricsRoute.loader} />
              <Route path='deadline-extension' element={<FacilitateExperienceTaskStudentDeadlineExtensionRoute.Element />} loader={FacilitateExperienceTaskStudentDeadlineExtensionRoute.loader} action={FacilitateExperienceTaskStudentDeadlineExtensionRoute.action} />
              <Route path='mid-cycle-report-ai' element={<FacilitateExperienceTaskStudentMidCycleReportAiRoute.Element />} loader={FacilitateExperienceTaskStudentMidCycleReportAiRoute.loader} action={FacilitateExperienceTaskStudentMidCycleReportAiRoute.action} />
              <Route path='feedback' element={<FacilitateExperienceTaskStudentFeedbackRoute.Element />} loader={FacilitateExperienceTaskStudentFeedbackRoute.loader} action={FacilitateExperienceTaskStudentFeedbackRoute.action} errorElement={<ErrorComponent />} />
              <Route path='comment-bank' element={<FacilitateExperienceTaskStudentCommentBankRoute.Element />} loader={FacilitateExperienceTaskStudentCommentBankRoute.loader} action={FacilitateExperienceTaskStudentCommentBankRoute.action} >
                <Route path='preset-comment/:commentId' element={<FacilitateExperienceTaskStudentCommentBankCreateRoute.Element />} loader={FacilitateExperienceTaskStudentCommentBankCreateRoute.loader} action={FacilitateExperienceTaskStudentCommentBankCreateRoute.action} />
              </Route>
            </Route>
          </Route>

          <Route path="faculty-availability/:employeeId" element={<FacultyAvailabilityRoute.Element />} loader={FacultyAvailabilityRoute.loader} action={FacultyAvailabilityRoute.action} />

          <Route path="employee" element={<EmployeeRoute />} errorElement={<ErrorComponent type="DEFAULT" />} >
            <Route path="*" element={<NotFoundRoute />} />
            <Route path=":user_id/notifications">
              <Route path="settings" element={<Employee$UserIdNotificationsSettingsRoute />} loader={userNotificationSettingsLoader} action={userNotificationSettingsAction} />
              <Route path="view" element={<Employee$IdNotificationsRoute />}>
                <Route path=":notification_type" element={<Employee$UserIdNotifications$NotificationTypeRoute />} loader={notificationsTypeLoader} action={notificationsTypeAction}>
                  <Route path="details/:notification_id" element={<Employee$UserIdNotifications$NotificationTypeDetailsIdRoute />} loader={userNotificationDetailsLoader} />
                </Route>
              </Route>
            </Route>
            <Route path="expeditions/edit" element={<EmployeeExpeditionsEditPage />} loader={employeeExpeditionsEditLoader} />
            <Route path="students">
              <Route index element={<EmployeeStudentsRoute />} loader={employeeStudentsIndexLoader} />
              <Route path=":student_id" element={<EmployeeStudentRoute.Element />} loader={EmployeeStudentRoute.loader}>
                <Route index element={<NavigateCycle to="overview" />} />
                <Route path="overview" element={<EmployeeStudentOverviewRoute.Element />} loader={EmployeeStudentOverviewRoute.loader} />
                <Route path="contact" element={<EmployeeStudentContactRoute />} loader={employeeStudentContactLoader} />
                <Route path="notes" element={<EmployeeStudentNotesRoute.Element />} loader={EmployeeStudentNotesRoute.loader}>
                  <Route path="form/:noteId?" element={<EmployeeStudentNotesFormRoute.Element />} loader={EmployeeStudentNotesFormRoute.loader} action={EmployeeStudentNotesFormRoute.action} />
                </Route>
                <Route path="program/:schoolStage" element={<EmployeeStudentProgramRoute.Element />} loader={EmployeeStudentProgramRoute.loader} action={EmployeeStudentProgramRoute.action}>
                  <Route path="overview" element={<EmployeeStudentProgramOverviewRoute.Element />} loader={EmployeeStudentProgramOverviewRoute.loader} />
                  <Route path="mentorship-capstone" element={<EmployeeStudentProgramMentorshipCapstoneRoute.Element />} loader={EmployeeStudentProgramMentorshipCapstoneRoute.loader} action={EmployeeStudentProgramMentorshipCapstoneRoute.action} />
                  <Route path=":subjectId" element={<EmployeeStudentProgramSubjectRoute.Element />} loader={EmployeeStudentProgramSubjectRoute.loader} action={EmployeeStudentProgramSubjectRoute.action}>
                    <Route path="details/:topicId" element={<EmployeeStudentProgramSubjectDetailsTopicRoute.Element />} loader={EmployeeStudentProgramSubjectDetailsTopicRoute.loader} />
                    <Route path="exemption/:topicId" element={<EmployeeStudentProgramSubjectExemptionRoute.Element />} loader={EmployeeStudentProgramSubjectExemptionRoute.loader} action={EmployeeStudentProgramSubjectExemptionRoute.action} />
                    <Route path="external-elective/:topicId" element={<EmployeeStudentProgramSubjectExternalElectiveRoute.Element />} loader={EmployeeStudentProgramSubjectExternalElectiveRoute.loader} action={EmployeeStudentProgramSubjectExternalElectiveRoute.action} />
                  </Route>
                </Route>
                <Route path="graduation" element={<StudentGraduationRoute />} loader={studentGraduationLoader} />
                <Route path="year" element={<StudentGraduationRoute />} loader={studentGraduationLoader} />
                <Route path="reports" element={<ProgressReports />} loader={studentProgressReportsLoader} />
                <Route path="exemptions" element={<ExemptionsEditRoute />} loader={exemptionsEditLoader} action={exemptionsEditAction} />
                <Route path="withdrawal" element={<EmployeeStudentWithdrawalRoute.Element />} action={EmployeeStudentWithdrawalRoute.action} loader={EmployeeStudentWithdrawalRoute.loader} />
                <Route path="documents" element={<Documents />} />
                <Route path="learning-availability" element={<EmployeeStudentLearningAvailabilityRoute.Element />} loader={EmployeeStudentLearningAvailabilityRoute.loader} action={EmployeeStudentLearningAvailabilityRoute.action} />
                <Route path="transcript" element={<StudentTranscriptRoute />} loader={studentTranscriptLoader} />
              </Route>
            </Route>

            <Route path="attendances" element={<EmployeeAtendancesRoute />}>
              <Route index element={<NavigateCycle to="calendar" />} />
              <Route path="calendar" element={<AttendanceFacultyRoute />} loader={attendanceFacultyLoader} />
              <Route path="report" element={<EmployeeAttendancesReportRoute />} loader={employeeAttendancesReportLoader} />
            </Route>
            <Route path="impersonate" element={<Impersonate />} loader={impersonateLoader} action={impersonateAction} />
          </Route>

          <Route path="reports" element={<ReportsRoute.Element />} loader={ReportsRoute.loader}>
            <Route index element={<ReportsIndexRoute.Element />} />
            <Route path="students/requests" element={<StudentsRequests />} loader={studentsRequestsLoader}></Route>
            <Route path="students/missing/units" element={<StudentMissingUnits />} loader={studentMissingUnitsLoader}></Route>
            <Route path="students/availability" element={<StudentAvailabilityReport />} loader={studentAvailabilityReportLoader}></Route>
            <Route path="registration-changes" element={<ReportsRegistrationChangesRoute.Element />} loader={ReportsRegistrationChangesRoute.loader} />
            <Route path="experiences-roster" element={<ExperiencesRosterReportsRoutes />} loader={experiencesRosterReportsLoader}></Route>
            <Route path="units-completion" element={<UnitsCompletion />} loader={unitsCompletionLoader}></Route>
            <Route path="materials" element={<MaterialsReportRoute />} loader={materialsReportLoader}></Route>
            <Route path="task-responses" lazy={() => import('@features/reports.task-responses')} >
              <Route index element={<NavigateCycle to="expert-overview" />} />
              <Route path="expert-overview" lazy={() => import('@features/reports.task-responses.expert-overview')} />
              <Route path="stale-tasks" lazy={() => import('@features/reports.task-responses.stale-tasks')} />
            </Route>
            <Route path="master-schedules/experts/:schoolStage" lazy={() => import('@features/reports.master-schedules.experts.$schoolStage')} />
            <Route path="master-schedules/students/:schoolStage" lazy={() => import('@features/reports.master-schedules.students.$schoolStage')} />
          </Route>

          <Route path="NEW_experience" element={<NEW_ExperienceRoute.Element />} loader={NEW_ExperienceRoute.loader} errorElement={<ErrorComponent type="DEFAULT" />}>
            <Route path=":experienceId?/edit" element={<NEW_ExperienceFormRoute />} loader={NEW_ExperienceFormRoute.loader} action={NEW_ExperienceFormRoute.action} />
            <Route path=":experienceId/tasks" element={<NEW_ExpeditionTasksRoute.Element />} loader={NEW_ExpeditionTasksRoute.loader} action={NEW_ExpeditionTasksRoute.action} />
            <Route path=":experienceId/registrations" element={<ExpeditionRegistrationsRoute.Element />} loader={ExpeditionRegistrationsRoute.loader} action={ExpeditionRegistrationsRoute.action} />
          </Route>

          <Route path="template">
            <Route path="library" element={<TemplateExpeditionLibrary />} loader={templateExpeditionLibraryLoader} action={templateExpeditionLibraryAction} />
            <Route path=":templateId?/edit" element={<NEW_TemplateFormRoute />} loader={NEW_TemplateFormRoute.loader} action={NEW_TemplateFormRoute.action} />
            <Route path=":templateId/use-picked-template" element={<UsePickedTemplateRoute.Element />} loader={UsePickedTemplateRoute.loader} action={UsePickedTemplateRoute.action} />
          </Route>

          <Route path="clubs" element={<ClubsRoute.Element />} loader={ClubsRoute.loader} action={ClubsRoute.action} />
          <Route path="clubs/:clubId?/edit" element={<ClubsEditRoute.Element />} loader={ClubsEditRoute.loader} action={ClubsEditRoute.action} />
          <Route path="clubs/:clubId/members" element={<ClubsMembersRoute.Element />} loader={ClubsMembersRoute.loader} action={ClubsMembersRoute.action} />

          <Route path="houses">
            <Route index element={<HousesIndexRoute.Element />} loader={HousesIndexRoute.loader} action={HousesIndexRoute.action} />
            <Route path="new" element={<HouseNewRoute.Element />} loader={HouseNewRoute.loader} action={HouseNewRoute.action} />
            <Route path="edit/:houseId" element={<HouseEditRoute.Element />} loader={HouseEditRoute.loader} action={HouseEditRoute.action} />
            <Route path=":houseId" element={<HouseRoute.Element />} loader={HouseRoute.loader}>
              <Route path="overview" element={<HouseOverviewRoute.Element />} loader={HouseOverviewRoute.loader}>
                <Route path=":reportId" element={<HouseOverviewReportRoute.Element />} loader={HouseOverviewReportRoute.loader} action={HouseOverviewReportRoute.action}>
                  <Route path=":reportItemId/note" element={<HouseOverviewReportNoteDialogRoute.Element />} loader={HouseOverviewReportNoteDialogRoute.loader} action={HouseOverviewReportNoteDialogRoute.action} />
                </Route>
              </Route>
              <Route path="students" element={<HouseStudentsRoute.Element />} loader={HouseStudentsRoute.loader} />
              <Route path="attendances" element={<HouseAttendancesRoute.Element />} loader={HouseAttendancesRoute.loader}>
                <Route path=":eventId" element={<HouseAttendanceEventRoute.Element />} loader={HouseAttendanceEventRoute.loader} action={HouseAttendanceEventRoute.action}>
                  <Route path="zoom-report" element={<HouseAttendanceEventZoomReportRoute.Element />} loader={HouseAttendanceEventZoomReportRoute.loader} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="impact-dashboard" element={<ImpactDashboardRoute.Element />} loader={ImpactDashboardRoute.loader} />

          <Route path="cycles" element={<CyclesRoute.Element />} loader={CyclesRoute.loader}>
            <Route path=":cycleId/edit" element={<CycleIdRoute.Element />} loader={CycleIdRoute.loader} action={CycleIdRoute.action} />
          </Route>

          <Route path="workbench" element={<WorkbenchRoute.Element />} loader={WorkbenchRoute.loader} errorElement={<ErrorComponent type="DEFAULT" />}>
            <Route path="*" element={<NotFoundRoute />} />
            <Route index element={<WorkbenchIndex />} />
            <Route path="units" element={<WorkbenchUnitsRoute />}>
              <Route index element={<WorkbenchUnitsList />} loader={WorkbenchUnitsListLoader} />
              <Route path="new" element={<NewUnitWorkbenchRoute />} loader={NewUnitWorkbenchLoader} action={NewUnitWorkbenchAction} />
              <Route path="edit/:unit_id" element={<EditUnitWorkbenchRoute />} loader={EditUnitWorkbenchLoader} action={EditUnitWorkbenchAction} />
            </Route>
            <Route path="heartbeat" element={<WorkbenchHeartbeatRoute />} />
            <Route path="student-heartbeat-server" element={<WorkbenchStudentHearbeatServerRoute.Element />}>
              <Route path="basic-setup" element={<WorkbenchStudentHearbeatServerBasicSetupRoute.Element />} action={WorkbenchStudentHearbeatServerBasicSetupRoute.action} />
              <Route path="students" element={<WorkbenchStudentHearbeatServerStudentsRoute.Element />} loader={WorkbenchStudentHearbeatServerStudentsRoute.loader} action={WorkbenchStudentHearbeatServerStudentsRoute.action} />
              <Route path="houses" element={<WorkbenchStudentHearbeatServerHousesRoute.Element />} loader={WorkbenchStudentHearbeatServerHousesRoute.loader} action={WorkbenchStudentHearbeatServerHousesRoute.action} />
              <Route path="experiences" element={<WorkbenchStudentHearbeatServerExperiencesRoute.Element />} loader={WorkbenchStudentHearbeatServerExperiencesRoute.loader} action={WorkbenchStudentHearbeatServerExperiencesRoute.action} />
              <Route path="users" element={<WorkbenchStudentHearbeatServerUsersRoute.Element />} loader={WorkbenchStudentHearbeatServerUsersRoute.loader} action={WorkbenchStudentHearbeatServerUsersRoute.action} />
              <Route path="employees" element={<WorkbenchStudentHearbeatServerEmployeesRoute.Element />} loader={WorkbenchStudentHearbeatServerEmployeesRoute.loader} action={WorkbenchStudentHearbeatServerEmployeesRoute.action} />
              <Route path="jobs" element={<WorkbenchStudentHearbeatServerJobsRoute.Element />} loader={WorkbenchStudentHearbeatServerJobsRoute.loader}>
                <Route path=":jobId" element={<WorkbenchStudentHearbeatServerJobsLogsRoute.Element />} loader={WorkbenchStudentHearbeatServerJobsLogsRoute.loader} />
              </Route>
            </Route>
            <Route path="rosetta-stone" element={<WorkbenchRosettaStoneRoute.Element />}>
              <Route path="update-credit" element={<WorkbenchRosettaStoneUpdateCreditRoute.Element />} loader={WorkbenchRosettaStoneUpdateCreditRoute.loader} action={WorkbenchRosettaStoneUpdateCreditRoute.action} />
              <Route path="manage-users" element={<WorkbenchRosettaStoneManageUsersRoute.Element />} loader={WorkbenchRosettaStoneManageUsersRoute.loader} action={WorkbenchRosettaStoneManageUsersRoute.action} />
            </Route>

            <Route path="gcal-sync" element={<WorkbenchGoogleCalendarSyncRoute.Element />} loader={WorkbenchGoogleCalendarSyncRoute.loader} action={WorkbenchGoogleCalendarSyncRoute.action} />

            <Route path='journey-builder'>
              <Route path='processes'>
                <Route index lazy={() => import('@features/workbench.journey-builder.processes._index')} />
                <Route path='new' lazy={() => import('@features/workbench.journey-builder.processes.new')} />
                <Route path=':processId' lazy={() => import('@features/workbench.journey-builder.processes.$processId')}>
                  <Route index lazy={() => import('@features/workbench.journey-builder.processes.$processId._index')} />
                  <Route path='students' lazy={() => import('@features/workbench.journey-builder.processes.$processId.students')} />
                  <Route path="templates">
                    <Route path="config" lazy={() => import('@features/workbench.journey-builder.processes.$processId.templates.config')} />
                    <Route path="review" lazy={() => import('@features/workbench.journey-builder.processes.$processId.templates.review')} />
                  </Route>
                  <Route path="assignments" lazy={() => import('@features/workbench.journey-builder.processes.$processId.assignments')} >
                    <Route path="students" lazy={() => import('@features/workbench.journey-builder.processes.$processId.assignments.students')} />
                    <Route path="expeditions" lazy={() => import('@features/workbench.journey-builder.processes.$processId.assignments.expeditions')} />
                  </Route>
                  <Route path="schedule">
                    <Route path="config" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.config')} />
                    <Route path="review" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review')} >
                      <Route path="students" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review.students')} />
                      <Route path="experts" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review.experts')} />
                      <Route path="expeditions" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review.expeditions')} />
                      <Route path='overview' lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.review.overview')} />
                    </Route>
                    <Route path="generated" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated')}>
                      <Route path="students" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated.students')} />
                      <Route path="experts" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated.experts')} />
                      <Route path="expeditions" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated.expeditions')} />
                      <Route path="missing" lazy={() => import('@features/workbench.journey-builder.processes.$processId.schedule.generated.missing')} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path='pathways' element={<WorkbenchPathwaysRoute.Element />}>
              <Route index element={<WorkbenchPathwaysIndexRoute.Element />} />
              <Route path='management'>
                <Route index element={<WorkbenchPathwaysManagementIndexRoute.Element />} loader={WorkbenchPathwaysManagementIndexRoute.loader} action={WorkbenchPathwaysManagementIndexRoute.action} />
                <Route path=':pathwayId' element={<WorkbenchPathwaysManagementPathwayIdRoute.Element />} loader={WorkbenchPathwaysManagementPathwayIdRoute.loader} action={WorkbenchPathwaysManagementPathwayIdRoute.action} />
              </Route>
              <Route path='students' element={<WorkbenchPathwaysStudentsRoute.Element />} loader={WorkbenchPathwaysStudentsRoute.loader} action={WorkbenchPathwaysStudentsRoute.action} />
            </Route>
            <Route path="employees" element={<WorkbenchEmployeesRoute />} >
              <Route index element={<WorkbenchEmployeeTable />} />
              <Route path=":employee_id" element={<WorkbenchEmployeeProfile />} />
              <Route path="new" element={<WorkbenchEmployeeNew />} />
            </Route>
            <Route path="onboarding" element={<WorkbenchOnboardingRoute.Element />} action={WorkbenchOnboardingRoute.action}>
              <Route path="families" element={<WorkbenchOnboardingFamiliesReportRoute.Element />} loader={WorkbenchOnboardingFamiliesReportRoute.loader}>
                <Route path=":campus_filter" element={<WorkbenchOnboardingFamiliesReportFilterRoute.Element />} loader={WorkbenchOnboardingFamiliesReportFilterRoute.loader} action={WorkbenchOnboardingFamiliesReportFilterRoute.action}>
                  <Route path="add-families-to-group" element={<WorkbenchOnboardingAddFamiliesToGroupRoute />} action={workbenchOnboardingAddFamiliesToGroupAction} loader={workbenchOnboardingAddFamiliesToGroupLoader} />
                </Route>
              </Route>
              <Route path="groups" element={<OnboardingGroupsRoute.Element />} loader={OnboardingGroupsRoute.loader}>
                <Route path=":campusId" element={<OnboardingGroupsCampusId.Element />} loader={OnboardingGroupsCampusId.loader} action={OnboardingGroupsCampusId.action}>
                  <Route path="new" element={<WorkbenchOnboardingGroupsNewRoute />} loader={workbenchOnboardingGroupsNewLoader} action={workbenchOnboardingGroupsNewAction} />
                  <Route path="edit/:onboarding_group_id" element={<WorkbenchOnboardingGroups$OnboardingGroupIdRoute />} loader={workbenchOnboardingGroups$OnboardingGroupIdLoader} action={workbenchOnboardingGroups$OnboardingGroupIdAction} />
                  <Route path="duplicated-group-modal/:onboarding_group_id" element={<WorkbenchOnboardingGroupDuplicatedGroupModal$onboardingIdRoute />} loader={workbenchOnboardingGroupDuplicatedGroupModal$onboardingIdLoader} />
                </Route>
              </Route>
            </Route>
            <Route path="onboarding/:onboarding_group_id/content" element={<WorkbenchOnboardingCampus$OnboardingGroupIdContentRoute />} loader={workbenchOnboardingCampus$OnboardingGroupIdContentLoader}>
              <Route path="checklist">
                <Route path="new" element={<WorkbenchOnboardingChecklistNewRoute />} loader={workbenchOnboardingChecklistNewRouteLoader} action={workbenchOnboardingChecklistNewRouteAction} />
                <Route path=":checklist_id" element={<OnboardingChecklistIdRoute.Element />} loader={OnboardingChecklistIdRoute.loader} action={OnboardingChecklistIdRoute.action}>
                  <Route path=":user_view_type" element={<OnboardingUserTypeRoute.Element />} loader={OnboardingUserTypeRoute.loader} action={OnboardingUserTypeRoute.action}>
                    <Route path="new" element={<WorkbenchOnboardingChecklistNewRoute />} loader={workbenchOnboardingChecklistNewRouteLoader} action={workbenchOnboardingChecklistNewRouteAction} />
                    <Route path="edit" element={<WorkbenchOnboardingChecklistEditRoute />} loader={workbenchOnboardingChecklistEditLoader} action={workbenchOnboardingChecklistEditAction} />
                    <Route path="todo/:todo_id" element={<Onboarding$TodoIdRoute.Element />} loader={Onboarding$TodoIdRoute.loader} action={Onboarding$TodoIdRoute.action} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="mailchimp" element={<WorkbenchMailchimpRoute />} />
            <Route path="external-students" lazy={() => import('@features/workbench.external-students')}>
              <Route path="import-students" lazy={() => import('@features/workbench.external-students.import-students')} />
            </Route>
            <Route path="external-students/:id" lazy={() => import('@features/workbench.external-students.$id')}>
              <Route path="overview" lazy={() => import('@features/workbench.external-students.$id.overview')} />
            </Route>
            <Route path="external-students/:id?/edit" lazy={() => import('@features/workbench.external-students.($id).edit')} />
            <Route path="admin-actions" element={<WorkbenchAdminRoute />} />
            <Route path="canny" element={<WorkbenchCannyRoute />}>
              <Route path="sync-users" element={<WorkbenchCannySyncUsersRoute />} action={workbenchCannySyncUsersAction} />
            </Route>
            <Route path="google" element={<WorkbenchGoogleRoute />} loader={workbenchGoogleLoader}>
              <Route path="office-hours" element={<WorkbenchGoogleOfficeHoursRoute />} loader={workbenchGoogleOfficeHoursLoader} action={workbenchGoogleOfficeHoursAction} />
              <Route path="sync-groups" element={<WorkbenchGoogleGroupsSyncRoute.Element />} loader={WorkbenchGoogleGroupsSyncRoute.loader} action={WorkbenchGoogleGroupsSyncRoute.action} />
              <Route path="force-holidays" element={<WorkbenchGoogleForceHolidaysRoute />} action={workbenchGoogleForceHolidaysAction} />
              <Route path="house-events" element={<WorkbenchGoogleHouseEventsRoute />} loader={workbenchGoogleHouseEventLoader} action={workbenchGoogleHouseEventAction} />
            </Route>
            <Route path="report/progress" element={<ReleaseProgressReport />} loader={releaseProgressReportLoader} />
            <Route path="feature-flags" element={<FeatureFlags />}>
              <Route path=":feature_flag_id/sora-labs-settings" element={<FeatureFlagSettingsLabsRoute.Element />} loader={FeatureFlagSettingsLabsRoute.loader} action={FeatureFlagSettingsLabsRoute.action} />
            </Route>
            <Route path="permissions" element={<Permissions />} />
            <Route path="math-placement" element={<WorkbenchMathPlacementRoute.Element />}>
              <Route index loader={WorkbenchMathPlacementIndexRoute.loader} />
              <Route path="timeline" element={<WorkbenchMathPlacementTimelineRoute.Element />} loader={WorkbenchMathPlacementTimelineRoute.loader} />
              <Route path="students" element={<WorkbenchMathPlacementStudentsRoute.Element />} loader={WorkbenchMathPlacementStudentsRoute.loader} action={WorkbenchMathPlacementStudentsRoute.action} />
            </Route>
            <Route path="enrolments" element={<EnrolmentsRoute />} loader={enrolmentsLoader} action={enrolmentsAction} />
            <Route path="notifications" element={<NotificationsWorkbenchRoute />} loader={notificationsWorkbenchLoader} action={notificationsWorkbenchAction} />
            <Route path="admissions" element={<Admissions />} loader={admissionsLoader} action={admissionsAction} />
            <Route path="config-admissions" element={<AdmissionsConfig />} >
              <Route path="copy" element={<AdmissionsCopyConfig />} loader={admissionsCopyConfigLoader} action={admissionsCopyConfigAction} />
              <Route path="start-dates" element={<AdmissionsStartDatesConfig />} loader={admissionsStartDatesConfigLoader} action={admissionsStartDatesConfigAction} />
            </Route>
          </Route>

        </Route>

        <Route path="/" element={<IndexNoAuthRoute />} loader={indexNoAuthLoader}>
          <Route path="signin" element={<SignInRoute />} loader={signInLoader} />
          <Route path="password/new" element={<NewPasswordRoute.Element />} loader={NewPasswordRoute.loader} action={NewPasswordRoute.action} />
        </Route>

        <Route path="*" element={<NotFoundRoute />} />
      </Route >
    )
  )
  return (
    <ErrorBoundary>
      <Toaster />
      <PageTitleProvider>
        <ConfirmModalProvider>
          <CustomSWRConfig>
            <TooltipBase.Provider>
              <RouterProvider router={router} />
            </TooltipBase.Provider>
          </CustomSWRConfig>
        </ConfirmModalProvider>
      </PageTitleProvider>
    </ErrorBoundary>
  )
}

function NotFoundRoute() {
  return <ErrorComponent type="PAGE_NOT_FOUND" />
}

const CustomSWRConfig = ({ children }) => {
  const fetcher = async (url) => {
    const res = await axios.get(url).catch((error) => error.response)
    return res.data
  }
  const onError = (err) => {
    captureException(err)
  }

  return (
    <SWRConfig value={{ fetcher, onError }}>
      {children}
    </SWRConfig>
  )
}

CustomSWRConfig.propTypes = {
  children: PropTypes.any,
}

export default Sentry.withProfiler(App)
