import React, { ComponentProps, ReactNode } from 'react'
import { Pill } from '@design-system'

export type PillGraduationProgressVariant = 'completed' | 'ahead-of-schedule' | 'on-track' | 'falling-behind'

interface PillAllowedProps extends Pick<ComponentProps<typeof Pill>, 'rounded' | 'variant' | 'color' | 'disabled'> { }

interface VariantProps extends PillAllowedProps {
  childrenFn: (props: Omit<PillGraduationProgressProps, 'variant'>) => ReactNode
}

const defaultProps: VariantProps = {
  childrenFn: ({ variant }: PillGraduationProgressProps) => <Pill.Value>{variant}</Pill.Value>,
}

const variants: Record<PillGraduationProgressVariant, VariantProps> = {
  completed: {
    childrenFn: () => (
      <>
        <Pill.Icon name="check-circle" />
        <Pill.Value>Completed</Pill.Value>
      </>
    ),
    color: 'green-20'
  },
  'ahead-of-schedule': {
    childrenFn: () => (
      <>
        <Pill.Icon name="star" />
        <Pill.Value>Ahead of schedule</Pill.Value>
      </>
    ),
    color: 'success-40'
  },
  'on-track': {
    childrenFn: () => (
      <>
        <Pill.Icon name="thumbs-up" />
        <Pill.Value>On track</Pill.Value>
      </>
    ),
    color: 'notice-25'
  },
  'falling-behind': {
    childrenFn: () => (
      <>
        <Pill.Icon name="alert-triangle" />
        <Pill.Value>Falling behind</Pill.Value>
      </>
    ),
    color: 'warning-20'
  },
}

interface PillGraduationProgressProps {
  variant: PillGraduationProgressVariant
}

/**
 * PillGraduationProgress display a pill with student progress
 */
export const PillGraduationProgress = (props: PillGraduationProgressProps) => {
  const { childrenFn, ...pillProps } = variants[props.variant] ?? defaultProps
  return (
    <Pill {...pillProps}>
      {childrenFn({ ...props })}
    </Pill>
  )
}
