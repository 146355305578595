import axios from 'axios'
import { toast } from 'sonner'

export default function useTasks() {
  

  const create = (task) => {
    return axios.post('/tasks', task)
      .then(() => toast.success('Task created!'))
      .catch((error) => {
        const errorMessage = error.response.data.message
        toast.error(errorMessage || 'Error on creating Task, please try again later.')
      })
  }

  const update = (task) => {
    return axios.put(`/tasks/${task.id}`, task)
      .then(() => toast.success('Task updated!'))
      .catch((error) => {
        const errorMessage = error.response.data.message
        toast.error(errorMessage || 'Error on updating Task, please try again later.')
      })
  }

  const remove = (taskId) => {
    return axios.delete(`/tasks/${taskId}`)
      .then(() => toast.success('Task deleted!'))
      .catch((error) => {
        const errorMessage = error.response.data.message
        toast.error(errorMessage || 'Error on deleting Task, please try again later.')
      })
  }

  return {
    update,
    create,
    remove,
  }
}
