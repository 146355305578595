import SoraLink from "@components/link"
import { Tab } from "@headlessui/react"
import React, { useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"

export interface TabData {
  path: string
  label: string
  element: React.ReactElement
}

export interface TabProps {
  tabInfo: TabData[]
  onTabChange: (selectedIndex: number) => void
  startIndex: number
}

function Tabs({ tabInfo, onTabChange, startIndex = 0 }: TabProps) {
  const location = useLocation()
  const [selectedIndex, setSelectedIndex] = useState<number>(startIndex)
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    setShowLoading(false)
  }, [location])

  return (
    <React.Fragment>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(selectedTabIndex: number) => {
          setShowLoading(true)
          setSelectedIndex(selectedTabIndex)
          onTabChange(selectedTabIndex)
        }}
      >
        <Tab.List>
          {tabInfo.map((item) => (
            <Tab key={item.path} aria-label={item.label} as={React.Fragment}>
              {({ selected }) => (
                <SoraLink
                  to={item.path}
                  role='tab-item'
                  data-testid='tabs-item'
                  className={`p-3 text-xl truncate focus:outline-none border-b-2 border-transparent hover:underline ${selected && 'font-bold border-accent'}`}
                >
                  {item.label}
                </SoraLink>
              )}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="overflow-auto h-full text-sm relative">
          {showLoading &&
            <div className='bg-white opacity-60 absolute w-full h-full z-100 flex flex-col justify-center items-center'>
              <div className='text-4xl font-bold'>Loading</div>
            </div>
          }
          {tabInfo.map((item) => (
            <Tab.Panel key={item.path} className="pt-8">
              <Outlet />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </React.Fragment>
  )
}

export default Tabs
