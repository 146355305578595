import React from 'react'

const StrategiesContent = () => {
  return (
    <div>
      <p>
        The secret to improving your GPA at Sora is going deep on the areas
        you're most interested in and demonstrating deep knowledge in these
        units and exemplary ability in every ability opportunity.
        Every level 4 (extending) unit and ability you achieve will be
        awarded an extra 1-point bonus on top of your GPA for that subject.
      </p>

      <p className="mt-4">
        Other strategies include filtering the units and abilities list by your
        lowest scores and seeking opportunities to improve your mastery in those
        units and abilities. You could do this by finding an activity in the
        library that allows you to further develop that unit or ability and
        prove a higher level of mastery, signing up for an expedition next cycle
        that gives you another opportunity to improve those units or abilities,
        or setting up your own plan with a faculty member via independent study.
      </p>

      <p className="mt-4">
        The trick for abilities is that you want to do more than 16
        opportunities throughout your time at Sora so you can start “knocking
        out” your earlier opportuntiies when you were still emerging. The more
        quality work you submit at “extending” levels, the higher your GPA.
        Abilities are worth 50% of your cumulative GPA so this is a high
        leverage way to boost this metric. Here's a
        <a href="https://docs.google.com/document/d/1qRMb8l7IHolP8YbNYRDL6yw60rvuWNW3xKmbEs7DMWE/edit">
          reminder
        </a>
        about how we're calculating your GPA.
      </p>

      <p className="mt-4">
        It's also important for us to note here that while we're happy to
        provide you with a GPA to go on to whatever you want to do after Sora,
        we see you all as much more than your GPA. You have many different
        intelligences, aptitudes, and interests that aren't represented in this
        number. We've done our best to represent this in the way we calculate
        GPA, but you are way more special than we can synthesize in one number.
      </p>
    </div>
  )
}

export default StrategiesContent
