import React from 'react'
import { Disclosure } from '@headlessui/react'

const TransferCreditInfo = () => {
  return (
    <div className="dark:text-gray-5">
      <h2 className="font-bold text-xl text-center mb-3">
        TRANSFER CREDIT/EXEMPTION EXPLAINER + CONTACT INFO
      </h2>
      <ul className="list-disc ml-10 mr-5">
        <li>
          Sora Schools provides incoming students with transfer credit for any
          successfully completed high school-level course offered by an
          accredited educational institution.
        </li>
        <li>
          Although most incoming middle school students and first-year high
          school students will not receive transfer credit for work done in
          middle school, some will have completed high school-level
          coursework. The most common high school-level course completed in
          middle school is Algebra I.
        </li>
      </ul>
      <Disclosure>
        {({ open }) => (
          <div className="my-3 w-full">
            {open && (
              <div className="pb-3">
                <Disclosure.Panel>
                  <ul className="list-disc ml-10 mr-5">
                    <li>
                      Transfer courses are not utilized in calculating a
                      student's grade point average (GPA) when a final Sora
                      transcript is produced. However, transfer courses will be
                      listed on a student's final Sora transcript, along with
                      the corresponding grade and institution.
                    </li>
                    <li>
                      Transfer courses which fulfill Sora's graduation
                      requirements will be honored, and will exempt a student
                      from being required to complete that topic at the same
                      level again while at Sora. However, if a Sora faculty
                      member identifies a gap in a student's knowledge which is
                      hindering them from advancing to a more challenging level
                      of inquiry, a student may be asked to refresh their
                      knowledge in any given area, including a subject they
                      received transfer credit for.
                    </li>
                    <li>Sora's graduation requirements are as follows:</li>
                    <ul className="list-disc ml-10 mr-5">
                      <li>
                        <table className=" ">
                          <tbody>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                English Language Arts - ELA (4)
                              </td>
                              <td className="border px-3 py-2 border-black">
                                4 credits must be Foundations and Extensions
                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                Math (4)
                              </td>
                              <td className="border px-3 py-2 border-black">
                                3 Credits must be Algebra 1, Geometry, Algebra 2
                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                Social Studies (3)
                              </td>
                              <td className="border px-3 py-2 border-black">
                                3 credits must be US History, World History A & B
                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                Science (4)
                              </td>
                              <td className="border px-3 py-2 border-black">
                                3 Credits must be Biology, Chemistry, Physics (all lab-based)
                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                World Language (2)
                              </td>
                              <td className="border px-3 py-2 border-black">
                                2 credits of the same world language
                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                PE* (0.5)
                              </td>
                              <td className="border px-3 py-2 border-black">
                                Will be completed async
                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                Health* (0.5)
                              </td>
                              <td className="border px-3 py-2 border-black">

                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                Electives (4)
                              </td>
                              <td className="border px-3 py-2 border-black">
                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                Capstone Project
                              </td>
                              <td className="border px-3 py-2 border-black">
                                Typically completed in year 4
                              </td>
                            </tr>
                            <tr>
                              <td className="border px-3 py-2 border-black">
                                Abilities
                              </td>
                              <td className="border px-3 py-2 border-black">
                                16 demonstrations of each of the 8 abilities:
                                <ol>
                                  <li>Analyzing historical events</li>
                                  <li>Conducting research</li>
                                  <li>Reading critically</li>
                                  <li>Utilizing Data</li>
                                  <li>Writing</li>
                                  <li>Leading scientific investigations</li>
                                  <li>Open minded discussion</li>
                                  <li>Problem solving</li>
                                </ol>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li className="italic">
                        For reference, one credit corresponds to one year of
                        coursework at a traditional school and 0.5 credits
                        corresponds to one semester of coursework at a
                        traditional school.
                      </li>
                      <li className="italic">
                        Students graduating 2023, 2024, 2025 will be exempt from Health and PE requirements.
                        Beginning with the graduating class of 2026, students will be required to complete Health and PE.
                      </li>
                    </ul>
                    <li>
                      If you have any questions about Sora's transfer credit
                      policy or believe that you or your student's transfer
                      credits have been recorded incorrectly, please reach out
                      to registrar@soraschools.com.
                    </li>
                  </ul>
                </Disclosure.Panel>
              </div>
            )}
            <Disclosure.Button className="underline w-full text-center  flex justify-center">
              {open ? 'see less info' : 'see more info'}
            </Disclosure.Button>
          </div>
        )}
      </Disclosure>
    </div>
  )
}

export default TransferCreditInfo
