import useSWR from 'swr'

const useHouses = () => {
  const { data, error } = useSWR('/houses')
  return {
    houses: data?.result || [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useHouses
