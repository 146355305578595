import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

export const inputPropTypes = {
  datalist: PropTypes.array,
  list: PropTypes.string,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClickReadOnly: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  readOnlyNode: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.any,
  disabled: PropTypes.bool,
}

const Input = forwardRef(
  (
    {
      datalist = [],
      list,
      readOnly,
      readOnlyNode,
      className,
      onClickReadOnly,
      hasError,
      type = 'text',
      disabled,
      ...rest
    },
    ref
  ) => {
    return readOnly ? (
      <div
        onClick={onClickReadOnly}
        className={`outline-none cursor-text w-full 
        border-b-2 border-dotted border-gray-40 hover:border-black cursor-text ${className}`}
        {...rest}
      >
        {readOnlyNode || rest.value}
      </div>
    ) : (
      <>
        <input
          list={list}
          type={type}
          ref={ref}
          disabled={disabled}
          aria-invalid={hasError ? 'true' : 'false'}
          className={`
        border-gray-10 rounded-lg py-1 mt-0.5 w-full
        ${hasError ? 'focus:border-danger-40 focus:ring-3 focus:ring-danger-40' : ''
            }
        ${disabled ? 'opacity-50' : ''}
        ${className} 
      `}
          {...rest}
        />
        <datalist id={list}>
          {datalist.map((data) => (
            <option key={data} value={data} />
          ))}
        </datalist>
      </>
    )
  }
)

Input.displayName = 'Input'

Input.defaultProps = {
  className: '',
  onClickReadOnly: () => null,
  readOnlyNode: <span>Click to edit...</span>,
}

Input.propTypes = inputPropTypes

export default Input
