import axios from "axios"
import useSWR from "swr"
import { toast } from 'sonner'
import type { NotificationTabs, Notification } from "@components/notifications/types"

const LIMIT = 7 as const

export default function useNotifications(userId: number, type: NotificationTabs) {

  const { data: notifications, error, mutate: mutateNotifications } =
    useSWR<Array<Notification>>(`/notifications?user_id=${userId}&type=${type}&limit=${LIMIT}`)

  const handleMarkAsRead = async (notificationId: number) => {
    try {
      await axios.put(`notifications/${notificationId}/read`)
      await mutateNotifications()
    } catch (error) {
      toast.error(error)
    }
  }

  const handleReadAllNotifications = async (userId: number) => {
    try {
      await axios.put(`/notifications/${userId}/read-all`)
      await mutateNotifications()
      toast.success('All Notifications read.')
    } catch (error) {
      toast.error(error)
    }
  }

  const handleDelete = async (notificationId: number) => {
    try {
      await axios.delete(`/notifications/${notificationId}`)
      await mutateNotifications()
    } catch (error) {
      toast.error(error)
    }
  }

  return {
    error,
    mutate: mutateNotifications,
    notifications,
    isLoading: !error && !notifications,
    handleDelete,
    handleMarkAsRead,
    handleReadAllNotifications,
  }
}
