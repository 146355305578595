import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ActionFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom'
import { Dialog, useFetcher, Typography, Button, Icon, Alert } from '@design-system'
import { TextField } from '@designsystem'
import Datepicker from '@components/forms/datepicker'
import { LoaderData } from './types'
import { D } from 'dist/assets/date-fns-72dbb7dd'

async function loader({ params }) {
  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/deadline-extension`
  )
  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/deadline-extension`,
    formData
  )

  return { status: res.status, ...res.data }
}

function Element() {
  const fetcher = useFetcher()
  const navigate = useNavigate()
  const loaderData = useLoaderData() as LoaderData

  const [formState, setFormState] = useState({
    dueDate: '',
    reason: '',
  })
  const handleInputChange = function (e) {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.formData.get('_action') === 'create_deadline_extension') {
      navigate('../')
    }
  }, [fetcher.data])

  return (
    <Dialog
      open={true}
      onClose={() => {
        navigate('../')
      }}
    >
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>
            Deadline extension
          </Dialog.Title>
          <Dialog.Close />
          <Dialog.Description>
            {'Extended deadline for '}
            <Typography className="inline" variant="body" weight="bold">
              {loaderData.title}
            </Typography>
          </Dialog.Description>
        </Dialog.Header>
        <fetcher.Form method="POST" id="createDeadlineExtensionForm">
          <Dialog.Body>
            <div className="flex flex-col gap-2">
              <Typography variant="body" weight="bold">
                Current due date
              </Typography>
              <div className="flex gap-2">
                <Icon name="calendar" />
                <Typography variant="body">{loaderData.currentDueDate}</Typography>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Typography variant="body" weight="bold">
                New due date
              </Typography>

              <div className="w-1/2">
                <Datepicker
                  // @ts-ignore - cant import type from JSX
                  name="dueDate"
                  required
                  className=" border-2 border-gray-30"
                  placeholder="Select a new due date"
                  min={new Date().toJSON().slice(0, 10)}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Typography variant="body" weight="bold">
                Reason
              </Typography>
              <TextField
                placeholder="Share a reason for extension"
                name="reason"
                required
                trim
                onChange={handleInputChange}
              />
            </div>

            <Alert>
              <Alert.Title>
                Reminder: students see tasks as due the day before the actual due date.
              </Alert.Title>
              <Alert.Description>
                Other students will not be affected by this change and are still going to see the original due date.
              </Alert.Description>
            </Alert>

          </Dialog.Body>
          <Dialog.Footer>
            <Button type="button" variant="outlined" onClick={() => navigate('../')}>
              Cancel
            </Button>
            <Button
              type="submit"
              name="_action"
              value="create_deadline_extension"
              form="createDeadlineExtensionForm"
              disabled={formState.dueDate === '' || formState.reason === ''}
            >
              Confirm extension
            </Button>
          </Dialog.Footer>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog>
  )
}
export const FacilitateExperienceTaskStudentDeadlineExtensionRoute = {
  Element,
  action,
  loader,
}
