import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import MetricButton from './metric-button'
import { Labels, Keys } from './constants'

const MetricsList = ({
  amountFlagged,
  amountMissed,
  amountOntime,
  percentAttendance,
  onClick,
  active,
  vertical,
}) => (
  <section className={`grid gap-2 ${vertical ? `grid-cols-2` : `grid-cols-4`}`}>
    <ReactTooltip delayShow={400} />
    <MetricButton label={Labels.missed} value={amountMissed} active={active === Keys.missed} onClick={onClick ? onClick(Keys.missed) : null} disabled={!onClick} positiveValue={`${amountFlagged}` === '0'}/>
    <MetricButton label={Labels.ontime} value={amountOntime} positiveValue disabled />
    <MetricButton label={Labels.attendance} value={percentAttendance} unitValue="%" positiveValue disabled />
  </section>
)

MetricsList.propTypes = {
  amountFlagged: PropTypes.number.isRequired,
  amountMissed: PropTypes.number.isRequired,
  percentAttendance: PropTypes.number.isRequired,
  amountOntime: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.string,
  vertical: PropTypes.bool
}

MetricsList.defaultProps = {
  vertical: false,
  onClick: null,
  active: null
}

export default MetricsList
