import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import Select from 'react-select'

const weekDaysOpts = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
].map(d => ({ label: d, value: d.toLowerCase() }))

const ExcuseRecurringPeriodSelector = ({ control, watch, register }) => {
  const showTimePicker = !watch('recurring_allDay')
  const isEditing = !!watch('recurring_id')
  return (
    <>
      <div className="flex -ml-1.5">
        <div className="flex flex-col mr-5">
          <FontAwesomeIcon icon={faCalendar} />
          <span className="text-tiny mt-1">(EST)</span>
        </div>
        <div className="flex flex-col space-y-3 -ml-1">
          <div className="flex flex-col">
            <span className="text-sm font-bold">Recurring Period</span>
            <div className="flex space-x-2">
              <div className="flex flex-col">
                <span className="text-xs">From</span>
                <Controller
                  control={control}
                  name="recurring_period_start"
                  defaultValue={new Date(Date.now())}
                  render={({ onChange, value }) => (
                    <DatePicker
                      className="rounded max-w-32 mr-5 text-sm disabled:bg-gray-10"
                      dateFormat="MMM d, yyyy"
                      onChange={onChange}
                      onChangeRaw={(e) => { e.preventDefault() }}
                      selected={value}
                      disabled={isEditing}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <span className="text-xs">To</span>
                <Controller
                  control={control}
                  name="recurring_period_end"
                  defaultValue={new Date(Date.now())}
                  render={({ onChange, value }) => (
                    <DatePicker
                      className="rounded max-w-32 mr-5 text-sm disabled:bg-gray-10"
                      dateFormat="MMM d, yyyy"
                      onChange={onChange}
                      onChangeRaw={(e) => { e.preventDefault() }}
                      selected={value}
                      disabled={isEditing}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          {
            showTimePicker
              ? <div className="flex flex-col">
                <span className="text-sm font-bold">Excuse Period</span>
                <div className="flex space-x-2">
                  <div className="flex flex-col">
                    <span className="text-xs">From (EST)</span>
                    <Controller
                      control={control}
                      name="recurring_excuse_start_at"
                      defaultValue={new Date(Date.now())}
                      render={({ onChange, value }) => (
                        <DatePicker
                          className="rounded max-w-32 mr-5 text-sm"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aaa"
                          onChange={onChange}
                          onChangeRaw={(e) => { e.preventDefault() }}
                          selected={value}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs">To (EST)</span>
                    <Controller
                      control={control}
                      name="recurring_excuse_end_at"
                      defaultValue={new Date(Date.now())}
                      render={({ onChange, value }) => (
                        <DatePicker
                          className="rounded max-w-32 text-sm"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aaa"
                          onChange={onChange}
                          onChangeRaw={(e) => { e.preventDefault() }}
                          selected={value}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              : null
          }
          <div className="flex space-x-3">
            <div className="flex items-center space-x-2">
              <input name="recurring_allDay" ref={register} type="checkbox" className="rounded" />
              <span className="text-sm font-bold">All day</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="mr-5"><FontAwesomeIcon icon={faCalendar} /></div>
        <div className="flex flex-col w-full">
          <span className="text-sm font-bold">Recurring Days</span>
          <div className="flex flex-col">
            <Controller
              name="recurring_days"
              defaultValue={null}
              options={weekDaysOpts}
              isDisabled={isEditing}
              control={control}
              isSearchable={false}
              isMulti
              className="border-0 text-sm"
              as={Select}
              styles={{ multiValueRemove: (base) => ({ ...base, display: 'none' }) }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
ExcuseRecurringPeriodSelector.propTypes = {
  watch: PropTypes.func,
  register: PropTypes.func,
  control: PropTypes.object,
}

export default ExcuseRecurringPeriodSelector
