import React, { useState } from 'react'
import { Switch } from '@headlessui/react'
import { Form } from 'react-router-dom'
import { PortalModal } from '@components/modal'
import { Button, TextField } from '@designsystem'
import OnboardingDatePickerInput, { useDatePicker } from '@components/onboarding/date-picker-input'
import Tooltip from '@components/tooltip'


interface OnboardingChecklistModalProps {
  onClose: () => void,
  title: string,
  onboardingPhasesOptions: Array<{
    value: number,
    label: string,
  }>,
  checklist?: {
    id: number,
    title: string,
    visibleFor: 'both' | 'student' | 'guardian',
    studentDescription: string,
    guardianDescription: string,
    dueDate: string,
    onboardingPhaseId: number,
  }
}

export default function OnboardingChecklistModal({ onClose, title, onboardingPhasesOptions, checklist }: OnboardingChecklistModalProps) {
  const [descriptionFieldsVisible, setDescriptionFieldsVisible] = useState({
    student: checklist ? checklist.visibleFor === 'student' || checklist.visibleFor === 'both' : true,
    guardian: checklist ? checklist.visibleFor === 'guardian' || checklist.visibleFor === 'both' : true,
  })
  const [date, setDate] = useDatePicker(checklist?.dueDate)
  return (
    <PortalModal
      className="w-[755px]"
      isOpen={true}
      onClose={onClose}
    >
      <div className="flex flex-col space-y-10">
        <header className="flex flex-col items-center w-full py-4 border-b-2 border-b-gray-10">
          <h1 className="text-2xl font-bold pt-4">{title}</h1>
        </header>
        <div className="px-14 pb-16">
          <Form className="flex flex-col space-y-6" method="POST">
            <div className="flex flex-col space-y-2">
              {checklist?.id && <input type="hidden" name="id" defaultValue={checklist?.id} />}
              <input type="hidden" name="actionName" value="upsertChecklist" />
              <p>Phase</p>
              <div className="flex space-x-8">
                {onboardingPhasesOptions?.map((phase) => (
                  <span key={phase.value} className="flex items-center space-x-1">
                    <input type="radio" name="onboarding_phase_id" value={phase.value} defaultChecked={phase.value === checklist?.onboardingPhaseId} />
                    <label>
                      {phase.label}
                    </label>
                  </span>
                ))}
              </div>
            </div>
            <TextField label="Title" name="title" type="text" placeholder="Type a title" required fullWidth defaultValue={checklist?.title} />
            <OnboardingDatePickerInput
              date={date}
              setDate={setDate}
              required
              fullWidth
              name="due_date"
              label="Due date"
              defaultValue={checklist?.dueDate}
            />
            <div className="flex flex-col space-y-2">
              <p>Users</p>
              <div className="flex space-x-8">
                <UserSwitch
                  name="users[student]"
                  label="Students"
                  enabled={descriptionFieldsVisible.student}
                  onChange={() => setDescriptionFieldsVisible((prevState) => ({ ...prevState, student: !prevState.student }))}
                />
                <UserSwitch
                  name="users[guardian]"
                  label="Guardians"
                  enabled={descriptionFieldsVisible.guardian}
                  onChange={() => setDescriptionFieldsVisible((prevState) => ({ ...prevState, guardian: !prevState.guardian }))}
                />
              </div>
            </div>
            <div data-for="studentTooltip" data-tip="If you want to edit Student Description, you must set Checklist visibility as true for students as well!">
              <TextField label="Student's Description" name="student_description" placeholder="Type a description" fullWidth defaultValue={checklist?.studentDescription} as="textarea" disabled={!descriptionFieldsVisible.student} />
            </div>
            <Tooltip id="studentTooltip" place="top" disable={descriptionFieldsVisible.student} isFromPortal />
            <div data-for="guardianTooltip" data-tip="If you want to edit Guardian Description, you must set Checklist visibility as true for guardians as well!">
              <TextField label="Guardian's Description" name="guardian_description" placeholder="Type a description" fullWidth defaultValue={checklist?.guardianDescription} as="textarea" disabled={!descriptionFieldsVisible.guardian} />
            </div>
            <Tooltip id="guardianTooltip" place="top" disable={descriptionFieldsVisible.guardian} isFromPortal />
            <div className="flex space-x-4 self-end">
              <Button onClick={onClose} variant="outlined">Cancel</Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </div>
      </div>
    </PortalModal >
  )
}


interface UserSwitchProps {
  name: string,
  label: string,
  enabled: boolean,
  onChange: () => void
}

function UserSwitch({ name, label, enabled, onChange }: UserSwitchProps) {
  return (
    <span className="flex items-center space-x-2">
      <Switch
        checked={enabled}
        name={name}
        onChange={onChange}
        value={String(enabled)}
        className={`${enabled ? 'bg-black' : 'bg-gray-40'
          } relative inline-flex h-9 w-14 items-center rounded-full`}
      >
        <span className="sr-only">{label}</span>
        <span
          className={`${enabled ? 'translate-x-6' : 'translate-x-1 shadow-md'
            } flex justify-center items-center h-7 w-7 transform rounded-full bg-white transition`}
        >
          {enabled && <img src="/assets/icons/check-icon.svg" className="w-[15px] h-[15px] fill-black" />}
        </span>
      </Switch>
      <label>{label}</label>
    </span>
  )
}