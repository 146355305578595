import React from 'react'

// eslint-disable-next-line react/prop-types
const TextFilter = ({ column: { filterValue = '', setFilter } }) => {
  return (
    <div className="grid grid-cols-12 items-center rounded divide-x border min-h-[2.375rem] bg-white focus-within:border-2 focus-within:border-blue-40">
      <input
        className="col-span-9 h-full pl-2 text-sm rounded !outline-none !border-none !ring-0"
        value={filterValue}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search..."
      />
      <button className="col-span-3 outline-none text-gray-50" onClick={() => setFilter('')}>x</button>
    </div>
  )
}

export default TextFilter
