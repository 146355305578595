import React from 'react'
import { Outlet, useLocation } from 'react-router'
import { Tab } from '@headlessui/react'
import SoraLink from '@components/link'


const Element = () => {
  const { pathname } = useLocation()

  const tabs = [
    { label: 'Math Timeline Table', path: './timeline' },
    { label: 'Students Math Placement', path: './students' },
  ]

  const currentIndex = tabs.findIndex(t => pathname.includes(t.path.slice(1)))

  return (
    <div className='flex flex-col relative h-full'>
      <div className="flex flex-row">
        <Tab.Group selectedIndex={currentIndex >= 0 ? currentIndex : 0}>
          <Tab.List className='flex-auto space-x-4'>
            {tabs.map(t => (
              <Tab
                key={t.label}
                className={({ selected }) =>
                  `pt-8 pb-3 focus:outline-none whitespace-nowrap ${selected
                    ? 'font-bold border-b-2 border-b-black-300'
                    : 'truncate ...'
                  }`
                }
              >
                <SoraLink to={t.path}>
                  {t.label}
                </SoraLink>
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>
      <div className="flex-auto relative">
        <div className='text-sm px-2 overflow-y-auto absolute top-5 bottom-0 left-0 right-0'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export const WorkbenchMathPlacementRoute = {
  Element,
}
