import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { RenderedSlate } from '@components/forms/short-slate-textarea'
import useConfirmModal from '@hooks/useConfirmModal'
import FeedbackFlagIcon from './feedback-flag-icon'

const HistoryFeedbackItem = ({ item, removeble, onRemove, type, startExpanded = false }) => {
  const confirm = useConfirmModal()
  const [toggleComment, setToggleComment] = useState(startExpanded)

  const handleRemoveItem = async () => {
    if (await confirm('Are you sure you want to remove this feedback?')) {
      onRemove(type, item.comment_id)
    }
  }

  if (item.deleted) return <></>

  return (
    <li className='flex flex-col border-b border-b-gray-30 last:border-0 first:border-t first:border-t-gray-30' >
      <ReactTooltip delayShow={1000} />
      <div className='flex flex-row items-center text-xs py-3 gap-2 cursor-pointer hover:bg-gray-10 px-3 w-full' onClick={() => { setToggleComment(!toggleComment) }}>
        <div className='font-bold truncate' data-tip={item.title}>{item.title}</div>
        <div className='whitespace-nowrap'>{item.subtitle}</div>
        <div className='whitespace-nowrap'>{new Date(item.created_at).toLocaleDateString([], { year: 'numeric', month: 'numeric', day: 'numeric' })}</div>
        <FeedbackFlagIcon flag={item?.flag} size={4} />
      </div>
      {toggleComment &&
        <div className='flex flex-row'>
          {removeble &&
            <div className='pl-2 pt-2'>
              <span className='cursor-pointer rounded-full px-2 py-1 text-xs text-gray-40 hover:bg-gray-10' onClick={handleRemoveItem}>
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
          }
          <div className='py-2 px-5 flex-auto'>
            <RenderedSlate
              key={`feedback:read:${item.id}`}
              id={`assment:read:${item.id}`}
              value={item?.body}
              placeholder={<span className="text-xs">No comment :(</span>}
              className="text-xs"
            />
          </div>
        </div>
      }
    </li>
  )
}
HistoryFeedbackItem.propTypes = {
  item: PropTypes.object,
  removeble: PropTypes.bool,
  onRemove: PropTypes.func,
  type: PropTypes.string,
  startExpanded: PropTypes.bool,
}

export default HistoryFeedbackItem
