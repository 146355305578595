import React from 'react'
import PropTypes from 'prop-types'
import { TemplateSelector } from './template-selector'
import { UnitList } from './unit-list'

export const CyclePlanInput = ({ planId, index, position, templateId, templateTitle, templates = [], units = [] }) => {
  return (
    <div className='flex flex-col gap-2 border p-2 bg-white'>
      <input type="hidden" name={`plan[${index}].cycle_position`} value={position} />
      <span className='font-bold text-lg'>Cycle {position}</span>
      <label className='text-xs font-bold'>Template</label>
      <TemplateSelector planId={planId} position={position} templateId={templateId} templateTitle={templateTitle} templates={templates} />
      <label className='text-xs font-bold'>Units</label>
      <UnitList units={units} />
    </div>
  )
}
CyclePlanInput.propTypes = {
  planId: PropTypes.number,
  index: PropTypes.number,
  position: PropTypes.number,
  templateId: PropTypes.number,
  templateTitle: PropTypes.string,
  templates: PropTypes.array,
  units: PropTypes.array,
}
