export function getTimezoneAbbreviation(timeZone) {
  const date = new Date()
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'short',
  })

  const formattedString = formatter.format(date)
  const abbreviation = formattedString.split(' ').pop()

  return abbreviation
}

export function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const getBrowserTimezoneAbbreviation = () => {
  return getTimezoneAbbreviation(getBrowserTimezone())
}

export default getBrowserTimezoneAbbreviation
