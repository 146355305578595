import React, { useRef } from 'react'
import useGroups from './useGroups'
import { Disclosure } from "@headlessui/react"
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import useUsers from '@hooks/useUsers'
import Select from '@components/forms/select'
import debounce from 'lodash/debounce'
import getColors from '../colors'
import { Button, TextField } from '@designsystem'

const Groups = () => {
  const inputTitle = useRef(null)
  const selectType = useRef(null)
  const { users } = useUsers()
  const { groups: groupsData = [], create, addUserToGroup, removeUserFromGroup } = useGroups()
  const groups = groupsData.map((group, i) => ({ ...group, colors: getColors(i) }))

  const getUsersOptionsGroupedByType = users => {
    const types = ['employee', 'student']
    const usersByType = {}
    types.forEach(type => {
      usersByType[type] = users.filter(u => !u.permission_group_id && u.type === type)
        .map(u => ({ label: `${u.first_name} ${u.last_name} - ${u.email}`, value: u.id }))
    })
    return usersByType
  }

  const handleAdd = async () => {
    const title = inputTitle.current.value
    if (title.length <= 0) return

    const type = selectType.current.value
    if (!type) return

    await create(title, type)

    selectType.current.value = ''
    inputTitle.current.value = ''
  }

  const handleAddUser = async (group_id, user_id) => {
    await addUserToGroup(group_id, user_id)
  }

  const handleRemoveUser = (id, userId) => async () => {
    if (!id || !userId) return
    await removeUserFromGroup(id, userId)
  }

  const usersOptionsByType = getUsersOptionsGroupedByType(users)

  const permissionGroups = groups.map((group) => {
    const options = usersOptionsByType[group.type].filter(opt => !group.users.some(u => u.id === opt.value))
    const users = group.users?.map((user) => (
      <li key={`group_email_${group.id}_${user.id}`} className='p-1'>
        <Button onClick={handleRemoveUser(group.id, user.id)} startIcon={faTrash} variant="nude" size="xs" tooltip="Remove user from this group" /> {user.name} - {user.email}
      </li>
    ))
    return (
      <li key={`group_${group.id}`} className={`flex flex-col my-2 border rounded ${group.colors.border}`}>
        <Disclosure>
          <Disclosure.Button className={`rounded font-bold px-2 py-2 flex flex-row  items-center uppercase bg-white justify-between ${group.colors.text}`}>
            <div>{group.title}</div>
            <div className="lowercase text-sm">{group.type}</div>
          </Disclosure.Button>
          <Disclosure.Panel className="bg-white p-2">
            <ul className='h-[25rem] overflow-y-auto'>
              <li className='flex mb-2'>
                <Select
                  searchFunction={debounce((query, callback) => {
                    const searchedOptions = options
                      .filter((option) => option.label.toUpperCase().includes(query.toUpperCase()))
                      .sort((a, b) => String(a.label).trim().localeCompare(String(b.label).trim()))
                    callback(searchedOptions)
                  }, 500)}
                  isMulti={false}
                  isSearchable={true}
                  placeholder="Select users to add"
                  value={[]}
                  options={options}
                  onChange={(e) => {
                    handleAddUser(group.id, e.value)
                  }}
                  className="text-gray-50 w-full"
                />
              </li>
              {users}
            </ul>
          </Disclosure.Panel>
        </Disclosure>
      </li>
    )
  })

  return (
    <div className="px-2">
      <h1 className='font-bold text-2xl'>Groups</h1>
      <div>
        <fieldset className="space-x-1 flex">
          <div className='min-w-xs'>
            <TextField ref={inputTitle} name="permission-group-title" id="permission-group-title" placeholder='Type a title' />
          </div>
          <TextField as="select" ref={selectType} name="permission-group-type" id="permission-group-type">
            <option value="" className="text-gray-50">Select the group type</option>
            <option value="employee">Employee</option>
            <option value="student">Student</option>
          </TextField>
          <Button onClick={handleAdd}>Add</Button>
        </fieldset>
        <ul className="mt-8">
          {permissionGroups}
        </ul>
      </div>
    </div>
  )
}

export default Groups
