import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

const Checkbox = forwardRef(
  (
    { readOnly, value, className, onClick, hasError, isDisabled, ...rest },
    ref
  ) => {
    return (
      <input
        ref={ref}
        checked={value}
        onClick={readOnly ? (e) => onClick(e) : () => null}
        type="checkbox"
        aria-invalid={hasError ? 'true' : 'false'}
        className={`
          ${hasError ? 'focus:border-danger-40 focus:ring-3 focus:ring-danger-40' : ''}
          ${isDisabled ? 'opacity-25' : ''}
          ${className} 
        `}
        readOnly={readOnly}
        disabled={isDisabled}
        {...rest}
      />
    )
  }
)

Checkbox.displayName = 'Checkbox'

Checkbox.defaultProps = {
  className: '',
  onClick: () => null,
}

Checkbox.propTypes = {
  readOnly: PropTypes.bool,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
}

export default Checkbox
