import { useEffect, useState } from 'react'

export default function useFocusFormField(defaultFieldName) {
  const [value, setValue] = useState({ focusedField: defaultFieldName, fnAfterFocus: null })
  const setValueObject = value => 
    setValue({ focusedField: value.focusedField || defaultFieldName, fnAfterFocus: value.fnAfterFocus })

  useEffect(() => {
    const domEl = document.getElementById(value.focusedField)
    if (value.focusedField && domEl)  {
      domEl.focus()
      try {
        domEl.setSelectionRange(domEl.value.length, domEl.value.length)
      } catch (error) {
        console.debug('Element doesnt support selection range.')
      }
    }
    if (value.fnAfterFocus) setTimeout(() => value.fnAfterFocus())
  }, [value])

  return setValueObject
}
