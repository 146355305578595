import { PillUnitStudentProgram } from '@blocks/pill-unit-student-program'
import { Button, DropdownMenu, Icon, Pill, Typography } from '@design-system'
import React, { useEffect } from 'react'

interface FinalTaskPerkCardProps {
  title: string
  currentMastery: string
  studentReflection: string
  name: string
  lastAssessmentValue?: string
  isOffProgram?: boolean
  isExempt?: boolean
  masteryLevels: Record<string, string>
}

export function FinalTaskPerkCard({
  title,
  currentMastery,
  studentReflection,
  name,
  lastAssessmentValue = '-',
  isOffProgram = false,
  isExempt = false,
  masteryLevels
}: FinalTaskPerkCardProps) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <Typography variant="callout" weight="bold">
          {title}
        </Typography>
        <div className="flex gap-2 items-center flex-wrap">
          <Typography variant="callout">Current mastery: {currentMastery}</Typography>
          {isExempt && <PillUnitStudentProgram variant="exempt" />}
          {isOffProgram && <PillUnitStudentProgram variant="off-program" />}
        </div>
      </div>
      {/* GRADING */}
      <div className="bg-gray-5 p-4">
        <div className="flex justify-between border-b pb-3 items-center">
          <Typography variant="callout" weight="bold">
            Student Reflection
          </Typography>

          <Pill rounded>
            <Pill.Value>{masteryLevels[`${studentReflection}`] ?? ` - `}</Pill.Value>
          </Pill>
        </div>

        <div className="flex justify-between pt-3 items-center">
          <Typography variant="callout" weight="bold">
            Assessment
          </Typography>
          <FinalAssessmentDropdown name={name} initialValue={lastAssessmentValue} masteryLevels={masteryLevels} />
        </div>
      </div>
    </div>
  )
}

type FinalAssessmentDropdownProps = Pick<FinalTaskPerkCardProps, 'name' | 'masteryLevels'> & {
  initialValue?: string
}

function FinalAssessmentDropdown({ initialValue = '0.0', name, masteryLevels }: FinalAssessmentDropdownProps) {
  const [status, setStatus] = React.useState(initialValue)

  useEffect(() => {
    setStatus(initialValue)
  }, [initialValue])

  return (
    <>
      <input type="hidden" name={name} value={status} />
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button variant="outlined" size="xs" color="soft" className="w-44 justify-between">
            <p className="truncate">{masteryLevels[status]}</p> <Icon name="chevron-down" size="xs" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content className="bg-white">
          <DropdownMenu.RadioGroup
            value={status}
            onValueChange={(value) => {
              setStatus(value)
            }}
          >
            {Object.entries(masteryLevels).map(([key, value]) => (
              <DropdownMenu.RadioItem key={key} value={key}>
                {value}
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu>
    </>
  )
}
