import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@headlessui/react'

const FlagItemMenuItem = ({ onClick, icon, label, className }) => (
  <Menu.Item>
    {({ active }) => (
      <div onClick={onClick} className={`flex flex-row gap-1 p-1 uppercase whitespace-nowrap ${className} ${active && 'bg-gray-30'}`}>
        <span className='w-8 text-center'>
          {icon}
        </span>
        <span>{label}</span>
      </div>
    )}
  </Menu.Item>
)

FlagItemMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

export default FlagItemMenuItem
