import React from 'react'
import { Icon, IconProps, unstable_Tooltip as Tooltip } from '@design-system'

interface TrendProps {
  label: string
  value?: number
}

type TrendType = 'positive' | 'negative'

interface TrendTypeProps {
  content: string
  name: IconProps['name']
  className: string
}

const trendConfig: Record<TrendType, TrendTypeProps> = {
  positive: {
    content: "is trending up",
    name: "trend-up",
    className: "text-green-40 block",
  },
  negative: {
    content: "is trending down",
    name: "trend-down",
    className: "text-danger-50 block",
  },
}

export const Trend = ({ label, value }: TrendProps) => {
  const trendType = value > 0 ? 'positive' : 'negative';
  const { content, ...props } = trendConfig[trendType]

  return (
    <>
      {value ? (
        <Tooltip content={`${label} ${content}`}>
          <span>
            <Icon size="xs" {...props} />
          </span>
        </Tooltip>
      ) : <></>}
    </>
  )
}
