import React, { useState } from 'react'

import { Form, useSubmit, useActionData, useLoaderData } from 'react-router-dom'
import { Button, Icon } from '@design-system'
import useConfirmModal from '@hooks/useConfirmModal'
import PropTypes from 'prop-types'
import { useToast } from '@hooks/useToast'
import DatePicker from '@components/forms/datepicker'
import axios from 'axios'


export default function AdmissionsStartDatesConfig() {
  const startDates = useLoaderData()
  const actionData = useActionData()
  useToast(actionData)
  const submit = useSubmit()
  const [selectedStartDate, setSelectedStartDate] = useState(null)

  return (
    <div className='gap-9 p-3'>
      <p className='mb-4'>
        The list is ordered by the start date.
      </p>
      <ul className='grid grid-cols-1 gap-2 w-fit'>
        {startDates.map((startDate) => (
          <li key={startDate.id} className='border p-4 rounded-lg flex items-center justify-between'>
            <div>
              <h2 className='font-bold text-lg'>{startDate.displayDate}</h2>
              <p>{startDate.date}</p>
            </div>
            <DeleteWithConfirmation startDate={startDate} />
          </li>
        ))}
      </ul>
      <Form
        method='post'
        className='mt-8 flex w-fit'
        onSubmit={async (e) => {
          e.preventDefault()
          const form = e.currentTarget
          submit(form)
          setSelectedStartDate(null)
        }}>
        <input type='hidden' name='formType' value='add-start-date' />
        <DatePicker
          name='start_date'
          onChange={setSelectedStartDate}
        />
        <Button
          className='ml-4'
          type='submit'
          disabled={!selectedStartDate}
        >Add start date</Button>
      </Form>
    </div>
  )
}

const DeleteWithConfirmation = ({ startDate }) => {
  const confirm = useConfirmModal()
  const submit = useSubmit()

  return <Form
    method='post'
    onSubmit={async (e) => {
      e.preventDefault()
      const form = e.currentTarget
      if (await confirm(`Are you sure you want to delete "${startDate.displayDate}" start date?`)) {
        submit(form)
      }
    }}>
    <input type='hidden' name='formType' value='delete-start-date' />
    <input type='hidden' name='id' value={startDate.id} />
    <Button
      variant="outlined"
      color="danger"
      type='submit'
    >
      <Icon name='trash' />
    </Button>
  </Form>
}

DeleteWithConfirmation.propTypes = {
  startDate: PropTypes.object.isRequired,
}

export const loader = async ({ request }) => {
  const { data } = await axios.get('/admissions-start-dates-config')
  return data
}

export const action = async ({ request }) => {
  const formData = await request.formData()
  const formType = formData.get('formType')

  if (formType === 'delete-start-date') {
    try {
      await axios.delete('/admissions-start-dates-config', {
        data: { startDateId: formData.get('id') }
      })

      return {
        toast: { message: 'Start date deleted', appearance: 'success' },
      }
    } catch (_) {
      return {
        toast: { message: 'Failed to delete start date', appearance: 'error' },
      }
    }
  }

  if (formType === 'add-start-date') {
    try {
      await axios.post('/admissions-start-dates-config', {
        startDate: formData.get('start_date')
      })

      return {
        toast: { message: 'Start date added', appearance: 'success' },
      }
    } catch (_) {
      return {
        toast: { message: 'Failed to add start date', appearance: 'error' },
      }
    }
  }

  return {}
}
