import TimeAgo from 'javascript-time-ago'

const TimeAgoHelper = () => {
  const timeAgo = new TimeAgo('en-US')

  return {
    format: (time) => {
      return timeAgo.format(time, 'twitter-now')
    }
  }
}

export default TimeAgoHelper
