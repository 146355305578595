import React, { useEffect } from 'react'
import axios from 'axios'
import { Tab } from '@headlessui/react'
import { Link, Outlet, useNavigate, useLoaderData, useLocation } from 'react-router-dom'

type LoaderData = {
  hasAccessToCalendarAutomation: boolean,
  officeHoursData: {
    employees: {
      id: number,
      name: string,
      conferenceUrl: string,
    }[],
    cohorts: {
      id: number,
      title: string,
    }[],
  }
}

export async function loader() {
  const res = await axios.get('/pages/admin/workbench/google')
  return res.data
}

export default function WorkbenchGoogleRoute() {
  const location = useLocation()
  const navigate = useNavigate()
  const loaderData = useLoaderData() as LoaderData
  const hasAccessToCalendarAutomation = loaderData.hasAccessToCalendarAutomation

  useEffect(() => {
    if (location.pathname.endsWith('google')) {
      return navigate('./sync-groups')
    }
  }, [])

  return (
    <Tab.Group>
      <Tab.List className="flex space-x-10 border-b border-b-gray-30 w-full">
        <Tab
          as={Link}
          to="./sync-groups"
          className={`py-2 ${location.pathname.includes('sync-groups') ? 'border-b-2 font-bold border-b-accent ' : ''}`}
        >
          Sync Groups
        </Tab>
        {hasAccessToCalendarAutomation && (
          <>
            <Tab
              as={Link}
              to="./force-holidays"
              className={`py-2 ${location.pathname.includes('force-holidays') ? 'border-b-2 font-bold border-b-accent ' : ''}`}
            >
              Holidays
            </Tab>
            <Tab
              as={Link}
              to="./house-events"
              className={`py-2 ${location.pathname.includes('house-events') ? 'border-b-2 font-bold border-b-accent ' : ''}`}
            >
              House Events
            </Tab>
            <Tab
              as={Link}
              to="./office-hours"
              className={`py-2 ${location.pathname.includes('office-hours') ? 'border-b-2 font-bold border-b-accent ' : ''}`}
            >
              Office Hours
            </Tab>
          </>
        )}
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel className="mt-4">
          <Outlet />
        </Tab.Panel>
        {hasAccessToCalendarAutomation && (
          <>
            <Tab.Panel className="mt-4">
              <Outlet />
            </Tab.Panel>
            <Tab.Panel className="mt-4">
              <Outlet />
            </Tab.Panel>
            <Tab.Panel className="mt-4">
              <Outlet />
            </Tab.Panel>
          </>
        )}
      </Tab.Panels>
    </Tab.Group>
  )
}
