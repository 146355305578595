import * as React from 'react'
import type { Session } from './types'

export type FormContextData = {
  type: string,
  units: string,
  abilities: string,
  sessions: Session[],
  employees: string,
  cycle_themes: string,
  interest_tags: string,
  school_stage: string,
}

const FormContext = React.createContext<[
  FormContextData,
  React.Dispatch<React.SetStateAction<FormContextData>>
]>(null)

export function FormProvider({ children }: { children: React.ReactNode }) {
  const [formData, setFormData] = React.useState<FormContextData>()
  return <FormContext.Provider value={[formData, setFormData]}>{children}</FormContext.Provider>
}

export function useFormContext() {
  const context = React.useContext(FormContext)
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider')
  }
  return context
}