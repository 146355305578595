import * as React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { RegistrationChanges } from '.'
import { HouseCell } from './house-cell'
import { CellStudentName } from './student-cell'
import { ExperienceCell } from './experience-cell'

export const columns: ColumnDef<RegistrationChanges>[] = [
  {
    accessorKey: 'student.id',
    header: 'Id',
  },
  {
    accessorKey: 'student.name',
    enableSorting: true,
    sortDescFirst: true,
    header: 'Student',
    cell: ({ row }) => <CellStudentName {...row.original.student} />,
  },
  {
    accessorKey: 'house.title',
    enableSorting: true,
    header: 'House',
    cell: ({ row }) => <HouseCell title={row.original.house.title} schoolStage={row.original.student.schoolStage} />,
  },
  {
    accessorKey: 'experience.type',
    header: 'Type',
  },
  {
    accessorKey: 'experience.title',
    enableSorting: true,
    header: 'Experience',
    cell: ({ row }) => <ExperienceCell {...row.original.experience} />,
  },
  {
    accessorKey: 'learningBlock',
    header: 'Learning Block',
  },
  {
    accessorKey: 'experience.cycle',
    header: 'Cycle',
  },
  {
    accessorKey: 'action.label',
    enableSorting: true,
    header: 'Category',
  },
  {
    accessorKey: 'source',
    header: 'Source',
  },
  {
    accessorKey: 'userName',
    enableSorting: true,
    header: 'User',
  },
  {
    accessorKey: 'notes',
    header: 'Notes',
  },
  {
    accessorKey: 'timestamp',
    header: 'Timestamp',
    enableSorting: true,
    sortDescFirst: true,
  }
]