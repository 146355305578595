import { Typography } from '@design-system'
import React from 'react'
import EmptyPerksImage from './empty-perks.svg'

export function EmptyPerksSection(): React.ReactNode {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <img
        className="object-scale-down mb-8"
        src={EmptyPerksImage}
        alt="Empty perks"
      />
      <Typography variant="body" weight="bold">
        No feedback yet
      </Typography>
      <Typography variant="body" className="text-center">
        There aren't units or abilities linked to this task yet. Please select one to provide feedback.
      </Typography>
    </div>
  )
}
