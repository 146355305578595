export enum BucketType {
  checkin = 'Check in',
  completed = 'Complete'
}

export interface HouseReportLoaderData {
  checkin: ReportItem[]
  celebrate: ReportItem[]
}

export interface ReportItem {
  id: number
  title: string
  recommendation: string
  dismissedAt: boolean
  doneAt: boolean
  studentId: number
  studentName: string
  studentProfileImageUrl?: string
  missedTasksCount?: number
  missedFinalTasksCount?: number
  unexcusedAbsencesCount?: number
  currentAttendance?: number
  previousAttendance?: number
  currentTaskSubmission?: number
  previousTaskSubmission?: number
}
