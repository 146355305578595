import React from 'react'
import axios from 'axios'
import { LoaderFunctionArgs, Outlet, useLoaderData } from 'react-router-dom'
import { Tabs } from '@design-system'
import { Header } from './header'

interface HouseLoaderProps {
  house: {
    id: number
    title: string
  }
  canManageHouses: boolean
  isHouseAdvisor: boolean
}

async function loader({ params }: LoaderFunctionArgs) {
  const { data } = await axios.get(`/backoffice/houses/${params.houseId}/_index`)
  return data
}

function Element() {
  const loaderData = useLoaderData() as HouseLoaderProps
  const { house: { id, title }, canManageHouses, isHouseAdvisor } = loaderData
  return (
    <div className="flex flex-col gap-4">
      <Header id={id} title={title} canManageHouses={canManageHouses} isHouseAdvisor={isHouseAdvisor} />
      <Tabs>
        <Tabs.Item title="Overview" to="overview" />
        <Tabs.Item title="Students" to="students" />
        <Tabs.Item title="Attendance" to="attendances" />
      </Tabs>
      <Outlet />
    </div>
  )
}

export const HouseRoute = {
  loader,
  Element,
}


