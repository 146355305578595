import React from 'react'
import PropTypes from 'prop-types'
import { SoraNavLink } from '@components/link'

const SidebarOpt = ({ url, label }) => (
  <li>
    <SoraNavLink to={url} className={(active) => `hover:underline ${active ? 'font-bold underline' : ''}`}>
      {label}
    </SoraNavLink>
  </li>
)

SidebarOpt.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
}

export default SidebarOpt
