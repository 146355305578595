import PropTypes from 'prop-types'
import React, { useContext, createContext, useState, useEffect } from 'react'

const DEFAULT_TITLE = 'Sora Home App | Sora Schools | Sora Login'

export const PageTitleContext = createContext({
  title: null,
  options: {},
  setState: () => {}
})

export const PageTitleProvider = ({ children }) => {
  const [{ title, options }, setState] = useState(DEFAULT_TITLE)

  useEffect(() => {
    document.title = title || DEFAULT_TITLE
  }, [title])

  return <PageTitleContext.Provider value={{ title, options, setState }}>
    { children }
  </PageTitleContext.Provider>
}

PageTitleProvider.propTypes = {
  children: PropTypes.any
}

const usePageContext = () => {
  const { title, options } = useContext(PageTitleContext)
  return { title, options }
}

export const useThisPageTitle = (title, options = {}) => {
  const { setState } = useContext(PageTitleContext)
  useEffect(() => {
    setState({ title, options })
    document.title = title
    return () => {
      document.title = DEFAULT_TITLE
      setState({ title: DEFAULT_TITLE, options })
    }
    // TODO: We should remove & fix this in the future
    // eslint-disable-next-line
  }, [title])
}
  
export default usePageContext
