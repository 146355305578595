export const CREDIT_OPTIONS_MAP = {
  1: 0.5,
  2: 0.75,
  3: 1,
  4: 1.5,
  5: 2.0,
}

export const REVERSE_CREDIT_OPTIONS_MAP = {
  0.5: 1,
  0.75: 2,
  1: 3,
  1.5: 4,
  2.0: 5,
}

export const NUMBER_OF_UNITS_PER_CREDIT = {
  1: 3,
  2: 3,
  3: 6,
  4: 8,
  5: 10
}

export const getCreditOptions = (topic) => {
  if (!topic) {
    return { 3: CREDIT_OPTIONS_MAP[3], 1: CREDIT_OPTIONS_MAP[1] }
  }

  if (topic.max_topic_credit === 0.5)
    return { 1: CREDIT_OPTIONS_MAP[1] }

  const creditOptions = {}
  if (topic.has_partial_exemption_50) {
    creditOptions[1] = CREDIT_OPTIONS_MAP[1]
  }
  if (topic.max_topic_credit || topic.has_partial_exemption_100) {
    creditOptions[3] = CREDIT_OPTIONS_MAP[3]
  }
  if (topic.max_topic_credit === 1.5 || topic.has_partial_exemption_150) {
    creditOptions[4] = CREDIT_OPTIONS_MAP[4]
  }
  if (topic.max_topic_credit === 2.0) {
    creditOptions[5] = CREDIT_OPTIONS_MAP[5]
  }

  return creditOptions
}

export const GRADE_OPTIONS = [
  'PASS',
  'FAIL',
  'A+',
  'A',
  'A-',
  'B+',
  'B',
  'B-',
  'C+',
  'C',
  'C-',
  'D+',
  'D',
  'D-',
  'F',
]

export const YEAR_OPTIONS = [5, 6, 7, 8, 9, 10, 11, 12]

export const getExemptionText = (exemptionStatus) => {
  return CREDIT_OPTIONS_MAP?.[exemptionStatus]
    ? CREDIT_OPTIONS_MAP?.[exemptionStatus] + ' Credit'
    : 'NONE Exempted'
}

export const isObjectEmpty = (object = {}) => Object.values(object).length === 0

export const findExemptedUnits = (units, credit = 3, topic) => {
  if (!topic) return units

  const topicMaxCredit = REVERSE_CREDIT_OPTIONS_MAP[topic.max_topic_credit]
  const nCredit = Number(credit)
  const isFullCredit = topicMaxCredit === nCredit

  let unitsToExempt = null

  if (isFullCredit)
    unitsToExempt = units
  else if (nCredit === 1)
    unitsToExempt = units.filter(u => u.is_partial_exemption_50)
  else if (nCredit === 2)
    unitsToExempt = units.filter(u => u.is_partial_exemption_75)
  else if (nCredit === 3)
    unitsToExempt = units.filter(u => u.is_partial_exemption_100)
  else if (nCredit === 4)
    unitsToExempt = units.filter(u => u.is_partial_exemption_150)

  return unitsToExempt?.map(u => u.title) || []
}

export const gradeNumberForELA = (topicName) => {
  if (/foundations/i.test(topicName)) return ' (9)'
  if (/analysis/i.test(topicName)) return ' (10)'
  if (/application/i.test(topicName)) return ' (11)'
  if (/extension/i.test(topicName)) return ' (12)'
  return ''
}

export const getSubjectSectionTooltipText = (subject) => {
  return (
    {
      'world languages': [
        'Sora Schools provides transfer credit for any successfully completed high school-level World Language course taken at an accredited educational institution.',
        'Receiving transfer credit for a World Language I or II level course will exempt a student from repeating the same material and will provide credit towards graduation requirements.',
      ],
      electives: [
        'Sora Schools provides transfer credit for any successfully completed high school-level Elective course taken at an accredited educational institution.',
        'Receiving transfer credit for an elective course will exempt a student from repeating the same material and will provide credit towards graduation requirements.',
      ],
      Arts: [
        'Sora Schools provides transfer credit for any successfully completed high school-level Arts course taken at an accredited educational institution.',
        'Receiving transfer credit for an arts course will exempt a student from repeating the same material and will provide credit towards graduation requirements.',
      ],
      'Business and Technology': [
        'Sora Schools provides transfer credit for any successfully completed high school-level Business and Technology course taken at an accredited educational institution.',
        'Receiving transfer credit for an business and technology course will exempt a student from repeating the same material and will provide credit towards graduation requirements.',
      ],
      'Social Studies': [
        'Sora Schools provides transfer credit for any successfully completed high school-level social studies course taken at an accredited educational institution. ',
        'Receiving transfer credit for U.S. history, World History, Economics, or Philosophy will exempt a student from repeating the same material and will provide credit towards graduation requirements.',
      ],
      Science: [
        'Sora Schools provides transfer credit for any successfully completed high school-level science course taken at an accredited educational institution.',
        'Receiving transfer credit for a lab-based Biology, Chemistry, Physics or higher-level science elective will exempt a student from repeating the same material and will provide credit towards graduation requirements.',
      ],
      Math: [
        'Sora Schools provides transfer credit for any successfully completed high school-level math course taken at an accredited educational institution. ',
        'Receiving transfer credit for Algebra I, Geometry, Algebra II, or a higher-level math elective course will exempt a student from repeating the same material and will provide credit towards graduation requirements.',
      ],
      ELA: [
        'Sora Schools provides transfer credit for any successfully completed high school-level English course taken at an accredited educational institution. Receiving transfer credit for ELA courses will exempt a student from repeating the same material and will provide credit towards graduation requirements.',
      ],
    }[subject] || []
  )
}
