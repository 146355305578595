import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MetricsList from '@components/facilitate/metrics/metrics-list'
import MetricsTaskList from './metrics-task-list'

const Metrics = ({ total_flagged, percentage_attendance, theme_id, student_id, new_total_missed, total_ontime, total_tasks }) => {
  const [metric, setMetric] = useState()
  const handleOpenMetric = status => _ => setMetric(status)
  const handleCloseMetric = () => setMetric(null)
  return (
    <>
      {!metric ? (
        <MetricsList
          amountFlagged={total_flagged}
          amountMissed={new_total_missed}
          percentAttendance={percentage_attendance}
          amountOntime={`${total_ontime}/${total_tasks}`}
          onClick={handleOpenMetric}
          vertical
        />
      ) : (
        <MetricsTaskList handleBack={handleCloseMetric} metric={metric} theme_id={theme_id} student_id={student_id} />
      )}
    </>
  )
}

Metrics.propTypes = {
  total_flagged: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  new_total_missed: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  total_ontime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  total_tasks: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  percentage_attendance: PropTypes.number,
  theme_id: PropTypes.number,
  student_id: PropTypes.number
}

export default Metrics
