import axios from 'axios'

export async function loader({ request }) {
  const searchParams = new URL(request.url).searchParams
  const cycle_id = searchParams.get('cycle_id')
  const search = searchParams.get('search')
  const cohorts = searchParams.get('cohorts')
  const type = searchParams.get('type')
  const query = {}
  if (cycle_id) {
    query.cycle_id = cycle_id
  }
  if (search) {
    query.search = search
  }
  if (type) {
    query.type = type
  }
  if (cohorts) {
    query.cohorts = cohorts === 'all' ? 'all' : 'primary'
  }
  const queryString = new URLSearchParams(query)
  const { data } = await axios.get(`/pages/employee/expeditions/edit?${queryString.toString()}`)
  return data
}
