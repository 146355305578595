import * as React from 'react'
import axios from 'axios'
import { ActionFunctionArgs, useLoaderData } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { Button, Card, Icon, unstable_Table as Table, Typography, useFetcher } from '@design-system'

interface LoaderData {
  users: Array<{
    id: number,
    name: string,
    type: string,
    heartbeatId: string,
  }>,
  headers: Array<string>
}

async function loader() {
  const res = await axios.get('/backoffice/workbench/student-heartbeat-server/users')
  return res.data
}

async function action({ request }: ActionFunctionArgs) {
  const data = serializeFormData(await request.formData())
  const res = await axios.post(`/backoffice/workbench/student-heartbeat-server/users`, data)
  return res.data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData

  return (
    <div className="flex flex-col gap-4 px-6">
      <Card>
        <Card.Content>
          <Typography variant="callout" color="secondary" className="flex flex-col">
            <p>This sync will:</p>
            <ul className="list-disc list-inside">
              <li><b>Invite to heartbeat</b> active users* that are not in heartbeat.</li>
              <li><b>Deactivate from heartbeat</b> inactive users*.</li>
            </ul>
            <p className="mt-2"><b>*Active students</b> are all that have a house currently.</p>
            <p><b>*Active employees</b> are all that have the field "Is Active" checked.</p>
            <p className="mt-2">The e-mail invitation is only sent once every 24 hours to avoid spamming users.</p>
          </Typography>
        </Card.Content>
        <div className="px-4">
          <SyncFormButton name="_action" value="sync_all_users" title="Sync all users" />
        </div>
      </Card>
      <Table className="border-separate border border-gray-30 rounded-lg">
        <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
          <Table.Row>
            {loaderData.headers.map((header, index) => (
              <Table.Head key={index} className="w-48 first:pl-4">{header}</Table.Head>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body className="[&_tr:last-child]:border-1">
          {loaderData.users.map((user) => (
            <Table.Row key={user.id} className="last-of-type:rounded-b-md">
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{user.id}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{user.name}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{user.type}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{user.heartbeatId ?? '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                <SyncFormButton title="Sync this user" name="userId" value={user.id.toString()}>
                  <input type="hidden" name="_action" value="sync_single_user" />
                </SyncFormButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div >
  )
}

interface SyncFormButtonProps extends React.PropsWithChildren {
  title: string,
  name?: string,
  value?: string,
}

function SyncFormButton({ title, name, value, children }: SyncFormButtonProps) {
  const fetcher = useFetcher()
  const loading = fetcher.state === 'loading' || fetcher.state === 'submitting'
  return (
    <fetcher.Form method="POST" className="self-end">
      <Button type="submit" name={name} value={value} size="sm" loading={loading}>
        <Icon name="arrow-swap" size="xs" /> {title}
      </Button>
      {children}
    </fetcher.Form>
  )
}


export const WorkbenchStudentHearbeatServerUsersRoute = { loader, action, Element }
