import * as React from 'react'
import { Typography } from '@design-system'

type HouseCellProps = {
  title: string,
  schoolStage: string,
}

export function HouseCell({ schoolStage, title }: HouseCellProps) {
  return (
    <div className="flex flex-col gap-1">
      <Typography variant="callout" weight="bold" className="whitespace-nowrap">
        {title}
      </Typography>
      <Typography variant="callout" className="uppercase">{schoolStage}</Typography>
    </div>
  )
}