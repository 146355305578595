import serializeFormData from '@utils/serializeFormData'
import axios from 'axios'
import Papa from 'papaparse'
import isValid from 'date-fns/isValid'
import format from 'date-fns/format'
import _set from 'lodash/set'

const action = async ({ request }) => {
  const formData = await request.formData()
  const formType = formData.get('formType')

  if (formType === 'deleteEnrolment') {
    const serializedFormData = serializeFormData(formData)
    try {
      await axios.delete(`/enrolments/${serializedFormData.enrolmentId}`)
      return {
        toast: {
          appearance: 'success',
          message: 'Enrolment removed successfully.',
        }
      }
    } catch (e) {
      if (e?.response?.data?.errors?.length) {
        return {
          toast: {
            appearance: 'error',
            message: e.response.data.errors,
          }
        }
      }
      console.error({ error: e })
      return {
        toast: {
          appearance: 'error',
          message: 'Something went wrong.',
        }
      }
    }
  }

  if (formType === 'completeEnrolment') {
    try {
      const { enrolmentId, is_completed } = serializeFormData(formData)
      await axios.put(`/enrolments/${enrolmentId}`, { is_completed: is_completed === 'true' })
      return {
        toast: {
          appearance: 'success',
          message: 'Enrolment Marked as Done.',
        }
      }
    } catch (e) {
      console.error({ error: e })
      return {
        toast: {
          appearance: 'error',
          message: 'Something went wrong.',
        }
      }
    }
  }

  if (formType === 'confirmCSVUpload') {
    const parsedCSVData = formData.get('parsedCSVData')
    try {
      await axios.post(
        '/enrolments/family?confirmed=true',
        JSON.parse(parsedCSVData)
      )
      return {
        toast: {
          message: 'CSV Data imported successfully',
          appearance: 'success',
        }
      }
    } catch (e) {
      console.error(e)
      return {
        toast: {
          message: 'Could not import csv data',
          appearance: 'error',
        }
      }
    }
  }

  if (formType === 'sendEmail') {
    const emailType = formData.get('emailType')
    const studentId = formData.get('studentId')
    try {
      if (emailType === 'welcome') await axios.post(`/enrolments/email/welcome?student_id=${studentId}`)
      return {
        toast: {
          message: 'Email sent successfully',
          appearance: 'success',
        }
      }
    } catch (e) {
      return {
        toast: {
          message: 'Could not send email',
          appearance: 'error',
        }
      }
    }
  }

  if (formType === 'uploadEnrolmentCSV') {
    const file = formData.get('enrolled_families_csv')

    if (!file?.size) {
      return {
        errors: {
          'enrolled_families_csv': 'Invalid CSV file'
        }
      }
    }

    try {
      const { parsedCSVData, rawCSVData } = await asyncPapaParse(file)
      try {
        await axios.post('/enrolments/family', parsedCSVData)
      } catch (e) {
        if (e?.response?.data?.errors?.length) {
          throw e.response.data.errors
        }
      }
      return {
        parsedCSVData,
        rawCSVData,
      }
    } catch (csvErrors) {
      return {
        csvErrors
      }
    }
  }
}

export function parseCSVData(csvData) {
  const transformVal = (val, row) => {
    const lowerVal = (val + '').toLowerCase()
    if (['n/a', 'na', 'not applicable'].includes(lowerVal)) return ''
    if (lowerVal === 'yes') return true
    if (lowerVal === 'no') return false
    if (
      val &&
      (row.includes('relationship') ||
        row.includes('first_name') ||
        row.includes('middle_name') ||
        row.includes('last_name'))
    ) {
      // Ensure naming consistency
      return lowerVal
        .split(' ')
        .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
        .join(' ')
    }

    // Hardcoded value as admissions do not know exactly the day-month, only year.
    if (row === 'student_expected_graduation') {
      const date = new Date(`${val}-04-30`)
      if (isValid(date)) return format(date, 'yyyy-MM-dd')
    }

    return val || ''
  }
  const parsedRow = []
  csvData.forEach((row) => {
    const student = {}
    const emergencyContact = {}
    const guardians = []
    Object.keys(row).forEach((k) => {
      const val = transformVal(row[k], k)
      if (val === '') return
      if (k.includes('student_'))
        _set(student, k.replace('student_', ''), val)
      if (k.includes('emergency_contact_'))
        _set(emergencyContact, k.replace('emergency_contact_', ''), String(val))
      if (k.includes('guardian_1_'))
        _set(guardians, '[0]' + k.replace('guardian_1_', ''), val)
      if (k.includes('guardian_2_'))
        _set(guardians, '[1]' + k.replace('guardian_2_', ''), val)
    })
    parsedRow.push({ student, guardians, emergencyContact })
  })

  return parsedRow
}

function asyncPapaParse(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const { data, errors } = results
        if (errors?.length) return reject(errors)
        let parsedCSVData = null
        try {
          parsedCSVData = parseCSVData(data)
          if (!parsedCSVData?.length)
            return reject([{ row: -1, message: 'CSV is empty' }])
        } catch (e) {
          return reject([
            {
              row: -1,
              message: 'Error while parsing CSV: ' + e?.message || '',
            },
          ])
        }
        return resolve({
          parsedCSVData,
          rawCSVData: data,
        })
      },
    })
  })
}

export default action
