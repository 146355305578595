import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Combobox } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons'

const UnitAbilitySearchBar = ({
  units = [],
  abilities = [],
  selectedUnits = [],
  selectedAbilities = [],
  onSelect
}) => {
  const [query, setQuery] = useState('')

  const filteredResult = {
    units: (
      query === ''
        ? units?.filter(unit => !selectedUnits.some(u => u.id === unit.id))
        : units?.filter(unit => !selectedUnits.some(u => u.id === unit.id) && (unit.title.toLowerCase().includes(query.toLowerCase()) || unit.topic_title.toLowerCase().includes(query.toLowerCase())))
    )?.filter(unit => !unit.archived),
    abilities: query === ''
      ? abilities?.filter(ability => !selectedAbilities.some(a => a.id === ability.id))
      : abilities?.filter(ability => !selectedAbilities.some(a => a.id === ability.id) && ability.title.toLowerCase().includes(query.toLowerCase())),
  }

  const handleOnChange = (value) => {
    if (!value) return
    const [type, id] = value.split('_')

    if (type === 'unit') {
      const unit = units.filter(u => u.id == id)[0]
      onSelect('unit', unit)
    }
    if (type === 'ability') {
      const ability = abilities.filter(a => a.id == id)[0]
      onSelect('ability', ability)
    }
  }

  const organizeUnitsByTopic = (units) => {
    const topics = [...new Set(units.map(u => u.topic_title))].sort((a, b) => String(a).localeCompare(String(b)))
    const unitsByTopics = topics.map(t => ({ title: t, units: units.filter(u => u.topic_title === t).sort((a, b) => String(a.title).localeCompare(String(b.title))) }))
    return unitsByTopics
  }
  const unitsByTopics = organizeUnitsByTopic(filteredResult.units)

  return (
    <Combobox value={null} onChange={handleOnChange}>
      <div className="relative">
        <div className='flex flex-row bg-gray-10 rounded-lg justify-between items-center px-2 min-w-72'>
          <span className='text-gray-60 pl-1'>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </span>
          <Combobox.Input onChange={(event) => setQuery(event.target.value)} className='flex-auto border-none outline-none focus:ring-0 bg-transparent appearance-none text-sm placeholder-gray-60' placeholder='Search for Units or Abilities' />
          <Combobox.Button className='flex justify-center items-center w-6 h-6 rounded-full hover:bg-gray-30'>
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </Combobox.Button>
        </div>

        <Combobox.Options className='absolute mt-1 z-10 w-full max-h-64 overflow-auto rounded-md bg-white p-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
          {unitsByTopics?.length > 0 && <><span className='font-bold py-2'>Units</span><br /></>}
          {unitsByTopics?.map((topic, index) => {
            return (
              <div key={`searchbar:topic:${index}`}>
                <span className='flex flex-row items-center gap-1 pl-2 underline'>{topic.title}</span>
                {
                  topic.units.map(unit => (
                    <Combobox.Option key={`searchbar:unit_${unit.id}`} value={`unit_${unit.id}`} className='pl-4 cursor-pointer hover:bg-gray-10'>
                      {unit.title}
                    </Combobox.Option>
                  ))
                }

              </div>
            )
          })}
          {filteredResult.abilities?.length > 0 && <span className='font-bold py-2'>Abilities</span>}
          {filteredResult.abilities?.map((ability, index) => (
            <Combobox.Option key={`searchbar:ability_${ability.id}`} value={`ability_${ability.id}`} className='pl-2 cursor-pointer hover:bg-gray-10'>
              {ability.title}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}
UnitAbilitySearchBar.propTypes = {
  units: PropTypes.array,
  selectedUnits: PropTypes.array,
  abilities: PropTypes.array,
  selectedAbilities: PropTypes.array,
  onSelect: PropTypes.func
}

export default UnitAbilitySearchBar
