import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faCopy,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import DurationInput from '../forms/duration-input'
import DateTimePicker from '../forms/date-time-picker'
import useToggle from '@hooks/useToggle'
import SlateTextarea from '@components/forms/slate-textarea'
import { TextField } from '@designsystem'
import getBrowserTimezoneAbbreviation from '@utils/getTimezoneAbbreviation'
export default function SessionFields({
  name,
  values,
  onRemove,
  onDuplicate,
  onRepeatWeekly,
  sessionPosition,
  minDate,
  maxDate,
  isInExpeditionTemplate,
  isInTemplateSessionTimePick,
  showManualTimeInput = false,
  desiredTimes = null,
}) {
  const [advancedExpanded, toggleAdvancedExpanded] = useToggle(false)

  const [localState, setLocalState] = useState(values)

  const handleRemove = (event) => {
    event.preventDefault()
    onRemove(localState.id)
  }

  const handleDuplicate = (event) => {
    event.preventDefault()
    onDuplicate(localState.id || values)
  }

  const handleRepeatWeekly = (event) => {
    event.preventDefault()
    onRepeatWeekly(localState.id)
  }

  const isTemplateRelated =
    isInExpeditionTemplate || isInTemplateSessionTimePick


  localState.attendance_type = 'manual'

  const isInvalidSession = (
    new Date(localState.timestamptz) > maxDate
    || new Date(localState.timestamptz) < minDate
  )

  const datePickerTimeProps = showManualTimeInput
    ? { showTimeInput: true }
    : {
      showTimeInput: false,
      ...(desiredTimes && {
        includeTimes: desiredTimes,
        injectTimes: desiredTimes,
      })
    }

  return (
    <div
      className={`space-y-4 border-l-4 border rounded px-4 py-1 
        ${isInvalidSession ? 'bg-danger-5 border-danger-20' : ''}
      `}
    >
      {localState.id && !isTemplateRelated && <input type='hidden' name={`${name}[id]`} value={localState.id} />}
      {isTemplateRelated && <input type='hidden' name={`${name}[sequence_number]`} value={localState.sequence_number} />}
      <TextField
        label="Title"
        name={`${name}[title]`}
        required
        defaultValue={localState.title}
        readOnly={isTemplateRelated}
        placeholder={isInExpeditionTemplate && `Session position: ${sessionPosition}`}
      />
      <div>
        <label htmlFor={`session-${localState.id}-description`} className={isInTemplateSessionTimePick ? 'opacity-50' : ''} >
          <h3 className="ml-3 text-sm font-bold">Description</h3>
          <SlateTextarea
            noAttachments
            id={`session-${localState.id}-description`}
            name={`${name}[rte_description]`}
            value={localState.rte_description}
            readOnly={isInTemplateSessionTimePick}
            placeholder="Type your description here"
          />
        </label>
      </div>
      {
        !isInExpeditionTemplate && (
          <label>
            <h3 className="ml-3 text-sm font-bold">Start At ({getBrowserTimezoneAbbreviation()})</h3>
            <DateTimePicker
              {...datePickerTimeProps}
              disabled={isInTemplateSessionTimePick}
              minDate={minDate}
              maxDate={maxDate}
              name={`${name}[timestamptz]`}
              dateFormat="PPPPp"
              onChange={e => {
                setLocalState(prev => ({
                  ...prev,
                  timestamptz: e.target.value
                }))
              }}
              value={localState.timestamptz}
              required
            />
            {isInvalidSession && (
              <small className="text-danger-60 text-xs ml-3">
                Must be between{' '}
                {minDate.toLocaleDateString([], {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}{' '}
                and{' '}
                {maxDate.toLocaleDateString([], {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </small>
            )}
          </label>
        )
      }
      <label>
        <h3 className="ml-3 text-sm font-bold">Duration</h3>
        <DurationInput
          readOnly={isInTemplateSessionTimePick}
          name={`${name}[duration]`}
          required
          defaultValue={localState.duration}
        />
      </label>

      <input type="hidden" name={`${name}[attendance_type]`} value={localState.attendance_type || 'manual'} />
      {!isTemplateRelated && (
        <label>
          <h3 className="ml-3 text-sm font-bold">Conference URL</h3>
          <TextField
            name={`${name}[conference_url]`}
            defaultValue={localState.conference_url}
          />
        </label>
      )}

      {!isTemplateRelated && (
        <fieldset className={`col-span-2 gap-2 p-4 grid grid-cols-1 md:grid-cols-2 border text-gray-40 ${!advancedExpanded && 'hidden'}`}>
          <legend className="font-bold">Advanced</legend>
          <TextField
            label="Google Calendar Event ID"
            name={`${name}[google_calendar_event_id]`}
            defaultValue={localState.google_calendar_event_id}
            className="text-xs py-0.5"
          />
        </fieldset >
      )}
      <div className="col-span-2 justify-between items-center flex py-2">
        <div className="text-gray-40 pl-3">
          {localState.id && !isTemplateRelated ? (
            <button
              type="button"
              className="text-xs hover:underline"
              onClick={toggleAdvancedExpanded}
            >
              {advancedExpanded ? 'Hide advanced ' : 'Show advanced '}
              <FontAwesomeIcon
                icon={advancedExpanded ? faCaretUp : faCaretDown}
              />
            </button>
          ) : (
            !isTemplateRelated && (
              <div className="bg-green-50 px-2 text-xs font-bold text-white rounded">
                New!
              </div>
            )
          )}
        </div>
        <label htmlFor="actions" className="text-white font-bold">
          {!isTemplateRelated && (
            <button
              onClick={handleRepeatWeekly}
              className="text-blue-40 hover:underline rounded px-2 py-1 ml-2"
            >
              Repeat weekly until end cycle
            </button>
          )}
          {!isTemplateRelated && (
            <button
              onClick={handleDuplicate}
              className="bg-blue-70 rounded px-2 py-1 ml-2"
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          )}
          <button
            onClick={handleRemove}
            className="bg-danger-50 rounded px-2 py-1 ml-2"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </label>
      </div>
    </div >
  )
}

SessionFields.propTypes = {
  name: PropTypes.string,
  isInTemplateSessionTimePick: PropTypes.bool,
  isInExpeditionTemplate: PropTypes.bool,
  sessionPosition: PropTypes.number,
  maxDate: PropTypes.shape({
    toLocaleDateString: PropTypes.func,
  }),
  minDate: PropTypes.shape({
    toLocaleDateString: PropTypes.func,
  }),
  onDuplicate: PropTypes.func,
  onRemove: PropTypes.func,
  onRepeatWeekly: PropTypes.func,
  values: PropTypes.shape({
    description: PropTypes.any,
    rte_description: PropTypes.any,
    duration: PropTypes.any,
    google_calendar_event_id: PropTypes.any,
    id: PropTypes.any,
    timestamptz: PropTypes.any,
    sequence_number: PropTypes.number,
    title: PropTypes.any,
    attendance_type: PropTypes.string,
    conference_url: PropTypes.string,
  }),
  desiredTimes: PropTypes.arrayOf(PropTypes.any),
  showManualTimeInput: PropTypes.bool,
}
