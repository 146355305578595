import { z } from 'zod'

export type MaterialsAndTools = {
  id: string,
  title: string,
  description: string,
  type: string,
  link?: string
}

export type LearningSection = {
  id: string,
  title: string,
  description: string,
}

export type Session = {
  id: string,
  title: string,
  rte_description: any[],
  sequenceNumber?: number,
  templateId?: number,
  tasks?: Task[]
}

export type ExperienceSession = {
  id?: string,
  title: string,
  rte_description: any[],
  timestamptz: string,
  duration?: string,
  themeId?: number,
  conferenceUrl?: string,
  localId?: string,
}

type Task = {
  id: string,
  title: string,
  rte_description: any[],
  readOnly: boolean,
  type: string,
}

export type Project = {
  id: string,
  title: string,
  description: string,
  units?: string[],
  abilities?: string[]
}

export type LabelValue = {
  value: string,
  label: string
}

export const buildFormSchema = ({ enforceRequiredFields, isExtracurricular }) => {
  const isFinalDeliverableRequired = enforceRequiredFields && !isExtracurricular

  return z.object({
    type: z.string().min(1, { message: 'Type is required' }),
    is_draft: z.string().optional(),
    short_description: z.string().max(500, { message: 'Short description must be less than 500 characters' }),
    category: z.string().optional(),
    school_stage: enforceRequiredFields ? z.string().min(1, { message: 'School stage is required' }) : z.string().optional(),
    experts: z.string().optional(),
    interest_tags: z.string().optional(),
    is_redesigned_experience: z.string(),
    units: z.string().optional(),
    abilities: z.string().optional(),
    projects: z.array(
      z.object({
        title: z.string().optional(),
        description: z.string().optional(),
        units: z.string().optional(),
        abilities: z.string().optional(),
      })
    ).optional(),
    title: z.string().min(1, { message: 'Title is required' }),
    short_title: z.string().min(1, { message: 'Short title is required' }).max(80, { message: 'Short title must be less than 80 characters' }),
    rte_description: enforceRequiredFields ? z.string().min(1, { message: 'Description is required' }) : z.string().optional(),
    image_url: enforceRequiredFields ? z.string().min(1, { message: 'Image URL is required' }) : z.string().optional(),
    image_alt: enforceRequiredFields ? z.string().min(1, { message: 'Image alt is required' }) : z.string().optional(),
    video_section_video_url: z.string().url().optional().or(z.literal('')),
    instructor_notes: z.string().optional(),
    key_features_essential_questions_description: z.string().optional(),
    key_features_learning_objectives_description: z.string().optional(),
    key_features_section_title: z.string().optional(),
    key_features_section_description: z.string().optional(),
    materials: z.array(
      z.object({
        type: enforceRequiredFields ? z.string().min(1) : z.string().optional(),
        title: enforceRequiredFields ? z.string().min(1) : z.string().optional(),
        description: z.string().optional(),
        link: z.string().url().optional().or(z.literal('')),
      })
    ).optional(),
    sections: z.array(
      z.object({
        title: enforceRequiredFields ? z.string().min(1, { message: 'Title is required' }) : z.string().optional(),
        description: z.string().optional()
      })
    ).optional(),
    sessions: z.array(
      z.object({
        id: z.string().optional(),
        timestamptz: z.string(),
        local_id: z.string().optional(),
        title: enforceRequiredFields ? z.string() : z.string().optional(),
        conference_url: z.string().optional(),
        duration: enforceRequiredFields ? z.string().min(1) : z.string().optional(),
        rte_description: z.string().optional(),
      })
    ).optional(),
    final_deliverable: z.object({
      id: z.string().optional(),
      theme_session_id: z.string().optional(),
      is_attached_session_new: z.string().optional(),
      title: isFinalDeliverableRequired ? z.string().min(1, { message: 'Title is required' }) : z.string().optional(),
      body: isFinalDeliverableRequired ? z.string().min(1, { message: 'Description is required' }) : z.string().optional(),
      type: isFinalDeliverableRequired ? z.string().refine(value => value === 'final', { message: 'Type must be final' }) : z.string().optional(),
      is_readonly: isFinalDeliverableRequired ? z.string() : z.string().optional()
    }).optional(),
    competition_interval_id: z.string().min(1, { message: 'Cycle is required' }),
    max_students: z.string().min(1, { message: 'Max students must be greater than 0' }),
    enable_heartbeat: z.string().optional(),
    heartbeat_group_id: z.string().optional(),
    heartbeat_channel_id: z.string().optional(),
    mainExpert: z.string(),
  }).refine((schema) => {
    if ((schema.abilities || schema.units) && !schema.final_deliverable.title) {
      return false
    }
    return true
  }, { message: 'Experience must have final deliverable to have abilities and units.', path: ['final_deliverable', 'title'] })
}
