import React from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router-dom'

const loader = async () => {
  const result = await axios.get('/backoffice/workbench/math-placement/timeline')
  return result.data
}

const Element = () => {
  const { data: { columns, rows } } = useLoaderData()

  const subColumns = () => {
    const cols = []
    let idx = -1

    for (const c of columns) {
      idx++
      for (const sc of c.subCol) {
        cols.push({ key: `subCol_${sc}_${idx}`, title: c.subCol.length > 1 ? sc : '' })
      }
    }

    return cols
  }

  return (
    <div className='p-2'>
      <table>
        <thead>
          <tr>
            <th rowSpan={2} colSpan={2} className='p-2'></th>
            {columns.map((c, idx) => (
              <th key={`main_col_${c.title}_${idx}`} colSpan={c.subCol.length} rowSpan={c.subCol.length > 1 ? 1 : 2} className='whitespace-nowrap py-2 px-10 border-black border-solid border '>{c.title}</th>
            ))}
          </tr>
          <tr>
            {subColumns().map(sc => {
              if (sc.title.length > 0) return (<th key={sc.key} className='py-2 px-10 text-xs border-black border-solid border'>{sc.title}</th>)
              return null
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((r, rIdx) => (
            <React.Fragment key={r.cycle_title}>
              <tr className={`${rIdx % 2 ? '' : 'bg-gray-15'}`}>
                <td rowSpan={2} className='whitespace-nowrap p-2 font-bold border-black border-solid border'>{`${r.cycle_title}`}</td>
                <td className='whitespace-nowrap p-2 font-bold border-black border-solid border'>Unit</td>
                {r.units.map((u, idx) => <td key={`row_unit_${u?.unit_id}_${idx}`} className='p-2 text-xs border-black border-solid border'>{u?.unit_title}</td>)}
              </tr>
              <tr className={`${rIdx % 2 ? '' : 'bg-gray-15'}`}>
                <td className='whitespace-nowrap p-2 font-bold border-black border-solid border'>Template</td>
                {r.units.map((u, idx) => <td key={`row_template_${u?.unit_id}_${idx}`} className={`p-2 text-xs border-black border-solid border ${u?.template_title.includes('archived') ? 'text-danger-60' : ''}`}>{u?.template_title}</td>)}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export const WorkbenchMathPlacementTimelineRoute = {
  loader,
  Element,
}
