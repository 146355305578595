import { isBlankValue } from '@components/forms/slate-textarea'

const RTE_KEYS = [
  'overview',
  'tools_and_materials',
  'directions',
  'rte_description',
  'video_section_description',
  'key_features_section_description',
  'key_features_career_connections_description',
  'key_features_essential_questions_description',
  'key_features_learning_objectives_description',
  'key_features_materials_and_tools_description',
  'key_features_optional_topic_description',
  'key_features_paths_of_inquiry_description',
  'key_features_project_creation_description',
] as const

const ARRAY_AS_OBJECT_KEYS = [
  'sessions',
  'units_levels',
] as const

const ON_OFF_KEYS = [
  'enable_heartbeat',
] as const

const ON_OFF_PARITY = {
  on: true,
  off: false,
} as const

const DURATION_KEY = 'duration' as const

type SlateValue = string | undefined
type ParsedSlateValue = Array<unknown> | undefined
type TemplateFormSlateFields<T> = Record<typeof RTE_KEYS[number], T>

export function getLocalStorageData<T extends TemplateFormSlateFields<SlateValue>>(localStorageKey: string): T | undefined {
  const data = JSON.parse(window.localStorage.getItem(localStorageKey))
  if (!data) return undefined

  return {
    ...data,
    ...parseSlateFields(data),
    ...parseSessionAndUnitsLevelsToArray(data),
  }
}

function parseSessionAndUnitsLevelsToArray(data: Record<string, unknown>) {
  if (!data.sessions && !data.units_levels) return {}

  return {
    sessions: Object.values(data.sessions || {}),
    units_levels: Object.values(data.units_levels || {}),
  }
}

export function parseSlateFields<T extends TemplateFormSlateFields<SlateValue>>(data: T): TemplateFormSlateFields<ParsedSlateValue> {
  return RTE_KEYS.reduce((acc, key) => {
    if (key in data === false) return acc
    return {
      ...acc,
      [key]: jsonParseIfExists(data[key]),
    }
  }, {} as TemplateFormSlateFields<ParsedSlateValue>)
}

export function jsonParseIfExists(slateValue: string): ParsedSlateValue {
  if (!slateValue) return undefined
  return JSON.parse(slateValue)
}

export const REMOTE_STATE_KEY = 'saved_remote_state'

export function getDiffsFromCommonKeys(obj1: Record<string, unknown>, obj2: Record<string, unknown>) {
  const commonKeys = getCommonKeys(obj1, obj2)
  const commonlyKeyedObject1 = commonKeys.reduce((acc, curr) => ({ ...acc, [curr]: obj1[curr] }), {})
  const commonlyKeyedObject2 = commonKeys.reduce((acc, curr) => ({ ...acc, [curr]: obj2[curr] }), {})
  const diffs = []
  for (const key of commonKeys) {
    if (!deepEquals(commonlyKeyedObject1[key], commonlyKeyedObject2[key], key)) {
      diffs.push(key)
    }
  }
  return diffs
}

export function deepEquals(a: any, b: any, key = '') {
  let val1 = a ?? ''
  let val2 = b ?? ''

  if (RTE_KEYS.includes(key as any)) {
    val1 = typeof a === 'string' ? JSON.parse(a) : a
    val2 = typeof b === 'string' ? JSON.parse(b) : b
    if (isBlankValue(val1) && isBlankValue(val2)) {
      return true
    }
  }
  if (ON_OFF_KEYS.includes(key as any)) {
    val1 = val1 === 'on' || val1 === 'off' ? ON_OFF_PARITY[val1] : val1
    val2 = val2 === 'on' || val2 === 'off' ? ON_OFF_PARITY[val2] : val2
  }
  if (DURATION_KEY === key) {
    const splittedVal1 = val1.split(':')
    val1 = [splittedVal1[0], splittedVal1[1]].join(':')
    const splittedVal2 = val2.split(':')
    val2 = [splittedVal2[0], splittedVal2[1]].join(':')
  }
  if (ARRAY_AS_OBJECT_KEYS.includes(key as any)) {
    val1 = Object.values(val1)
    val2 = Object.values(val2)
  }

  const isEqualValue = (value1: any, value2: any) => {
    return String(value1) === String(value2)
  }

  const isEqualArray = (array1: Array<any>, array2: Array<any>) => {
    if (array1.length !== array2.length) return false
    let remaining = array2.slice()
    for (let item of array1) {
      const index = remaining.findIndex((remItem) => deepEquals(item, remItem))
      if (index > -1) {
        remaining.splice(index, 1)
      } else {
        return false
      }
    }
    return remaining.length === 0
  }

  if (Array.isArray(val1) && Array.isArray(val2)) {
    return isEqualArray(val1, val2)
  } else if (typeof val1 === 'object' && val1 !== null && typeof val2 === 'object' && val2 !== null) {
    const commonKeys = getCommonKeys(val1, val2)
    for (const key of commonKeys) {
      if (!deepEquals(val1[key], val2[key], key)) {
        return false
      }
    }
    return true
  } else {
    return isEqualValue(val1, val2)
  }
}

function getCommonKeys(obj1: Record<string, unknown>, obj2: Record<string, unknown>) {
  /**
    * Using the way below to get the commonKyes instead of ketting just comparing obj1[key] === obj2[key] because undefined is a valid value.
    */
  return Object.keys(obj1).filter((key) => Object.keys(obj2).includes(key))
}