import * as React from 'react'
import { FormLabel } from '@radix-ui/react-form'
import { cn, Icon } from '@design-system'

export function FormDescription({ children }: { children: React.ReactNode }) {
  return <p className="text-sm">{children}</p>
}

export function BlockWrapper({ children, id, unstyled = false }: { id?: string, children: React.ReactNode, unstyled?: boolean }) {
  return (
    <div id={id} className={cn('scroll-mt-64 flex flex-col gap-8', !unstyled ? 'p-6 bg-white rounded-lg border border-gray-50' : '')} >
      {children}
    </ div>
  )
}

export function BlockTitle({ children }: { children: React.ReactNode }) {
  return (
    <h2 className="text-xl font-bold">{children}</h2>
  )
}

export function BlockContent({ children, className }: { children: React.ReactNode, className?: string }) {
  return <div className={cn('flex flex-col gap-8', className)}>{children}</div>
}

export function SoraFormLabel({ children }: { children: React.ReactNode }) {
  return <FormLabel className="text-sm font-bold">{children}</FormLabel>
}

export function SoraFormMessage({ children }: { children: React.ReactNode }) {
  return (
    <span className="text-sm flex space-x-2 items-center text-danger-40 error-form-message">
      <Icon size="xs" name="alert-triangle" />
      <p>{children}</p>
    </span>
  )
}