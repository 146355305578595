/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import useSWR from 'swr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import UnitsAbilitiesSelfAssessment from "@components/submission/units-abilities-self-assessment"
import CommentThread from '@components/comment-thread'
import Metrics from './metrics'

const LevelSummary = ({ title, level, units = [], abilities = [], showNumericValue=true }) => {
  const circleBgColor = 'bg-turquoise-50'
  const circleValue = level

  return (
    <div className="flex items-center px-8 py-5 bg-gray-20 space-x-5 rounded-lg w-full">
      {showNumericValue && 
        <div className={`flex ${circleBgColor} h-10 w-10 rounded-full`}>
          <span className="text-xl text-white leading-none m-auto">{circleValue}</span>
        </div>
      }
      <div className="flex flex-col">
        <span className="font-bold">{title}</span>
        <span className="text-gray-70">{units} Units | {abilities} Abilities</span>
      </div>
    </div>
  )
}

const EndCycleReport = ({ report, cycleId, isExpanded, toggleIsExpanded }) => {
  const { theme_id, theme_title, employee_name, student_id } = report
  const { data } = useSWR(`/reports/v2/progress-report/${student_id}/cycle/${cycleId}/theme/${theme_id}/final`)

  const total = useMemo(() => {
    const { result: { abilities = [], units = [], showNumericValue } = {} } = data || {}
    const total = {
      developing: {
        abilities: abilities.filter(a => a.picked && a.faculty_level >= 1 && a.faculty_level < 2).length,
        units: units.filter(u => u.picked && u.faculty_level >= 1 && u.faculty_level < 2).length,
      },
      emerging: {
        abilities: abilities.filter(a => a.picked && a.faculty_level >= 2 && a.faculty_level < 3).length,
        units: units.filter(u => u.picked && u.faculty_level >= 2 && u.faculty_level < 3).length,
      },
      demonstrating: {
        abilities: abilities.filter(a => a.picked && a.faculty_level >= 3 && a.faculty_level < 4).length,
        units: units.filter(u => u.picked && u.faculty_level >= 3 && u.faculty_level < 4).length,
      },
      extending: {
        abilities: abilities.filter(a => a.picked && a.faculty_level >= 4).length,
        units: units.filter(u => u.picked && u.faculty_level >= 4).length,
      },
      showNumericValue,
    }
    return total
  }, [data])

  const canExpand = !!data

  return (
    <div key={theme_id} className="flex flex-col w-full border border-gray-60 bg-white rounded-lg p-10 text-black space-y-8">
      <div className="flex flex-col">
        <span className="font-bold text-2xl">{theme_title}</span>
        <span>{employee_name}</span>
      </div>
      <div className="flex gap-6">
        <div className="min-w-[320px]">
          <Metrics {...report} />
        </div>
        <CommentThread
          taskId={report.task_id}
          studentId={report.student_id}
          taskSubmissionId={report.task_submission_id}
          readOnly
          collapsable={false}
          className='border-t border-b border-t-gray-30 border-b-gray-30 w-full py-2'
        />
      </div>
      <div className="flex flex-col w-full pr-25 space-y-5">
        <div className="flex space-x-5 w-full">
          <LevelSummary title="EXPOSURE" level={1} units={total.developing.units} abilities={total.developing.abilities} showNumericValue={total.showNumericValue} />
          <LevelSummary title="DEVELOPING" level={2} units={total.emerging.units} abilities={total.emerging.abilities} showNumericValue={total.showNumericValue} />
          <LevelSummary title="DEMONSTRATING" level={3} units={total.demonstrating.units} abilities={total.demonstrating.abilities} showNumericValue={total.showNumericValue} />
          <LevelSummary title="EXTENDING" level={4} units={total.extending.units} abilities={total.extending.abilities} showNumericValue={total.showNumericValue} />
          {
            !!canExpand &&
            <button className="flex rounded-full bg-gray-10 px-4 py-3 items-center my-auto" onClick={toggleIsExpanded}>
              <FontAwesomeIcon className="mx-auto" icon={isExpanded ? faArrowUp : faArrowDown} />
            </button>
          }
        </div>
        {isExpanded &&
          <UnitsAbilitiesSelfAssessment apiUrl={`/reports/v2/progress-report/${student_id}/cycle/${cycleId}/theme/${theme_id}/final`} disabled={true} isAssessed={true} showNumericValue={total.showNumericValue} />
        }
      </div>
    </div>
  )
}

export default EndCycleReport
