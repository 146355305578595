import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const Notification = ({ identification, color, children, className = '' }) => {
  const [open, setOpen] = useState(!identification)
  const key = identification + '_dismissed'

  const bgColors = {
    cyan: 'bg-blue-5',
    blue: 'bg-blue-2',
    red: 'bg-danger-5 dark:bg-danger-70',
    yellow: 'bg-yellow-5',
    green: 'bg-green-2 dark:bg-green-80',
  }

  const borderColors = {
    cyan: 'border-blue-40',
    blue: 'border-blue-30',
    red: 'border-danger-20 dark:border-danger-50',
    yellow: 'border-yellow-30',
    green: 'border-green-40 dark:border-green-60',
  }

  const textColors = {
    cyan: 'text-blue-60',
    blue: 'text-blue-60',
    red: 'text-danger-80',
    yellow: 'text-yellow-720',
    green: 'text-green-90 dark:text-gray-5',
  }

  useEffect(() => {
    identification && setOpen(!JSON.parse(window.localStorage.getItem(key)))
  }, [])

  return (
    <div className={`w-full ${bgColors[color]} border ${borderColors[color]} rounded-lg p-8 relative ${open ? 'block' : 'hidden'} ${className}`}>
      {identification && (
        <FontAwesomeIcon
          icon={faTimes as IconProp}
          onClick={() => {
            window.localStorage.setItem(key, JSON.stringify(true))
            setOpen(false)
          }}
          className={`absolute right-3 top-3 cursor-pointer ${textColors[color]}`}
        />
      )}
      {children}
    </div>
  )
}
Notification.propTypes = {
  identification: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
}

export default Notification
