import React from 'react'
import PropTypes from 'prop-types'
import UnitRubric from '../unit-rubric'
import AbilityRubric from '../ability-rubric'
import Modal from '../modal'

const FFRubricModal = ({ id, open, setOpen, type }) => {
  return (
    <>
      <Modal isOpen={open} onClickClose={setOpen} className="w-[90rem]">
        {type === 'UNIT' && <UnitRubric id={id} />}
        {type === 'ABILITY' && <AbilityRubric id={id} />}
      </Modal>
    </>
  )
}

FFRubricModal.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool,
  type: PropTypes.string,
  setOpen: PropTypes.func,
}

export default FFRubricModal
