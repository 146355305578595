import * as React from 'react'
import { Tabs } from '@design-system'
import { Outlet } from 'react-router-dom'

function Element() {
  const tabs = [
    { title: 'Basic Setup', link: './basic-setup', },
    { title: 'Houses', link: './houses', },
    { title: 'Experiences', link: './experiences', },
    { title: 'Users', link: './users', },
    { title: 'Students', link: './students', },
    { title: 'Employees', link: './employees', },
    { title: 'Jobs', link: './jobs', },
  ]
  return (
    <>
      <nav>
        <Tabs>
          {tabs.map((tab) => (
            <Tabs.Item key={tab.title} title={tab.title} to={tab.link} />
          ))}
        </Tabs>
      </nav>
      <Outlet />
    </>
  )
}

export const WorkbenchStudentHearbeatServerRoute = { Element }