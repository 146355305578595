import React, { useState } from "react"
import { usePopper } from "react-popper"
import { Popover } from "@headlessui/react"
import type { Placement } from "@popperjs/core"
import useCurrentUser from "@hooks/useCurrentUser"
import { NotificationTabs, NotificationTabsText } from './types'

function filterTabAccordingToUserAndSelectedTab(userRole: string, selectedTab: NotificationTabs) {
  return (tab: NotificationTabs) => {
    return (tab !== selectedTab) && !((userRole === 'employee' || userRole === 'admin') && tab === NotificationTabs.REPORTS)
  }
}

type NotificationFilter = {
  onSelectTab: (tab: string) => void,
  selectedTab: NotificationTabs,
  showConfigurationIcon?: boolean,
  onClickSettingsIcon?: () => void,
  gridCols?: number,
  popoverPlacement?: Placement
}

export default function NotificationFilter({ popoverPlacement = 'bottom-end', gridCols = 1, onSelectTab, selectedTab, onClickSettingsIcon }: NotificationFilter) {
  const user = useCurrentUser()
  const [popperElement, setPopperElement] = useState<HTMLDivElement>()
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: popoverPlacement })

  const handleSelectTab = (tab: string, close: (focusableElement?: HTMLElement | React.MutableRefObject<HTMLElement>) => void) => {
    onSelectTab(tab)
    close()
  }

  const filterTab = filterTabAccordingToUserAndSelectedTab(user?.role, selectedTab)

  return (
    <header className={`grid grid-cols-1 lg:grid-cols-${gridCols} border-b-1 border-b-gray-30`}>
      <div className="flex justify-between w-full">
        <h3 className="font-bold border-b-2 pb-4 border-b-accent">{NotificationTabsText[selectedTab]}</h3>
        <div className="flex space-x-4">
          <img
            src="/assets/icons/configuration-icon.svg"
            className="cursor-pointer w-6 h-6"
            alt="Notifications settings icon"
            title="Notification Settings"
            onClick={onClickSettingsIcon}
          />
          <Popover>
            <Popover.Button ref={setReferenceElement} className="h-min">
              <img
                src="/assets/icons/filter-icon.svg"
                className="w-6 h-6"
                alt="Notifications filter icon"
                title="Filter Options"
              />
            </Popover.Button>
            <Popover.Panel
              className="z-10"
              ref={setPopperElement}
              style={{ ...styles.popper, filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25))' }}
              {...attributes.popper}
            >
              {({ close }) => (
                <ul className="rounded-lg grid items-center overflow-hidden bg-white">
                  {Object.values(NotificationTabs).filter(filterTab).map((value) => (
                    <li key={value} className="cursor-pointer flex space-x-3 first-of-type:py-5 last-of-type:py-5 px-4 py-4 hover:bg-gray-30" onClick={() => handleSelectTab(value, close)}>
                      <NotificationFilterIcon filterType={value} />
                      <span>
                        {NotificationTabsText[value]}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </Popover.Panel>
          </Popover>
        </div>
      </div>
    </header>
  )
}

type NotificationFilterIconProps = {
  filterType: NotificationTabs
}

function NotificationFilterIcon({ filterType }: NotificationFilterIconProps) {
  switch (filterType) {
    case NotificationTabs.ACADEMIC:
      return <img src="/assets/icons/users-icon.svg" className="w-6 h-6" />
    case NotificationTabs.REPORTS:
      return <img src="/assets/icons/paper-icon.svg" className="w-6 h-6" />
    case NotificationTabs.PRODUCT:
      return <img src="/assets/icons/flag-icon.svg" className="w-6 h-6" />
    case NotificationTabs.SCHOOL:
      return <img src="/assets/icons/academic-icon.svg" className="w-6 h-6" />
    case NotificationTabs.ALL:
      return <img src="/assets/icons/academic-icon.svg" className="w-6 h-6" />
    default:
      return null
  }
}

