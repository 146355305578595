import { useEffect, useRef, useState } from 'react'
import { useBlocker, useLocation } from 'react-router-dom'
import useConfirmModal from './useConfirmModal'
import serializeFormData from '@utils/serializeFormData'
import { REMOTE_STATE_KEY, getDiffsFromCommonKeys } from '@utils/formPersistence'

/**
 * @description
 * Hook to persist form data in local storage. This hook runs every 3 seconds and saves the form data in local storage if the content of the form has changed.
 * There are also some cleanup functions to remove the data from local storage when the form is submitted and also to update the "initial remote state" when the form is changed.
 * The form local data is saved using the pathname of the current route as the key. The Initial Remote State is saved using the pathname of the current route with the prefix "REMOTE_STATE_KEY".
 * Initial Remote State is the data that is saved in local storage when the form is first loaded. This is used to compare the current form data with the initial form data to determine if the form has been changed. 
 * It's also usefull to compare with the server remote state to determine if the data of the form has been changed on the database by another user.
 * Beware this hook only saves data in local storage, it does not retrieve it. This responsiblity should be delegated to the route loader.
 * @link See examples here. https://github.com/soraschools/home/wiki/Form-Data-Persistence
 * @param {Object} options
 * @param {boolean} options.disableBlocker - If true, the useBlocker will not be used to prevent the user from leaving the page if there are unsaved changes.
 * @returns React.MutableRefObject<HTMLFormElement>
 */
export function useFormPersistence({ disableBlocker }: { disableBlocker: boolean } = { disableBlocker: false }) {
  const location = useLocation()
  const confirm = useConfirmModal()
  const formRef = useRef<HTMLFormElement>()
  const [shouldRunDataUpdate, setShouldRunDataUpdate] = useState(false)
  const [initialData, setInitialData] = useState<Record<string, unknown>>({})
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => !disableBlocker && currentLocation.pathname !== nextLocation.pathname
  )

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const hasLocalData = !!window.localStorage.getItem(location.pathname)
      if (hasLocalData) {
        confirm({
          title: 'Are you sure you want to leave this page?',
          subtitle: 'You have unsaved changes. If you leave, your changes will be lost.',
          confirmLabel: 'Leave',
          cancelLabel: 'Cancel',
        }).then((confirm) => {
          if (confirm) {
            blocker.proceed()
            window.localStorage.removeItem(location.pathname)
            window.localStorage.removeItem(`${REMOTE_STATE_KEY}_${location.pathname}`)
          } else {
            blocker.reset()
          }
        })
      } else {
        blocker.proceed()
      }
    }
  }, [blocker.state])

  useEffect(() => {
    const formData = new FormData(formRef.current)
    const serializedData = serializeFormData(formData) as Record<string, unknown>
    setInitialData(serializedData)
  }, [shouldRunDataUpdate])

  useEffect(() => {
    const removeLocalStorageDataAndRevalidate = () => {
      window.localStorage.removeItem(location.pathname)
      window.localStorage.removeItem(`${REMOTE_STATE_KEY}_${location.pathname}`)
      setShouldRunDataUpdate(true)
    }
    const currentForm = formRef.current
    if (currentForm) {
      currentForm.addEventListener('submit', removeLocalStorageDataAndRevalidate)
    }
    return () => currentForm.addEventListener('submit', removeLocalStorageDataAndRevalidate)
  }, [formRef.current])

  useEffect(() => {
    const saveDataOnLocalStorage = () => {
      window.localStorage.setItem(`${REMOTE_STATE_KEY}_${location.pathname}`, JSON.stringify(initialData))
    }
    const currentForm = formRef.current
    if (currentForm) {
      currentForm.addEventListener('change', saveDataOnLocalStorage)
    }
    return () => currentForm.addEventListener('change', saveDataOnLocalStorage)
  }, [initialData])

  useEffect(() => {
    const interval = window.setInterval(() => {
      const formData = new FormData(formRef.current)
      const serializedData = serializeFormData(formData) as Record<string, unknown>
      const areEqual = getDiffsFromCommonKeys(serializedData, initialData).length === 0
      if (!areEqual) {
        window.localStorage.setItem(location.pathname, JSON.stringify(serializedData))
      }
    }, 3000)

    return () => window.clearInterval(interval)
  }, [initialData])

  return formRef
}

