import React from 'react'
import PropTypes from 'prop-types'
import { ButtonTile } from '@designsystem'
import MetricInnerButton from './metric-inner-button'

const MetricButton = ({ active, disabled, onClick, ...rest }) => {
  return (
    <ButtonTile tooltip="Open tasks" onClick={onClick} active={active} disabled={disabled}>
      <MetricInnerButton {...rest} />
    </ButtonTile>
  )
}

MetricButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  ...MetricInnerButton.propTypes
}

MetricButton.defaultProps = {
  active: false,
  disabled: false,
  onClick: null,
  ...MetricInnerButton.defaultProps
}

export default MetricButton
