import React, { useReducer, useCallback, useEffect } from "react"
import PropTypes from 'prop-types'
import { initialState, reducer, HIDE_CONFIRM } from "./reducer"
import ConfirmModalContext from "./context"
import { ConfirmModal } from "@design-system"
import { Keys } from '@utils/constants/keyboard'

export const ConfirmModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleClose = useCallback(() => {
    dispatch({ type: HIDE_CONFIRM })
  }, [dispatch])

  const onConfirm = useCallback(() => {
    state.promise?.resolve(true)
    handleClose()
  }, [handleClose, state.promise])

  const onCancel = useCallback(() => {
    state.promise?.resolve(false)
    handleClose()
  }, [handleClose, state.promise])

  const keyPressedFn = useCallback((e) => {
    if ([Keys.Enter, Keys.Escape].includes(e.key)) {
      e.stopImmediatePropagation()
      e.preventDefault()
      e.stopPropagation()
      if (e.key === Keys.Enter) {
        onConfirm()
      } else {
        onCancel()
      }
    }
  }, [onConfirm, onCancel])

  useEffect(() => {
    if (state.isOpen) {
      document.addEventListener('keydown', keyPressedFn, false)
    } else {
      document.removeEventListener('keydown', keyPressedFn, false)
    }
    return () => {
      document.removeEventListener('keydown', keyPressedFn, false)
    }
  }, [state.isOpen, keyPressedFn])

  return (
    <>
      <ConfirmModal {...state} onConfirm={onConfirm} onCancel={onCancel} />
      <ConfirmModalContext.Provider value={[state, dispatch]}>
        {children}
      </ConfirmModalContext.Provider>
    </>
  )
}

ConfirmModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
