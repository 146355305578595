import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import FlagItem from '@components/facilitate/feedback/flag-item'
import LevelCard from '@components/facilitate/assessment/level-card'
import SelfLevelUpSelect from '@components/submission/self-assessment/self-level-up-select'
import HistoryFeedbackList from '@components/facilitate/feedback/history-feedback-list'

const SelfAssessmentPickedItem = ({
  type,
  item,
  onRemove,
  onChange,
  onShowRubrics,
  disabled,
  isAssessed,
  showNumericValue=true
}) => {
  const [expanded, setExpanded] = useState(true)
  const [viewMode, _] = useState(false)

  const handleExpand = () => { setExpanded(!expanded) }

  const handleFlagChange = (flag) => {
    onChange(type, { ...item, ...{ flag } })
    setExpanded(flag !== 'not_yet_covered')
  }

  const handleRubricInfo = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onShowRubrics(type, item.id)
  }

  const handleLevelChange = (levelAssessed) => {
    onChange(type, { ...item, ...{ levelAssessed } })
  }

  const title = `${item.title}${item.topic_title ? ` (${item.topic_title})` : ''}`
  const studentLevel = item.student_has_self_assessment === undefined || item.student_has_self_assessment === true ? Number(item.student_level) : '-'
  const facultyLevel = item.faculty_has_assessed === undefined || item.faculty_has_assessed === true ? Number(item.faculty_level) : '-'

  return (
    <li className='flex flex-col border-b border-b-gray-30 py-4 gap-4 last:border-b-transparent'>
      <ReactTooltip delayShow={1000} />
      <div className='flex flex-row justify-between items-center gap-3'>
        <FlagItem selected={item.flag} removable={!item.picked} onChange={handleFlagChange} onRemove={onRemove} disabled={disabled || item.picked} hideNeedsImprovements={true} hideNotYetCovered={true} />
        <div className='flex-auto flex flex-row justify-between items-center gap-4 cursor-pointer' onClick={handleExpand}>
          <div className={`flex-auto flex flex-col w-0 text-sm ${!item.flag ? 'text-gray-40' : ''}`}>
            <span className='font-bold truncate' data-tip={title}>{title}</span>
            <div className='flex flex-row gap-2'>
              <span className="text-gray-60">Current Mastery: {item.level ? item.level : 'Not Yet Started'}</span>
              {typeof onShowRubrics === 'function' &&
                <span className='text-gray-70 cursor-pointer' onClick={handleRubricInfo}><FontAwesomeIcon icon={faCircleInfo} /></span>
              }
            </div>
          </div>
          <div className='whitespace-nowrap flex-0'>
            {(!viewMode && !disabled) &&
              <div className='flex flex-col'>
                <SelfLevelUpSelect onChange={handleLevelChange} selectedLevel={Number(item.levelAssessed ? item.levelAssessed : 0)} />
              </div>
            }
            {(viewMode || disabled) &&
              <div className='flex flex-row gap-4 justify-between'>
                <LevelCard title='My Reflection' level={studentLevel} className='bg-gray-50' showNumericValue={showNumericValue} />
                {isAssessed && <LevelCard title='Final Assessment' level={facultyLevel} className='bg-green-90' showNumericValue={showNumericValue} />}
              </div>
            }
          </div>
        </div>
      </div>
      <div>
        <HistoryFeedbackList list={item?.feedbacks} removeble={false} type={type} startExpanded={true} />
      </div>
    </li>
  )
}
SelfAssessmentPickedItem.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onShowRubrics: PropTypes.func,
  disabled: PropTypes.bool,
  isAssessed: PropTypes.bool,
  showNumericValue: PropTypes.bool,
}

export default SelfAssessmentPickedItem
