import React, { Fragment, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { debounce } from 'lodash'
import { useLoaderData, ActionFunctionArgs, Form, useSubmit, useNavigation, Outlet, useSearchParams, Link, useParams, useLocation } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { Typography, Pill, cn, Icon, Accordion, unstable_Tooltip as Tooltip, Button, FetcherForm, EmptyState, Spinner, DropdownMenu, useFetcher } from '@design-system'
import pluralize from '@utils/pluralize'
import { TextField } from '@designsystem'
import EmptyStateIllustration from './illustration.svg'
import { PillTopicStudentProgram, PillTopicStudentProgramVariant } from '@blocks/pill-topic-student-program'
import SoraLink from '@components/link'
import { MasterLabelValuesType, PillMasteryLabels } from '@blocks/pill-mastery-labels'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

interface LoaderData {
  canManageExemption: boolean
  canManageProgram: boolean
  subject: {
    id: number
    title: string
    creditDiff: number
    children: Record<number, {
      id: number
      title: string
      creditDiff: number
    }>
  }
  onProgramTopics: {
    id: number
    title: string
    label?: PillTopicStudentProgramVariant
    canRemove: boolean
    credit: number
    unitsTargetCount: number
    unitsCompletionsCount: number
    exemption?: Exemption
    units?: Unit[]
  }[]
  offProgramTopicsGroupedBySubject: {
    id: number
    title: string
    topics: {
      id: number
      title: string
      unitsTargetCount: number
      unitsCompletionsCount: number
      label?: PillTopicStudentProgramVariant
    }[]
  }[]
}
interface Exemption {
  id: number
  credit: number
  grade: number
  course: string
  school: string
  year: number
}

interface Unit {
  id: number
  title: string
  avgMastery?: MasterLabelValuesType
  isExempt: boolean
}

interface TopicDetailsProps {
  exemption?: Exemption
  units?: Unit[]
}

export async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.student_id}/program/${params.schoolStage}/${params.subjectId}?${searchParams.toString()}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.student_id}/program/${params.schoolStage}/${params.subjectId}`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  return data
}

function Element() {
  const { canManageProgram, canManageExemption, subject, onProgramTopics, offProgramTopicsGroupedBySubject } = useLoaderData() as LoaderData
  const hasMoreThanOneTopic = offProgramTopicsGroupedBySubject.length > 1
  const params = useParams()
  const location = useLocation()
  const isDetailsPage = location.pathname.includes('details')
  const navigate = useNavigateWithCycle()
  const [searchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '')
  const submit = useSubmit()
  const fetcher = useFetcher()
  const navigation = useNavigation()

  const handleNavigate = (id: number) => {
    const destination = params?.topicId && params?.topicId === id.toString() ? '.' : `details/${id}`
    navigate(destination)
  }

  const debouncedSubmit = useCallback(
    debounce((value) => {
      if (value !== searchParams.get('search') && (value || searchParams.get('search'))) {
        submit({ search: value }, { replace: true })
      }
    }, 500),
    [searchParams, submit]
  )

  useEffect(() => {
    setSearchTerm('')
  }, [params.subjectId])

  useEffect(() => {
    debouncedSubmit(searchTerm)
    return () => {
      debouncedSubmit.cancel()
    }
  }, [searchTerm, debouncedSubmit])

  return (
    <>
      <Accordion disabledScroll type="single" collapsible variant="inset">
        <Accordion.RootHeader>
          <Typography variant="heading-6" weight="bold">{subject.title} Program</Typography>
          {subject.creditDiff < 0 && (
            <Pill color="danger-60">
              <Pill.Value>Program Insufficient: Select {subject.creditDiff * -1} More Credits</Pill.Value>
            </Pill>
          )}
        </Accordion.RootHeader>
        {onProgramTopics.map(topic => (
          <Accordion.Item key={topic.id} value={String(topic.id)}>
            <Accordion.Header>
              <Accordion.Trigger>
                <Accordion.Icon />
                <Typography weight="bold">{topic.title}</Typography>
                {topic.label && (
                  <PillTopicStudentProgram variant={topic.label} />
                )}
                <span className="grow" />
                {topic.unitsCompletionsCount < topic.unitsTargetCount && (
                  <Tooltip content="Number of units student has completed towards this topic.">
                    <Pill variant="outlined" disabled={topic.unitsCompletionsCount === 0}>
                      <Pill.Value>{topic.unitsCompletionsCount}/{topic.unitsTargetCount}</Pill.Value>
                    </Pill>
                  </Tooltip>
                )}
                {topic.unitsCompletionsCount === topic.unitsTargetCount && topic.exemption?.credit !== topic.credit && (
                  <Tooltip content="Topic completed">
                    <Pill color="success-30">
                      <Pill.Value>Completed | {topic.credit} {pluralize(topic.credit, 'Credit')}</Pill.Value>
                    </Pill>
                  </Tooltip>
                )}
                {topic.exemption && (
                  <Tooltip content={topic.unitsCompletionsCount === topic.unitsTargetCount && "Topic completed outside of Sora."}>
                    <Pill variant={topic.unitsCompletionsCount === topic.unitsTargetCount ? 'contained' : 'outlined'} color={topic.unitsCompletionsCount === topic.unitsTargetCount ? 'success-30' : undefined}>
                      <Pill.Value>Exempt | {topic.exemption.credit} {pluralize(topic.exemption.credit, 'Credit')}</Pill.Value>
                    </Pill>
                  </Tooltip>
                )}
              </Accordion.Trigger>
              {canManageProgram && topic.canRemove && !canManageExemption && (
                <fetcher.Form method="post">
                  <input type="hidden" name="topicId" value={topic.id} />
                  <Tooltip content={Boolean(topic.exemption) ? "Topics with exemptions can’t be removed from the program" : "Remove topic from program"}>
                    <Button variant="outlined" size="xs" color="soft" disabled={Boolean(topic.exemption)} asChild>
                      <button type="submit" name="_action" value="remove_topic">
                        <Icon name="minus" size="xs" />
                      </button>
                    </Button>
                  </Tooltip>
                </fetcher.Form>
              )}
              {canManageExemption && (
                <DropdownMenu>
                  <DropdownMenu.Trigger asChild>
                    <Button variant="outlined" size="xs" color="soft">
                      <Icon name="more-horiz-filled" size="xs" />
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content className="mr-14">
                    {topic.label === 'external-elective' ? (
                      <DropdownMenu.Item className="hover:bg-neutral-100 w-full" asChild>
                        <Link to={`external-elective/${topic.id}`}>
                          Edit External Elective
                        </Link>
                      </DropdownMenu.Item>
                    ) : (
                      <DropdownMenu.Item className="hover:bg-neutral-100 w-full" asChild>
                        <Link to={`exemption/${topic.id}`}>
                          {topic.exemption ? 'Edit Exemption' : 'Add Exemption'}
                        </Link>
                      </DropdownMenu.Item>
                    )}
                    {topic.exemption && (
                      <fetcher.Form method="post" className="w-full">
                        <input type="hidden" name="topicId" value={topic.id} />
                        <input type="hidden" name="exemptionId" value={topic.exemption.id} />
                        <DropdownMenu.Item className="hover:bg-neutral-100 w-full" asChild>
                          <button type="submit" name="_action" value="delete_exemption">
                            {topic.label === 'external-elective' ? 'Delete External Elective' : 'Delete Exemption'}
                          </button>
                        </DropdownMenu.Item>
                      </fetcher.Form>
                    )}
                    {canManageProgram && topic.canRemove && (
                      <>
                        {topic.exemption ? (
                          <Tooltip content="Topics with exemptions can’t be removed from the program">
                            <DropdownMenu.Item className="opacity-50 pointer-events-none" asChild>
                              <button disabled>
                                Remove topic from program
                              </button>
                            </DropdownMenu.Item>
                          </Tooltip>
                        ) : (
                          <fetcher.Form method="post" className="w-full">
                            <input type="hidden" name="topicId" value={topic.id} />
                            <DropdownMenu.Item className="hover:bg-neutral-100 w-full" asChild>
                              <button type="submit" name="_action" value="remove_topic">
                                Remove topic from program
                              </button>
                            </DropdownMenu.Item>
                          </fetcher.Form>
                        )}
                      </>
                    )}
                  </DropdownMenu.Content>
                </DropdownMenu>
              )}
            </Accordion.Header>
            <Accordion.Content className="space-y-6">
              {topic.exemption && (
                <div className="space-y-4 pt-2">
                  <Typography weight="bold">Exemption</Typography>
                  <div className="pt-4 flex flex-wrap gap-x-12 lg:gap-x-20 gap-y-4 border-t-2 border-solid border-gray-30 dark:border-gray-90">
                    {Object.entries(topic.exemption).map(([label, value]) => (
                      <div className="flex flex-col gap-3" key={label}>
                        <Typography weight="bold" className="capitalize">{label}</Typography>
                        <Typography>{value}</Typography>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {topic.units?.length > 0 && (
                <div className="space-y-4 pt-2">
                  <Typography weight="bold">Units</Typography>
                  <ul>
                    {topic.units.map(({ id, title, avgMastery, isExempt }) => (
                      <li key={id} className="flex justify-between items-center gap-2 py-4 border-t-2 first:border-solid border-gray-30 dark:border-gray-90 border-dashed">
                        <Typography weight="bold">{title}</Typography>
                        <PillMasteryLabels value={avgMastery} isExempt={isExempt} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Accordion.Content>
          </Accordion.Item>
        ))}
        {onProgramTopics.length == 0 && (
          <EmptyState>
            <EmptyState.Illustration>
              <img src={EmptyStateIllustration} alt="Nothing to report" />
            </EmptyState.Illustration>
            <EmptyState.Title>No topics yet</EmptyState.Title>
            <EmptyState.Description>
              {`This subject doesn’t have any topics yet.\nAdd some from below!`}
            </EmptyState.Description>
          </EmptyState>
        )}
      </Accordion >

      {canManageExemption && (
        <Button asChild variant="ghost" color="soft" size="lg" className="w-full">
          <SoraLink to='external-elective/new'>
            <Icon name="plus" size="sm" />
            Add External {subject.title}
          </SoraLink>
        </Button>
      )}

      {(offProgramTopicsGroupedBySubject.length > 0 || searchTerm.length > 0) && (
        <Accordion disabledScroll type="single" collapsible variant="inset">
          <Accordion.RootHeader>
            <Typography variant="heading-6" weight="bold" className="grow">Off Program</Typography>
            <Form onSubmit={e => e.preventDefault()}>
              <TextField onChange={(e) => setSearchTerm(e.currentTarget.value)} value={searchTerm} name="search" placeholder="Search" startAdornment={navigation.state === 'loading' ? <Spinner size="sm" /> : <Icon name="search" />} />
            </Form>
          </Accordion.RootHeader>
          {offProgramTopicsGroupedBySubject.map(sub => (
            <Fragment key={sub.id}>
              {hasMoreThanOneTopic && (
                <div className="border-t border-gray-20 dark:border-gray-90 px-6 py-4 flex gap-3 items-center bg-gray-2 dark:bg-gray-98">
                  <Typography weight="bold">{sub.title}</Typography>
                  {subject.children[sub.id]?.creditDiff < 0 && (
                    <Pill color="warning-20">
                      <Pill.Value>Select {subject.children[sub.id]?.creditDiff * -1} Credits</Pill.Value>
                    </Pill>
                  )}
                </div>
              )}
              {sub.topics.map(topic => (
                <Accordion.Item key={topic.id} value={String(topic.id)}>
                  <Accordion.Header className={cn(hasMoreThanOneTopic && "!pl-14")}>
                    <Accordion.Trigger onClick={() => handleNavigate(topic.id)}>
                      <Accordion.Icon />
                      <Typography weight="bold">{topic.title}</Typography>
                      {topic.label && (
                        <PillTopicStudentProgram variant={topic.label} />
                      )}
                      <span className="grow" />
                      <Pill variant="outlined" disabled={topic.unitsCompletionsCount === 0}>
                        <Pill.Value>{topic.unitsCompletionsCount}/{topic.unitsTargetCount}</Pill.Value>
                      </Pill>
                    </Accordion.Trigger>
                    {canManageProgram && (
                      <FetcherForm method="post">
                        {({ fetcher }) => (
                          <>
                            <input type="hidden" name="topicId" value={topic.id} />
                            <Tooltip content="Add topic to program">
                              <Button type="submit" name="_action" value="add_topic" variant="outlined" color="soft" size="sm" loading={fetcher.state !== 'idle'}>
                                <Icon name="plus" size="sm" />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </FetcherForm>
                    )}
                  </Accordion.Header>
                  <Accordion.Content className={cn("space-y-4", hasMoreThanOneTopic && "!pl-14")}>
                    <Outlet />
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Fragment>
          ))}
          {offProgramTopicsGroupedBySubject.length === 0 && searchTerm.length > 0 && (
            <div className="border-t border-gray-20 dark:border-gray-90 px-6 py-4 flex gap-2 items-center justify-center">No results found.</div>
          )}
        </Accordion>
      )}
      {!isDetailsPage && (
        <Outlet />
      )}
    </>
  )
}

export const EmployeeStudentProgramSubjectRoute = {
  Element,
  loader,
  action
}


