import React from 'react'
import { useLoaderData, useSearchParams } from 'react-router-dom'
import _debounce from 'lodash/debounce';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import SoraLink from '@components/link'
import { Button } from '@designsystem';
import { ExperienceStudentsReportTable } from './report-table'
import type { TExperienceStudentRow, AvailableFilters } from './types'

export default function ExpeditionsReportsRoutes() {
  const data = useLoaderData() as TExperienceStudentRow[]
  return (
    <div className="flex flex-col overflow-x-auto p-2 gap-y-3 min-h-[550px]">
      <div className="flex items-center gap-x-3">
        <Filters />
        <ExportCsvBtn />
      </div>
      <ExperienceStudentsReportTable data={data} />
    </div>
  )
}

function Filters() {
  const [searchParams, setSearchParams] = useSearchParams()
  const onChangeFilter = _debounce((value: string, filterName: AvailableFilters) => {
    setSearchParams((sp: URLSearchParams) => {
      if (!value) {
        sp.delete(filterName);
      } else {
        sp.set(filterName, value);
      }

      sp.delete('page');
      return sp;
    });
  }, 300)

  return (
    <div className="flex flex-col rounded-xl max-h-24 leading-none space-y-2 w-1/2">
      <input
        placeholder="Search for experience or student..."
        type="search"
        className="border px-4 py-[8px] border-gray-20 placeholder:text-gray-60 rounded-lg shadow text-black"
        defaultValue={searchParams.get('search')}
        onChange={(e) => {
          onChangeFilter(e.target.value, 'search')
        }}
      />
    </div>
  )
}

function ExportCsvBtn() {
  return (
    <div title="Download as CSV">
      <Button
        as={SoraLink}
        to={`/api/pages/admin/reports/experiences-roster?${useSearchParams()[0].toString()}&output=csv`}
        target='_blank'
        role="link"
        variant="outlined"
        size="sm"
        startIcon={faDownload}
      />
    </div>
  )
}
