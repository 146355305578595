import React, { PropsWithChildren } from 'react'
import { Typography } from '@design-system'

export type MasterLabelValuesType = 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4

enum MasteryLabelsEnum {
  NOT_STARTED = 'Not started',
  ATTEMPTED = 'Attempted',
  EXPOSURE = 'Exposure',
  DEVELOPING = 'Developing',
  DEMONSTRATING = 'Demonstrating',
  EXTENDING = 'Extending',
}

const LabelValues: Record<MasterLabelValuesType, MasteryLabelsEnum> = {
  0: MasteryLabelsEnum.NOT_STARTED,
  0.5: MasteryLabelsEnum.ATTEMPTED,
  1: MasteryLabelsEnum.EXPOSURE,
  1.5: MasteryLabelsEnum.EXPOSURE,
  2: MasteryLabelsEnum.DEVELOPING,
  2.5: MasteryLabelsEnum.DEVELOPING,
  3: MasteryLabelsEnum.DEMONSTRATING,
  3.5: MasteryLabelsEnum.DEMONSTRATING,
  4: MasteryLabelsEnum.EXTENDING,
}


interface PillMasteryLabelsProps extends PropsWithChildren {
  value?: MasterLabelValuesType
  hideValue?: boolean
  isExempt?: boolean
}

/**
 * PillMasteryLabels display a pill with student progress
 */
export const PillMasteryLabels = ({ value, hideValue, isExempt }: PillMasteryLabelsProps) => {
  const masteryValue = value ?? 0
  return (
    <Typography>
      {isExempt ? 'Exempt' : `${hideValue ? '' : `${masteryValue} - `} ${LabelValues[masteryValue]}`}
    </Typography>
  )
}
