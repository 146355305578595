import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Listbox, RadioGroup } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, useActionData, useLoaderData, useNavigation } from 'react-router-dom'
import { faChevronDown, faClose } from '@fortawesome/free-solid-svg-icons'
import { Button, TextField } from '@designsystem'
import { useThisPageTitle } from '@hooks/usePageTitle'
import serializeFormData from '@utils/serializeFormData'
import Notification from '@components/notifications/notification'
import SlateTextarea, { RenderedSlate } from '@components/forms/slate-textarea'
import { useToast } from '@hooks/useToast'

const DEFAULT_CHARACTER_AMOUNT = 120
const NOTIFICATION_REDIRECT_URL_DEFAULT_VALUE = 'https://'
const NOTIFICATION_CLICK_BEHAVIOR = {
  REDIRECT: 'REDIRECT',
  SHOW_CONTENT: 'SHOW_CONTENT'
}

const INITIAL_PREVIEW_CONTENT_STATE = {
  title: '',
  body: '',
  redirect_url: '',
  created_at: Date.now(),
  onClick: () => null,
  type: "SCHOOL",
  onDeleteNotification: () => null,
  read: false
}

export default function NotificationsWorkbenchRoute() {
  useThisPageTitle('Notification Workbench')
  const slateRef = useRef()
  const actionData = useActionData()
  const { result: targets } = useLoaderData()
  const navigation = useNavigation()
  const [slateContent, setSlateContent] = useState()
  const [selectedTargets, setSelectedTargets] = useState([])
  const [charactersLeft, setChractersLeft] = useState(DEFAULT_CHARACTER_AMOUNT)
  const [previewContent, setPreviewContent] = useState(INITIAL_PREVIEW_CONTENT_STATE)
  const [notificationClickBehavior, setNotificationClickBehavior] = useState(NOTIFICATION_CLICK_BEHAVIOR.REDIRECT)
  useToast(actionData)
  useEffect(() => {
    if (actionData?.resetForm) {
      document.getElementById('notification_form').reset()
      setSelectedTargets([])
      setSlateContent(undefined)
      setPreviewContent(INITIAL_PREVIEW_CONTENT_STATE)
      setNotificationClickBehavior(NOTIFICATION_CLICK_BEHAVIOR.REDIRECT)
    }
  }, [actionData])


  const updatePreviewContent = (name, value) => {
    setPreviewContent((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const normalizeURL = (e) => {
    let url = e.target.value.replaceAll('http://', '').replaceAll('https://', '')
    e.target.value = `https://${url}`
  }

  const characterCounter = (currentLength) => setChractersLeft(DEFAULT_CHARACTER_AMOUNT - currentLength)

  const selectedTargetsValues = selectedTargets.map(({ value }) => value)
  const availableTargets = targets.filter((target) => !selectedTargetsValues.includes(target.value))

  return (
    <div className="grid grid-cols-2 gap-9 bg-white p-10">
      <Form className="flex flex-col w-full" method="post" id="notification_form">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold">Notifications</h1>
            <h3 className="text-lg">This is a space to send manual notifications to guardians, students and employees.</h3>
          </div>
          <TextField id="type" name="type" as="select" label="Notification type" defaultValue="SCHOOL" onChange={(e) => updatePreviewContent(e.target.name, e.target.value)}>
            <option value="SCHOOL">Schools Announcements</option>
            <option value="PRODUCT">Product Updates</option>
          </TextField>
          <Listbox name="targets" value={selectedTargets} multiple onChange={setSelectedTargets}>
            <Listbox.Label>Notification Recipients</Listbox.Label>
            <Listbox.Button className="flex justify-between items-center bg-white text-gray-90 border border-gray-50 hover:(bg-gray-30 text-gray-70) focus-within:(ring-2 ring-blue-30 border-gray-50) px-3 min-h-12 rounded-lg">
              <div className="flex space-x-3 flex-wrap">
                {selectedTargets.map(({ name, value }) => (
                  <div
                    key={value}
                    className="flex items-center border border-gray-10 px-3 py-1 rounded-md"
                    onClick={(e) => {
                      e.stopPropagation()
                      setSelectedTargets((prevState) => [...prevState.filter(({ value: prevValue }) => prevValue !== value)])
                    }}>
                    <span>{name}</span>
                    <Button as="span" startIcon={faClose} size="xs" variant="nude" />
                  </div>
                ))}
              </div>
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            </Listbox.Button>
            <Listbox.Options className="bg-white mt-[-10px] relative max-h-[200px] overflow-auto" style={{ filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25))' }}>
              {availableTargets.map((target) => (
                <Listbox.Option key={target.value} value={target} className="py-2 px-4 cursor-pointer hover:bg-gray-10">
                  {target.name}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
          <TextField id="title" name="title" label="Title" required={true} onChange={(e) => updatePreviewContent(e.target.name, e.target.value)} />
          <TextField id="body" name="body" label="Subtitle" as="textarea" required={true} helperText={`${charactersLeft} characters left`} maxLength={DEFAULT_CHARACTER_AMOUNT}
            onChange={(e) => {
              characterCounter(e.target.value.length)
              updatePreviewContent(e.target.name, e.target.value)
            }} />
          <fieldset className="flex flex-row bg-white border border-gray-50 rounded-md p-3 space-x-6 items-center">
            <legend>Click Behavior - How do you wish this notification to behave on click?</legend>
            <RadioGroup onChange={setNotificationClickBehavior} defaultValue={NOTIFICATION_CLICK_BEHAVIOR.REDIRECT}>
              <RadioGroup.Option value={NOTIFICATION_CLICK_BEHAVIOR.REDIRECT}>
                {({ active, checked }) => (
                  <div className="flex space-x-1">
                    <span
                      className={`h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center 
                      ${checked ? 'bg-black border-transparent' : 'bg-white border-gray-20'}
                      ${active ? 'ring-2 ring-offset-2 ring-black' : ''}`}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label>Launch an internal or external link</RadioGroup.Label>
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value={NOTIFICATION_CLICK_BEHAVIOR.SHOW_CONTENT}>
                {({ active, checked }) => (
                  <div className="flex space-x-1">
                    <span
                      className={`h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center 
                      ${checked ? 'bg-black border-transparent' : 'bg-white border-gray-20'}
                      ${active ? 'ring-2 ring-offset-2 ring-black' : ''}`}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label>Show Content on click</RadioGroup.Label>
                  </div>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          </fieldset>
          {notificationClickBehavior === NOTIFICATION_CLICK_BEHAVIOR.SHOW_CONTENT &&
            <>
              <label htmlFor="contentSlate">Content</label>
              <SlateTextarea id="contentSlate" ref={slateRef} value={slateContent} onChange={setSlateContent} placeholder="Testing slate" />
              <input type="hidden" name="content" value={JSON.stringify(slateContent)} />
            </>
          }
          {notificationClickBehavior === NOTIFICATION_CLICK_BEHAVIOR.REDIRECT &&
            <TextField id="mobile_redirect_url" name="mobile_redirect_url" label={<>
              <h2>Redirect Url</h2>
              <h6 className="opacity-70 text-xs">Use https as http is not supported</h6>
            </>}
              pattern="https://.*" placeholder="https://your-link.com"
              onChange={(e) => {
                updatePreviewContent(e.target.name, e.target.value)
                normalizeURL(e)
              }}
              defaultValue={NOTIFICATION_REDIRECT_URL_DEFAULT_VALUE}
            />
          }
          <TextField id="cta_text" name="cta_text" label="Button Label (optional - add button only for notifications that need explicit time bound actions)" onChange={(e) => updatePreviewContent(e.target.name, e.target.value)} />
          <fieldset className="flex gap-2 w-full">
            <TextField id="image_url" name="image_url" label="Image Url" fullWidth onChange={(e) => updatePreviewContent(e.target.name, e.target.value)} helperText={
              <>
                <span>Pro tip: use images from <a href="https://unsplash.com/" target="_blank" rel="noreferrer" className="underline font-bold" tabIndex={-1}>unsplash.</a></span>
                <span className="mt-1">Find the desired image, right click it, then select "Copy image address".</span>
                <span className="mt-1">After that, just paste the link in the input above.</span>
              </>
            } placeholder="Unsplash image link" />
            <TextField id="image_alt" name="image_alt" label={<span className="flex space-x-2"><span>Image description</span><i className="flex items-center justify-center rounded-full w-5 h-5 bg-gray-30 cursor-pointer" title="Image description in case the image isn't loaded (image alt)"><small>?</small></i></span>} fullWidth onChange={(e) => updatePreviewContent(e.target.name, e.target.value)} />
          </fieldset>
          <div className="flex flex-row justify-end">
            <Button type="submit" loading={navigation.state === 'submitting'}>Send</Button>
          </div>
        </div>
      </Form>
      <section>
        <h2 className="text-xl font-bold">Notification preview</h2>
        <ul>
          <Notification.Item {...previewContent} />
        </ul>
        {notificationClickBehavior === NOTIFICATION_CLICK_BEHAVIOR.SHOW_CONTENT &&
          <>
            <h2 className="text-xl font-bold">Notification content preview</h2>
            <RenderedSlate value={slateContent} placeholder="Type on the content field to see real time changes on this component." />
          </>
        }
      </section>
    </div>
  )
}

export const loader = async () => {
  const result = await axios.get('/pages/admin/workbench/notifications/cohorts')
  return result?.data
}

export const action = async ({ request }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  switch (serializedFormData.type) {
    case 'PRODUCT':
      serializedFormData.category = 'product_updates'
      break
    default:
      serializedFormData.category = 'announcements'
      break
  }

  const parsedRedirectUrl = serializedFormData.redirect_url === NOTIFICATION_REDIRECT_URL_DEFAULT_VALUE ? null : serializedFormData.redirect_url
  serializedFormData.redirect_url = parsedRedirectUrl

  try {
    await axios.post('/pages/admin/workbench/notifications', serializedFormData)
    return {
      resetForm: true,
      toast: { message: 'Notification created!', appearance: 'success' }
    }
  } catch (error) {
    console.error(error)
    return {
      toast: { message: 'Error creating notification.', appearance: 'error' }
    }
  }
}
