import useSWR from 'swr'
import useCurrentUser from './useCurrentUser'

const useUserPermissions = () => {
  const { data, error } = useSWR(`/permissions/user`)
  const userPermissions = data?.result || []
  const currentUser = useCurrentUser()

  const hasPermission = key => currentUser?.role === 'admin' || userPermissions.some(permission => permission.key === key)

  return {
    isLoading: !data?.result,
    isError: error,
    hasPermission,
  }
}

export default useUserPermissions
