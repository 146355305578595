import React from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router-dom'
import { Button, Icon } from '@design-system'
import { CyclePlanInput } from './cycle-plan-input'
import serializeFormData from '@utils/serializeFormData'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import { BasicDetails } from './basic-details'

const loader = async ({ params, request }) => {
  const { pathwayId } = params

  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'
  const planId = searchParams.get('planId')

  const result = await axios.get(`/backoffice/workbench/pathways/management/${pathwayId}`, { params: { action, planId } })
  return result?.data
}

const action = async ({ params, request }) => {
  const { pathwayId } = params
  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  try {
    const result = await axios.post(`/backoffice/workbench/pathways/management/${pathwayId}`, { ...serializedFormData, action })
    return  result?.data
  } catch (error) {
    return {
      toast: { message: 'There was an error running this action. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const navigate = useNavigateWithCycle()
  const { pathway, plan, templates, types } = useLoaderData()

  const handleDone = (e) => {
    e.preventDefault()
    navigate('../')
  }

  return (
    <div className='flex flex-row justify-around'>
      <div className='flex flex-col min-w-[400px] w-1/2 p-2'>
        <div className='flex flex-row border-b gap-2 items-center'>
          <Button variant='ghost' onClick={handleDone} size='sm'>
            <Icon name='arrow-left' />
          </Button>
          <h1 className='font-bold text-2xl py-2'>Pathway: {pathway.title}</h1>
        </div>
        <BasicDetails pathway={pathway} types={types} />
        <div className='flex flex-col p-2 gap-3 border-t border-t-alpha/30 border-b border-b-alpha/30'>
          {plan.map((item, index) => (
            <CyclePlanInput 
              key={`cycle_plan_${index}_${item.cycle_position}`} 
              planId={item.id} 
              index={index} 
              position={item.cycle_position} 
              templateId={item.template_id} 
              templateTitle={item.template_title} 
              units={item.units} 
              templates={templates}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const WorkbenchPathwaysManagementPathwayIdRoute  = {
  loader,
  action,
  Element,
}
