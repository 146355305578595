import { useState } from 'react'

const useToggle = (initialValue) => {
  const [isOn, setIsOn] = useState(initialValue)

  const toggler = (value) => {
    if (typeof value === 'boolean')
      setIsOn(value)
    else
      setIsOn(prev => !prev)
  }
  return [isOn, toggler]
}

export default useToggle
