import React from 'react'
import { Student } from './types'
import { CopyButton } from './copy-button'
import { Table } from '@tanstack/react-table'

interface HeaderGuardiansProps {
  table: Table<Student>
}

export const HeaderGuardians = ({ table }: HeaderGuardiansProps) => {
  const content = table.getRowModel().rows.flatMap((row) => row.original.guardians.map(guardian => guardian.email)).join(', ')
  return (
    <CopyButton content={content} tooltip="Copy emails">
      Guardians
    </CopyButton>
  )
}
