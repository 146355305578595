export const SUBJECT_BG_COLORS = {
  0: 'bg-blue-70',
  1: 'bg-yellow-30',
  2: 'bg-green-40',
  3: 'bg-orange-20',
  4: 'bg-danger-30',
  5: 'bg-blue-30',
  6: 'bg-cyan-40',
  7: 'bg-purple-20',
  8: 'bg-pink-20',
  9: 'bg-blue-50',
  10: 'bg-blue-70',
  17: 'bg-blue-30',
  18: 'bg-green-40',
}

export const SUBJECT_TEXT_COLORS = {
  0: 'text-blue-70',
  1: 'text-yellow-30',
  2: 'text-green-40',
  3: 'text-orange-20',
  4: 'text-danger-30',
  5: 'text-blue-30',
  6: 'text-cyan-40',
  7: 'text-purple-20',
  8: 'text-pink-20',
  9: 'text-blue-50',
  10: 'text-blue-70',
  17: 'text-blue-30',
  18: 'text-green-40',
}

export const SUBJECT_BORDER_COLORS = {
  0: 'text-blue-70',
  1: 'text-yellow-30',
  2: 'text-green-40',
  3: 'text-orange-20',
  4: 'text-danger-30',
  5: 'text-blue-30',
  6: 'text-cyan-40',
  7: 'text-purple-20',
  8: 'text-pink-20',
  9: 'text-blue-50',
  10: 'text-blue-70',
  17: 'text-blue-30',
  18: 'text-green-40',
}

export const getSubjectColors = subject_id => ({
  bg: SUBJECT_BG_COLORS[subject_id] || 'bg-blue-70',
  text: SUBJECT_TEXT_COLORS[subject_id] || 'text-blue-70',
  border: SUBJECT_BORDER_COLORS[subject_id] || 'border-blue-70',
})
