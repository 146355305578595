import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, useFetcher } from '@design-system'

export const PendingJobsSync = ({ isProcessing = false }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state === 'loading' || isProcessing

  return (
    <fetcher.Form method='post'>
      <input type="hidden" name="_action" value="resync_pending_jobs" />
      <Typography variant="body" color='danger'>Only execute this if you're sure that you cleaned up all messages from the queues:</Typography>
      <Button type='submit' loading={isLoading} disabled={isLoading}>
        Re-sync pending jobs
      </Button>
    </fetcher.Form>
  )
}
PendingJobsSync.propTypes = {
  cycleId: PropTypes.number.isRequired,
  isProcessing: PropTypes.bool,
}
