import useSWR from 'swr'

export const useProgressReport = (student_id, cycle_id) => {
  const { data, error, mutate } = useSWR(student_id ? `/reports/progress-report?student_id=${student_id}&cycle_id=${cycle_id}` : null)

  return {
    progressReports: data?.reports,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}
