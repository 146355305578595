/* eslint-disable react/prop-types */
import React from 'react'
// import useToggle from '@hooks/useToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import UnitsAbilitiesFeedback from '@components/facilitate/feedback/units-abilities-feedback'
import FeedbackSummaryCard from '@components/formative-feedback/feedback-summary-card'
import CommentThread from '@components/comment-thread'
import { ON_TRACK, NEEDS_IMPROVEMENT, NOT_COVERED, LABELS, ICONS, BACKGROUND_COLORS, BORDER_COLORS } from "@utils/constants/formativeFeedback"
import Metrics from './metrics'

const MidCycleReport = ({ report, cyclePeriodTitle, isExpanded, toggleIsExpanded, selectedFeedbackFlag, onSelectFeedbackFlag }) => {
  const {
    theme_id, theme_title, employee_name, student_id, task_submission_id, task_id,
    units_needs_improvement, units_not_yet_covered, units_ontrack,
    abilities_needs_improvement, abilities_not_yet_covered, abilities_ontrack
  } = report || {}

  const canExpand = units_needs_improvement || abilities_needs_improvement || units_not_yet_covered || abilities_not_yet_covered || units_ontrack || abilities_ontrack

  return (
    <div key={theme_id} className="flex flex-col w-full border border-gray-60 bg-white rounded-lg p-10 text-black space-y-8">
      <div className="flex flex-col">
        <div className='flex flex-row gap-2'>
          <span className="font-bold text-2xl">{theme_title}</span>
          <span className="text-2xl">{cyclePeriodTitle ? `(${cyclePeriodTitle})` : ''}</span>
        </div>
        <span>{employee_name}</span>
      </div>
      <div className="flex gap-6">
        <div className="min-w-[320px]">
          <Metrics {...report} />
        </div>
        <CommentThread
          taskId={String(task_id)}
          studentId={String(student_id)}
          taskSubmissionId={String(task_submission_id)}
          readOnly
          collapsable={false}
          className='border-t border-b border-t-gray-30 border-b-gray-30 flex-auto py-2'
        />
      </div>
      <div className="flex flex-col w-full gap-5">
        <div className="flex space-x-5 w-full">
          <FeedbackSummaryCard
            title={LABELS[ON_TRACK]}
            emoji={ICONS[ON_TRACK]}
            units={units_ontrack}
            abilities={abilities_ontrack}
            className={`${BACKGROUND_COLORS[ON_TRACK]} border border-transparent ${selectedFeedbackFlag === ON_TRACK ? BORDER_COLORS[ON_TRACK] : ''}`}
          />
          <FeedbackSummaryCard
            title={LABELS[NEEDS_IMPROVEMENT]}
            emoji={ICONS[NEEDS_IMPROVEMENT]}
            units={units_needs_improvement}
            abilities={abilities_needs_improvement}
            className={`${BACKGROUND_COLORS[NEEDS_IMPROVEMENT]} border border-transparent ${selectedFeedbackFlag === NEEDS_IMPROVEMENT ? BORDER_COLORS[NEEDS_IMPROVEMENT] : ''}`}
          />
          <FeedbackSummaryCard
            title={LABELS[NOT_COVERED]}
            emoji={ICONS[NOT_COVERED]}
            units={units_not_yet_covered}
            abilities={abilities_not_yet_covered}
            className={`${BACKGROUND_COLORS[NOT_COVERED]} border border-transparent ${selectedFeedbackFlag === NOT_COVERED ? BORDER_COLORS[NOT_COVERED] : ''}`}
          />
          {
            !!canExpand &&
            <button className="flex rounded-full bg-gray-10 px-4 py-3 items-center my-auto" onClick={toggleIsExpanded}>
              <FontAwesomeIcon className="mx-auto" icon={isExpanded ? faArrowUp : faArrowDown} />
            </button>
          }
        </div>
        {
          isExpanded &&
          <UnitsAbilitiesFeedback apiUrl={`/experiences/${theme_id}/student/${student_id}/units_abilities`} expanded={true} />
        }
      </div>
    </div>
  )
}

export default MidCycleReport
