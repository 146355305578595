import React from 'react'
import PropTypes from 'prop-types'
import HorizontalLevelIndicator from '@designsystem/HorizontalLevelIndicator'

export default function MasteryBar({ level }) {
  return <HorizontalLevelIndicator filled={level} size={4} />
}

MasteryBar.propTypes = {
  level: PropTypes.number.isRequired,
}