import { useCallback, useState } from 'react'

type CopyStatus = 'inactive' | 'copied' | 'failed'

// a hook for copying items to the users clipboard for easy copy and paste
const useClipboard = () => {
  const [copyStatus, setCopyStatus] = useState<CopyStatus>('inactive')

  const copy = useCallback((text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => setCopyStatus('copied'))
      .catch(() => setCopyStatus('failed'))
  }, [])

  return [copyStatus, copy] as const
}

export default useClipboard
