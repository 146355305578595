import React, { forwardRef, useState } from 'react'
import Select from '../select'
import matchMultipleProps from '../../../utils/filter/matchMultipleProps'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import alphabetically from '../../../utils/sort/alphabetically'

const SelectGuardiansProps = {
  guardians: PropTypes.array,
  selected_ids: PropTypes.array,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  selectAllEnabled: PropTypes.bool,
  readOnlyNode: PropTypes.any,
  onClickReadOnly: PropTypes.func,
  onClickGroup: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.any,
  id: PropTypes.any,
  isDisabled: PropTypes.bool
}

const SelectGuardians = forwardRef(function ({
  guardians = [],
  selected_ids,
  onChange,
  isMulti,
  onClickGroup,
  selectAllEnabled,
  ...rest
}, ref) {
  let options = guardians
    .map(guardian => ({
      ...guardian, label: `${guardian.first_name} ${guardian.last_name}`, value: guardian.id
    }))
    .sort(alphabetically({ by: 'label' }))

  if (isMulti && selectAllEnabled) options = [{ label: 'All Guardians', value: 'ALL' }, ...options]

  const value = selected_ids.map(id => options.find(option => option.id === id))

  const searchFunction = debounce((_, callback) => {
    const searchedOptions = options.filter(matchMultipleProps(
      inputValue,
      ['first_name', 'last_name', 'program_title']
    ))
    callback(searchedOptions)
  }, 500)

  const [inputValue, setInputValue] = useState()
  const onInputChange = (query, { action }) => {
    if (action !== 'set-value') setInputValue(query)
    return inputValue
  }

  const onChangeEvent = (selectedGuardiansParam, object) => {
    if (isMulti) {
      if (selectAllEnabled) {
        const selectedGuardian = selectedGuardiansParam[0]
        if (selectedGuardian?.value === 'ALL') {
          onChange(options.map(option => option.id), options, object)
          return
        }
      } else {
        const selectedGuardians = selectedGuardiansParam || []
        const orderedselectedGuardians = options
          .filter(option => selectedGuardians.some((selectedGuardian) => selectedGuardian.id === option.id))
        onChange(orderedselectedGuardians.map(option => option.id), orderedselectedGuardians, object)
      }
    } else {
      const selectedGuardian = selectedGuardiansParam || value[0]
      onChange(selectedGuardian.id, selectedGuardian, object)
    }
    setInputValue('')
    setInputValue(inputValue)
  }

  const onClickGroupEvent = (groupGuardians) => {
    const groupGuardianIds = groupGuardians.map((guardian) => guardian.id)
    const allSelectedGuardiansIds = Array.from(new Set([...selected_ids, ...groupGuardianIds]))
    const orderedSelectedGuardians = options
      .filter(option => allSelectedGuardiansIds.some((guardianId) => guardianId === option.id))
    onClickGroup(orderedSelectedGuardians.map(option => option.id), orderedSelectedGuardians)
  }

  return <Select
    isClearable={true}
    options={options}
    searchFunction={searchFunction}
    isMulti={isMulti}
    value={value}
    placeholder="Type to search guardians"
    onChange={onChangeEvent}
    blurInputOnSelect={false}
    closeMenuOnSelect={false}
    inputValue={inputValue}
    onInputChange={onInputChange}
    GroupHeaderComponent={data => (
      <div
        className={`flex content-center justify-between text-md font-bold 
          ${onClickGroup ? 'cursor-pointer' : ''}`}
        onClick={() => onClickGroupEvent(data.options)}>
        <span>{data?.label}</span>
        <span className="bg-gray-10 h-4 w-4 rounded-full text-center align-middle">
          {data?.options.length}
        </span>
      </div>
    )}
    ref={ref}
    {...rest}
  />
})

SelectGuardians.displayName = 'SelectGuardians'

SelectGuardians.defaultProps = {
  onClickGroup: () => null,
  selected_ids: [],
  selectAllEnabled: false,
}

SelectGuardians.propTypes = SelectGuardiansProps

export default SelectGuardians
