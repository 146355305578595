import React from 'react'
import axios from 'axios'
import { Link, Outlet, useLoaderData, useOutletContext, useParams } from 'react-router-dom'

export function useCampusData() {
  return useOutletContext<CampusOption[]>()
}

async function loader() {
  const res = await axios.get('/backoffice/workbench/onboarding/families')
  return res.data
}

export interface CampusOption {
  value: number,
  label: string,
}

function Element() {
  const campus = useLoaderData() as CampusOption[]
  const params = useParams()

  return (
    <div className="flex flex-col">
      <div className="space-x-3 flex">
        {campus?.map((filter) => (
          <Link
            key={filter.value}
            to={String(filter.value)}
            className={`${params.campus_filter === String(filter.value) ? 'bg-black border-gray-100 text-white' : 'bg-gray-10 border-gray-10 text-gray-100'}  rounded-lg border px-5 py-3 font-bold`}
          >
            {filter.label}
          </Link>
        ))}
      </div>
      <Outlet context={campus} />
    </div>
  )
}

export const WorkbenchOnboardingFamiliesReportRoute = {
  loader,
  Element
}