import * as React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { createPortal } from 'react-dom'

import 'react-datepicker/dist/react-datepicker.css'
import './date-time-picker.css'
import { cn } from '@design-system'

interface DateTimePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string
  error: boolean
  defaultValue: string
  dateFormat: string
}

export function DateTimePicker({
  name,
  error = false,
  defaultValue,
  dateFormat = 'Pp',
  ...rest
}: DateTimePickerProps) {
  const [localState, setLocalState] = React.useState(defaultValue ? new Date(defaultValue).toISOString() : new Date().toISOString())

  return <>
    <input type="hidden" name={name} value={localState} />
    <ReactDatePicker
      popperContainer={({ children }) => createPortal(children, document.body)}
      showTimeSelect={true}
      filterTime={() => true}
      className={cn('px-2 border rounded-lg py-3 w-full font-normal', error ? 'border-danger-40' : 'border-gray-40')}
      dateFormat={dateFormat}
      selected={localState ? new Date(localState) : null}
      onChange={(newDate) => setLocalState(newDate.toISOString())}
      {...rest}
    />
  </>

}

