import React, { forwardRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withDurationMask, { incrementDuration, decrementDuration } from '../../../utils/withDurationMask'
import { TextField } from '@designsystem'

const DurationInput = forwardRef(({
  readOnly,
  className,
  hasError,
  value = '',
  defaultValue,
  name,
  onChange,
  ...rest }, ref) => {

  const [localState, setLocalState] = useState((defaultValue || value) + ((defaultValue || value).length === 5 ? ':00' : ''))

  useEffect(() => {
    onChange && onChange({
      target: {
        name,
        value: localState + ':00'
      }
    })
  }, [onChange, name, localState])

  const handleChange = event => {
    let { value: targetValue } = event.target
    setLocalState((targetValue + ':00'))
  }


  const handleKeyDown = event => {
    const { keyCode, target: { value } } = event
    if (keyCode === 38)
      setLocalState(incrementDuration(value) + ':00')
    if (keyCode === 40)
      setLocalState(decrementDuration(value) + ':00')
  }

  return <TextField
    type="text"
    name={name}
    readOnly={readOnly}
    ref={ref}
    className={`
      ${hasError ? 'focus:border-danger-40 focus:ring-3 focus:ring-danger-40' : ''}
      ${className} 
    `}
    value={localState.slice(0, -3)}
    error={hasError}
    onKeyDown={handleKeyDown}
    onChange={withDurationMask(handleChange)}
    {...rest}
  />

})

DurationInput.displayName = 'DurationInput'

DurationInput.defaultProps = {
  className: '',
}

DurationInput.propTypes = {
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  readOnlyNode: PropTypes.any,
  hasError: PropTypes.bool,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export default DurationInput
