/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import format from 'date-fns/format'
import { toast } from 'sonner'
import { useNavigate } from 'react-router'
import { Button, TextField } from '@designsystem'

const ProfileForm = ({ title, fields, data, onSubmit, isNew }) => {
  const [isEditing, setIsEditing] = useState(isNew)

  return (
    <form onSubmit={async (e) => {
      !isNew && setIsEditing(false)
      await onSubmit(e)
    }} className="divide-y-2" autoComplete="off">
      <div className="flex justify-between pb-2">
        <h3 className="font-bold text-2xl" data-testid="form-title">{title}</h3>
        <div className="flex flex-row space-x-2">
          {!isNew && (
            <Button
              type="button"
              variant={isEditing ? 'outlined' : 'filled'}
              onClick={() => { setIsEditing(!isEditing) }}
              data-testid="btn-edit"
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
          )}
          {isEditing && (
            <Button
              type="submit"
              data-testid="btn-save"
            >
              Save
            </Button>
          )}
        </div>
      </div>
      {fields.map((field, idx) => {
        const testid = title.toLowerCase().trim().replace(' ', '_').concat('_', field.name)
        const val = data?.[field.name]
        return (
          <div className="py-2 grid grid-cols-3" key={field.label + field.name + idx}>
            <span className="font-bold">{field.label}</span>
            {isEditing ? (
              field.editable ? (
                field.input
                  ? field.input(val)
                  : <TextField type="text" className="p-0" name={field.name} defaultValue={val} data-testid={testid} required={field.required} />
              ) : field.parse
                ? field.parse(val, 'text-gray-40 border border-transparent')
                : <span data-testid={testid} className="text-gray-40 border border-transparent">{val || '-'}</span>
            ) : field.parse
              ? field.parse(val)
              : <span className="border border-transparent" data-testid={testid}>
                {val ? field.isDate ? format(new Date(val), 'dd/MM/yyyy') : val.toString() : '-'}
              </span>
            }
          </div>
        )
      })}
    </form>
  )
}

const ProfileEdit = ({ employee, jobs, cohorts, isNew, mutate }) => {

  const navigate = useNavigate()
  const [isTestUser, setIsTestUser] = useState(employee.is_test_user)
  const [isActive, setIsActive] = useState(employee.is_active)
  const [admin, setAdmin] = useState(employee.admin)
  const [showOnFacultyDirectory, setShowOnFacultyDirectory] = useState(employee.show_on_faculty_directory)
  const [checkedCohorts, setCheckedCohorts] = useState((employee.cohorts || []).map(c => c.cohort_id))

  const employeeFields = [
    { label: 'First Name', name: 'first_name', editable: true, required: true },
    { label: 'Last Name', name: 'last_name', editable: true, required: true },
    { label: 'Email', name: 'email', editable: isNew, required: true },
    { label: 'ID', name: 'id', editable: false },
    { label: 'Heartbeat ID', name: 'heartbeat_id', editable: false },
    { label: 'Conference URL', name: 'conference_url', editable: true },
    {
      label: 'Job',
      name: 'job',
      editable: true,
      input: (job) => {
        const currentValue = jobs.find((h) => h.title === job)?.id
        return (
          <TextField as="select" name="job_id" className="p-0" defaultValue={currentValue}>
            {jobs.map((j) => {
              return (
                <option key={j.id} value={j.id}>
                  {j.title}
                </option>
              )
            })}
          </TextField>
        )
      },
    },
    {
      label: 'Primary Cohort',
      name: 'primary_cohort_id',
      editable: true,
      parse: (cohort_id, style) => <div className={style}>
        {cohorts.find((c) => c.id === cohort_id)?.title || '-'}
      </div>,
      input: (cohort_id) => {
        const currentValue = cohorts.find((c) => c.id === cohort_id)?.id
        return (
          <TextField as="select" name="primary_cohort_id" className="p-0" defaultValue={currentValue}>
            <option value="">-</option>
            {
              cohorts.filter(cohort => checkedCohorts.length === 0 || checkedCohorts.includes(cohort.id)).map((c) => {
                return (
                  <option key={c.id} value={c.id}>
                    {c.title}
                  </option>
                )
              })
            }
          </TextField>
        )
      },
    },
    {
      label: 'Cohorts',
      name: 'cohorts',
      editable: true,
      parse: (_, style) => <div className={style}>
        {checkedCohorts.length === 0 ? <span>All</span> : (
          <span>
            {cohorts.filter(c => checkedCohorts?.includes(c.id)).map(c => c.title).join(', ')}
          </span>
        )}
      </div>,
      input: (_) => {
        return (
          <div>
            {cohorts.map((c) => <div key={c.id}>
              <label>{c.title}: </label>
              <input
                type="checkbox"
                checked={checkedCohorts.includes(c.id)}
                onChange={() => {
                  checkedCohorts.includes(c.id)
                    ? setCheckedCohorts(checkedCohorts.filter(cc => cc !== c.id))
                    : setCheckedCohorts([...checkedCohorts, c.id])
                }}
              />
            </div>)}
          </div>
        )
      },
    },
    {
      label: 'Test User',
      name: 'is_test_user',
      editable: !isNew,
      parse: (is_test_user, style) => <span className={style}>{is_test_user ? 'Yes' : 'No'}</span>,
      input: () => {
        return (
          <input
            type="checkbox"
            name="is_test_user"
            checked={isTestUser}
            onChange={() => setIsTestUser(!isTestUser)}
          />
        )
      },
    },
    {
      label: 'Active',
      name: 'is_active',
      editable: true,
      parse: (is_active, style) => <span className={style}>{is_active ? 'Yes' : 'No'}</span>,
      input: () => {
        return (
          <input
            type="checkbox"
            name="is_active"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
        )
      },
    },
    {
      label: 'Admin',
      name: 'admin',
      editable: true,
      parse: (is_admin, style) => <span className={style}>{is_admin ? 'Yes' : 'No'}</span>,
      input: () => {
        return (
          <input
            type="checkbox"
            name="admin"
            checked={admin}
            onChange={() => setAdmin(!admin)}
          />
        )
      },
    },
    {
      label: 'Show on faculty directory',
      name: 'show_on_faculty_directory',
      editable: true,
      parse: (show_on_faculty_directory, style) => <span className={style}>{show_on_faculty_directory ? 'Yes' : 'No'}</span>,
      input: () => {
        return (
          <input
            type="checkbox"
            name="show_on_faculty_directory"
            checked={showOnFacultyDirectory}
            onChange={() => setShowOnFacultyDirectory(!showOnFacultyDirectory)}
          />
        )
      },
    },
    { label: 'Fun fact', name: 'fun_fact', editable: true },
    { label: 'Pronouns', name: 'pronouns', editable: true },
    { label: 'Profile picture URL', name: 'directory_picture_url', editable: true },
  ]

  const onSubmitEmployee = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formProps = Object.fromEntries(formData)

    const [url, method] = isNew
      ? [`/api/pages/admin/workbench/employees/`, 'POST']
      : [`/api/pages/admin/workbench/employees/${employee.id}`, 'PATCH']

    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formProps,
        is_active: isActive,
        job_id: Number(formProps.job_id),
        primary_cohort_id: formProps.primary_cohort_id ? Number(formProps.primary_cohort_id) : null,
        show_on_faculty_directory: showOnFacultyDirectory,
        admin,
        is_test_user: isTestUser,
        cohorts: checkedCohorts,
      }),
    })
    const resp = await response.json()

    if (resp.error) {
      toast.error(`Failed to ${isNew ? 'create' : 'update'} employee. ${resp.error.message}`)
    } else {
      toast.success(`Successfully ${isNew ? 'created' : 'updated'} employee.`)
      navigate('..')
    }

    mutate()
  }

  return (
    <section className="overflow-y-auto mb-8 pr-3 space-y-8">
      <ProfileForm isNew={isNew} onSubmit={onSubmitEmployee} title="Employee" data={employee} fields={employeeFields} />
    </section>
  )
}

export default ProfileEdit
