import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Emoji from '@components/emoji'
import { LABELS, ICONS, BACKGROUND_COLORS } from "@utils/constants/formativeFeedback"

const SIZES = {
  'xs': 4,
  'md': 5,
  'lg': 7
}

const PADDING = {
  'xs': '',
  'md': 'px-5 py-2',
  'lg': 'px-5 py-2',
}

const FeedbackFlagIcon = ({ flag, size, title }) => (
  <>
    <ReactTooltip delayShow={300} />
    <div className={`${PADDING[size]} rounded-lg ${BACKGROUND_COLORS[flag]}`} data-tip={title || LABELS[flag]}>
      <Emoji name={ICONS[flag]} title={LABELS[flag]} size={SIZES[size]} />
    </div>
  </>
)

FeedbackFlagIcon.defaultProps = {
  size: 'md',
}

FeedbackFlagIcon.propTypes = {
  flag: PropTypes.string,
  size: PropTypes.any,
  title: PropTypes.any
}

export default FeedbackFlagIcon
