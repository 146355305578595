import React from 'react'
import PropTypes from 'prop-types'
import { grades } from '@components/facilitate/assessment/level-up-select'

const LevelCard = ({ title, level, className = 'bg-white', showNumericValue=true }) => {
  const grade = grades.find(g => g.level === Number(level))
  return (
    <div className='flex flex-row py-4 gap-2 items-center'>
      <div className='flex flex-col'>
        <span className='font-bold'>{title}</span>
        <span className='text-xs'>{showNumericValue ? grade?.title : grade?.nonNumericTitle}</span>
      </div>
      <div className='flex justify-center items-center'>
        <span className={`flex justify-center items-center rounded-full text-xl leading-none w-12 h-12 text-white ${showNumericValue ? className : 'bg-white'}`}>{level}</span>
      </div>
    </div>
  )
}
LevelCard.propTypes = {
  title: PropTypes.string,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showNumericValue: PropTypes.bool,
  className: PropTypes.string,
}

export default LevelCard
