import * as React from 'react'
import { ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import { RegistrationChanges } from '.'
import { Button, Card, cn, Icon, unstable_Table as Table } from '@design-system'

interface DataTableProps {
  columns: ColumnDef<RegistrationChanges>[]
  data: RegistrationChanges[]
}

export function DataTable({ data, columns }: DataTableProps) {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    pageCount: Math.ceil((data.length + 10 - 1) / 10),
  })
  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <React.Fragment>
      <Card>
        <Card.Content padding="xs">
          <Table>
            <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.Head key={header.id} className="first:pl-4">
                      <div className="flex items-center gap-1 group">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        {header.column.columnDef.enableSorting && (
                          <Button
                            aria-label={`Sort ${header.column.columnDef.header}`}
                            variant="ghost"
                            size="xs"
                            onClick={() => header.column.toggleSorting()}
                            className={cn(!header.column.getIsSorted() && "invisible opacity-0", "group-hover:visible group-hover:opacity-100 transition-opacity")}
                          >
                            <Icon name={header.column.columnDef.invertSorting ? "arrow-top" : "arrow-bottom"} size="xs" className={cn(header.column.getIsSorted() === "asc" && "rotate-180")} />
                          </Button>
                        )}
                      </div>
                    </Table.Head>
                  ))}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <Table.Row
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Table.Cell key={cell.id} className="first:pl-4">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={table.getFlatHeaders().length} className="h-24 text-center">
                    No results.
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
      <div className="flex w-full justify-center">
        <Pagination
          onClick={table.setPageIndex}
          totalPages={table.getPageCount()}
          currentPage={table.getState().pagination.pageIndex}
        />
      </div>
    </React.Fragment>
  )
}

interface PaginationProps {
  totalPages: number
  currentPage: number
  onClick: (page: number) => void
}

function Pagination({ totalPages, currentPage, onClick }: PaginationProps) {
  const pagination = generatePagination(totalPages, Number(currentPage))
  return (
    <div>
      {pagination.map((page, index) =>
        <span key={page} className={pagination[index + 1] !== page + 1 ? 'pr-4' : 'pr-1'}>
          <Button
            variant={page - 1 === Number(currentPage) ? 'contained' : 'outlined'}
            type="button"
            onClick={() => onClick(page - 1)}
          >
            {page}
          </Button>
        </span>
      )}
    </div>
  )
}

function generatePagination(totalPages: number, currentPage: number) {
  let pages = [1]

  if (totalPages <= 1) return [1]

  if (totalPages > 7) {
    if (currentPage <= 4) {
      for (let i = 2; i <= 7; i++) {
        pages.push(i)
      }
    } else if (currentPage >= totalPages - 3) {
      for (let i = totalPages - 6; i < totalPages; i++) {
        pages.push(i)
      }
    } else {
      for (let i = currentPage - 3; i <= currentPage + 3; i++) {
        if (i > 1 && i < totalPages) {
          pages.push(i)
        }
      }
    }
  } else {
    for (let i = 2; i <= totalPages; i++) {
      pages.push(i)
    }
  }

  if (!pages.includes(totalPages)) {
    pages.push(totalPages)
  }

  return pages
}