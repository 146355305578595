import PropTypes from "prop-types"
import Input from '@components/forms/input'
import SelectGuardians from '@components/forms/select-guardians'
import SelectStudents from '@components/forms/select-students'
import SlateTextarea, { RenderedSlate } from '@components/forms/slate-textarea'
import TableRow from '@components/table/table-row'
import { faChevronDown, faChevronRight, faEnvelope, faEnvelopeOpenText, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useForm from '@hooks/useForm'
import useToggle from '@hooks/useToggle'
import format from 'date-fns/format'
import React, { useEffect, useRef } from 'react'

import useFocusFormField from '@hooks/useFocusFormField'
import { toast } from 'sonner'
import useCurrentUser from '@hooks/useCurrentUser'
import useConfirmModal from '@hooks/useConfirmModal'
import joinFirstLastName from '@utils/joinFirstLastName'
import { Button } from "@designsystem"


const CollapsibleButton = ({ is_readonly, isCollapsed, toggleCollapse }) => {
  const toggleColapseRef = useRef(toggleCollapse)
  useEffect(() => {
    if (!is_readonly) toggleColapseRef.current(false)
  }, [is_readonly, isCollapsed])

  return <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronDown} onClick={toggleCollapse} className="cursor-pointer" />
}

CollapsibleButton.propTypes = {
  isCollapsed: PropTypes.any,
  is_readonly: PropTypes.any,
  toggleCollapse: PropTypes.any
}

const EditableDocument = ({
  readOnly,
  onCancel,
  onSave,
  onDelete,
  guardians,
  tags,
  type,
  guardian_document: {
    id,
    guardian = {},
    guardian_id,
    student_id,
    employee_id,
    student = {},
    tag,
    body,
    created_at = 0,
    is_read = false
  } = {}
}) => {
  const currentUser = useCurrentUser()
  const confirm = useConfirmModal()
  const isGuardian = currentUser?.role === 'guardian'
  const isDocumentOwner = employee_id === currentUser?.id && currentUser?.role === 'admin'
    || guardian_id === currentUser?.id && currentUser?.role === 'guardian' && employee_id === null

  const focusFormField = useFocusFormField(null)
  const [loadingAction, toggleIsLoadingAction] = useToggle(false)

  const [is_readonly, toggleReadonly] = useToggle(readOnly)
  const guardianSelectRef = useRef()
  const studentSelectRef = useRef()

  const slateRef = useRef()
  const initialValues = {
    id,
    guardian_id: isGuardian ? currentUser.id : guardian_id,
    student_id,
    tag,
    body
  }
  const [form, , setForm] = useForm(initialValues)

  const students = guardians?.find((guardian) => guardian.id === form.guardian_id)?.students || []
  const created_at_formatted = format(new Date(created_at), 'MMMM do, yyyy')

  const onCancelCB = () => {
    setForm(initialValues)
    onCancel ? onCancel() : toggleReadonly()
  }

  const onClickReadOnly = (field) => {
    if (isDocumentOwner) {
      toggleReadonly()
      focusFormField({ focusedField: `${id}-${field}` })
    }
  }

  const handleSubmit = async (e) => {
    toggleIsLoadingAction()
    e.preventDefault()
    if (!form.student_id) {
      toggleIsLoadingAction()
      return toast.error('You need to select a student!')
    }
    const result = await onSave(form)
    toggleIsLoadingAction()
    if (!result.error) onCancel ? onCancel() : toggleReadonly()
  }

  const onDeleteCB = async () => {
    toggleIsLoadingAction()
    if (await confirm('Are you sure you want to delete? This cannot be undone!')) {
      await onDelete(id)
    }
    toggleIsLoadingAction()
  }

  const updateIsRead = async () => {
    if (!isDocumentOwner && is_read === false) {
      const newForm = { ...form, is_read: true }
      setForm(newForm)
      await onSave(newForm, true)
    }
  }

  return <form onSubmit={handleSubmit}>
    <TableRow className={`font-bold ${is_readonly ? '' : 'bg-gray-10'}`} collapsibleSlot={is_readonly ?
      <div className="border-b-2 hover:border-black border-dotted cursor-text font-normal"
        onClick={() => onClickReadOnly('body')}
        onMouseEnter={updateIsRead}>
        <RenderedSlate value={body} />
      </div> :
      <>
        <div className="text-xs ml-1 mt-2 text-gray-70 font-bold">
          TIP: Click the <FontAwesomeIcon icon={faPaperclip} /> icon for uploading documents
        </div>
        <SlateTextarea
          id={`${id}-body`}
          className="font-normal"
          value={form.body}
          onChange={(body) => {
            if (body !== form.body) setForm({ ...form, body })
          }}
          ref={slateRef} />
        <div className="flex justify-between space-x-2">
          <div className="space-x-2 space-y-3">
            <Button
              type="button"
              variant="outlined"
              onClick={onCancelCB}
              disabled={loadingAction}>
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={loadingAction}
              loading={loadingAction}
            >
              Save
            </Button>
          </div>

          {id && <button
            className="border-danger-50 bg-danger-50 border-solid border-2 text-white text-xs font-bold p-1 rounded-md uppercase my-5 self-end"
            type="button"
            onClick={onDeleteCB}
            disabled={loadingAction}>
            {loadingAction ? 'Loading...' : 'Delete'}
          </button>}
        </div>
      </>
    }>
      <TableRow.Column colSpan={1} className="uppercase"><CollapsibleButton is_readonly={is_readonly} /></TableRow.Column>
      <TableRow.Column colSpan={3} className="uppercase justify-start" label="TAG">
        <Input
          value={form.tag}
          onChange={(e) => setForm({ ...form, tag: e.target.value.toUpperCase() })}
          readOnly={is_readonly}
          id={`${id}-tag`}
          name="tag"
          list='tag'
          datalist={tags}
          placeholder="Add a tag this relate to..."
          readOnlyNode={tag}
          onClickReadOnly={() => onClickReadOnly('tag')}
          required />
      </TableRow.Column>
      <TableRow.Column colSpan={3} className="uppercase" label="GUARDIAN">
        <SelectGuardians
          readOnly={is_readonly}
          id={`${id}-guardian`}
          className="w-full lg:min-w-80"
          guardians={guardians}
          selected_ids={[form.guardian_id]}
          isDisabled={isGuardian}
          onChange={(guardian_id) => {

            if (studentSelectRef.current) studentSelectRef.current.select.select.setValue(null)
            setForm({ ...form, guardian_id, student_id: undefined })
          }}
          placeholder="Select the guardian"
          readOnlyNode={joinFirstLastName(guardian)}
          onClickReadOnly={() => onClickReadOnly('guardian')}
          required
          ref={guardianSelectRef}
        />
      </TableRow.Column>
      <TableRow.Column colSpan={2} className="uppercase" label="STUDENT">
        {form.guardian_id && <SelectStudents
          readOnly={is_readonly}
          id={`${id}-student`}
          className="w-full lg:min-w-80"
          students={students}
          selected_ids={[form.student_id]}
          onChange={(student_id) => setForm({ ...form, student_id })}
          placeholder="Select the student"
          readOnlyNode={joinFirstLastName(student)}
          onClickReadOnly={() => onClickReadOnly('student')}
          required
          ref={studentSelectRef}
        />}
      </TableRow.Column>
      <TableRow.Column colSpan={2} className="uppercase">{is_readonly && created_at_formatted}</TableRow.Column>
      <TableRow.Column colSpan={1} className="uppercase">
        <span>{type}</span>
        {isDocumentOwner && <FontAwesomeIcon className="ml-auto text-turquoise-50 text-lg" title={is_read ? 'Read' : 'Unread'} icon={is_read ? faEnvelopeOpenText : faEnvelope} />}
      </TableRow.Column>
    </TableRow>
  </form>
}

EditableDocument.propTypes = {
  guardian_document: PropTypes.any,
  guardians: PropTypes.array,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  readOnly: PropTypes.any,
  tags: PropTypes.any,
  type: PropTypes.string,
}

export default EditableDocument
