import React from 'react'
import PropTypes from 'prop-types'

const Emoji = ({ name, title, size = 5, type = 'svg', className = '' }) => {
  const sizeStyle = `h-${size} w-${size}`
  return (
    <img src={`/assets/emoji/${type}/${name}.${type}`} alt={title} className={sizeStyle + ' ' + className} />
  )
}
Emoji.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.number,
  type: PropTypes.string,
  className: PropTypes.string,
}

export default Emoji
