/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import axios from 'axios'
import { toast } from 'sonner'
import useOnScreen from '../useOnScreen'
import useInfinitePagination from '../useInfinitePagination.js'
import useSWR from 'swr'
import useCurrentUser from '@hooks/useCurrentUser'

const PAGE_SIZE = 15

const TAGS_SUGGESTION = [
  'TRANSCRIPT',
  'REIMBURSEMENT',
  'MEDIA',
  'OTHER STUDENT INFO'
]

const getKey = (params = {}) => (pageIndex, previousPageData) => {
  if (previousPageData && !previousPageData.result.length) return null
  const nextQuery = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      if (Array.isArray(value)) {
        value.forEach(item => {
          nextQuery.append(key + '[]', item)
        })
      } else {
        nextQuery.set(key, value)
      }
    }
  }

  return `/guardian-documents?page=${pageIndex}&limit=${PAGE_SIZE}&${nextQuery.toString()}`
}

const useGuardianDocuments = (params = {}) => {
  const currentUser = useCurrentUser()
  const { data: { result = [] } = {}, mutate: mutateTags } = useSWR(`/guardian-documents/tags`)
  const tags = [...new Set([...TAGS_SUGGESTION, ...result])]
    .sort((a, b) => ('' + a).localeCompare(b))
    .map(tag => ({
      label: tag, value: tag
    }))
  const { data, size, setSize, mutate: mutateGuardianDocuments, isLoadingMore, isEndReached } = useInfinitePagination(getKey(params), PAGE_SIZE)


  const mutate = async () => {
    await mutateTags()
    await mutateGuardianDocuments()
  }

  const { setRef, isOnScreen } = useOnScreen('200px')

  const loadMore = () => setSize(size + 1)

  useEffect(() => {
    if (isOnScreen && !isLoadingMore) setSize(size + 1)
  }, [isLoadingMore, isOnScreen, setSize, size])


  const LoadMoreButton = () => !isEndReached
    ? <button className="font-bold text-sm animate-pulse text-gray-60" ref={setRef} onClick={loadMore}>
      {isLoadingMore ? 'Getting more documents...' : 'Load more...'}
    </button>
    : null

  const create = (document) => axios.post('/guardian-documents', {
    ...document,
    ...(currentUser?.role === 'admin' && { employee_id: currentUser.id })
  })
    .then(async res => {
      const createdDocument = res.data.result
      await mutate()
      toast.success('Document created!')
      return { document: createdDocument }
    })
    .catch(error => {
      const message = error.response.data.message
      toast.error(message)
      return { error: new Error(message) }
    })

  const update = (document, disableToast) => axios.put(`/guardian-documents/${document.id}`, document)
    .then(async res => {
      const updatedDocument = res.data.result
      await mutate()
      if (!disableToast) toast.success('Document updated!')
      return { document: updatedDocument }
    })
    .catch(error => {
      const message = error.response.data.message
      toast.error(message)
      return { error: new Error(message) }
    })

  const destroy = (id) => axios.delete(`/guardian-documents/${id}`)
    .then(async () => {
      await mutate()
      toast.success('Document deleted!')
      return {}
    })
    .catch(error => {
      const message = error.response.data.message
      toast.error(message)
      return { error: new Error(message) }
    })

  let documents = []
  data.forEach(({ result: documentsToConcat }) => {
    documents = documents.concat(documentsToConcat)
  })

  return {
    documents,
    tags,
    isLoadingMore,
    isEndReached,
    create,
    update,
    destroy,
    LoadMoreButton
  }
}

export default useGuardianDocuments
