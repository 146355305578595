import React from 'react'

const CatchUpContent = () => {
  return (
    <div>
      <p>
        Your target each cycle is set by using your anticipated graduation date.
        A “standard” 4-year plan would have you targeting about 10 units
        & ability opportunities each cycle. Depending on your progress in
        previous cycles, how many additional electives you've added to your
        pathway, and how much transfer credit you came to Sora with, this number
        will look different.
      </p>
      <p className="mt-4">
        If you are behind on your progress to graduation, your target for the
        cycle will be high to get you back on track. To meet your target number
        of units & abilities, we've recommended some high impact
        experiences on registration which will give you the most new things in
        one experience to move toward the graduation requirements. If you're in
        the middle of the cycle looking to catch up, check out the Activity
        Library where we can also recommend activities that will help you get
        units and ability opportunities to count toward your cycle
        target. Finally, if you have a unique set of units & abilities
        you're trying to target or you're looking to “fast track” through a
        certain topic, apply for independent study next cycle so you can work
        1:1 with a faculty member to target the specific units and abilities you
        need at your own pace, in a project or setting that's most relevant to
        you!
      </p>
    </div>
  )
}

export default CatchUpContent
