import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

const Datepicker = forwardRef(({
  readOnly,
  readOnlyNode,
  className,
  onClickReadOnly,
  hasError,
  ...rest }, ref) => {
  if (readOnly)
    return <div
      onClick={onClickReadOnly}
      className={`outline-none cursor-text w-full border-b-2 border-dotted border-gray-40 hover:border-black cursor-text ${className}`}
      {...rest}>
      {readOnlyNode || new Date(rest.value).toLocaleDateString([], {
        month: 'short', day: 'numeric', year: 'numeric'
      })}
    </div>

  return <input
    type="date"
    ref={ref}
    className={`
      border-gray-10 rounded-lg text-sm py-1 mt-0.5 w-full
      ${hasError ? 'focus:border-danger-40 focus:ring-3 focus:ring-danger-40' : ''}
      ${className}
    `}
    {...rest} />

})

Datepicker.displayName = 'Datepicker'

Datepicker.defaultProps = {
  className: '',
  onClickReadOnly: () => null,
}

Datepicker.propTypes = {
  readOnly: PropTypes.bool,
  readOnlyNode: PropTypes.any,
  hasError: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClickReadOnly: PropTypes.func,
}

export default Datepicker
