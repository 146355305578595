import React, { useState, useEffect } from 'react'
import { useThrottle } from '@hooks/useThrottle'
import { unstable_Tooltip as Tooltip, Button, Icon, DropdownMenu, Typography, Spinner, Avatar } from '@design-system'
import { TextField } from '@designsystem'
import { useFetcher } from '@design-system'
import SoraLink from '@components/link'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

export const SearchStudent = ({ suggestions }) => {
  const fetcher = useFetcher()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedQuery = useThrottle(searchTerm, 150)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const navigate = useNavigateWithCycle()

  const handleClose = () => {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }))
  }

  const onOpenChange = (isOpen) => {
    if (!isOpen) {
      setSelectedIndex(-1)
      setSearchTerm('')
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, fetcher.data?.students?.length - 1))
      e.preventDefault()
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0))
      e.preventDefault()
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      handleClose()
      e.preventDefault()
      navigate(`/employee/students/${fetcher.data?.students?.[selectedIndex]?.id}`)
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    };
  }, [fetcher.data, selectedIndex])

  useEffect(() => {
    setSelectedIndex(fetcher.data?.students?.length === 1 ? 0 : -1)
  }, [fetcher.data])

  useEffect(() => {
    if (debouncedQuery?.length > 0) {
      fetcher.submit({ _loader: "searchStudents", search: debouncedQuery }, { method: 'get' })
    }
  }, [debouncedQuery])

  return (
    <>
      <DropdownMenu onOpenChange={onOpenChange}>
        <Tooltip content="Switch between students">
          <DropdownMenu.Trigger asChild>
            <Button variant="ghost" size="xs">
              <Icon name="chevron-down" size="sm" />
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
        <DropdownMenu.Content className="w-64 -ml-56">
          <div className="p-3">
            <TextField
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.currentTarget.value)}
              startAdornment={<Icon name="search" size="sm" />}
              endAdornment={fetcher.state === "loading" && <Spinner size="sm" />}
              name="search"
              placeholder="Search for student"
              autoFocus
              autoComplete="off"
            />
          </div>
          <div className="overflow-y-scroll max-h-[31rem]">
            {searchTerm.length === 0 && suggestions && (
              <Typography variant="footnote" weight="bold" color="secondary" className="px-4 py-2">
                Suggestions
              </Typography>
            )}
            {searchTerm.length === 0 && suggestions?.map((student, index) => (
              <DropdownMenu.Item key={student.id} asChild>
                <SoraLink to={`/employee/students/${student.id}`} onClick={handleClose} className={selectedIndex === index && "bg-gray-10 dark:bg-gray-95"}>
                  <Avatar size="lg">
                    <Avatar.Image src={student.profileImageUrl} />
                    <Avatar.Fallback>{student.name}</Avatar.Fallback>
                  </Avatar>
                  {student.name}
                </SoraLink>
              </DropdownMenu.Item>
            ))}
            {searchTerm.length > 0 && fetcher.data?.students?.map((student, index) => (
              <DropdownMenu.Item key={student.id} asChild>
                <SoraLink to={`/employee/students/${student.id}`} onClick={handleClose} className={selectedIndex === index && "bg-gray-10 dark:bg-gray-95"}>
                  <Avatar size="lg">
                    <Avatar.Image src={student.profileImageUrl} />
                    <Avatar.Fallback>{student.name}</Avatar.Fallback>
                  </Avatar>
                  {student.name}
                </SoraLink>
              </DropdownMenu.Item>
            ))}
          </div>
          {searchTerm.length > 0 && fetcher.data?.students?.length === 0 && (
            <Typography color="tertiary" className="text-center w-full py-4">No results</Typography>
          )}
        </DropdownMenu.Content>
      </DropdownMenu >
    </>
  )
}
