import { Disclosure } from '@headlessui/react'
import React from 'react'
import capitalize from '@utils/capitalize'
import useToggle from '@hooks/useToggle'
import isToday from 'date-fns/isToday'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import SoraIcon, { soraEvent } from '@components/sora-icon'
import { createExcuseForAttendance, toggleAttendanceAbsence } from '@components/attendance/faculty/functions'
import { toast } from 'sonner'
import useCurrentUser from '@hooks/useCurrentUser'
import _sortBy from 'lodash/sortBy'

const AttendanceDisclosure = ({ attendances, view, attStatus, disabledCheckboxes, toggleDisabledCheckboxes, onEdit, isFaculty }) => {
  if (!attendances || !attendances.length) {
    return null
  }

  const colour = { tardy: 'yellow-30', absence: 'danger-30', excused: 'cyan-30' }[attStatus] || ''
  const controlAttendance = isFaculty && (attStatus && attStatus !== 'tardy')
  const icon = attStatus === 'present' ? <FontAwesomeIcon icon={faCheck} className='text-left text-green-30' /> : null

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full focus:outline-none">
            <div className="flex items-center w-full text-left px-3 py-2 text-sm text-gray-70 rounded-lg hover:bg-gray-20">
              <div className="w-full items-center flex gap-1">
                {
                  icon ?
                    <div>{icon}</div> :
                    <div className={`col-span-1 h-3 w-3 mx-auto rounded-full bg-${colour}`} />
                }
                <div className="col-span-1" />
                <span className="flex-1">{attendances.length} {attStatus}</span>
              </div>
              <FontAwesomeIcon icon={faChevronDown} className={`${open ? 'transform rotate-180' : ''} w-3 h-3 text-gray-70`} />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 text-gray-70">
            <div className="max-h-40 overflow-y-auto">
              {
                controlAttendance &&
                <div className="grid grid-cols-18">
                  <div className="col-span-14" />
                  <span className="col-span-2 text-center text-sm text-danger-40">A</span>
                  <span className="col-span-1 text-center text-sm text-cyan-40">E</span>
                </div>
              }
              {
                _sortBy(attendances, ['student_name'])
                  .map((att) =>
                    <AttendanceRow
                      key={uuidv4()}
                      attendance={att}
                      controlAttendance={controlAttendance}
                      disabledCheckboxes={disabledCheckboxes}
                      toggleDisabledCheckboxes={toggleDisabledCheckboxes}
                      onEdit={onEdit}
                      view={view}
                    />
                  )
              }
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
AttendanceDisclosure.propTypes = {
  attendances: PropTypes.array,
  attStatus: PropTypes.string,
  disabledCheckboxes: PropTypes.bool,
  toggleDisabledCheckboxes: PropTypes.func,
  onEdit: PropTypes.func,
  view: PropTypes.string,
  isFaculty: PropTypes.bool,
}

const AttendanceList = ({ attendances, view, disabledCheckboxes, toggleDisabledCheckboxes, onEdit, isFaculty }) => {
  const { present, tardy, absent, excused } = attendances
  return (
    <div className="p-3 bg-white rounded-2xl space-y-1">
      <AttendanceDisclosure
        attendances={tardy}
        attStatus="tardy"
        view={view}
        isFaculty={isFaculty}
      />
      <AttendanceDisclosure
        attendances={absent}
        attStatus="absence"
        disabledCheckboxes={disabledCheckboxes}
        toggleDisabledCheckboxes={toggleDisabledCheckboxes}
        onEdit={onEdit}
        view={view}
        isFaculty={isFaculty}
      />
      <AttendanceDisclosure
        attendances={excused}
        attStatus="excused"
        disabledCheckboxes={disabledCheckboxes}
        toggleDisabledCheckboxes={toggleDisabledCheckboxes}
        onEdit={onEdit}
        view={view}
        isFaculty={isFaculty}
      />
      <AttendanceDisclosure
        attendances={present}
        attStatus="present"
        disabledCheckboxes={disabledCheckboxes}
        toggleDisabledCheckboxes={toggleDisabledCheckboxes}
        onEdit={onEdit}
        view={view}
        isFaculty={isFaculty}
      />
    </div>
  )
}
AttendanceList.propTypes = {
  attendances: PropTypes.object,
  disabledCheckboxes: PropTypes.bool,
  toggleDisabledCheckboxes: PropTypes.func,
  onEdit: PropTypes.func,
  view: PropTypes.string,
  isFaculty: PropTypes.bool,
}

function AttendanceRow({ attendance, view, controlAttendance, onEdit, disabledCheckboxes = false, toggleDisabledCheckboxes = null }) {
  const [showExpertInfo, toggleShowExpertInfo] = useToggle(false)

  const { id: employee_id } = useCurrentUser()
  const title = view === 'houseEvent' || view === 'sessions' ? attendance.student_name : (attendance.theme_title || capitalize(attendance.kind))
  const studentIsExcused = attendance.excused
  const studentIsAbsent = !attendance.present
  const failsafe = !(studentIsExcused && studentIsAbsent) // If both are disabled (wrong scenario, should not happen), allow to change
  return (
    <div className="w-full grid grid-cols-18 mb-2">
      <div className="flex flex-col col-span-14">
        <div className="flex items-center space-x-2">
          <SoraIcon icon={soraEvent} className='text-sm' />
          <div className="flex flex-col">
            <span className="text-sm">{title}</span>
            {
              showExpertInfo &&
              <div className="flex items-center space-x-1">
                <span className="text-xxs text-regular-dark leading-none truncate max-w-36">Expert: {attendance.employee_name}</span>
                {
                  isToday(new Date(attendance.session_start)) &&
                  <FontAwesomeIcon
                    onClick={() => { location.href = `mailto:${attendance.employee_email}` }}
                    icon={faPaperPlane}
                    className={`text-sm text-blue-60 hover:cursor-pointer`}
                    title="Send email"
                  />
                }
              </div>
            }
          </div>
        </div>
      </div>
      {
        controlAttendance &&
        <>
          <input
            disabled={(disabledCheckboxes || studentIsExcused) && failsafe}
            defaultChecked={studentIsAbsent}
            onClick={() => toggleAttendanceAbsence(attendance, employee_id, onEdit, toast, toggleDisabledCheckboxes)}
            type="checkbox"
            className="m-auto col-span-2 border border-danger-40 rounded-md disabled:cursor-not-allowed disabled:opacity-50 hover:cursor-pointer"
          />
          <input
            disabled={(disabledCheckboxes || studentIsAbsent) && failsafe}
            defaultChecked={studentIsExcused}
            onClick={() => createExcuseForAttendance(attendance, onEdit, toast, toggleDisabledCheckboxes)}
            type="checkbox"
            className="m-auto col-span-1 border border-cyan-40 rounded-md disabled:cursor-not-allowed disabled:opacity-50 hover:cursor-pointer"
          />
        </>
      }
      {
        attendance.employee_name && view !== 'sessions' &&
        <FontAwesomeIcon onClick={toggleShowExpertInfo} icon={faChevronDown} className={`my-auto ml-auto col-span-1 text-tiny text-gray-70 hover:cursor-pointer ${showExpertInfo ? 'transform rotate-180 ' : ''}`} />
      }
    </div>
  )
}
AttendanceRow.propTypes = {
  attendance: PropTypes.object,
  controlAttendance: PropTypes.bool,
  disabledCheckboxes: PropTypes.bool,
  toggleDisabledCheckboxes: PropTypes.func,
  onEdit: PropTypes.func,
  view: PropTypes.string,
}

export default AttendanceList
