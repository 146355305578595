import React from 'react'
import PropTypes from 'prop-types'
import { Button, useFetcher } from '@design-system'

export const StudentsSync = ({ isProcessing = false }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state === 'loading' || isProcessing

  return (
    <fetcher.Form method='post'>
      <input type="hidden" name="_action" value="students_groups_sync" />
      <Button type='submit' loading={isLoading} disabled={isLoading}>
        Sync Students Google Groups
      </Button>
    </fetcher.Form>
  )
}
StudentsSync.propTypes = {
  isProcessing: PropTypes.bool,
}
