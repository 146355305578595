/**
 * Parses a string representing a form field name and extracts its keys.
 * 
 * @param {string} string - The form field name string to parse.
 * @returns {string[]} An array of keys extracted from the input string.
 */
function parseKeys(string) {
  const prefix = /^([^[\]]*)/ // Regular expression to capture the prefix
  const children = /(\[[^[\]]*\])/g // Regular expression to capture the keys inside brackets

  const matchPrefix = prefix.exec(string)
  const keys = matchPrefix[1] ? [matchPrefix[1]] : [] // Initialize keys array with the prefix if it exists

  let matchChild
  while ((matchChild = children.exec(string)) !== null) {
    keys.push(matchChild[1]) // Add each key inside brackets to the keys array
  }

  return keys
}

/**
 * Recursively assigns a value to the correct location within a nested object/array structure based on the provided keys.
 * 
 * @param {object|array} result - The current state of the nested structure.
 * @param {string[]} keys - An array of keys that indicate where to place the value.
 * @param {*} value - The value to assign.
 * @returns {object|array} The updated object/array structure with the value assigned.
 */
function assignValue(result, keys, value) {
  if (keys.length === 0) return value // Base case: no more keys, return the value

  const [key, ...remainingKeys] = keys // Destructure to get the first key and the remaining keys
  const arrayMatch = key.match(/^\[(.+?)\]$/) // Match keys that are inside brackets

  if (key === '[]') { // Handle the case where the key is an empty array index
    const arrayResult = Array.isArray(result) ? result : []
    return [...arrayResult, assignValue(null, remainingKeys, value)]
  }

  if (arrayMatch) {
    const index = arrayMatch[1] // Extract the index or key inside brackets
    const isIndex = !isNaN(index) // Check if it is a numeric index
    const nextValue = assignValue(result ? result[index] : undefined, remainingKeys, value)

    if (isIndex) {
      const arrayResult = result ? [...result] : []
      arrayResult[+index] = nextValue
      return arrayResult
    }

    return { ...result, [index]: nextValue }
  }

  return { ...result, [key]: assignValue(result ? result[key] : undefined, remainingKeys, value) }
}

/**
 * Takes form data entries and serializes them into a nested object/array structure using the parseKeys and assignValue functions.
 * 
 * @param {FormData} formData - The form data object containing key-value pairs from a form.
 * @returns {object} The serialized nested object/array structure.
 */
export default function serializeFormData(formData) {
  const result = Array.from(formData.entries()).reduce((root, [path, value]) => {
    const keys = parseKeys(path) // Parse the keys from the path
    return assignValue(root, keys, value) // Assign the value to the root object
  }, {})

  // Clean up any arrays to remove undefined elements
  function cleanArrays(obj) {
    if (Array.isArray(obj)) {
      return obj.filter((item) => item !== undefined).map(cleanArrays)
    }
    if (typeof obj === 'object' && obj !== null) {
      return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, cleanArrays(v)]))
    }
    return obj
  }

  return cleanArrays(result)
}
