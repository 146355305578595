import { z } from 'zod'

const taskActionTypeSchema = z.union([
  z.literal('link'),
  z.literal('file'),
  z.literal('video'),
])

export const formResponseTodoSchema = z.object({
  id: z.string().optional(),
  onboarding_checklist_id: z.string(),
  title: z.string(),
  file: z.any().optional(),
  rte_student_description: z.string().optional(),
  rte_guardian_description: z.string().optional(),
  actions: z.array(
    z.object({
      title: z.string(),
      redirect_to: z.string().url(),
      type: taskActionTypeSchema,
    })
  ).optional(),
})

export type Todo = {
  id: number,
  title: string,
  phaseTitle: string,
  studentDescription: string,
  guardianDescription: string,
  actions: Array<Omit<TaskAction, 'id' | 'previewData'>> | null
}
export type UploadedFileData = {
  type: 'video',
  url: string,
  thumbnailUrl: string,
  fileName: string,
} | {
  type: 'file',
  fileName: string,
  url: string,
}

export type TaskActionType = z.infer<typeof taskActionTypeSchema>

export interface TaskAction {
  id: string,
  title: string,
  type: TaskActionType,
  redirectTo: string,
  thumbnailUrl: string | '',
  previewData?: UploadedFileData & { actionId: string }
}
