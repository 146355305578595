import { useState, useEffect, useLayoutEffect } from 'react'

const updateTheme = () => {
  if (
    localStorage.theme === 'dark'
  ) {
    document.documentElement.classList.add('dark')
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#0B1120')
  } else {
    document.documentElement.classList.remove('dark')
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#f8fafc')
  }
}

const useTheme = () => {
  let [theme, setTheme] = useState(localStorage.theme ?? 'light')

  const toggleTheme = () => setTheme(prev => prev === 'light' ? 'dark' : 'light')

  useLayoutEffect(() => {
    let theme = localStorage.theme ?? 'light'
    setTheme(theme)
  }, [])

  useLayoutEffect(() => {
    localStorage.theme = theme
    updateTheme()
  }, [theme])

  useEffect(() => {
    function onStorage() {
      updateTheme()
      let theme = localStorage.theme
      setTheme(theme)
    }
    window.addEventListener('storage', onStorage)
    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [])

  return [theme, toggleTheme, setTheme]
}

export default useTheme
