import React, { useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import useToggle from '@hooks/useToggle'
import 'react-datepicker/dist/react-datepicker.css'
import '../override.css'
import format from 'date-fns/format'
import AttendanceList from './attendanceDisclosure'
import { Disclosure } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import SoraIcon, { soraEvent } from '@components/sora-icon'

const AttendanceDetailsModal = ({ onClose, view, onEdit = null, selectedDate, attendances, isFaculty = true }) => {
  const attendancesOnDay = useMemo(() => {
    const startYMD = format(new Date(selectedDate), 'yyyy-MM-dd')
    return attendances[startYMD] || []
  }, [selectedDate, attendances])

  const keyPressedFn = useCallback(({ keyCode }) => {
    if (keyCode === 27) { // Close modal w/ Esc key
      onClose()
    }
  }, [onClose])
  const [disabledCheckboxes, toggleDisabledCheckboxes] = useToggle(false)
  useEffect(() => {
    document.addEventListener('keydown', keyPressedFn, false)
    return () => {
      document.removeEventListener('keydown', keyPressedFn, false)
    }
  }, [keyPressedFn])

  return <div
    aria-modal="true"
    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-100 md:min-w-96 shadow-2xl rounded-lg bg-white ring-1 ring-black ring-opacity-10"
    onClick={onClose}
  >
    <div className="h-full rounded-lg px-7 py-6" onClick={e => e.stopPropagation()}>
      <header className="flex items-center border-b-1 font-bold text-lg mb-5">
        <span>{format(new Date(selectedDate), 'iii, MMMM dd')}</span>
        <svg
          onClick={onClose}
          className="fill-current text-gray-70 w-6 h-6 cursor-pointer ml-auto"
          viewBox="0 0 18 18"
        >
          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
        </svg>
      </header>
      {
        view === 'houses' ?
          <div className="space-y-3">
            <HouseEventAttendanceList
              attendances={attendancesOnDay.checkpoint}
              toggleDisabledCheckboxes={toggleDisabledCheckboxes}
              disabledCheckboxes={disabledCheckboxes}
              onEdit={onEdit}
              kind="Checkpoint"
            />
            <HouseEventAttendanceList
              attendances={attendancesOnDay.standup}
              toggleDisabledCheckboxes={toggleDisabledCheckboxes}
              disabledCheckboxes={disabledCheckboxes}
              onEdit={onEdit}
              kind="Standups"
            />
          </div> :
          <AttendanceList
            attendances={attendancesOnDay}
            toggleDisabledCheckboxes={toggleDisabledCheckboxes}
            disabledCheckboxes={disabledCheckboxes}
            onEdit={onEdit}
            view={view}
            isFaculty={isFaculty}
          />
      }
    </div>
  </div>
}
AttendanceDetailsModal.propTypes = {
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
  attendances: PropTypes.object,
  view: PropTypes.string,
  isFaculty: PropTypes.bool,
}

function HouseEventAttendanceList({ attendances, kind, toggleDisabledCheckboxes, disabledCheckboxes, onEdit }) {
  const { present, absent, excused } = attendances || {}
  return (
    <Disclosure as='div'>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full ">
            <div className="flex items-center justify-between p-2 text-gray-70 rounded-lg hover:bg-gray-20">
              <div className="flex items-center space-x-3">
                <SoraIcon icon={soraEvent} className='text-sm' />
                <span>{kind}</span>
              </div>
              <FontAwesomeIcon icon={faChevronDown} className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-gray-70`} />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>
            {
              (!present?.length && !absent?.length && !excused?.length) ?
                <span className="ml-5 text-sm">There were no {kind} events on this day</span> :
                <AttendanceList
                  attendances={attendances}
                  toggleDisabledCheckboxes={toggleDisabledCheckboxes}
                  disabledCheckboxes={disabledCheckboxes}
                  onEdit={onEdit}
                  view="houseEvent"
                />
            }
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
HouseEventAttendanceList.propTypes = {
  attendances: PropTypes.object,
  kind: PropTypes.string,
  disabledCheckboxes: PropTypes.bool,
  toggleDisabledCheckboxes: PropTypes.func,
  onEdit: PropTypes.func,
}

export default AttendanceDetailsModal
