import * as React from 'react'
import axios from 'axios'
import { ActionFunctionArgs, LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import serializeFormData from '@utils/serializeFormData'
import { Button, Card, Icon, unstable_Table as Table, Typography, useFetcher } from '@design-system'

interface LoaderData {
  employees: Array<{
    id: number,
    name: string,
    advisedHouses: Array<string>,
    job: string,
    heartbeatId: string,
  }>,
  headers: Array<string>
}

async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const res = await axios.get(`/backoffice/workbench/student-heartbeat-server/employees?${searchParams.toString()}`)
  return res.data
}

async function action({ request }: ActionFunctionArgs) {
  const form = serializeFormData(await request.formData())
  const employeeId = form?.employeeId ?? ''
  const res = await axios.post(`/backoffice/workbench/student-heartbeat-server/employees/${employeeId}`)
  return res.data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData

  return (
    <div className="flex flex-col gap-4 px-6">
      <Card>
        <Card.Content>
          <Typography variant="callout" color="secondary" className="flex flex-col">
            <p>This sync will only affect active employees that have a Heartbeat ID assigned.</p>
            <p>To map users from HB to Sora, please use the "Users" sync instead.</p>
            <p className="mt-2"><b>*Active employees</b> are all that have the field "Is Active" checked.</p>
          </Typography>
        </Card.Content>
        <div className="px-4">
          <SyncFormButton title="Sync all employees" />
        </div>
      </Card>
      <Table className="border-separate border border-gray-30 rounded-lg">
        <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
          <Table.Row>
            {loaderData.headers.map((header, index) => (
              <Table.Head key={index} className="w-48 first:pl-4">{header}</Table.Head>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body className="[&_tr:last-child]:border-1">
          {loaderData.employees.map((employee) => (
            <Table.Row key={employee.id} className="last-of-type:rounded-b-md">
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{employee.id}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{employee.name}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{employee.advisedHouses ? employee.advisedHouses.join(' / ') : '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{employee.job}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{employee.heartbeatId}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                <SyncFormButton title="Sync this employee" name="employeeId" value={employee.id.toString()} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

interface SyncFormButtonProps {
  title: string,
  name?: string,
  value?: string,
}

function SyncFormButton({ title, name, value }: SyncFormButtonProps) {
  const fetcher = useFetcher()
  const loading = fetcher.state === 'loading' || fetcher.state === 'submitting'
  return (
    <fetcher.Form method="POST" className="self-end">
      <Button type="submit" name={name} value={value} size="sm" loading={loading}>
        <Icon name="arrow-swap" size="xs" /> {title}
      </Button>
    </fetcher.Form>
  )
}

export const WorkbenchStudentHearbeatServerEmployeesRoute = { loader, action, Element }
