import React from 'react'
import axios from 'axios'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { RenderedSlate } from '@components/forms/slate-textarea'
import { Button } from '@designsystem'
import { faClose } from '@fortawesome/free-solid-svg-icons'

export default function Employee$UserIdNotifications$NotificationTypeDetailsIdRoute() {
  const navigate = useNavigate()
  const { content, title } = useLoaderData() as { title: string; content: Array<unknown> }
  return (
    <section className="flex flex-col space-y-4 self-start lg:pl-0 pl-8">
      <div className="flex items-center">
        <h2 className="text-2xl font-bold">{title || 'Notification Content'}</h2>
        <Button endIcon={faClose} title="Close Content" onClick={() => navigate('../')} className="lg:hidden" variant="nude" size="md" />
      </div>
      <RenderedSlate value={content} />
    </section >
  )
}

export async function loader({ params }) {
  const { notification_id: notificationId } = params
  const { data } = await axios.get(`/notifications/content/${notificationId}`)
  return data
}
