import React from 'react'
import { Student } from './types'
import { PillGraduationProgress } from '@blocks/pill-graduation-progress'
import SoraLink from '@components/link'

interface CellRequestListProps extends Pick<Student, 'graduationStatus' | 'graduationStatusUrl'> { }

export const CellProgress = ({ graduationStatus, graduationStatusUrl }: CellRequestListProps) => {
  return (
    <>
      {graduationStatus && (
        <SoraLink to={graduationStatusUrl} target="_blank">
          <PillGraduationProgress variant={graduationStatus} />
        </SoraLink>
      )}
    </>
  )
}
