import { Button, Icon } from '@design-system'
import React, { useState } from 'react'
import NotificationsDrawer from '@components/notifications'
import useSWR from 'swr'

interface NotificationProps {
  userId: number
  userRole: string
}

const ONE_MINUTE_IN_MS = 60000

export const Notifications = ({ userId, userRole }: NotificationProps) => {
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  /**
   * @TODO use fetcher instead of useSWR
   */
  let { data: unreadNotifications } = useSWR(`/notifications/unread/${userId}`, { refreshInterval: ONE_MINUTE_IN_MS })
  unreadNotifications = Number(unreadNotifications)
  return (
    <>
      <Button type="button" size="sm" variant="outlined" color="soft" className="relative" onClick={() => setNotificationsOpen(true)}>
        <Icon name="bell" size="sm">
          {unreadNotifications > 0 &&
            <span className={`${unreadNotifications > 9 ? '-right-1 w-5' : '-right-0.5 w-4'} absolute z-40 -top-0.5 h-4`}>
              <span className="flex items-center justify-center h-full w-full rounded-full bg-accent">
                <small data-testid="notifications-number" className="text-white dark:text-gray-100 text-xxs mt-px font-bold">{unreadNotifications > 9 ? '9+' : unreadNotifications}</small>
              </span>
            </span>
          }
        </Icon>
      </Button>
      {notificationsOpen &&
        <NotificationsDrawer
          open
          user={{
            user_id: userId,
            role: userRole,
          }}
          onCloseNotifications={() => setNotificationsOpen(false)}
        />
      }
    </>
  )
}
