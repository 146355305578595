import axios from 'axios'

export const toggleAttendanceAbsence = async ({ student_id, attendable_id, present }, employee_id, onEdit, toast, toggleDisabledCheckboxes) => {
  try {
    toggleDisabledCheckboxes()
    await axios.patch('/attendances', {
      student_id,
      employee_id,
      attendable_id,
      present: !present,
    })
    onEdit && onEdit()
    toast.success('Absence changed!')
  } catch (e) {
    toast.error('Could not change absence')
    console.error(e)
  }
  toggleDisabledCheckboxes()
}

export const createExcuseForAttendance = async (attendance, onEdit, toast, toggleDisabledCheckboxes) => {
  toggleDisabledCheckboxes()
  if (!attendance.excused) {
    const excuse = {
      start_at: (attendance.session_start || attendance.start_at),
      end_at: (attendance.session_end || attendance.end_at),
      student_id: attendance.student_id,
    }

    try {
      await axios.post('/excuses', excuse)
      onEdit && onEdit()
      toast.success('Excuse added!')
    } catch (e) {
      toast.error('Could not change excuse, please reopen this window and try again.')
      console.error(e)
    }

    toggleDisabledCheckboxes()
    return
  }

  const { excuse_id, attendable_id } = attendance
  try {
    await axios.delete('/excuses/' + excuse_id + '/' + attendable_id)
    toast.success('Excuse removed!')
    onEdit && onEdit()
  } catch (e) {
    toast.error('Could not change excuse, please reopen this window and try again.')
    console.error(e)
  }

  toggleDisabledCheckboxes()
  return excuse_id
}
