import React, { useState } from 'react'
import { Form, useNavigation } from 'react-router-dom'
import { Button, TextField } from '@designsystem'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import uniqueId from 'lodash/uniqueId'
import TextareaAutosize from 'react-textarea-autosize'
import { getSubjectColors } from '@utils/subjectColors'
import PropTypes from 'prop-types'

export default function WorkbenchUnitForm({
  unit = { title: '', description: '', objectives: [], school_stage: 'hs' },
  topics = [],
}) {
  const navigation = useNavigation()
  const [objectives, setObjectives] = useState(unit?.objectives.map(objective => ({ ...objective, key: objective.id })))
  const isFormSubmitting = navigation.state === 'submitting'

  const onClickAddObjective = () => {
    setObjectives([...objectives, { title: '', key: uniqueId() }])
  }

  const onClickRemoveObjective = (index) => {
    const newObjectives = objectives.map((objective, i) => {
      if (i === index)
        return { ...objective, deleted: true }
      return objective
    })
    setObjectives(newObjectives)
  }

  const handleObjectiveChange = (event, index) => {
    const newObjectives = objectives.map((objective, i) => {
      if (i === index)
        return { ...objective, title: event.target.value }
      return objective
    })
    setObjectives(newObjectives)
  }

  return (
    <div className="flex justify-center">
      <Form method="post" className="w-96">
        <TextField label="Title" name="title" defaultValue={unit.title} required data-testid="title-input" />
        <TextField as={TextareaAutosize} label="Description" name="description" defaultValue={unit.description} data-testid="description-input" />
        <TextField label="Topic" as="select" name="topic_id" defaultValue={unit.topic_id} data-testid="topic-input">
          {
            topics.map((topic, index) => {
              const shouldAddSubjectDivider = topics[index - 1]?.subject_title !== topic.subject_title
              const obj = shouldAddSubjectDivider ? [
                <option key={`sub_${topic.id}`} disabled className={`font-bold ${getSubjectColors(topic.subject_id)?.bg} text-white`}>
                  {topic.subject_title}
                </option>,
                <option key={topic.id} value={topic.id}>
                  {topic.topic_title}
                </option>,
              ] :
                <option key={topic.id} value={topic.id}>
                  {topic.topic_title}
                </option>

              return obj
            })
          }
        </TextField>
        <TextField label="School Stage" as="select" name="school_stage" data-testid="school-stage-input" defaultValue={unit.school_stage}>
          <option value="hs">High School</option>
          <option value="ms">Middle School</option>
        </TextField>
        <div id="objectives">
          <span>Objectives</span>
          {objectives.map((objective, i) => (
            !objective.deleted && (
              <div key={i} className="flex flex-row gap-2 items-center">
                <input type="hidden" name={`objectives[${objective.key}][id]`} value={objective.id}></input>
                <div className="grow">
                  <TextField fullWidth name={`objectives[${objective.key}][title]`} value={objective.title} onChange={(e) => handleObjectiveChange(e, i)} />
                </div>
                <Button variant="nude" startIcon={faTrash} onClick={() => onClickRemoveObjective(i)} size="sm" />
              </div>
            )
          ))}
          <div className="my-4">
            <Button variant='outlined' size="sm" display='block' type='button' disabled={isFormSubmitting} onClick={onClickAddObjective}>+ Add Objective</Button>
          </div>

        </div>
        <div className="flex justify-end">
          <Button type='submit' disabled={isFormSubmitting}>Save</Button>
        </div>
      </Form>
    </div>
  )
}

WorkbenchUnitForm.propTypes = {
  unit: PropTypes.shape({
    id: PropTypes.number,
    topic_id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    objectives: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      })
    )
  }),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      topic_title: PropTypes.string,
      subject_title: PropTypes.string,
      subject_id: PropTypes.number,
    })
  ).isRequired
}
