import React from 'react'
import PropTypes from 'prop-types'
import { Listbox } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

export const grades = [
  { level: 0, title: '0: Not Started' },
  { level: 0.5, title: '0.5: Attempted' },
  { level: 1, title: '1: Exposure' },
  { level: 1.5, title: '1.5: Exposure' },
  { level: 2, title: '2: Developing' },
  { level: 2.5, title: '2.5: Developing' },
  { level: 3, title: '3: Demonstrating' },
  { level: 3.5, title: '3.5: Demonstrating' },
  { level: 4, title: '4: Extending' },
]

const SelfLevelUpSelect = ({ type, onChange, selectedLevel = 0 }) => {
  const selectedItem = grades.find(g => g.level === selectedLevel)
  return (
    <div className='flex flex-row bg-gray-10 p-4 rounded-lg gap-4 justify-between items-center'>
      <div className='flex flex-col'>
        <span className='font-bold text-sm'>Self Reflection</span>
      </div>
      <Listbox as='div' value={selectedLevel} onChange={onChange} className='relative flex flex-col text-sm'>
        <Listbox.Button className='flex-auto flex flex-row py-2 justify-center items-center text-left bg-white border-2 border-gray-30 rounded'>
          <span className='w-min-40 whitespace-nowrap px-3 border-r border-r-gray-30 leading-none font-bold'>{selectedItem.title}</span>
          <div className='px-3'>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </Listbox.Button>
        <Listbox.Options className='absolute top-full w-full overflow-auto max-h-32 bg-white shadow-lg z-30'>
          {grades.map((grade) => (
            <Listbox.Option
              key={`${type}:level:${grade.level}`}
              value={grade.level}
              className={`p-2 cursor-pointer hover:bg-gray-30 ${grade.level === selectedLevel ? 'font-bold' : ''}`}
            >
              {grade.title}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}
SelfLevelUpSelect.propTypes = {
  type: PropTypes.string,
  selectedLevel: PropTypes.number,
  onChange: PropTypes.func,
}

export default SelfLevelUpSelect
