import React from 'react'
import Select from 'react-select'

function MultiselectFilter({
  // eslint-disable-next-line react/prop-types
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const [selectedFilters, setSelectedFilters] = React.useState([])
  const options = React.useMemo(() => {
    const options = new Set()
    // eslint-disable-next-line react/prop-types
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()].sort()
  }, [id, preFilteredRows])

  const applyFilter = (filterList) => {
    setFilter(filterList.map((filter) => filter.value))
    setSelectedFilters(filterList)
  }

  React.useEffect(() => { if (!filterValue) setSelectedFilters([]) }, [filterValue])

  return <Select
    isMulti
    isSearchable={false}
    value={selectedFilters}
    options={options.map((option) => ({ value: option, label: option }))}
    onChange={applyFilter}
    className="text-gray-50 w-full"
  />
}

export default MultiselectFilter
