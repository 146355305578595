import React, { PropsWithChildren } from 'react'
import { shutdown } from '@intercom/messenger-js-sdk'
import { Outlet } from 'react-router-dom'

export async function loader() {
  shutdown()
  return null
}

export function IndexNoAuthRoute() {
  return <div className="h-screen flex bg-white sm:bg-gray-10 dark:bg-gray-100 sm:dark:bg-gray-100 text-primary overflow-hidden text-base">
    <main className="flex flex-col w-full overflow-x-hidden">
      <div className="flex-1 sm:px-8 overflow-y-auto sm:pt-4">
        <Outlet />
      </div>
    </main>
  </div>
}

export const SubmenuPortal = ({ children }: PropsWithChildren) => {
  return (
    <div className='mb-6'>
      {children}
    </div>
  )
}
