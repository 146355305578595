import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { toast } from 'sonner'
import axios from 'axios'
import { Button } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'

export default function WorkbenchMailchimpRoute() {
  
  const [syncBtnDisabled, setSyncBtnDisabled] = useState(false)
  const confirmAction = useConfirmModal()

  const syncWithMailchimp = async () => {
    if (await confirmAction('Confirm Sync Guardians & Students with Mailchimp')) {
      try {
        setSyncBtnDisabled(true)
        toast.success('The Sync has started. It may take a while, please wait...')
        await axios.put('/pages/admin/workbench/mailchimp/sync')
      } catch (e) {
        toast.error('Something went wrong while syncing...')
        console.error(e)
      }
      setTimeout(() => {
        setSyncBtnDisabled(false)
      }, 5000)
    }
  }

  return (
    <div className="flex items-center space-x-3">
      <Button onClick={syncWithMailchimp} loading={syncBtnDisabled}>
        {syncBtnDisabled ? 'Syncing...' : 'Sync Guardians & Students with Mailchimp'}
      </Button>
      <a
        data-tip="tooltip"
        className="rounded-full border-2 border-black px-2 py-0.5 text-center text-black font-bold text-xs hover:cursor-default"
      >
        ?
      </a>
      <ReactTooltip place="right" delayShow={800}>
        <span>This sync, for both Guardians & Students, will:</span>
        <ul className="list-disc ml-4">
          <li>Add their e-mails to Mailchimp</li>
          <li>Update their respective Mailchimp Tags</li>
          <li>For inactive students, remove the student & respective guardians from Mailchimp</li>
          <br />
          <li>
            Mailchimp Tags to sync:
            <ul className="list-disc ml-4">
              <li>ALL_SORA_PARENTS</li>
              <li>ALL_STUDENTS</li>
              <li>MS_PARENTS</li>
              <li>MS_STUDENTS</li>
              <li>ATHENA_PARENTS</li>
              <li>ATHENA_STUDENTS</li>
              <li>OG_PARENTS</li>
              <li>OG_STUDENTS</li>
            </ul>
          </li>
        </ul>
      </ReactTooltip>
    </div>
  )
}
