import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import Slideover from '@components/slideover'
import UnitRubric from './unit-rubric'
import AbilityRubric from '../ability-rubric'

const RubricDrawer = forwardRef(({ onChange }, ref) => {

  const [showDrawer, setShowDrawer] = useState(false)
  const [rubricItemType, setRubricItemType] = useState(null)
  const [rubricItemId, setRubricItemId] = useState(null)

  useImperativeHandle(ref, () => ({
    open(type, id) {
      setRubricItemType(type)
      setRubricItemId(id)
      setShowDrawer(true)
    }
  }))

  return (
    <>
      <Slideover maxWidth="max-w-4xl" open={showDrawer} setOpen={setShowDrawer}>
        {rubricItemType === 'unit' && <UnitRubric id={String(rubricItemId)} />}
        {rubricItemType === 'ability' && <AbilityRubric id={String(rubricItemId)} />}
      </Slideover>
    </>
  )
})
RubricDrawer.displayName = 'RubricDrawer'
RubricDrawer.propTypes = {
  onChange: PropTypes.func
}

export default RubricDrawer
