import React from 'react'

export enum ONBOARDING_STATUS {
  NOT_STARTED = 'not_started',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  NOT_INVITED = 'not_invited',
  NONE = '',
}

interface OnboardingStatusTagProps {
  status: ONBOARDING_STATUS,
  lastCompletedChecklist?: string
}

export default function OnboardingStatusTag({ status, lastCompletedChecklist }: OnboardingStatusTagProps) {
  if (status === ONBOARDING_STATUS.NONE)
    return <span className="justify-center flex"><p>-</p></span>

  return status === ONBOARDING_STATUS.IN_PROGRESS && lastCompletedChecklist ? (
    <div className="flex flex-col w-fit space-y-1">
      <TagContainer status={status}>
        {status.replace('_', ' ')}
      </TagContainer>
      <small>{lastCompletedChecklist}</small>
    </div>
  )
    : (
      <TagContainer status={status}>
        {status.replace('_', ' ')}
      </TagContainer>
    )
}

function TagContainer({ children, status }: { children: React.ReactNode, status: ONBOARDING_STATUS }) {
  let textColor: string, bgColor: string
  switch (status) {
    case ONBOARDING_STATUS.IN_PROGRESS:
      textColor = 'text-blue-80'
      bgColor = 'bg-blue-5'
      break
    case ONBOARDING_STATUS.COMPLETED:
      textColor = 'text-green-90'
      bgColor = 'bg-success-30'
      break
    case ONBOARDING_STATUS.NOT_STARTED:
      textColor = 'text-gray-90'
      bgColor = 'bg-gray-15'
      break
    case ONBOARDING_STATUS.NOT_INVITED:
      textColor = 'text-white'
      bgColor = 'bg-gray-85'
      break
  }
  return (
    <span className={`${textColor} ${bgColor} w-28 px-3 py-1 rounded-md text-sm font-bold text-center capitalize`}>
      {children}
    </span>
  )
}