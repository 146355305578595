import React from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router-dom'
import { Typography } from '@design-system'

type LoaderData = {
  url: string
}
const loader = async (): Promise<LoaderData> => {
  const { data } = await axios.get('/backoffice/impact-dashboard')
  return data
}

export const Element = () => {
  const { url } = useLoaderData() as LoaderData
  return (
    <div className="flex flex-col w-full h-full">
      <Typography variant="heading-3" weight="bold" className="mb-6">Impact Dashboard</Typography>
      <iframe src={url} className="w-full h-full" />
    </div>
  )
}

export const ImpactDashboardRoute = {
  loader,
  Element,
}
