import React, { useRef, useState } from "react"
import Slideover from "@components/slideover"
import axios from "axios"
import { Button, TextField } from "@designsystem"
import { useLoaderData, useNavigation, useNavigate } from "react-router"
import { Form, useActionData } from "react-router-dom"
import Select from "@components/forms/select"
import { Switch } from '@headlessui/react'
import SlateTextarea from "@components/forms/slate-textarea"
import { useToast } from "@hooks/useToast"

async function loader({ params }) {
  const { feature_flag_id } = params
  const { data: featureFlagSettings } = await axios.get(`sora_labs/${feature_flag_id}`)
  const { data: soraLabsConfigs } = await axios.get(`sora_labs/configs`)

  return {
    featureFlagSettings: featureFlagSettings,
    soraLabsConfigs: soraLabsConfigs
  }
}

async function action({ request }) {
  const formData = await request.formData()

  try {
    await axios.post(`sora_labs/`, formData, {
      'Content-Type': 'multipart/form-data'
    })

    return {
      toast: { message: 'Sora Labs settings saved!', appearance: 'success' },
    }
  } catch (error) {
    return {
      toast: { message: 'Failed to save Sora Labs settings', appearance: 'error' },
    }
  }
}

function Element() {
  useToast(useActionData())
  const loaderData = useLoaderData()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const slateRef = useRef(null)
  const [description, setBody] = useState(loaderData.featureFlagSettings.description)
  const [enabled, setEnabled] = useState(loaderData.featureFlagSettings.isEnabled ?? false)
  const initialCohortsIds = loaderData.featureFlagSettings.permissions
  const [_, setFormValues] = useState({
    cohorts_permissions_ids: initialCohortsIds,
  })
  const soraLabsCohortPermissions = loaderData.soraLabsConfigs.soraLabsPermissions.permissionsTypes.cohorts

  let thumbnailUrl
  let videoUrl
  if (loaderData.featureFlagSettings.thumbnailUrl) {
    thumbnailUrl = loaderData.featureFlagSettings.thumbnailUrl
    videoUrl = loaderData.featureFlagSettings.videoUrl
    thumbnailUrl = `/${thumbnailUrl.slice(thumbnailUrl.lastIndexOf('sora_labs'), thumbnailUrl.length)}`
    if (videoUrl) {
      videoUrl = `/${videoUrl.slice(videoUrl.lastIndexOf('sora_labs'), videoUrl.length)}`
    }
  }

  return (
    <Slideover
      open={true}
      setOpen={() => navigate('../')}
      title={<span>Sora Labs Settings</span>}
      maxWidth="max-w-[27vw]"
    >
      <Form method="POST" className="flex flex-col space-y-4" encType='multipart/form-data'>
        <input type="hidden" name="id" value={loaderData.featureFlagSettings.id} />
        <input type="hidden" name="featureFlagId" value={loaderData.featureFlagSettings.featureFlagId} />
        <input type="hidden" name="description" value={JSON.stringify(description)} />
        <input type="hidden" name="isEnabled" value={enabled} />
        <input type="hidden" name="thumbnailUrl" value={loaderData.featureFlagSettings.thumbnailUrl} />
        <input type="hidden" name="videoUrl" value={loaderData.featureFlagSettings.videoUrl} />

        <h1 className="text-2xl font-medium  dark:text-white">{loaderData.featureFlagSettings.title}</h1>
        <h3>This information will  be visible for users for features that have the Sora Labs status.</h3>
        <TextField label="Name" name="name" defaultValue={loaderData.featureFlagSettings.name} type="text" required />
        <SlateTextarea
          noAttachments
          ref={slateRef}
          key={`description`}
          value={description}
          id='description'
          className='min-h-[15rem]'
          onChange={setBody}
          placeholder='Project description'
        />
        <TextField label="Thumbnail" name="thumbnail" type="file" required={!loaderData.featureFlagSettings.thumbnailUrl} multiple={false} />
        <h3 className="ml-4 text-left">Current thumbnail file: {thumbnailUrl}</h3>
        <TextField label="Video" name="video" type="file" multiple={false} />
        <h3 className="ml-4 text-left">Current video file: {videoUrl}</h3>
        <TextField label="Learn more link (optional)" name="link" defaultValue={loaderData.featureFlagSettings?.link} type="text" />
        <TextField label="Path" name="path" defaultValue={loaderData.featureFlagSettings?.path} type="text" />
        <h3 className="ml-3 text-left">Available To</h3>
        <Select
          name="cohorts_permissions_ids"
          label="Available To"
          defaultValue={initialCohortsIds.map(id => {
            const permission = soraLabsCohortPermissions.find((option) => option.id === id)
            return { label: permission.label, value: permission.id, cohortId: permission.cohortId, role: permission.role }
          })}
          options={soraLabsCohortPermissions.map(lb => ({ label: lb.label, value: lb.id }))}
          onChange={(cohorts) => {
            setFormValues((prevState) => {
              const newCohorts = cohorts.map(each => ({ value: each.id }))
              return {
                ...prevState,
                cohorts_permissions_ids: newCohorts,
              }
            })
          }}
          isMulti
        />
        <legend className="font-bold px-2 flex flex-row justify-between">
          <span className="ml-2">Show on Sora Labs</span>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${enabled ? 'bg-blue-50' : 'bg-gray-10'} relative inline-flex items-center h-6 rounded-full w-12 transition-colors ease-in-out duration-200`}
          >
            <span className={`${enabled ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 bg-white rounded-full transform ring-0 transition ease-in-out duration-200`} />
          </Switch>
        </legend>
        <Button
          type="submit"
          loading={navigation.state === 'submitting'}
        >
          {navigation.state === 'submitting' ? 'Sending...' : 'Save changes'}
        </Button>
      </Form>
    </Slideover>
  )
}

export const FeatureFlagSettingsLabsRoute = {
  loader,
  Element,
  action
}
