import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import useSWR from 'swr'
import { toast } from 'sonner'
import { SingleSlateTextarea, isBlankValue } from '@components/forms/short-slate-textarea'
import { createSearchParams } from 'react-router-dom'
import TimeAgoHelper from '@utils/timeAgoHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import useConfirmModal from '@hooks/useConfirmModal'
import Emoji from '@components/emoji'
import Avatar from '@components/avatar'
import { TextOnlyWithLinkSlate } from '@components/forms/slate-textarea'
import { Transforms } from 'slate'

const CommentThread = forwardRef(({ user, taskId, studentId, taskSubmissionId, placeholder, className, readOnly = false, collapsable = true, hideSendButton = false, hideAiButton = true, onClickAi, onChange, lastTypedMessage, customEditor }, ref) => {
  const confirm = useConfirmModal()
  const slateRef = useRef()
  const [message, setMessage] = useState(lastTypedMessage)
  const [isCollapsable, setIsCollapsable] = useState(false)
  const [collapsed, setCollapsed] = useState(null)
  const [slateKey, setSlateKey] = useState(Math.random())

  const params = Object.fromEntries(Object.entries({ task_id: taskId, student_id: studentId, task_submission_id: taskSubmissionId }).filter(([_, v]) => Boolean(v)))
  const { data, mutate } = useSWR(taskId && studentId ? `/comments?${createSearchParams(params)}` : null, {
    onSuccess: (data) => {
      const numComments = data?.comments?.length || 0
      const hasComments = numComments > 0
      const noTypedMessage = isBlankValue(message)
      setIsCollapsable(hasComments)
      if ((hasComments && numComments > 3 && noTypedMessage && collapsed === null) && collapsable) setCollapsed(true)
    }
  })

  useImperativeHandle(ref, () => ({
    async submitCommentsBeforePublish() {
      if (!isCommentInvalid()) {
        await handleSend()
      }
    }
  }))

  const isCommentInvalid = () => {
    const content = customEditor ? customEditor.children : message
    return !content || isBlankValue(content)
  }

  const handleUpdateMessage = (msg) => {
    setMessage(msg)
    if (onChange) onChange(msg)
  }

  const handleSend = async () => {
    const content = customEditor ? customEditor.children : message

    const payload = {
      student_id: studentId,
      task_id: taskId,
      task_submission_id: taskSubmissionId,
      user_id: user.user_id,
      body: content
    }

    try {
      await axios.post('/comments', payload)
      setMessage(null)
      if (customEditor)
        Transforms.select(customEditor, { offset: 0, path: [0, 0] })
      setSlateKey(Math.random())
      mutate()
    } catch (error) {
      toast.error('Unable to send message, please try again.')
    }
  }

  const handleRemove = (id) => async () => {
    if (!await confirm('Are you sure you want to remove this comment?')) return
    try {
      await axios.delete(`/comments/${id}`)
      mutate()
    } catch (error) {
      toast.error('Unable to remove this message, please try again.')
    }
  }

  const toggleCollapseView = () => {
    setCollapsed(!collapsed)
  }

  const renderComments = () => {
    return data?.comments.map(comment => (
      <div key={`comment:${comment.id}:${comment.user_id}`} className='flex flex-row gap-2'>
        <div className='flex flex-col'>
          <Avatar name={comment.author_name} imgUrl={comment.author_img_url} className='w-7 h-7' />
        </div>
        <div className='flex flex-col'>
          <div className='flex flex-row gap-2 items-center h-7'>
            <span className='text-md text-black font-bold'>{comment.author_name}</span>
            <span className='text-sm text-gray-40'>{TimeAgoHelper().format(new Date(comment.updated_at))}</span>
            {(!readOnly && user.user_id == comment.user_id) &&
              <span className='px-2 py-1 text-gray-40 cursor-pointer hover:bg-gray-10 hover:text-gray-60 rounded-full' onClick={handleRemove(comment.id)}>
                <FontAwesomeIcon icon={faTrashCan} />
              </span>
            }
          </div>
          <div style={{ wordBreak: "break-word" }}>
            <TextOnlyWithLinkSlate value={comment.body} />
          </div>
        </div>
      </div>
    ))
  }

  if (readOnly && data?.comments?.length <= 0) return (<></>)

  return (
    <div className={className}>
      <div className={`flex flex-col gap-3 ${collapsed ? 'hidden' : ''}`}>
        {(collapsable && isCollapsable) &&
          <div className='text-center text-gray-40'>
            <span className='hover:text-white hover:underline cursor-pointer' onClick={toggleCollapseView}>- collapse -</span>
          </div>
        }
        {renderComments()}
        {!readOnly &&
          <div style={{ wordBreak: "break-word" }}>
            <SingleSlateTextarea
              ref={slateRef}
              key={slateKey}
              id={`assment:bla`}
              className='min-h-10'
              placeholder={placeholder ? placeholder : 'Add a comment...'}
              onChange={handleUpdateMessage}
              value={message}
              onSend={handleSend}
              avatarComponent={user && <Avatar name={user.name} imgUrl={user.profileImgUrl} className='h-7 w-7' />}
              hideSend={hideSendButton}
              hideAi={hideAiButton}
              onClickAi={onClickAi}
              customEditor={customEditor}
            />
          </div>
        }
      </div>
      {!readOnly &&
        <div className={`flex flex-row gap-3 p-2 -mx-2 cursor-pointer hover:bg-gray-10 ${collapsed ? '' : 'hidden'}`} onClick={toggleCollapseView}>
          <div className='rounded-full bg-gray-30 w-7 h-7'></div>
          <span className='self-center'>
            <Emoji name='chat' title='Comments' />
          </span>
          <span className='self-center'>{Number(data?.comments?.length || 0)}</span>
        </div>
      }
    </div>
  )
})

CommentThread.displayName = 'CommentThread'

CommentThread.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string,
  taskId: PropTypes.string,
  studentId: PropTypes.string,
  taskSubmissionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  collapsable: PropTypes.bool,
  hideSendButton: PropTypes.bool,
  hideAiButton: PropTypes.bool,
  onClickAi: PropTypes.func,
  lastTypedMessage: PropTypes.any,
  onChange: PropTypes.func,
  customEditor: PropTypes.any,
}

export default CommentThread
