export const CATEGORIES = {
  learning_goal: 'learning_goal',
  math_learning_goal: 'math',
  language_learning_goal: 'world languange'
}

export const CATEGORY_BG_LIGHT_COLORS = {
  humanities: 'bg-turquoise-5 bg-opacity-30',
  stem: 'bg-green-5 bg-opacity-30',
  activity: 'bg-cyan-5 bg-opacity-30',
  language_learning_goal: 'bg-blue-5 bg-opacity-30',
  math_learning_goal: 'bg-purple-5 bg-opacity-30',
  elective: 'bg-orange-5 bg-opacity-30',
  learning_goal: 'bg-notice-5 bg-opacity-30',
  track: 'bg-danger-5 bg-opacity-30',
  coworking: 'bg-pink-5 bg-opacity-30',
  student_club: 'bg-notice-5 bg-opacity-30',
  independent_study: 'bg-yellow-5 bg-opacity-30',
}

export const CATEGORY_BG_DARK_COLORS = {
  humanities: 'bg-turquoise-5',
  stem: 'bg-green-5',
  activity: 'bg-cyan-5',
  language_learning_goal: 'bg-blue-5',
  math_learning_goal: 'bg-purple-5',
  elective: 'bg-orange-5',
  learning_goal: 'bg-notice-5',
  track: 'bg-danger-5',
  coworking: 'bg-pink-5',
  student_club: 'bg-notice-5',
  independent_study: 'bg-yellow-5',
}

export const CATEGORY_BORDER_COLORS = {
  humanities: 'border-turquoise-5',
  stem: 'border-green-5',
  activity: 'border-cyan-5',
  language_learning_goal: 'border-blue-5',
  math_learning_goal: 'border-purple-5',
  elective: 'border-orange-5',
  learning_goal: 'border-notice-5',
  track: 'border-danger-5',
  coworking: 'border-pink-5',
  student_club: 'border-notice-5',
  independent_study: 'border-yellow-5',
}

export const CATEGORY_TEXT_COLORS = {
  humanities: 'text-turquoise-50',
  stem: 'text-green-50',
  activity: 'text-cyan-50',
  language_learning_goal: 'text-blue-50',
  math_learning_goal: 'text-purple-50',
  elective: 'text-orange-50',
  learning_goal: 'text-notice-50',
  track: 'text-danger-50',
  coworking: 'text-pink-50',
  student_club: 'text-notice-50',
  independent_study: 'text-yellow-5',
}

export const CATEGORY_COLORS_HEX = {
  humanities: '#CBECE5',
  stem: '#DDF2D9',
  activity: '#CDF0F6',
  language_learning_goal: '#D4E1F7',
  math_learning_goal: '#E0D6F5',
  elective: '#FEE3D0',
  learning_goal: '#FBFBDE',
  track: '#F2D0CE',
  coworking: '#FBDAEB',
  student_club: '#FBFBDE',
  independent_study: '#FBF0CE',
}

export const getCategoryName = category => CATEGORIES[category] ?? category

const TYPES = {
  'learning_goal': 'learning goal',
  'language_learning_goal': 'world language',
  'math_learning_goal': 'math',
}
export const getTypeName = type => TYPES[type] ?? type
 
export const getCategoryColor = _category => {
  const category = _category.toLowerCase().replace(' ', '_')
  return {
    bgLight: CATEGORY_BG_LIGHT_COLORS[category] || 'bg-blue-5 bg-opacity-30',
    bgDark: CATEGORY_BG_DARK_COLORS[category] || 'bg-blue-5',
    border: CATEGORY_BORDER_COLORS[category] || 'border-blue-5',
    text: CATEGORY_TEXT_COLORS[category] || 'text-blue-50',
    hex: CATEGORY_COLORS_HEX[category] || '#CDF0F6',
  }
}
