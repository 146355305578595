import React from 'react'

const WorkbenchIndex = () => {
  return (
    <div className='flex flex-col items-center gap-4 py-50'>
      <img src="/assets/workbench.png" alt="Workbench" className='h-40' />
      <p>Choose the tool you want to work on the left</p>
    </div>
  )
}

export default WorkbenchIndex