import * as React from 'react'
import axios from 'axios'
import { useLoaderData, defer, useSubmit, useSearchParams, Form, Await } from 'react-router-dom'
import { columns } from './columns'
import SoraLink from '@components/link'
import { DataTable } from './data-table'
import { TextField } from '@designsystem'
import { Icon, Button, Typography, Combobox } from '@design-system'

type LabelValue = {
  value: string;
  label: string;
}

export type RegistrationChanges = {
  student: {
    id: number,
    name: string,
    email: string,
    grade: string,
    schoolStage: string,
  }
  experience: {
    id: number,
    type: string,
    title: string,
    experts: string[],
    cycle: string,
  },
  action: {
    label: string,
    value: string,
  },
  learningBlock: string,
  notes: string,
  source: string,
  timestamp: Date,
  userName: string,
  house: {
    id: number,
    title: string
  },
}

type LoaderData = {
  data: {
    registrationChanges: RegistrationChanges[],
    houseFilterOptions: LabelValue[],
    changeActionsFilters: LabelValue[],
    schoolStageFilterOptions: LabelValue[],
  }
}

async function getRegistrationChanges(options: { houseId: number[], search: string, category: string, schoolStage: string }) {
  const { data } = await axios.get('/backoffice/reports/registration-changes')
  return {
    houseFilterOptions: data.houseFilterOptions,
    changeActionsFilters: data.changeActionsFilters,
    schoolStageFilterOptions: data.schoolStageFilterOptions,
    registrationChanges: filterRegistrationChanges(data.registrationChanges, options),
  }
}

function filterRegistrationChanges(data: RegistrationChanges[], options: { houseId: number[], search: string, category: string, schoolStage: string }) {
  const { houseId, search, category, schoolStage } = options
  const result = data.filter((registrationChange) => {
    const matchesHouseId = !houseId.length || houseId.includes(registrationChange.house.id)
    const searchTerm = search?.trim().toLowerCase()
    const searchFields = [registrationChange.student.name, registrationChange.experience.title]
    const matchesSearch = !searchTerm || searchFields.some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()))
    const matchesAction = !category || category === registrationChange.action.value
    const matchesSchoolStage = !schoolStage || schoolStage === registrationChange.student.schoolStage
    return matchesHouseId && matchesSearch && matchesAction && matchesSchoolStage
  })
  return result
}

async function loader({ request }) {
  const searchParams = new URL(request.url).searchParams
  if (searchParams.get('output') === 'csv') {
    return await getCsv()
  }
  return defer({
    data: await getRegistrationChanges({
      houseId: searchParams.getAll('houseId').map(Number),
      search: searchParams.get('search'),
      category: searchParams.get('category'),
      schoolStage: searchParams.get('schoolStage'),
    })
  })
}

async function getCsv() {
  return await axios.get('/backoffice/reports/registration-changes/csv')
}

function Element() {
  const { data } = useLoaderData() as LoaderData
  const submit = useSubmit()
  const [searchParams, setSearchParams] = useSearchParams()
  const { houseFilterOptions, changeActionsFilters, schoolStageFilterOptions } = data

  const houseIds = searchParams.getAll('houseId')
  const search = String(searchParams.get('search') || '')
  const category = String(searchParams.get('category') || '')
  const schoolStage = String(searchParams.get('schoolStage') || '')
  const selectedHouses = houseIds.map((houseId) => houseFilterOptions.find((house) => house.value === houseId))

  const handleClearFilter = (filter: 'houseId' | 'category' | 'schoolStage', currentValue: string) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams)
      newParams.delete(filter, currentValue)
      return newParams
    })
  }

  return (
    <div className="flex flex-col gap-6 w-[calc(100%-var(--aside-reports-width))]"> 
      <div className="flex items-center justify-between">
        <Typography variant="heading-3" weight="bold">Registration Changes</Typography>
        <Button variant="outlined" asChild>
          <SoraLink
            role="link"
            target='_blank'
            to={`/api/backoffice/reports/registration-changes/csv`}
          >
            <Icon name="file-download" />
            Download as CSV
          </SoraLink>
        </Button>
      </div>
      <Form method="GET" onChange={(e) => submit(e.currentTarget)} className="grid xl:grid-cols-2 gap-2 items-center">
        <TextField
          startAdornment={<Icon name="search" size="sm" />}
          placeholder="Search for student or experience"
          name="search"
          fullWidth
          defaultValue={search}
          autoFocus
        />
        <div className="flex xl:justify-around gap-2">
          <Combobox
            multiple
            name="houseId"
            className="w-full space-y-0 h-full"
            defaultValue={houseIds}
            placeholder="All houses"
            key={`combobox-houseId-${houseIds.toString()}`}
            search={<Combobox.Search placeholder="Type here to search for houses..." />}
          >
            {houseFilterOptions.map((house) => (
              <Combobox.Item key={`citem-house-${house.value}`} value={house.value}>
                {house.label}
              </Combobox.Item>
            ))}
          </Combobox>
          <Combobox
            name="category"
            defaultValue={category}
            key={`combobox-category-${category}`}
            placeholder="All categories"
            className="w-full space-y-0 h-full"
          >
            {changeActionsFilters.map((category) => (
              <Combobox.Item key={`citem-category-${category.value}`} value={category.value}>
                {category.label}
              </Combobox.Item>
            ))}
          </Combobox>
          <Combobox
            name="schoolStage"
            defaultValue={schoolStage}
            key={`combobox-schoolStage-${schoolStage}`}
            placeholder="All students"
            className="w-full space-y-0 h-full"
          >
            {schoolStageFilterOptions.map((schoolStage) => (
              <Combobox.Item key={`citem-schoolStage-${schoolStage.value}`} value={schoolStage.value}>
                {schoolStage.label}
              </Combobox.Item>
            ))}
          </Combobox>
        </div>
      </Form>
      <div className="flex flex-wrap gap-2">
        {houseIds.length > 0 && (
          selectedHouses.map((house) => (
            <Button size="sm" key={`button-${house.value}`} onClick={() => handleClearFilter('houseId', house.value)}>
              {house.label}
              <Icon name="cross" size="sm" />
            </Button>
          ))
        )}
        {category && (
          <Button size="sm" onClick={() => handleClearFilter('category', category)}>
            Clear Category Filter
            <Icon name="cross" size="sm" />
          </Button>
        )}
        {schoolStage && (
          <Button size="sm" onClick={() => handleClearFilter('schoolStage', schoolStage)}>
            Clear School Stage Filter
            <Icon name="cross" size="sm" />
          </Button>
        )}
      </div>
      <React.Suspense fallback="loading...">
        <Await resolve={data.registrationChanges}>
          {(registrationChanges) => <DataTable data={registrationChanges} columns={columns} />}
        </Await>
      </React.Suspense>
    </div>
  )
}

export const ReportsRegistrationChangesRoute = { Element, loader }
