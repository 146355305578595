import React from 'react'

const LearnMoreContent = () => {
  return (
    <div>
      <p>
        Units and abilities are the currency that fuel your pace and
        progress at Sora. Where your pace incentivizes you to cover the floor
        (breadth), your GPA incentivizes you to go deep in areas of interest
        (depth) by gaining higher mastery levels in your goals. We've separated
        these metrics so if you're looking for guidance on if you'll graduate on
        time and what to do next to meet your requirements, you'll look at your
        pace. If you're looking to know how well you're doing or trying to
        translate your performance to a traditional system you'll look at your
        GPA.
      </p>

      <p className="mt-4">
        The more units & abilities you sign up for in registration each
        cycle, the more likely you are to be above pace (meaning you could
        potentially graduate early). The deeper you go in one specific unit or ability,
        the higher your GPA will be. All students who wish to
        graduate from Sora will have to cover the floor requirements, that's why
        we drive you toward completing new units & ability
        demonstrations in your cycle target. We set the floor low so you could
        spend more time exploring the areas you're most interested in and really
        show that through higher GPA in those areas.
      </p>
    </div>
  )
}

export default LearnMoreContent
