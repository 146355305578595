import PropTypes from 'prop-types'
import React, { useEffect, useCallback, useState } from 'react'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { toast } from 'sonner'
import axios from 'axios'
import { faChevronDown, faX } from '@fortawesome/free-solid-svg-icons'
import { Disclosure } from '@headlessui/react'
import useCurrentUser from '@hooks/useCurrentUser'
import SoraIcon, {
  soraAssignment,
  soraEvent,
  soraMailOutline,
} from '@components/sora-icon'
import { Button } from '@designsystem'

const ExcuseDetailsModal = ({ onClose, onEdit, onRemove, excuse = {} }) => {
  const { title, excuses = [] } = excuse || {}

  const keyPressedFn = useCallback(({ keyCode }) => {
    if (keyCode === 27) { // Close modal w/ Esc key
      onClose()
    }
  }, [onClose])
  useEffect(() => {
    document.addEventListener('keydown', keyPressedFn, false)
    return () => {
      document.removeEventListener('keydown', keyPressedFn, false)
    }
  }, [keyPressedFn])

  return <div
    aria-modal="true"
    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-100 md:min-w-96 shadow-2xl rounded-lg bg-white ring-1 ring-black ring-opacity-10"
    onClick={onClose}
  >
    <div className="h-full rounded-lg px-7 py-6" onClick={e => e.stopPropagation()}>
      <header className="flex flex-col font-bold text-lg max-w-[32rem] space-y-1 mb-1">
        <div className="flex items-center">
          <span className="truncate">{title}</span>
          <div className="ml-auto">
            <Button startIcon={faX} size="xs" variant="nude" onClick={onClose} />
          </div>
        </div>
      </header>
      {
        excuses.length ?
          <div className="flex flex-col space-y-2 py-3">
            {excuses.map((exc, i) => (
              <>
                <Disclosure key={i}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='w-full focus:outline-none'>
                        <div className="flex items-center w-full justify-between p-2 text-sm text-gray-70 rounded-lg hover:bg-gray-20">
                          <div className="flex flex-col">
                            <span>{exc.title}</span>
                            <span className="text-xs text-gray-70 text-left font-bold">{!exc.approved ? '(Waiting for approval)' : ''}</span>
                          </div>
                          <FontAwesomeIcon icon={faChevronDown} className={`${open ? 'transform rotate-180' : ''} ml-3 w-4 h-4`} />
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className='px-8'>
                        <ExcuseDetails excuse={exc} onEdit={onEdit} onRemove={onRemove} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <div className="w-11/12 self-center border-b rounded-full" />
              </>
            ))}
          </div>
          : <div className="flex flex-col space-y-2 pt-5">
            <ExcuseDetails excuse={excuse} onEdit={onEdit} onRemove={onRemove} />
          </div>
      }
    </div>
  </div>
}
ExcuseDetailsModal.propTypes = {
  excuse: PropTypes.object,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func
}

function ExcuseDetails({ excuse, onEdit, onRemove }) {
  const { id, start, end, reason, additional_comments, recurring_id = null, allDay = false } = excuse
  const currentUser = useCurrentUser()
  const [currentView, setCurrentView] = useState('details')
  const [eventsToUpdate, setEventsToUpdate] = useState('current_following')
  const isAdmin = currentUser?.role === 'admin'
  return (
    <>
      <div className="flex flex-col w-full max-w-md bg-white rounded-2xl space-y-3 mb-5 text-gray-70">
        <div className="flex space-x-3">
          <div className="flex flex-col">
            <SoraIcon icon={soraEvent} />
          </div>
          <div className="flex items-center space-x-5 text-sm">
            <span><b>From:</b> {formatInTimeZone(new Date(start), 'America/New_York', allDay ? 'MMM, dd' : 'MMM, dd hh:mm a')} (EST)</span>
            <span><b>To:</b> {formatInTimeZone(new Date(end), 'America/New_York', allDay ? 'MMM, dd' : 'MMM, dd hh:mm a')} (EST)</span>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <SoraIcon icon={soraAssignment} />
          <span className="text-sm">{reason || 'No reason was added'}</span>
        </div>
        <div className="flex items-center space-x-3">
          <SoraIcon icon={soraMailOutline} />
          <span className="text-sm">{additional_comments || 'No comment was added'}</span>
        </div>
      </div>
      {
        currentView === 'details' &&
        <div className="flex justify-between w-full mb-3">
          <Button startIcon={faTrashAlt} color="danger" size="sm" onClick={() => { setCurrentView('delete') }} />
          <Button size="sm" onClick={() => { onEdit(excuse) }}>Edit</Button>
        </div>
      }
      {
        currentView === 'delete' &&
        <div className="space-y-5">
          {
            recurring_id && isAdmin ?
              <div className="flex flex-col">
                <span className="text-center text-sm">Please select the events to remove:</span>
                <div className="flex flex-col mx-auto mt-1 space-y-1">
                  <div className="flex items-center space-x-2 text-sm">
                    <input defaultChecked={true} type="radio" name="eventsToUpdate" onClick={() => setEventsToUpdate('current_following')} />
                    <span>This and following events</span>
                  </div>
                  <div className="flex items-center space-x-2 text-sm">
                    <input type="radio" name="eventsToUpdate" onClick={() => setEventsToUpdate('current')} />
                    <span>This Event</span>
                  </div>
                  <div className="flex items-center space-x-2 text-sm">
                    <input type="radio" name="eventsToUpdate" onClick={() => setEventsToUpdate('all')} />
                    <span>All Events</span>
                  </div>
                </div>
              </div>
              : <div className="flex justify-center text-sm">Are you sure you want to remove this Excuse?</div>
          }
          <div className="flex items-center justify-center w-full space-x-8">
            <Button size="xs" onClick={() => { setCurrentView('details') }}>
              Cancel
            </Button>
            <Button color="danger" size="xs" onClick={() => removeExcuse(id, onRemove, isAdmin, { excuseId: id, recurring_id, eventsToUpdate })}>
              Remove
            </Button>
          </div>
        </div>
      }
    </>
  )
}
ExcuseDetails.propTypes = {
  excuse: PropTypes.object,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
}

async function removeExcuse(id, onRemove, isAdmin, recurringProps = {}) {
  try {
    if (isAdmin && recurringProps.recurring_id) {
      await axios.delete('/excuses/recurring/' + recurringProps.recurring_id + '?' + new URLSearchParams(recurringProps).toString())
    } else {
      await axios.delete('/excuses/' + id)
    }
    onRemove && onRemove()
    toast.success('Excuse removed!')
  } catch (e) {
    toast.error('Could not remove excuse!')
  }
}

export default ExcuseDetailsModal
