import React from 'react'
import PropTypes from 'prop-types'

export const CampusTag = ({ index = 0, title }) => {
  const colorStyles = [
    'border-blue-20 text-blue-20',
    'border-yellow-40 text-yellow-40', 
    'border-red-400 text-red-400',
    'border-green-40 text-green-40', 
    'border-gray-50 text-gray-50',
  ]
  return (
    <span className={`rounded border p-1 font-bold ${colorStyles[index]}`}>
      {title}
    </span>
  )
}
CampusTag.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
}
