import * as React from 'react'
import axios from 'axios'
import { ActionFunctionArgs, useLoaderData } from 'react-router-dom'
import { Button, Icon, unstable_Table as Table, useFetcher } from '@design-system'
import serializeFormData from '@utils/serializeFormData'

interface LoaderData {
  houses: Array<{
    id: number,
    title: string,
    cohortTitle: string,
    heartbeatChannelId: string,
    heartbeatGroupId: string,
    heartbeatVoiceChannelId: string,
  }>,
  headers: Array<string>
}

async function loader() {
  const res = await axios.get('/backoffice/workbench/student-heartbeat-server/houses')
  return res.data
}

async function action({ request }: ActionFunctionArgs) {
  const form = serializeFormData(await request.formData())
  const houseId = form?.houseId ?? ''
  const res = await axios.post(`/backoffice/workbench/student-heartbeat-server/houses/${houseId}`)
  return res.data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData
  
  return (
    <div className="flex flex-col gap-4 px-6">
      <SyncFormButton title="Sync all houses" />
      <Table className="border-separate border border-gray-30 rounded-lg">
        <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
          <Table.Row>
            {loaderData.headers.map((header, index) => (
              <Table.Head key={index} className="w-48 first:pl-4">{header}</Table.Head>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body className="[&_tr:last-child]:border-1">
          {loaderData.houses.map((house) => (
            <Table.Row key={house.id} className="last-of-type:rounded-b-md">
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{house.id}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{house.title}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{house.cohortTitle}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{house.heartbeatChannelId ?? '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{house.heartbeatGroupId ?? '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{house.heartbeatVoiceChannelId ?? '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                <SyncFormButton title="Sync this house" name="houseId" value={house.id.toString()} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

interface SyncFormButtonProps {
  title: string,
  name?: string,
  value?: string,
}

function SyncFormButton({ title, name, value }: SyncFormButtonProps) {
  const fetcher = useFetcher()
  const loading = fetcher.state === 'loading' || fetcher.state === 'submitting'
  return (
    <fetcher.Form method="POST" className="self-end">
      <Button type="submit" name={name} value={value} size="sm" loading={loading}>
        <Icon name="arrow-swap" size="xs" /> {title}
      </Button>
    </fetcher.Form>
  )
}

export const WorkbenchStudentHearbeatServerHousesRoute = { loader, action, Element }