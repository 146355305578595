import React, { Fragment, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'

const Slideover = ({
  title,
  open,
  setOpen,
  children,
  maxWidth = 'max-w-2xl',
  position = 'right',
}) => {
  const keyPressedFn = useCallback(({ keyCode }) => {
    if (keyCode === 27) { setOpen(false) }
  }, [setOpen])

  useEffect(() => {
    document.addEventListener('keydown', keyPressedFn, false)
    return () => {
      document.removeEventListener('keydown', keyPressedFn, false)
    }
  }, [keyPressedFn])

  return (
    <Transition.Root show={open} as={Fragment} appear={true}>
      {/* appear=true to have the component slide in on mount */}
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-50"
        onClose={() => null}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 bg-alpha/70 transition-opacity" onClick={() => setOpen(false)} />
          </Transition.Child>
          <div
            className={`pointer-events-none fixed ${position === 'right'
              ? 'inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'
              : 'inset-x-0 bottom-0 flex max-w-full pt-10 sm:pt-16'
              }`}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom={
                position === 'right' ? 'translate-x-full' : 'translate-y-full'
              }
              enterTo={position === 'right' ? 'translate-x-0' : 'translate-y-0'}
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom={
                position === 'right' ? 'translate-x-0' : 'translate-y-0'
              }
              leaveTo={
                position === 'right' ? 'translate-x-full' : 'translate-y-full'
              }
            >
              <div
                className={`pointer-events-auto w-screen ${position === 'right' ? maxWidth : ''
                  }`}
              >
                <div
                  className={`flex ${position === 'right' ? 'h-full' : 'w-full'
                    } flex-col overflow-y-auto bg-white dark:bg-gray-100 dark:text-gray-5 shadow-xl z-50 px-4 pb-4 md:px-8 md:pb-8`}
                  id="slideover-content-wrapper"
                >
                  <div className="flex sticky top-0 z-10 pt-4 pb-4 items-center justify-between mb-4 bg-white dark:bg-gray-100">
                    <Dialog.Title className="text-2xl font-medium  dark:text-white">
                      {title}
                    </Dialog.Title>
                    <div className="flex items-center">
                      <img
                        src="/assets/icons/close-icon.svg"
                        alt="Close icon"
                        title="Close"
                        className="cursor-pointer w-4 h-4 dark:invert"
                        onClick={() => setOpen(false)}
                      />
                    </div>
                  </div>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Slideover.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  maxWidth: PropTypes.string,
  children: PropTypes.node,
  position: PropTypes.oneOf(['right', 'bottom']),
}

export default Slideover
