/* eslint-disable react/prop-types */
import React from 'react'
import Select from 'react-select'
import getYear from 'date-fns/getYear'
import isValid from 'date-fns/isValid'

function YearFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const [selectedFilters, setSelectedFilters] = React.useState([])
  const options = React.useMemo(() => {
    const options = new Set()
    // eslint-disable-next-line react/prop-types
    preFilteredRows.forEach((row) => {
      const dt = new Date(row.values[id])
      if (!isValid(dt)) return
      options.add(getYear(dt))
    })
    return [...options.values()].sort()
  }, [id, preFilteredRows])

  const applyFilter = (filterList) => {
    setFilter(filterList.map((filter) => filter.value))
    setSelectedFilters(filterList || [])
  }

  React.useEffect(() => { if (!filterValue?.length) setSelectedFilters([]) }, [filterValue])

  return (
    <Select
      isMulti
      isSearchable={false}
      value={selectedFilters}
      options={options.map((option) => ({ value: option, label: option }))}
      onChange={applyFilter}
      className="text-gray-50 w-full"
    />
  )
}

export default YearFilter
