import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

interface SortingButtonsProps {
  currentSortingValue: string,
  handleClick: () => (value: string) => void,
  sortingValues: [`${string}_asc`, `${string}_desc`],
}

export default function SortingButtons({ handleClick, sortingValues, currentSortingValue }: SortingButtonsProps) {
  return (
    <div className="flex flex-col">
      {sortingValues.map((value) => (
        <button
          key={value}
          value={value}
          data-testid={value}
          onClick={() => handleClick()(value)}
          className="h-3 flex items-center"
        >
          <FontAwesomeIcon
            size="xs"
            icon={value.endsWith('asc') ? faChevronUp : faChevronDown}
            className={`cursor-pointer ${currentSortingValue === value ? 'text-accent' : 'text-gray-60'}`}
          />
        </button>
      ))}
    </div>
  )
}
