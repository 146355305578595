import React from 'react'
import PropTypes from 'prop-types'
import HistoryFeedbackItem from './history-feedback-item'

const HistoryFeedbackList = ({ list = [], removeble, currentTaskSubmissionId, onRemoveItem, type, startExpanded = false }) => {
  if (!list || list.length === 0) return null

  const checkIsRemovable = (item) => removeble && item.task_submission_id === currentTaskSubmissionId

  return (
    <ul className='flex flex-col'>
      {list?.map(item => <HistoryFeedbackItem key={`history:${item.type}:${item.comment_id}`} item={item} removeble={checkIsRemovable(item)} onRemove={onRemoveItem} type={type} startExpanded={startExpanded} />)}
    </ul>
  )
}
HistoryFeedbackList.propTypes = {
  list: PropTypes.array,
  removeble: PropTypes.bool,
  currentTaskSubmissionId: PropTypes.number,
  onRemoveItem: PropTypes.func,
  type: PropTypes.string,
  startExpanded: PropTypes.bool,
}

export default HistoryFeedbackList
