/* eslint-disable react/prop-types */
import React, { useState } from 'react'
// utils
import round from '../../../utils/round'
// content
import LearnAboutProgressCard from '../learn-about-progress-card'
import differenceInDays from 'date-fns/differenceInDays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

export default function MsPanel({ data }) {
  const [carouselPage, setCarouselPage] = useState(0)
  const handlePrevious = () => {
    setCarouselPage(prev => prev > 0 ? prev - 1 : prev)
  }
  const handleNext = () => {
    setCarouselPage(prev => prev <= 2 ? prev + 1 : prev)
  }
  const subjects = data?.subjects?.filter(sub => Number(sub.target) > 0) || []
  const expectedProgress = getExpectedProgress(data?.currentSchoolYear || {})
  const categoriesWithAbilities = data?.categoriesWithAbilities || []

  const getBarProgressBySubject = (subject_id) => {
    const subjectIds = subject_id.length ? subject_id : [subject_id]
    const selectedSubjects = subjects.filter(sub => subjectIds.includes(sub.id))

    const totalCompletions = selectedSubjects.reduce((acc, sub) => acc + Number(sub.completions), 0)
    const totalTarget = selectedSubjects.reduce((acc, sub) => acc + Number(sub.target), 0)

    return round((totalCompletions / totalTarget) * 100)
  }

  const SubjectProgressbar = ({ progress }) => {
    return (
      <div className="relative w-full h-6 rounded-full bg-gray-30 dark:bg-gray-60 overflow-hidden">
        <span
          style={{ width: progress + '%' }}
          className='absolute bg-turquoise-40 dark:bg-green-30 h-full block rounded-l-full'
        />
      </div>
    )
  }

  return (
    <div className="pb-12">
      <h1 className="group-[.flutter]/flutter:hidden font-bold text-4xl whitespace-nowrap text-bg-gray-90 mb-4">
        Year Progress
      </h1>
      <LearnAboutProgressCard type="ms" page="graduation" />

      <section className='bg-white dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg p-6 mt-6'>
        <header className='flex justify-between mb-6'>
          <div className='w-10'>
            {carouselPage > 0 && (
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                className="h-8 w-8 text-gray-100 hover:text-gray-90 dark:text-gray-5 dark:hover:text-gray-20 cursor-pointer"
                onClick={handlePrevious}
              />
            )}
          </div>
          <h2 className='font-bold text-xl'>
            {carouselPage === 0 && 'Units'}
            {carouselPage === 1 && 'Abilities'}
          </h2>
          <div className='w-10 text-right'>
            {carouselPage < 1 && (
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                className="h-8 w-8 text-gray-100 hover:text-gray-90 dark:text-gray-5 dark:hover:text-gray-20 cursor-pointer"
                onClick={handleNext}
              />
            )}
          </div>
        </header>

        {carouselPage === 0 && (
          <div className="flex flex-col gap-6 relative mb-4">
            {subjects.map(subject => (
              <div key={`carousel_subject_${subject.id}`} className='grid grid-cols-1 items-center'>
                <h2 className='col-span-1'>{subject.title}</h2>
                <div className='col-span-1'>
                  <SubjectProgressbar key={subject.id} progress={getBarProgressBySubject(subject.id)} />
                </div>
              </div>
            ))}
            <div
              className={`absolute h-full w-[1px] border-l-4 border-success-50 dark:border-success-40 border-dotted -top-1`}
              style={{ left: expectedProgress + '%' }}
            >
              <span className={`absolute -bottom-8 ${expectedProgress < 20 ? 'left-0' : 'right-0'} text-sm text-success-50 font-bold dark:text-success-40 whitespace-nowrap`}>
                Target ({expectedProgress + '%'})
              </span>
            </div>
          </div>
        )}
        {carouselPage === 1 && (
          <div className="flex flex-col gap-6 relative mb-4">
            {categoriesWithAbilities.map((category) =>
              category.abilities.map(ability => (
                <div key={`carousel_ability_${ability.id}`} className='grid grid-cols-1 items-center gap-2'>
                  <h2 className='col-span-1'>{ability.title}</h2>
                  <div className='col-span-1'>
                    <SubjectProgressbar key={ability.id} progress={round((ability.completions / ability.target) * 100)} />
                  </div>
                </div>
              ))
            )}
            <div
              className={`absolute h-full w-[1px] border-l-4 border-success-50 dark:border-success-40 border-dotted -top-1`}
              style={{ left: expectedProgress + '%' }}
            >
              <span className={`absolute -bottom-8 ${expectedProgress < 20 ? 'left-0' : 'right-0'} text-sm text-success-50 font-bold dark:text-success-40 whitespace-nowrap`}>
                Target ({expectedProgress + '%'})
              </span>
            </div>
          </div>
        )}
      </section>
    </div>
  )
}

const getExpectedProgress = (currentSchoolYear) => {
  if (!currentSchoolYear.school_year_start_timestamp) return 0

  const start = new Date(currentSchoolYear.school_year_start_timestamp)
  const end = new Date(currentSchoolYear.school_year_end_timestamp)
  const now = new Date()

  if (now <= start) return 0
  if (now >= end) return 100

  const totalDays = differenceInDays(start, end)
  const spentDays = differenceInDays(start, now)

  return parseInt(Math.abs(spentDays / totalDays) * 100)
}
