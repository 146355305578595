import { useState } from 'react'

const useForm = (initialFormValues) => {
  const [formValues, setFormValues] = useState(initialFormValues)

  const handleChange = event => {
    const { target: { name, value } } = event
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  return [formValues, handleChange, setFormValues]
}
export default useForm
