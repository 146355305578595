import queriedUrl from '@utils/queriedUrl'
import useSWR from 'swr'

const useGuardians = ({ student_ids } = {}) => {

  const { data, error } = useSWR(queriedUrl('/guardians', {
    student_ids
  }))
  return {
    guardians: data?.result || [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useGuardians
