import React from 'react'
import { LoaderFunctionArgs, useLoaderData, useParams, Form, useSubmit, Outlet, redirect } from 'react-router-dom'
import axios from 'axios'
import { Typography, Combobox, Avatar, cn } from '@design-system'
import pluralize from '@utils/pluralize'
import { QuickActionsCard } from './quick-actions-card'
import SoraLink from '@components/link'

interface HouseOverviewProps {
  quickActions: {
    emptyRequestListCount: number
    pendingSwapRequestsCount: number
  }
  upcomingExcuses: {
    id: number,
    studentId: number,
    studentName: string,
    studentImageUrl: string,
    title: string,
    subtitle: string,
  }[],
  reports: {
    id: number,
    title: string,
  }[]
}

async function loader({ params }: LoaderFunctionArgs) {
  const { houseId, reportId } = params
  const { data } = await axios.get(`/backoffice/houses/${houseId}/overview`)
  if (!reportId) {
    return redirect(`/houses/${houseId}/overview/${data.reports?.[0]?.id ?? 0}`)
  }
  return data
}

function Element() {
  const { houseId, reportId } = useParams()
  const submit = useSubmit()
  const {
    quickActions: {
      emptyRequestListCount,
      pendingSwapRequestsCount,
    },
    upcomingExcuses,
    reports
  } = useLoaderData() as HouseOverviewProps
  const selectedReport = reports.find(({ id }) => String(id) === reportId) ?? reports[0]

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const reportId = new FormData(e.currentTarget).get('report')
    submit(new FormData(), { method: 'get', action: `/houses/${houseId}/overview/${reportId}` })
  }

  return (
    <div className="space-y-8">
      <section className="space-y-4">
        <header>
          <Typography variant="heading-6" weight="bold">Quick Actions</Typography>
        </header>
        <nav className="grid md:grid-cols-3 gap-3">
          {pendingSwapRequestsCount > 0 && (
            <QuickActionsCard
              title="Swaps"
              subtitle={`Review ${pendingSwapRequestsCount} swap ${pluralize(pendingSwapRequestsCount, 'request')}`}
              to="/employee/expeditions/swap-requests"
            />
          )}
          {emptyRequestListCount > 0 && (
            <QuickActionsCard
              title="Empty Requests Lists"
              subtitle={`View ${emptyRequestListCount} ${pluralize(emptyRequestListCount, 'student')} with empty lists`}
              to={`/houses/${houseId}/students?filterBy=emptyRequestLists`}
            />
          )}
          <QuickActionsCard
            title="House Attendance"
            subtitle="Track House Attendance"
            to={`/houses/${houseId}/attendances`}
          />
        </nav>
      </section>
      <section className="space-y-4">
        <header className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
          <Typography variant="heading-6" weight="bold">Weekly House Report</Typography>
          <Form onChange={handleSubmit}>
            <Combobox defaultValue={String(selectedReport?.id)} name="report" search={<Combobox.Search placeholder="Search reports" />} className="min-w-64" >
              {reports.map(({ id, title }) => (
                <Combobox.Item key={id} value={String(id)}>{title}</Combobox.Item>
              ))}
            </Combobox>
          </Form>
        </header>
        <Outlet />
      </section>
      {upcomingExcuses.length > 0 && (
        <section>
          <div className="flex justify-between">
            <Typography variant="heading-6" weight="bold">Upcoming Absences</Typography>
          </div>
          <ul className="my-4">
            {upcomingExcuses.map(({ id, studentId, studentName, studentImageUrl, title, subtitle }) => (
              <li key={id} className="flex gap-4 py-6 border-t items-start border-gray-30 dark:border-gray-90 group">
                <SoraLink to={`/employee/students/${studentId}`} className="relative">
                  <Avatar size="xl">
                    <Avatar.Image src={studentImageUrl} alt={studentName} />
                    <Avatar.Fallback>{studentName}</Avatar.Fallback>
                  </Avatar>
                </SoraLink>
                <div className="flex flex-col md:flex-row md:items-center gap-4 grow">
                  <div className={cn("flex flex-col grow")}>
                    <Typography variant="body" weight="bold" className="flex items-center gap-1">
                      {studentName} {title}
                    </Typography>
                    <Typography variant="callout">
                      {subtitle}
                    </Typography>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
    </div>
  )
}

export const HouseOverviewRoute = {
  loader,
  Element,
}


