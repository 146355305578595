import React, { PropsWithChildren } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { TextOnlySlate } from '@components/forms/slate-textarea'

type CardPopoverItemProps = {
  title: string,
  label: string,
}

function CardPopoverItem({ title, label }: CardPopoverItemProps) {
  return (
    <span
      title={title}
      className="bg-white p-2 flex w-full border-b-1 border-gray-10 shadow-sm hover:bg-gray-10"
    >
      {label}
    </span>
  )
}

function CardPopover({ children }: PropsWithChildren) {
  return (
    <Popover className="relative">
      <Popover.Button className="font-bold text-xl">...</Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute z-10 right-0 border">
          <div className="flex flex-col">
            {children}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

type CardHeaderProps = PropsWithChildren<{
  title: string,
  subtitle?: string,
  tags?: React.ReactNode,
}>

function CardHeader({ children, title, subtitle, tags }: CardHeaderProps) {
  return (
    <div className="flex flex-row justify-between mb-1">
      <div className="flex flex-col items-start space-y-0">
        <h3 className="text-xl font-bold">{title}</h3>
        {subtitle && <small>{subtitle}</small>}
        {tags}
      </div>
      {children}
    </div>
  )
}

type CardBodyProps = {
  description: Array<unknown>,
  units: Array<{ id: number, title: string }>,
  abilities: Array<{ id: number, title: string }>,
  descriptionAction: () => void,
  title: string,
}

function CardBody({ title, description, units, abilities, descriptionAction }: CardBodyProps) {
  return (
    <>
      <TextOnlySlate
        value={description}
        placeholder="No description provided"
        parseText={(text) => {
          return text.length > 150 ? (
            <>
              {text.slice(0, 150) + '...'}
              <span
                className="cursor-pointer underline text-blue-70"
                onClick={descriptionAction}
              >
                see more
              </span>
            </>
          ) : (
            text
          )
        }}
      />
      <div className="grid grid-cols-2 gap-3">
        <div>
          <span className="font-bold">
            {'Units' + ` [${units?.length || 0}]`}
          </span>
          {units?.length > 0 ? (
            <ul
              className="pl-4 list-disc"
              aria-label={title + ' units'}
            >
              {units.slice(0, 2).map(({ id, title }) => (
                <li key={id}>{title}</li>
              ))}
              {units.slice(2).length ? (
                <span className="font-bold" role="listitem">{`and ${units.length - 2
                  } more`}</span>
              ) : null}
            </ul>
          ) : (
            <p>No units provided</p>
          )}
        </div>
        <div>
          <span className="font-bold">
            {'Abilities' + ` [${abilities?.length || 0}]`}
          </span>
          {abilities?.length > 0 ? (
            <ul
              className="pl-4 list-disc"
              aria-label={title + ' abilities'}
            >
              {abilities.slice(0, 3).map(({ id, title }) => (
                <li key={id}>{title}</li>
              ))}
              {abilities.slice(3).length ? (
                <span className="font-bold" role="listitem">{`and ${abilities.length - 3
                  } more`}</span>
              ) : null}
            </ul>
          ) : (
            <p>No abilities provided</p>
          )}
        </div>
      </div>
    </>
  )
}

function CardFooter({ children }: PropsWithChildren) {
  return <div>{children}</div>
}

function TemplateCard({ children }: PropsWithChildren) {
  return (
    <div
      className="p-4 border rounded-md min-h-[250px] border-gray-10 flex flex-col justify-between bg-white dark:bg-gray-95 shadow-light-500 space-y-3"
      data-cy="template-card"
    >
      {children}
    </div>
  )
}

TemplateCard.Popover = CardPopover
TemplateCard.PopoverItem = CardPopoverItem
TemplateCard.Header = CardHeader
TemplateCard.Body = CardBody
TemplateCard.Footer = CardFooter

export default TemplateCard
