import React from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router-dom'
import { Typography, Card, unstable_Tooltip as Tooltip } from '@design-system'
import { CardGraduationProgress, CardGraduationProgressVariant } from '@blocks/card-graduation-progress'
import SoraLink from '@components/link'

interface LoaderData {
  graduationProgress?: {
    status: CardGraduationProgressVariant
    statusUrl: string
    percentage: number
    gpaForecast: number
    gpaTooltip: string
  }
  unitsProgress: {
    total: number
    completed: number
    completedPercentage: number
    completedUrl: string
    toComplete: number
    toCompleteUrl: string
  }
  subjectsUnitsProgress: {
    id: number
    title: string
    totalUnits: number
    completedUnits: number
  }[]
}

export async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.student_id}/program/${params.schoolStage}/overview?${searchParams.toString()}`)
  return data
}

function Element() {
  const { graduationProgress, unitsProgress, subjectsUnitsProgress } = useLoaderData() as LoaderData
  return (
    <>
      {graduationProgress && (
        <Card>
          <Card.Content>
            <header className="border-b pb-4 mb-4 border-b-gray-20 dark:border-b-gray-90">
              <Typography color="secondary">Progress</Typography>
              <Typography variant="heading-6" weight="bold">🎓  Graduation</Typography>
            </header>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-stretch">
              <CardGraduationProgress variant={graduationProgress.status} url={graduationProgress.statusUrl} />
              <Tooltip content={graduationProgress.gpaTooltip}>
                <Card actionable className="flex justify-between items-center grow h-full" asChild>
                  <a href="https://drive.google.com/file/d/1e9KU_GwS8_EE-o93R6PIrthuEvo3cRn6/view" target="_blank" rel="noreferrer noopener">
                    <Card.Content className="space-y-1">
                      <Typography>GPA Forecast</Typography>
                      <Typography variant="heading-5" weight="bold">{graduationProgress.gpaForecast}</Typography>
                    </Card.Content>
                    <Card.Arrow />
                  </a>
                </Card>
              </Tooltip>
              <Tooltip content="Percentage of completed units and abilities">
                <Card className="flex justify-between items-center grow h-full">
                  <Card.Content className="space-y-1">
                    <Typography>Program Progress</Typography>
                    <Typography variant="heading-5" weight="bold">{graduationProgress.percentage}%</Typography>
                  </Card.Content>
                </Card>
              </Tooltip>
            </div>
          </Card.Content>
        </Card>
      )}
      <Card>
        <Card.Content>
          <header className="flex flex-col md:flex-row md:justify-between gap-2 items-start md:items-end border-b pb-4 mb-4 border-b-gray-20 dark:border-b-gray-90">
            <div>
              <Typography color="secondary">Overview</Typography>
              <Typography variant="heading-6" weight="bold">🔢  Units</Typography>
            </div>
            <Typography>Total: {unitsProgress.total} units</Typography>
          </header>
          <div className="relative rounded-lg overflow-hidden">
            <div className="bg-cyan-10 absolute inset-0" style={{ width: `${unitsProgress.completedPercentage}%` }}></div>
            <div className="flex z-10 relative">
              <Card className="border-r-0 rounded-r-none bg-transparent grow" asChild>
                <SoraLink to={unitsProgress.completedUrl} target="_blank">
                  <Card.Content>
                    <Typography>
                      Completed
                    </Typography>
                    <div className="flex gap-2">
                      <Typography variant="heading-5" weight="bold">
                        {unitsProgress.completed}
                      </Typography>
                      <Typography variant="heading-5">
                        ({unitsProgress.completedPercentage}%)
                      </Typography>
                    </div>
                  </Card.Content>
                </SoraLink>
              </Card>
              <Card className="border-l-0 rounded-l-none bg-transparent grow" asChild>
                <SoraLink to={unitsProgress.toCompleteUrl} target="_blank">
                  <Card.Content className="text-right">
                    <Typography>
                      To complete
                    </Typography>
                    <Typography variant="heading-5" weight="bold">
                      {unitsProgress.toComplete}
                    </Typography>
                  </Card.Content>
                </SoraLink>
              </Card>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:flex gap-2 mt-4">
            {subjectsUnitsProgress.map(({ id, title, completedUnits, totalUnits }) => (
              <Card actionable asChild key={id} className="grow flex-1 shrink-0 gap-0">
                <SoraLink to={`../${id}`}>
                  <Card.Content className="flex flex-col justify-center gap-0 pr-0">
                    <Typography className="whitespace-nowrap">
                      {title}
                    </Typography>
                    <div className="flex gap-0.5 items-center">
                      <Typography variant="heading-5" weight="bold">
                        {completedUnits}
                      </Typography>
                      <Typography variant="footnote" weight="bold" className="whitespace-nowrap">
                        / {totalUnits}
                      </Typography>
                    </div>
                  </Card.Content>
                  <Card.Arrow />
                </SoraLink>
              </Card>
            ))}
          </div>
        </Card.Content>
      </Card>
    </>
  )
}

export const EmployeeStudentProgramOverviewRoute = {
  Element,
  loader,
}


