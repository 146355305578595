import React from 'react'
import PropTypes from 'prop-types'
import capitalize from '@utils/capitalize'
import { getSubjectColors } from '@utils/subjectColors'

const SubjectSidebar = ({ sidebarItems }) => {
  const handleAnchorFollowing = (id) => {
    const element = document.querySelector(id)
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  return (
    <aside className="overflow-scroll">
      <ul className="space-y-4 pb-20" aria-label="exemptions subjects">
        {sidebarItems &&
          sidebarItems.map((item, index) => (
            <li
              key={item}
              className={`${getSubjectColors(
                index
              ).bg} p-4 text-white text-center rounded-lg font-bold w-40 cursor-pointer`}
              onClick={() => handleAnchorFollowing(`#${item.toLowerCase().split(' ').join('_')}`)}
            >
              <div>{capitalize(item)}</div>
            </li>
          ))}
      </ul>
    </aside>
  )
}

export default SubjectSidebar

SubjectSidebar.propTypes = {
  sidebarItems: PropTypes.arrayOf(PropTypes.string).isRequired,
}
