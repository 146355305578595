import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { default as AsyncSelect } from 'react-select/async'
import debounce from 'lodash/debounce'

const formatGroupLabel = (data) => (
  <div className="flex content-center justify-between">
    <span>{data?.label}</span>
    <span className="text-blue-70 bg-gray-10 h-4 w-4 rounded-full text-center align-middle">
      {data?.options.length}
    </span>
  </div>
)

const defaultSearchFunction = (options) =>
  debounce((input, callback) => {
    const searchedOptions = options.filter((option) => {
      if (typeof option.value === 'string') {
        return option.value.toUpperCase().includes(input.toUpperCase())
      }
      if (typeof option.value === 'number') {
        return Number(option.value) === Number(input) || option.label.toUpperCase().includes(input.toUpperCase())
      }
    })
    callback(searchedOptions)
  }, 500)

const Select = forwardRef(
  (
    {
      readOnly,
      isMulti,
      readOnlyNode,
      GroupHeaderComponent = formatGroupLabel,
      options,
      searchFunction,
      noOptionsMessage,
      onClickReadOnly,
      className,
      hasError,
      required,
      name,
      id = 'hidden-select-input',
      isSearchable = true,
      ...rest
    },
    ref
  ) => {
    if (readOnly)
      return (
        <div
          className={`outline-none cursor-text w-full border-b-2 border-dotted border-gray-40 hover:border-black ${className}`}
          onClick={onClickReadOnly}
        >
          {readOnlyNode}
        </div>
      )

    return (
      <>
        <AsyncSelect
          cacheOptions
          defaultOptions={options}
          formatGroupLabel={GroupHeaderComponent}
          loadOptions={searchFunction || defaultSearchFunction(options)}
          isMulti={isMulti}
          isSearchable={isSearchable}
          noOptionsMessage={noOptionsMessage}
          name={name}
          className={`  
            mt-0.5
            ${className}
          `}
          ref={ref}
          styles={{
            valueContainer: (base) => ({
              ...base,
              padding: '0.25rem 0.75rem',
            }),
            input: (base) => ({
              ...base,
              '> input': {
                boxShadow: 'none !important',
                fontSize: 'inherit',
              },
            }),
            indicatorsContainer: (base) => ({
              ...base,
              '> div': {
                padding: '0 8px',
              },
            }),
            menu: (base, state) => ({
              ...base,
              zIndex: 20,
            }),
            control: (base, state) => ({
              ...base,
              minHeight: '0',
              borderRadius: '0.5rem',
              outline: 'none',
              outlineOffset: '0',
              transition: 'none',
              '--tw-border-opacity': 1,
              borderColor: 'rgba(229, 231, 235, var(--tw-border-opacity))',
              '&:hover': {},
              ...(state.isFocused && {
                outlineOffset: '2px',
                '--tw-ring-inset': 'var(--tw-empty, )',
                '--tw-ring-offset-width': '0px',
                '--tw-ring-offset-color': '#fff',
                '--tw-ring-color': '#3182ce',
                '--tw-ring-offset-shadow':
                  'var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)',
                '--tw-ring-shadow':
                  'var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
                boxShadow:
                  'var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)',
                borderColor: hasError ? '#EF5777' : '#3182ce',
              }),
            }),
          }}
          {...rest}
        />
        <input
          className="opacity-0 h-0 w-0 absolute"
          id={id}
          onFocus={() => ref?.current && ref.current.focus()}
          defaultValue={
            !rest.value ||
              (Array.isArray(rest.value) && rest.value.filter(Boolean)?.length === 0)
              ? ''
              : 'FILLED'
          }
          required={required}
        />
      </>
    )
  }
)

Select.displayName = 'Select'

Select.defaultProps = {
  GroupHeaderComponent: formatGroupLabel,
  isMulti: false,
  noOptionsMessage: () => 'No options',
  className: '',
  onClickReadOnly: () => null,
  readOnlyNode: 'Click to select',
}

const optionsPropType = PropTypes.arrayOf(
  PropTypes.shape(() => ({
    label: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  }))
)

Select.propTypes = {
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  readOnlyNode: PropTypes.any,
  onClickReadOnly: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([
    optionsPropType,
    PropTypes.arrayOf(
      PropTypes.shape(() => ({
        label: PropTypes.any.isRequired,
        options: optionsPropType,
      }))
    ),
  ]),
  isSearchable: PropTypes.bool,
  searchFunction: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  hasError: PropTypes.bool,
  className: PropTypes.string,
  // React Nodes
  noOptionsMessage: PropTypes.func,
  GroupHeaderComponent: PropTypes.func,
  required: PropTypes.bool,
}

export default Select
