import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { toast } from 'sonner'
import SoraIcon, { soraAddCommentOutline, soraRewriteOutline, soraTrashOutline } from '@components/sora-icon'
import { RenderedSlate } from '@components/forms/short-slate-textarea'
import useConfirmModal from '@hooks/useConfirmModal'

const PresetCommentPopover = forwardRef(({ children, onSelectedPresetComment, onOpenManager, presetComments, refreshList }, ref) => {
  const confirm = useConfirmModal()
  const presetRef = useRef(null)
  const [presetShow, setPresetShow] = useState(false)


  useImperativeHandle(ref, () => ({
    open() {
      setPresetShow(true)
    }
  }))

  useEffect(() => {
    function handleClickOutside(event) {
      if (presetRef.current && !presetRef.current.contains(event.target)) setPresetShow(false)
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [presetRef])


  const handleSelectComment = (comment) => () => {
    onSelectedPresetComment(comment)
    setPresetShow(false)
  }

  const handleRemoveItem = (id) => async () => {
    if (id && await confirm('Are you sure you want to remove this preset?')) {
      try {
        await axios.delete(`/preset-comments/${id}`)
        toast.success('Comment successfully removed!')
      } catch (error) {
        toast.error('Save failed, please try again later!')
      }
      refreshList()
    }
  }

  const renderList = () => {
    return presetComments?.map(item => (
      <li key={`preset:commnet:popover:${item.id}`} className='group flex flex-row text-sm border-b border-b-gray-30 last:pb-0 last:border-b-0'>
        <div className='flex-1 flex flex-col cursor-pointer rounded p-1 hover:bg-gray-10' onClick={handleSelectComment(item.body)}>
          <span className='font-bold'>{item.title}</span>
          <div className='text-xs'>
            <RenderedSlate value={item.body} />
          </div>
        </div>
        <div className='flex flex-col gap-2 opacity-0 group-hover:opacity-100'>
          <div className='rounded-full cursor-pointer hover:bg-gray-10 p-2' onClick={() => { onOpenManager(item.id) }}>
            <SoraIcon icon={soraRewriteOutline} className='text-gray-40 w-4 h-4' />
          </div>
          <div className='rounded-full cursor-pointer hover:bg-gray-10 p-2' onClick={handleRemoveItem(item.id)}>
            <SoraIcon icon={soraTrashOutline} className='text-gray-40 w-4 h-4' />
          </div>
        </div>
      </li>
    ))
  }

  return (
    <div className='relative' ref={presetRef}>
      {presetComments?.length > 0 &&
        <div className={`absolute flex flex-col z-20 bottom-5 left-[-26.5rem] w-[26rem] min-h-fit max-h-[40rem] p-3 rounded-lg shadow-xl bg-gray-2 border overflow-y-auto ${presetShow ? '' : 'hidden'}`}>
          <ul className='flex flex-col gap-2'>
            {renderList()}
          </ul>
        </div>
      }
      {children}
      <div className='flex justify-center items-center z-10 bg-gray-10 hover:bg-gray-30 rounded-full w-10 h-10 bottom-2 left-2 cursor-pointer select-none mt-2' title="Manage Comments" onClick={() => { onOpenManager(0) }}>
        <SoraIcon icon={soraAddCommentOutline} className='text-gray-60 w-5 h-5' />
      </div>
    </div>
  )
})
PresetCommentPopover.displayName = 'PresetCommentPopover'
PresetCommentPopover.propTypes = {
  children: PropTypes.node,
  onSelectedPresetComment: PropTypes.func,
  onOpenManager: PropTypes.func,
  presetComments: PropTypes.array,
  refreshList: PropTypes.func
}

export default PresetCommentPopover
