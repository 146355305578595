import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@designsystem'
import { Labels } from './constants'
import getBrowserTimezoneAbbreviation from '@utils/getTimezoneAbbreviation'

const formatDueDate = (value) => {
  const date = new Date(value)
  return `${date.toLocaleString([], {
    month: 'numeric',
  })}/${date.toLocaleString([], {
    day: 'numeric',
  })}, ${date.toLocaleString([], {
    weekday: 'short',
  })} ${date.toLocaleString([], { hour: 'numeric', minute: 'numeric' })} (${getBrowserTimezoneAbbreviation()})`
}

const TaskList = ({ status, items, onClick }) => (
  <ul className="border-t border-b divide-y">
    <ReactTooltip delayShow={400} />
    {items.map(({ id, title, due_at }) => (
      <li key={id} className={`flex flex-row items-center text-xs gap-2 px-2 py-2 ${onClick ? 'cursor-pointer hover:bg-gray-20' : ''}`} onClick={onClick ? onClick(id) : null}>
        <span className="font-bold truncate" data-tip={title}>{title}</span>
        <span className="flex-shrink-0 flex-auto">Due {formatDueDate(due_at)}</span>
        {onClick && (
          <Button size="xs" tooltip="Open task" variant="nude" startIcon={faChevronRight} />
        )}
      </li>
    ))}
    {items.length == 0 && (
      <li className="flex flex-row items-center text-xs gap-2 px-2 py-2">
        <span className="flex-shrink-0 flex-auto">{items.length} {Labels[status]} found.</span>
      </li>
    )}
  </ul>
)

TaskList.propTypes = {
  status: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    due_at: PropTypes.string,
  })),
  onClick: PropTypes.func,
}

export default TaskList
