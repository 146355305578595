import { SoraNavLink } from '@components/link'
import { Typography, Avatar } from '@design-system'
import React from 'react'
import { useLocation, useNavigation, useResolvedPath } from 'react-router-dom'
import { Student } from './types'

const StudentTile = ({ student, to }: { student: Student; to: string }) => {
  const navigation = useNavigation()
  const location = useLocation()
  const resolvedPath = useResolvedPath(to)

  const pathname = navigation.location?.pathname || location.pathname
  const isActive = pathname.includes(resolvedPath.pathname)

  return (
    <Typography
      asChild
      variant="callout"
      className={` px-2 py-1 ${isActive ? 'bg-neutral-900/10' : 'hover:bg-neutral-900/5'} rounded-lg`}
    >
      <li>
        <SoraNavLink to={to} className={(active) => 'flex  items-center gap-2 ' + (active ? `font-bold` : '')}>
          <Avatar size="md">
            <Avatar.Image src={student.imageUrl} alt={student.name} />
            <Avatar.Fallback>
              {student.name.charAt(0)}
            </Avatar.Fallback>
          </Avatar>
          {student.name}
        </SoraNavLink>
      </li>
    </Typography>
  )
}

export default StudentTile
