import React from 'react'
import { useThisPageTitle } from '@hooks/usePageTitle'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import Tabs from '@components/tabs'
import { useLocation } from 'react-router-dom'

export const tabsData = [
  {
    path: "copy",
    label: "Copy",
  },
  {
    path: "start-dates",
    label: "Start dates",
  },
]

export default function AdmissionsConfig() {
  const navigate = useNavigateWithCycle()
  const location = useLocation()
  const currentTab = location.pathname.split('/').pop()
  const currentTabIndex = tabsData.findIndex((tab) => tab.path === currentTab)

  useThisPageTitle(`Admissions Config`)

  const handleOnTabChange = (selectedIndex) => {
    const { path } = tabsData[selectedIndex]
    navigate(path || "/")
  }

  return (
    <div className='flex flex-col space-y-4 h-full relative  bg-white p-4'>
      <Tabs
        tabInfo={tabsData}
        startIndex={currentTabIndex}
        onTabChange={(selectedIndex) => handleOnTabChange(selectedIndex)}
      />
    </div>
  )
}
