import React, { forwardRef, useState } from 'react'
import Select from '../select'
import matchMultipleProps from '../../../utils/filter/matchMultipleProps'
import groupBy from '../../../utils/reduce/groupBy'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import alphabetically from '../../../utils/sort/alphabetically'

function createGroupedOptions(studentsOptions) {
  const studentsGroupedByProgram = studentsOptions.reduce(groupBy('program_title'), {})
  return Object.entries(studentsGroupedByProgram).map(([program_title, students]) => ({
    label: program_title === 'undefined' ? 'Students' : program_title,
    options: students
  })).sort(alphabetically({ by: 'label' }))
}

const SelectStudentsProps = {
  students: PropTypes.array,
  selected_ids: PropTypes.array,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  selectAllEnabled: PropTypes.bool,
  readOnlyNode: PropTypes.any,
  onClickReadOnly: PropTypes.func,
  onClickGroup: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.any,
  id: PropTypes.any,
  isDisabled: PropTypes.bool
}

const SelectStudents = forwardRef(function ({
  students,
  selected_ids,
  onChange,
  isMulti,
  onClickGroup,
  selectAllEnabled,
  ...rest
}, ref) {
  const studentsOptions = students
    .map(student => ({
      ...student, label: student.name, value: student.id
    }))
    .sort(alphabetically({ by: 'label' }))

  let options = createGroupedOptions(studentsOptions)

  if (isMulti && selectAllEnabled) options = [{ label: 'All Students', value: 'ALL' }, ...options]

  const value = selected_ids.map(id => studentsOptions.find(option => option.id === id))

  const searchFunction = debounce((_, callback) => {
    const searchedStudents = studentsOptions.filter(matchMultipleProps(
      inputValue,
      ['name', 'first_name', 'last_name', 'program_title']
    ))
    callback(createGroupedOptions(searchedStudents))
  }, 500)

  const [inputValue, setInputValue] = useState()
  const onInputChange = (query, { action }) => {
    if (action !== 'set-value') setInputValue(query)
    return inputValue
  }

  const onChangeEvent = (selectedStudentsParam, object) => {
    if (isMulti) {
      if (selectAllEnabled) {
        const selectedStudent = selectedStudentsParam[0]
        if (selectedStudent?.value === 'ALL') {
          onChange(studentsOptions.map(option => option.id), studentsOptions, object)
          return
        }
      } else {
        const selectedStudents = selectedStudentsParam || []
        const orderedSelectedStudents = studentsOptions
          .filter(option => selectedStudents.some((selectedStudent) => selectedStudent.id === option.id))
        onChange(orderedSelectedStudents.map(option => option.id), orderedSelectedStudents, object)
      }
    } else {
      const selectedStudent = selectedStudentsParam || value[0]
      onChange(selectedStudent.id, selectedStudent)
    }
    setInputValue('')
    setInputValue(inputValue)
  }

  const onClickGroupEvent = (groupStudents) => {
    const groupStudentIds = groupStudents.map((groupStudent) => groupStudent.id)
    const allSelectedStudentsIds = Array.from(new Set([...selected_ids, ...groupStudentIds]))
    const orderedSelectedStudents = studentsOptions
      .filter(option => allSelectedStudentsIds.some((studentId) => studentId === option.id))
    onClickGroup(orderedSelectedStudents.map(option => option.id), orderedSelectedStudents)
  }

  return <Select
    isClearable
    options={options}
    searchFunction={searchFunction}
    isMulti={isMulti}
    value={value}
    placeholder="Type to search students"
    onChange={onChangeEvent}
    blurInputOnSelect={false}
    closeMenuOnSelect={false}
    inputValue={inputValue}
    onInputChange={onInputChange}
    GroupHeaderComponent={data => (
      <div
        className={`flex content-center justify-between text-md font-bold 
          ${onClickGroup ? 'cursor-pointer' : ''}`}
        onClick={() => onClickGroupEvent(data.options)}>
        <span>{data?.label}</span>
        <span className="bg-gray-10 h-4 w-4 rounded-full text-center align-middle">
          {data?.options.length}
        </span>
      </div>
    )}
    ref={ref}
    {...rest}
  />
})

SelectStudents.displayName = 'SelectStudents'

SelectStudents.defaultProps = {
  onClickGroup: () => null,
  selected_ids: [],
  selectAllEnabled: false,
}

SelectStudents.propTypes = SelectStudentsProps

export default SelectStudents
