import PropTypes from 'prop-types'
import React from 'react'

import { ReactComponent as DesignAndCreation } from '../assets/svgs/design-and-creation.svg'
import { ReactComponent as Elective } from '../assets/svgs/elective.svg'
import { ReactComponent as English } from '../assets/svgs/english.svg'
import { ReactComponent as FinancialLiteracy } from '../assets/svgs/financial-literacy.svg'
import { ReactComponent as ForeignLanguage } from '../assets/svgs/foreign-languages.svg'
import { ReactComponent as LifeSkills } from '../assets/svgs/life-skills.svg'
import { ReactComponent as Math } from '../assets/svgs/math.svg'
import { ReactComponent as Philosophy } from '../assets/svgs/philosophy.svg'
import { ReactComponent as Science } from '../assets/svgs/science.svg'
import { ReactComponent as SocialStudies } from '../assets/svgs/social-studies.svg'
import { getSubjectColors } from './subjectColors'

export const SubjectIcon = ({ id: subjectId, selected, className = '', ...rest }) => {
  const subjectColor = getSubjectColors(subjectId)
  const compountClassName = `fill-current inline h-full w-full text-xs ${selected ? 'text-white' : subjectColor.text} ${className}`
  switch (subjectId) {
    case 1:
      return <LifeSkills className={compountClassName} {...rest} />
    case 2:
      return <English className={compountClassName} {...rest} />
    case 5:
      return <Math className={compountClassName} {...rest} />
    case 6:
      return <Science className={compountClassName} {...rest} />
    case 7:
      return <SocialStudies className={compountClassName} {...rest} />
    case 8:
      return <Philosophy className={compountClassName} {...rest} />
    case 3:
      return <DesignAndCreation className={compountClassName} {...rest} />
    case 4:
      return <FinancialLiteracy className={compountClassName} {...rest} />
    case 10:
      return <ForeignLanguage className={compountClassName} {...rest} />
    case 17:
      return <Math className={compountClassName} {...rest} />
    case 18:
      return <English className={compountClassName} {...rest} />
    default:
      return <Elective className={compountClassName} {...rest} />
  }
}

SubjectIcon.propTypes = {
  id: PropTypes.any,
  className: PropTypes.string,
  selected: PropTypes.string
}
